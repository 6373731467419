import colors from "constants/colors";
import { ScoutNotification } from "models/ScoutNotification";
import { User } from "models/User";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { withFirestore } from "react-redux-firebase";
import { toast } from "react-toastify";
import { compose } from "recompose";
import { NotificationService } from "services/NotificationService";

function AcceptRequestModal(props: {
  firestore: any;
  onHide: any;
  request: ScoutNotification;
  me: User;
}) {
  const [loading, setLoading] = useState(false);

  const markRead = () => {
    props.onHide();
  };

  const acceptRequest = async () => {
    setLoading(true);

    if (!props.me.friends.includes(props.request.sender)) {
      await NotificationService.acceptFriendRequest(
        props.request,
        props.me,
        props.firestore
      );

      await NotificationService.markNotificationRemoved(
        props.request,
        props.firestore
      );

      setLoading(false);
      toast.dark(`You are now friends with ${props.request.senderName}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
      props.onHide();
    } else {
      setLoading(false);
      toast.dark(`You are already friends with ${props.request.senderName}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Accept friend request?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: colors.white, backgroundColor: colors.accent }}
      >
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <p>
            {props.request
              ? `Friend request from ${props.request.senderName}`
              : ""}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => markRead()}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            acceptRequest();
          }}
        >
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(AcceptRequestModal);
