import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function LabelledDatePicker(props: {
  date: Date;
  setDate: any;
  containerStyles?: any;
  showTime?: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...props.containerStyles,
      }}
    >
      <p>Date</p>
      {/* @ts-ignore */}
      <DatePicker
        showTimeSelect={props.showTime ? props.showTime : false}
        dateFormat={props.showTime ? "Pp" : "MM/dd/yyyy"}
        selected={props.date}
        onChange={(date) => props.setDate(date)}
      />
    </div>
  );
}

export default LabelledDatePicker;
