import colors from "constants/colors";
import React, { useState } from "react";

function ThreeBoxRow({ label, p, f, adj, comments }) {
  const colorForValue = (value: string) => {
    if (label && label !== "") {
      if (value) {
        switch (parseInt(value)) {
          case 2:
            return "#4A3D3D";
          case 3:
            return "#DB2330";
          case 4:
            return "#DB8E33";
          case 5:
            return "#80B304";
          case 6:
            return "#6690FF";
          case 7:
            return "#F5EE48";
          case 8:
            return "rgba(0, 0, 0, 0.4)";
          default:
            return "transparent";
        }
      }
      return "transparent";
    }
    return "transparent";
  };

  return (
    <tr>
      <td
        style={{
          width: "50%",
          textAlign: "left",
          padding: "2px 4px",
          border: "1px solid black",
        }}
      >
        {label}
      </td>
      <td
        style={{
          width: "16.6%",
          backgroundColor: colorForValue(p),
          padding: "2px 4px",
          border: "1px solid black",
          textAlign: "center",
        }}
      >
        {p}
      </td>
      <td
        style={{
          width: "16.6%",
          backgroundColor: colorForValue(f),
          padding: "2px 4px",
          border: "1px solid black",
          textAlign: "center",
        }}
      >
        {f}
      </td>
      <td
        style={{
          width: "16.6%",
          backgroundColor: colorForValue(adj),
          padding: "2px 4px",
          border: "1px solid black",
          textAlign: "center",
        }}
      >
        {adj}
      </td>
    </tr>
  );
}

export default ThreeBoxRow;
