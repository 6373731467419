import React, { useState } from "react";
import colors from "../../../constants/colors";
import { Text, View } from "@react-pdf/renderer";

function PDFOneBoxRowComments({ label, value, comments }) {
  const colorForValue = (value: string) => {
    if (value) {
      switch (parseInt(value)) {
        case 2:
          return "#4A3D3D";
        case 3:
          return "#DB2330";
        case 4:
          return "#DB8E33";
        case 5:
          return "#80B304";
        case 6:
          return "#6690FF";
        case 7:
          return "#F5EE48";
        case 8:
          return "rgba(0, 0, 0, 0.4)";

        default:
          return colors.lightGray;
      }
    }
    return colors.lightGray;
  };

  return (
    <View style={{ display: "flex", flexDirection: "column" }}>
      <View
        style={{
          flexDirection: "row",
          display: "flex",
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: 10,
            borderColor: colors.softDarkText,
            borderWidth: 1,
            flex: 1,
            padding: 4,
            borderRightWidth: 0,
            borderStyle: "solid",
          }}
        >
          {label}
        </Text>

        <View
          style={{
            flex: 1,
            borderColor: colors.softDarkText,
            borderWidth: 1,
            padding: 4,

            borderStyle: "solid",
            backgroundColor: colorForValue(value),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {value}
          </Text>
        </View>
      </View>

      {comments && comments !== "" ? (
        <Text
          style={{
            fontWeight: "normal",
            fontSize: 10,
            backgroundColor: colors.lightestBlue,
            padding: 4,
            marginBottom: 10,
            color: colors.softDarkText,
          }}
        >
          {comments}
        </Text>
      ) : (
        <Text
          style={{
            fontStyle: "italic",
            fontSize: 10,
            backgroundColor: colors.lightestBlue,
            padding: 4,
            fontWeight: "normal",
            marginBottom: 10,
            color: colors.softDarkText,
          }}
        >
          N/A
        </Text>
      )}
    </View>
  );
}

export default PDFOneBoxRowComments;
