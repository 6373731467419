import React, { useState } from "react";
import { PlayerService } from "services/PlayerService";
import "../search-results.css";
import { Link } from "react-router-dom";
import PlayerAvatar from "shared/player-avatar";
import StarButton from "shared/star-button";
import PlayerName from "popovers/player-name";
import colors from "constants/colors";
import { QueryHelper } from "services/QueryHelper";
import { CollegePlayerIcon } from "components/smart-icons";
import CTAButton from "shared/cta-button";
import { Button } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillLock,
  AiOutlineDownload,
} from "react-icons/ai";
import { useFirestore } from "react-redux-firebase";
import { useMe } from "@tara-wilson/mcc-lib";
import moment from "moment";
import * as XLSX from "xlsx";

function ResultItem({
  player,
  index,
  onlyCollege,
  shouldLoad,
  addToRoster,
  onAddToRoster,
  activeRosterIds,
  onRemoveFromRoster,
  disabledMode,
}: {
  player: any;
  index: number;
  onlyCollege: boolean;
  shouldLoad: boolean;
  addToRoster?: boolean;
  onAddToRoster?: any;
  onRemoveFromRoster?: any;
  activeRosterIds?: string[];
  disabledMode: "pbr_disabled" | "pg_disabled" | "both" | "none";
}) {
  const firestore = useFirestore();
  const me = useMe();
  const [updatedPlayer, setUpdatedPlayer] = useState(null);
  const [fetching, setFetching] = useState(false);

  const fetchDataForURLParams = async () => {
    let lastUpdated = player.lastStatUpdate
      ? moment(new Date(player.lastStatUpdate.seconds * 1000))
      : null;
    let updatedToday = lastUpdated
      ? lastUpdated.isAfter(moment().startOf("day"))
      : false;
    if (updatedToday) {
      setFetching(true);
    }
    if (!fetching) {
      setFetching(true);
      let p = await QueryHelper.getPlayerForExternalId(player.external_id);
      if (p && p.data && p.data.length > 0) {
        let players = p.data.filter(
          (item) => item.external_id === player.external_id
        );
        if (players.length > 0) {
          let pl = players[0];
          let newPlayer = {
            ...player,
            ...pl,
            primary_position: player.primary_position,
            summer_team: player.summer_team,
          };
          Object.keys(newPlayer).forEach((key) => {
            if (newPlayer[key] === undefined) {
              delete newPlayer[key];
            }
          });
          setUpdatedPlayer(newPlayer);
          checkPlayerUpdate(player, newPlayer);
          setFetching(false);
        }
      } else {
        setUpdatedPlayer(player);
        setFetching(false);
      }
    }
  };

  const checkPlayerUpdate = async (player, updatedPlayer) => {
    let needsUpdate = false;

    let lastUpdated = player.lastStatUpdate
      ? moment(new Date(player.lastStatUpdate.seconds * 1000))
      : null;
    let updatedToday = lastUpdated
      ? lastUpdated.isAfter(moment().startOf("day"))
      : false;
    if (!updatedToday) {
      Object.keys(player).forEach((key) => {
        if (player[key] != updatedPlayer[key]) {
          if (
            key != "pgData" &&
            key != "prepData" &&
            key != "score" &&
            key != "yearlyStats"
          ) {
            if (!needsUpdate) {
              console.log(
                "update for",
                player.name,
                key,
                "was: ",
                player[key],
                "should be: ",
                updatedPlayer[key]
              );
            }
            needsUpdate = true;
          }
        }
      });
      if (needsUpdate) {
        console.log("update", player.id);
        firestore
          .collection("users")
          .doc(me.id)
          .collection("prospects")
          .doc(player.id)
          .update({ ...updatedPlayer, lastStatUpdate: new Date() });
      }
    }
  };

  if (shouldLoad && updatedPlayer === null) {
    setUpdatedPlayer(player);
    fetchDataForURLParams();
  } else if (!shouldLoad && updatedPlayer === null) {
    setUpdatedPlayer(player);
  }

  const exportSpreadsheet = () => {
    if (!player) {
      return;
    }

    const data = [
      {
        "FIRST NAME": player.first_name,
        "LAST NAME": player.last_name,
        CLASS: player.graduating_class,
        POSITION: player.position,
        "HIGH SCHOOL": player.high_school,
        STATE: player.state,
        BAT: player.bats,
        THROW: player.throws,
        HEIGHT: player.height,
        WEIGHT: player.weight,
        TWITTER: player.twitter_handle,
        "PHONE NUMBER": player.contact_phone,
        //"EMAIL": player.email,
        "SUMMER TEAM": player.summer_team,
      },
    ];

    console.log(Object.keys(data[0]));
    //@ts-ignore
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    const widths = Object.keys(data[0]).map((key) =>
      Math.max(key.length, 10, (data[0][key] || "").length)
    );
    console.log(widths);
    worksheet["!cols"] = widths.map((width) => ({ wch: width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workbook,
      `${PlayerService.getName(player)}-${player.id}.xlsx`
    );
  };

  if (addToRoster && updatedPlayer) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: index % 2 === 1 ? colors.white : colors.lightGray,
          width: "45vw",
        }}
        key={index}
      >
        <div
          style={{
            width: 50,
            minWidth: 50,
            maxWidth: 50,
          }}
        >
          <PlayerAvatar
            player={updatedPlayer}
            blueBackground={true}
            containerStyles={{}}
          />
        </div>
        <div
          style={{
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StarButton player={updatedPlayer} size={18} />
        </div>
        <div
          style={{
            flex: 1,
            width: 200,
            minWidth: 200,
            maxWidth: 200,
            paddingTop: 4,
          }}
        >
          <PlayerName
            player={updatedPlayer}
            skipPosition={true}
            disabledMode={disabledMode}
          />
          {updatedPlayer.college && !onlyCollege ? (
            <CollegePlayerIcon
              player={updatedPlayer}
              color={colors.lightBlue}
            />
          ) : (
            <div />
          )}
        </div>
        <div
          style={{
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            justifyContent: "center",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <p className="row-p" style={{ fontWeight: "bold" }}>
            {PlayerService.getPosition(updatedPlayer)}
          </p>
        </div>

        <div
          style={{
            width: 125,
            minWidth: 125,
            maxWidth: 125,
            justifyContent: "center",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <p
            className="row-p"
            style={onlyCollege ? {} : { textAlign: "center" }}
          >
            {updatedPlayer.college
              ? updatedPlayer.academic_class
              : updatedPlayer.graduating_class
              ? updatedPlayer.graduating_class
              : "N/A"}
          </p>
        </div>

        <div style={{ flex: 1 }} />

        {updatedPlayer &&
        (activeRosterIds || []).includes(updatedPlayer.external_id) ? (
          <Button
            style={{
              justifySelf: "center",
              alignSelf: "center",
              backgroundColor: colors.lightBlue,
              width: 120,
              maxWidth: 120,
              height: 26,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 0,
              marginRight: 20,
            }}
            onClick={() => onRemoveFromRoster(updatedPlayer)}
          >
            <AiFillCheckCircle style={{ color: "#fff", fontSize: 20 }} />
          </Button>
        ) : (
          <CTAButton
            text="ADD"
            styles={{ marginRight: 20, width: 120, maxWidth: 120 }}
            fontStyles={{ fontSize: 16 }}
            onClick={() => onAddToRoster(updatedPlayer)}
          />
        )}
      </div>
    );
  }
  return updatedPlayer ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 8,
        backgroundColor: index % 2 === 1 ? colors.white : colors.lightGray,
        minWidth: onlyCollege ? 700 : 1350,
      }}
      key={index}
    >
      <div
        style={{
          width: 50,
          minWidth: 50,
        }}
      >
        <PlayerAvatar
          player={updatedPlayer}
          blueBackground={true}
          containerStyles={{}}
        />
      </div>
      <div
        style={{
          width: 30,
          minWidth: 30,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StarButton player={updatedPlayer} size={18} />
      </div>
      <div
        style={{
          flex: 1,
          minWidth: 200,
          maxWidth: 250,
          paddingTop: 4,
        }}
      >
        <PlayerName
          player={updatedPlayer}
          skipPosition={true}
          disabledMode={disabledMode}
        />
        {updatedPlayer.college && !onlyCollege ? (
          <CollegePlayerIcon
            color={colors.lightBlue}
            player={updatedPlayer}
          />
        ) : (
          <div />
        )}
      </div>
      <div
        style={{
          width: 95,
          minWidth: 95,
        }}
      >
        <p className="row-p" style={{ fontWeight: "bold" }}>
          {updatedPlayer.college
            ? updatedPlayer.position
            : updatedPlayer.primary_position
            ? updatedPlayer.alt_position
              ? `${updatedPlayer.primary_position}, ${updatedPlayer.alt_position}`
              : `${updatedPlayer.primary_position}`
            : "N/A"}
        </p>
      </div>
      <div
        style={{
          width: 75,
          minWidth: 75,
        }}
      >
        <p className="row-p">
          {updatedPlayer.height ? updatedPlayer.height : "N/A"}
        </p>
      </div>
      <div
        style={{
          width: 75,
          minWidth: 75,
        }}
      >
        <p className="row-p">
          {updatedPlayer.weight ? `${updatedPlayer.weight}lbs` : "N/A"}
        </p>
      </div>

      <div
        style={{
          width: onlyCollege ? 125 : 110,
          minWidth: onlyCollege ? 125 : 110,
        }}
      >
        <p className="row-p" style={onlyCollege ? {} : { textAlign: "center" }}>
          {updatedPlayer.college
            ? updatedPlayer.academic_class
            : updatedPlayer.graduating_class
            ? updatedPlayer.graduating_class
            : "N/A"}
        </p>
      </div>

      {onlyCollege ? (
        <div />
      ) : (
        <div
          style={{
            width: 70,
            minWidth: 70,
          }}
        >
          <p style={{ whiteSpace: "nowrap" }}>
            {updatedPlayer.state ? `${updatedPlayer.state}` : ""}
          </p>
        </div>
      )}

      {onlyCollege ? (
        <div />
      ) : disabledMode != "both" ? (
        <div
          style={{
            marginLeft: -15,
            width: 120,
            minWidth: 120,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: 20 }} />
          {disabledMode == "none" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.national_overall_ranking || ""}
            </p>
          ) : disabledMode == "pbr_disabled" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pg_national_overall_ranking || ""}
            </p>
          ) : (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pbr_national_overall_ranking || ""}
            </p>
          )}

          {disabledMode == "none" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.national_overall_ranking ? "|" : ""}
            </p>
          ) : disabledMode == "pbr_disabled" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pg_national_overall_ranking ? "|" : ""}
            </p>
          ) : (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pbr_national_overall_ranking ? "|" : ""}
            </p>
          )}

          {disabledMode == "none" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.national_position_ranking || ""}
            </p>
          ) : disabledMode == "pbr_disabled" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pg_national_position_ranking || ""}
            </p>
          ) : (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pbr_national_position_ranking || ""}
            </p>
          )}

          <div style={{ width: 20 }} />
        </div>
      ) : (
        <div
          style={{
            marginLeft: -15,
            width: 120,
            minWidth: 120,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AiFillLock style={{ width: 35, height: 20, color: colors.accent }} />
        </div>
      )}

      {onlyCollege ? (
        <div />
      ) : disabledMode != "both" ? (
        <div
          style={{
            width: 120,
            minWidth: 120,
            display: "flex",
            flexDirection: "row",
            marginLeft: -5,
            marginRight: 20,
          }}
        >
          <div style={{ width: 20 }} />
          {disabledMode == "none" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.state_overall_ranking || ""}
            </p>
          ) : disabledMode == "pbr_disabled" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pg_state_overall_ranking || ""}
            </p>
          ) : (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pbr_state_overall_ranking || ""}
            </p>
          )}

          {disabledMode == "none" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.state_overall_ranking ? "|" : ""}
            </p>
          ) : disabledMode == "pbr_disabled" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pg_state_overall_ranking ? "|" : ""}
            </p>
          ) : (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pbr_state_overall_ranking ? "|" : ""}
            </p>
          )}

          {disabledMode == "none" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.state_position_ranking || ""}
            </p>
          ) : disabledMode == "pbr_disabled" ? (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pg_state_position_ranking || ""}
            </p>
          ) : (
            <p style={{ width: 35, textAlign: "center" }}>
              {updatedPlayer.pbr_state_position_ranking || ""}
            </p>
          )}

          <div style={{ width: 20 }} />
        </div>
      ) : (
        <div
          style={{
            width: 120,
            minWidth: 120,
            display: "flex",
            flexDirection: "row",
            marginLeft: -5,
            marginRight: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AiFillLock style={{ width: 35, height: 20, color: colors.accent }} />
        </div>
      )}

      {onlyCollege ? (
        <div />
      ) : (
        <div
          style={{
            flex: 1,
            minWidth: 200,
          }}
        >
          {updatedPlayer.college ? (
            <div />
          ) : updatedPlayer.summer_team &&
            updatedPlayer.summer_team !== "NA" ? (
            <Link
              to={`/search-results?summerTeam=${updatedPlayer.summer_team}`}
              style={{
                color: colors.accent,

                textDecoration: "underline",
                textDecorationColor: colors.accent,
                textDecorationStyle: "solid",
                padding: 0,
              }}
            >
              <p
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxLines: 1,
                  marginRight: 5,
                }}
              >
                {updatedPlayer.summer_team}
              </p>
            </Link>
          ) : (
            <p>N/A</p>
          )}
        </div>
      )}

      <div
        style={{
          flex: 1,
          minWidth: updatedPlayer.college ? 100 : 200,
        }}
      >
        {updatedPlayer.college ? (
          <Link
            to={`/search-results?asCollege=true&university=${updatedPlayer.university}`}
            style={{
              color: colors.accent,

              textDecoration: "underline",
              textDecorationColor: colors.accent,
              textDecorationStyle: "solid",
              padding: 0,
            }}
          >
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxLines: 1,
                marginRight: 5,
              }}
            >
              {updatedPlayer.university}
            </p>
          </Link>
        ) : updatedPlayer.college_commitment &&
          updatedPlayer.college_commitment !== "Uncommited" ? (
          <Link
            to={`/search-results?uncommitted=Committed&commitment=${updatedPlayer.college_commitment}`}
            style={{
              color: colors.accent,

              textDecoration: "underline",
              textDecorationColor: colors.accent,
              textDecorationStyle: "solid",
              padding: 0,
            }}
          >
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxLines: 1,
                marginRight: 5,
              }}
            >
              {updatedPlayer.college_commitment}
            </p>
          </Link>
        ) : (
          <p>
            {updatedPlayer.college
              ? ""
              : updatedPlayer.college_commitment
              ? updatedPlayer.college_commitment
              : "N/A"}
          </p>
        )}
      </div>
      <div
        onClick={exportSpreadsheet}
        style={{ marginLeft: -15, cursor: "pointer", marginRight: 8 }}
      >
        <AiOutlineDownload style={{ color: colors.accent }} />
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 8,
        backgroundColor: index % 2 === 1 ? colors.white : colors.lightGray,
        overflow: "hidden",
      }}
      key={index}
    ></div>
  );
}

export default ResultItem;
