import React from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import colors from "constants/colors";
import ScoutTitle from "./scout-title";
import moment from "moment";
import CTAButton from "./cta-button";
import CTALink from "./cta-link";

function SidebarEvents(props: any) {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const myEvents = useSelector((state) => state.firestore.data.myEvents);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "events" }],
      storeAs: `myEvents`,
    },
  ]);

  const todaysGames = (): any[] => {
    if (!isLoaded(myEvents)) {
      return [];
    }

    let events = myEvents
      ? Object.keys(myEvents).map((key) =>
          myEvents ? { ...myEvents[key], id: key } : null
        )
      : [];

    events = events.filter(
      (item) =>
        item.eventDate &&
        item.eventDate.seconds &&
        moment(new Date(item.eventDate.seconds * 1000)).isAfter(
          moment().startOf("day")
        ) &&
        moment(new Date(item.eventDate.seconds * 1000)).isBefore(
          moment().endOf("day")
        )
    );

    events.sort(function (a, b) {
      if (
        a.eventDate &&
        a.eventDate.seconds &&
        b.eventDate &&
        b.eventDate.seconds
      ) {
        if (a.eventDate.seconds > b.eventDate.seconds) return 1;
        if (a.eventDate.seconds < b.eventDate.seconds) return -1;
      }
      return 0;
    });

    return events.filter((item) => item !== null);
  };

  const sortedEvents = (): any[] => {
    if (!isLoaded(myEvents)) {
      return [];
    }

    let events = myEvents
      ? Object.keys(myEvents).map((key) =>
          myEvents ? { ...myEvents[key], id: key } : null
        )
      : [];

    events = events.filter(
      (item) =>
        item.eventDate &&
        item.eventDate.seconds &&
        moment(new Date(item.eventDate.seconds * 1000)).isAfter(
          props.sidebar ? moment().startOf("day") : moment().endOf("day")
        )
    );

    events.sort(function (a, b) {
      if (
        a.eventDate &&
        a.eventDate.seconds &&
        b.eventDate &&
        b.eventDate.seconds
      ) {
        if (a.eventDate.seconds > b.eventDate.seconds) return 1;
        if (a.eventDate.seconds < b.eventDate.seconds) return -1;
      }
      return 0;
    });

    return events.filter((item) => item !== null).splice(0, 4);
  };

  const renderTodayEvent = (item, index) => {
    let locString = `${item.street || ""}+${item.city}+${item.state}+${
      item.zip
    }`.replaceAll(" ", "+");
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 8,
          padding: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 800, fontSize: 18, margin: 4 }}>VS.</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 6,
            }}
          >
            <p style={{ fontWeight: "bold" }}>{item.teamOne || "Team One"}</p>
            <p style={{ color: colors.accent, fontWeight: "bold" }}>
              {item.teamTwo || "Team Two"}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 12,
              }}
            >
              <p style={{ fontWeight: "bold" }}>Location</p>
              {item.street ? (
                <p style={{ fontStyle: "italic" }}>{`${item.street}, ${
                  item.city
                }, ${item.state} ${item.zip || ""}`}</p>
              ) : (
                <div />
              )}
            </div>
            <CTAButton
              text="MAP"
              styles={{ paddingRight: 20, paddingLeft: 20 }}
              onClick={() => {
                window.open(
                  `https://maps.google.com/?q=${locString}`,
                  "_blank"
                );
              }}
            />
          </div>

          <p style={{ fontWeight: "bold", marginTop: 6 }}>Date</p>
          <p style={{ fontStyle: "italic" }}>
            {moment(new Date(item.eventDate.seconds * 1000)).format(
              "MM/DD hh:mm a"
            )}
          </p>
        </div>
      </div>
    );
  };

  const renderEvent = (item, index) => {
    let location =
      item.game_title && item.game_title !== ""
        ? item.game_title.split(" No ")[0]
        : `${item.city && item.city !== "" ? item.city : ""}${
            item.city && item.city !== "" ? ", " : ""
          }${item.state || ""}`;

    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",

          marginBottom: 8,
          backgroundColor: index % 2 === 1 ? colors.white : colors.lightGray,
          padding: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontStyle: "italic",
          }}
        >
          <p>
            {moment(new Date(item.eventDate.seconds * 1000)).format(
              "MM/DD hh:mm a"
            )}
          </p>
          <p>{location}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 800, fontSize: 18, margin: 4 }}>VS.</p>
          <div
            style={
              props.sidebar
                ? {
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 6,
                    maxWidth: 230,
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 6,
                  }
            }
          >
            <p style={{ fontWeight: "bold" }}>{item.teamOne || "Team One"}</p>
            <p style={{ color: colors.accent, fontWeight: "bold" }}>
              {item.teamTwo || "Team Two"}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderTodaysGames = () => {
    if (todaysGames().length > 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Link to="/calendar">
            <ScoutTitle text="Games Today" styles={{ paddingBottom: 0 }} />
          </Link>
          <div
            style={{
              padding: "1rem",
              paddingBottom: 0,

              display: "flex",
              flexDirection: "column",
            }}
          >
            {(todaysGames() || []).map((item, index) => {
              return renderTodayEvent(item, index);
            })}
          </div>
        </div>
      );
    }
    return <div />;
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      {props.sidebar ? <div /> : renderTodaysGames()}

      <Link to="/calendar">
        <ScoutTitle text="Upcoming Games" styles={{ paddingBottom: 0 }} />
      </Link>

      {(sortedEvents() || []).length > 0 ? (
        <div
          style={{
            flex: 1,
            backgroundColor: colors.white,
            padding: "1rem",
            paddingBottom: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(sortedEvents() || []).map((item, index) => {
            return renderEvent(item, index);
          })}
          <CTALink
            styles={{ marginBottom: 12, marginTop: 12 }}
            linkTo={`/calendar`}
            text={"VIEW ALL"}
          />
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            minHeight: 80,
            backgroundColor: colors.white,
            padding: "1rem",
            borderRadius: 6,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontStyle: "italic", opacity: 0.8 }}>
            No upcoming events.
          </p>
        </div>
      )}
    </div>
  );
}

export default SidebarEvents;
