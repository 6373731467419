import React, { useState } from "react";
import colors from "constants/colors";

function ReportTop({ posReport }) {
  const positionTable = () => {
    return (
      <table style={{ width: "100%" }}>
        <tr>
          <th
            style={{
              width: "50%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Future Position
          </th>
          <th
            style={{
              width: "50%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Round
          </th>
        </tr>
        <tr>
          <td
            style={{
              padding: "45px 0px",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.roleFuturePosition}
          </td>
          <td
            style={{
              padding: "45px 0px",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.round}
          </td>
        </tr>
      </table>
    );
  };

  const gamesTable = () => {
    return (
      <table style={{ width: "100%" }}>
        <tr>
          <th
            style={{
              width: "33%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Games
          </th>
          <th
            style={{
              width: "33%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Innings
          </th>
          <th
            style={{
              width: "33%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            ABs
          </th>
        </tr>
        <tr>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.games}
          </td>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.innings}
          </td>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.aBs}
          </td>
        </tr>
      </table>
    );
  };

  const rawTable = () => {
    return (
      <table style={{ width: "100%" }}>
        <tr>
          <th
            style={{
              width: "25%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Raw
          </th>
          <th
            style={{
              width: "25%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Adj.
          </th>
          <th
            style={{
              width: "25%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Current
          </th>
          <th
            style={{
              width: "25%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Future
          </th>
        </tr>
        <tr>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.raw}
          </td>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.adj}
          </td>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.current}
          </td>
          <td
            style={{
              padding: 8,
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {posReport.future}
          </td>
        </tr>
      </table>
    );
  };

  return (
    <table style={{ marginBottom: 20, width: "100%" }}>
      <tr>
        <td
          style={{
            border: "none",
            textAlign: "center",
            marginRight: 20,
          }}
        >
          {positionTable()}
        </td>
        <td style={{ border: "none", textAlign: "center" }}>
          <p style={{ width: 10 }} />
        </td>
        <td style={{ border: "none", textAlign: "center" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ border: "none", textAlign: "center" }}>
                {gamesTable()}
              </td>
            </tr>
            <tr>
              <td style={{ border: "none", textAlign: "center" }}>
                <p style={{ height: 5 }} />
              </td>
            </tr>
            <tr>
              <td style={{ border: "none", textAlign: "center" }}>
                {rawTable()}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  );
}

export default ReportTop;
