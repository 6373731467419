import React, { useState } from "react";
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PositionReport } from "models/PositionReport";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import colors from "constants/colors";
import ImagesList from "./components/images-list";
import VideosList from "./components/videos-list";
import PositionProReport from "./displays/position-pro-report";
import { Link } from "react-router-dom";
import GeneralReport from "./displays/general-report";
import NotesReport from "./displays/notes-report";
import PitcherReport from "./displays/pitcher-report";
import LoadingView from "shared/loading-view";
import ShareReportModal from "modals/share-report-modal";
import * as XLSX from "xlsx";
import AreYouSureModal from "modals/are-you-sure-modal";
import RelatedGamesList from "./components/related-games-list";

function DisplayReport(props: any) {
  let { reportId } = useParams();
  const userId = useSelector((state) => state.firebase.auth.uid);
  const currentReport = useSelector<PositionReport>(
    (state) => state.firestore.data.currentReport
  );
  const [modalShow, setModalShow] = React.useState(false);
  const [copyModalShow, setCopyModalShow] = useState(false);
  const firestore = useFirestore();
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: reportId }],
      storeAs: "currentReport",
    },
  ]);

  if (!isLoaded(currentReport)) {
    return <LoadingView />;
  }

  const renderPositionReport = () => {
    if (currentReport.kind === "PRO") {
      return <PositionProReport posReport={currentReport} />;
    }
    return <div />;
  };

  const renderPitcherReport = () => {
    if (currentReport.kind === "PITCHER") {
      return <PitcherReport posReport={currentReport} />;
    }
    return <div />;
  };

  const renderGeneralReport = () => {
    if (currentReport.kind === "GENERAL") {
      return (
        <div style={{ marginBottom: 20 }}>
          <GeneralReport posReport={currentReport} />
        </div>
      );
    }
    return <div />;
  };

  const renderNotes = () => {
    if (currentReport.kind === "NOTES") {
      return (
        <div style={{ marginBottom: 20 }}>
          <NotesReport posReport={currentReport} />
        </div>
      );
    }
    return <div />;
  };

  const exportxls = () => {
    let report = currentReport;
    var data =
      currentReport.kind === "PITCHER"
        ? [
            {
              Parameter: "Role Future Position",
              Value: report.roleFuturePosition,
            },
            { Parameter: "Round", Value: report.round },
            { Parameter: "Games", Value: report.games },
            { Parameter: "Innings", Value: report.innings },
            { Parameter: "ABs", Value: report.aBs },
            { Parameter: "Raw", Value: report.raw },
            { Parameter: "Adj.", Value: report.adj },
            { Parameter: "Current", Value: report.current },
            { Parameter: "Future", Value: report.future },
            {
              Parameter: "Physical Description",
              Value: report.physicalDescription,
            },
            { Parameter: "Player Comparison", Value: report.playerComparison },
            {
              Parameter: "FB",
              Value: `${report.fB.p}, ${report.fB.f}, ${report.fB.adj}, ${report.fB.h}, ${report.fB.l}, ${report.fB.a}`,
            },
            {
              Parameter: "FBMVMT",
              Value: `${report.fBMVMT.p}, ${report.fBMVMT.f}, ${report.fBMVMT.adj}, ${report.fBMVMT.h}, ${report.fBMVMT.l}, ${report.fBMVMT.a}`,
            },
            {
              Parameter: "FB Stretch",
              Value: `${report.fBStretch.p}, ${report.fBStretch.f}, ${report.fBStretch.adj}, ${report.fBStretch.h}, ${report.fBStretch.l}, ${report.fBStretch.a}`,
            },
            {
              Parameter: "SL",
              Value: `${report.sL.p}, ${report.sL.f}, ${report.sL.adj}, ${report.sL.h}, ${report.sL.l}, ${report.sL.a}`,
            },
            {
              Parameter: "CB",
              Value: `${report.cB.p}, ${report.cB.f}, ${report.cB.adj}, ${report.cB.h}, ${report.cB.l}, ${report.cB.a}`,
            },
            {
              Parameter: "CH",
              Value: `${report.cH.p}, ${report.cH.f}, ${report.cH.adj}, ${report.cH.h}, ${report.cH.l}, ${report.cH.a}`,
            },
            {
              Parameter: "Control",
              Value: `${report.control.p}, ${report.control.f}, ${report.control.adj}, ${report.control.h}, ${report.control.l}, ${report.control.a}`,
            },
            {
              Parameter: "Command",
              Value: `${report.command.p}, ${report.command.f}, ${report.command.adj}, ${report.command.h}, ${report.command.l}, ${report.command.a}`,
            },
            {
              Parameter: "Can He Pitch",
              Value: `${report.canHePitch.p}, ${report.canHePitch.f}, ${report.canHePitch.adj}, ${report.canHePitch.h}, ${report.canHePitch.l}, ${report.canHePitch.a}`,
            },
            {
              Parameter: "OFP",
              Value: `${report.oFP.p}, ${report.oFP.f}, ${report.oFP.adj}`,
            },
            { Parameter: "Aggressiveness", Value: report.aggressiveness },
            { Parameter: "Athleticism", Value: report.athleticism },
            { Parameter: "Aptitude", Value: report.aptitude },
            { Parameter: "Poise", Value: report.poise },
            { Parameter: "Baseball IQ", Value: report.baseballIQ },
            { Parameter: "Fielding", Value: report.fielding },
            { Parameter: "Deception", Value: report.deception },
            { Parameter: "Release Times", Value: report.releaseTimes },
            { Parameter: "Outpitch", Value: report.outpitch },
            { Parameter: "Character", Value: report.character },
            { Parameter: "Entry Level", Value: report.entryLevel },
            { Parameter: "ETA", Value: report.eTA },
            {
              Parameter: "How Well Known",
              Value: `${report.howWellKnown.value}, ${report.howWellKnown.comments}`,
            },
            {
              Parameter: "How Well Seen",
              Value: `${report.howWellSeen.value}, ${report.howWellSeen.comments} `,
            },
            {
              Parameter: "Signability",
              Value: `${report.signability.value}, ${report.signability.comments} `,
            },
            { Parameter: "Summary", Value: report.athleticism },
          ]
        : [
            {
              Parameter: "Role Future Position",
              Value: report.roleFuturePosition,
            },
            { Parameter: "Round", Value: report.round },
            { Parameter: "Games", Value: report.games },
            { Parameter: "Innings", Value: report.innings },
            { Parameter: "ABs", Value: report.aBs },
            { Parameter: "Raw", Value: report.raw },
            { Parameter: "Adj.", Value: report.adj },
            { Parameter: "Current", Value: report.current },
            { Parameter: "Future", Value: report.future },
            {
              Parameter: "Physical Description",
              Value: report.physicalDescription,
            },
            { Parameter: "Player Comparison", Value: report.playerComparison },
            {
              Parameter: "Hitting Ability",
              Value: `${report.hittingAbility.p}, ${report.hittingAbility.f}, ${report.hittingAbility.adj}`,
            },
            {
              Parameter: "Hit App/Type",
              Value: `${report.hitAppType.p}, ${report.hitAppType.f}, ${report.hitAppType.adj}`,
            },
            {
              Parameter: "Power Freq.",
              Value: `${report.powerFreq.p}, ${report.powerFreq.f}, ${report.powerFreq.adj}`,
            },
            {
              Parameter: "Raw Power",
              Value: `${report.rawPower.p}, ${report.rawPower.f}, ${report.rawPower.adj}`,
            },
            {
              Parameter: "Running Ability",
              Value: `${report.runningAbility.p}, ${report.runningAbility.f}, ${report.runningAbility.adj}`,
            },
            {
              Parameter: "Base Stealer",
              Value: `${report.baseStealer.p}, ${report.baseStealer.f}, ${report.baseStealer.adj}`,
            },
            {
              Parameter: "Arm Strength",
              Value: `${report.armStrength.p}, ${report.armStrength.f}, ${report.armStrength.adj}`,
            },
            {
              Parameter: "Fielding Ability",
              Value: `${report.fieldingAbility.p}, ${report.fieldingAbility.f}, ${report.fieldingAbility.adj}`,
            },
            {
              Parameter: "Hands",
              Value: `${report.hands.p}, ${report.hands.f}, ${report.hands.adj}`,
            },
            {
              Parameter: "Range",
              Value: `${report.range.p}, ${report.range.f}, ${report.range.adj}`,
            },
            {
              Parameter: "Feet",
              Value: `${report.feet.p}, ${report.feet.f}, ${report.feet.adj}`,
            },
            {
              Parameter: "Can He Play",
              Value: `${report.canHePlay.p}, ${report.canHePlay.f}, ${report.canHePlay.adj}`,
            },
            {
              Parameter: "OFP",
              Value: `${report.oFP.p}, ${report.oFP.f}, ${report.oFP.adj}`,
            },
            { Parameter: "Aggressiveness", Value: report.aggressiveness },
            { Parameter: "Athleticism", Value: report.athleticism },
            { Parameter: "Body Control", Value: report.bodyControl },
            { Parameter: "Instincts", Value: report.instincts },
            { Parameter: "Competitiveness", Value: report.competitiveness },
            { Parameter: "Baseball IQ", Value: report.baseballIQ },
            { Parameter: "Hand-eye Control", Value: report.handEyeControl },

            { Parameter: "BP", Value: report.bp },
            { Parameter: "Infield", Value: report.infield },
            { Parameter: "OFP Offense", Value: report.oFPOffense },
            { Parameter: "OFP Defense", Value: report.oFPDefense },
            { Parameter: "Home to First", Value: report.homeToFirst },
            { Parameter: "60 Yard", Value: report.sixtyYard },
            { Parameter: "40 Yard", Value: report.fortyYard },
            { Parameter: "Pos. in Order", Value: report.posInOrder },
            { Parameter: "Character", Value: report.character },
            { Parameter: "Entry Level", Value: report.entryLevel },
            { Parameter: "ETA", Value: report.eTA },
            {
              Parameter: "How Well Known",
              Value: `${report.howWellKnown.value}, ${report.howWellKnown.comments}`,
            },
            {
              Parameter: "How Well Seen",
              Value: `${report.howWellSeen.value}, ${report.howWellSeen.comments} `,
            },
            {
              Parameter: "Signability",
              Value: `${report.signability.value}, ${report.signability.comments} `,
            },
            { Parameter: "Summary", Value: report.athleticism },
          ];

    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${report.playerName} Report`);
    XLSX.writeFile(wb, "report.xlsx");
  };

  const canExportXLS = () => {
    return currentReport.kind === "PITCHER" || currentReport.kind === "PRO";
  };

  const copyReport = async () => {
    let newReport = { ...currentReport };
    newReport.date = new Date();
    delete newReport.relatedNotificationId;
    delete newReport.authorName;

    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports" }],
    };

    let r = await firestore.add(boardsDocRefConfig, newReport);
    history.replace(`/edit-report/${r.id}`);
  };

  if (!isLoaded(currentReport)) {
    return <LoadingView />;
  }
  return currentReport ? (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 50,
          borderRadius: 8,
          backgroundColor: colors.white,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "60vw",

          padding: 20,
          color: colors.accent,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
          }}
        >
          <Link
            to={
              currentReport && currentReport.playerId.includes("cl_")
                ? `/collegeplayer/${
                    currentReport ? currentReport.playerId : ""
                  }`
                : `/player/${currentReport ? currentReport.playerId : ""}`
            }
            style={{ color: colors.accent }}
          >
            <h2 style={{ fontFamily: "Raleway" }}>
              {currentReport ? currentReport.playerName : ""}
            </h2>
          </Link>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 10,
            }}
          >
            {currentReport &&
            currentReport.relatedNotificationId &&
            currentReport.authorName ? (
              <div>
                <UnderlineButton
                  text={"copy"}
                  onClick={() => {
                    setCopyModalShow(true);
                  }}
                  color={colors.accent}
                />
              </div>
            ) : (
              <Link to={`/edit-report/${reportId}`}>
                <UnderlineButton
                  text={"edit"}
                  onClick={() => {}}
                  color={colors.accent}
                />
              </Link>
            )}

            <div>
              <UnderlineButton
                text={"share"}
                onClick={() => {
                  setModalShow(true);
                }}
                color={colors.accent}
              />
            </div>
          </div>
        </div>

        <p style={{ paddingLeft: 10 }}>
          Report
          {currentReport.date
            ? moment(new Date(currentReport.date.seconds * 1000)).format(
                " MM/DD/YYYY"
              )
            : ""}
        </p>

        {currentReport.relatedNotificationId && currentReport.authorName ? (
          <p
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              marginTop: 6,
              paddingLeft: 10,
            }}
          >{`Shared by ${currentReport.authorName}`}</p>
        ) : (
          <div />
        )}

        <div
          style={{
            marginLeft: -4,
            paddingLeft: 10,
          }}
        >
          <Link to={`/display-pdf/${reportId}`}>
            <UnderlineButton
              text="report.pdf"
              onClick={() => {}}
              color={colors.accent}
            />
          </Link>
        </div>

        {canExportXLS() ? (
          <div
            style={{
              marginLeft: -4,
              paddingLeft: 10,
              marginTop: -5,
            }}
          >
            <UnderlineButton
              text="report.xls"
              onClick={() => exportxls()}
              color={colors.accent}
            />
          </div>
        ) : (
          <div />
        )}

        {renderPositionReport()}
        {renderPitcherReport()}
        {renderGeneralReport()}
        {renderNotes()}

        <div style={{ paddingRight: 20 }}>
          {currentReport && currentReport.kind !== "NOTES" ? (
            <ImagesList posReport={currentReport} large={false} />
          ) : (
            <div />
          )}
          {currentReport && currentReport.kind !== "NOTES" ? (
            <VideosList posReport={currentReport} />
          ) : (
            <div />
          )}
          <RelatedGamesList posReport={currentReport} />
        </div>
      </div>

      <ShareReportModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        report={currentReport}
      />

      <AreYouSureModal
        show={copyModalShow}
        onHide={() => setCopyModalShow(false)}
        onConfirm={copyReport}
        title={"Copy Report"}
        body={"Do you want to make an editable copy of this shared report?"}
      />
    </div>
  ) : (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>Sorry, we can't find that report.</p>
    </div>
  );
}

export default DisplayReport;
