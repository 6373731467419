import React from "react";
import { Player } from "models/Player";
import { PlayerService } from "services/PlayerService";
import { BsStar, BsStarFill } from "react-icons/bs";

import { useSelector } from "react-redux";
import { compose } from "recompose";
import { useFirestoreConnect, withFirestore } from "react-redux-firebase";
import { Button } from "react-bootstrap";

function StarButton(props: {
  firestore: any;
  playerId?: string;
  player?: Player;
  size?: number;
  buttonStyles?: any;
  gold?: boolean;
}) {
  const myProspects = useSelector((state) => state.firestore.data.myProspects);
  const userId = useSelector((state) => state.firebase.auth.uid);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  const inactivePlayerIds = () => {
    return (
      myProspects &&
      Object.keys(myProspects)
        .map((key) => (myProspects ? { ...myProspects[key], id: key } : null))
        .filter((item) => item !== null && item.active === false)
        .map((item) => item.external_id)
    );
  };

  const playerIds = () => {
    return (
      myProspects &&
      Object.keys(myProspects)
        .map((key) => (myProspects ? { ...myProspects[key], id: key } : null))
        .filter((item) => item !== null && item.active)
        .map((item) => item.external_id)
    );
  };

  const saved = PlayerService.isSaved(
    playerIds() || [],
    props.player ? props.player.external_id : props.playerId
  );

  return (
    <Button
      onClick={() => {
        PlayerService.toggleSaved(
          props.firestore,
          userId,
          playerIds(),
          inactivePlayerIds(),
          props.player,
          props.playerId
        );
      }}
      style={{
        ...props.buttonStyles,
        backgroundColor: "transparent",
        borderWidth: 0,
        padding: 0,
        boxShadow: "none",
      }}
    >
      {saved ? (
        <BsStarFill
          // color={props.gold ? "gold" : "white"}
          color={"gold"}
          size={props.size ? props.size : 30}
        />
      ) : (
        <BsStar
          // color={props.gold ? "gold" : "white"}
          color={"gold"}
          size={props.size ? props.size : 30}
        />
      )}
    </Button>
  );
}

const enhance = compose(withFirestore);

export default enhance(StarButton);
