import React from "react";
import TopLabelBox from "../components/top-label-box";
import TopLabelMultilineBox from "../components/top-label-multiline-box";
import SixBoxLine from "../components/six-box-line";
import colors from "constants/colors";
import OneBoxLine from "../components/one-box-line";
import OneBoxLineWithComments from "../components/one-box-line-comments";
import LabelledDatePicker from "../components/labelled-date-picker";
import moment from "moment";
import ImagesPicker from "../components/images-picker";
import VideosPicker from "../components/videos-picker";
import { Link } from "react-router-dom";
import UnderlineButton from "shared/underline-button";
import { useParams } from "react-router-dom";
import RelatedGamePicker from "../components/related-game-picker";

function PitcherReportForm({ posReport, setPosReport, deleteReport }) {
  let { reportId } = useParams();

  const renderOFP = () => {
    return <div className="report-row"></div>;
  };

  const renderLinesAndLabels = () => {
    return (
      <div
        className="report-row"
        style={{
          borderBottomColor: colors.accent,
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          marginRight: 10,
        }}
      >
        <p style={{ alignSelf: "center" }}>Parameter</p>
        <div style={{ flex: 1 }} />
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            width: 80,

            color: colors.white,
            padding: 8,
            marginRight: 12,
          }}
        >
          P
        </p>
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            width: 80,

            color: colors.white,
            padding: 8,
            marginRight: 12,
          }}
        >
          F
        </p>
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            width: 80,

            color: colors.white,
            padding: 8,
            marginRight: 12,
          }}
        >
          Adj
        </p>
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            width: 80,

            color: colors.white,
            padding: 8,
            marginRight: 12,
          }}
        >
          H
        </p>
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            width: 80,

            color: colors.white,
            padding: 8,
            marginRight: 12,
          }}
        >
          L
        </p>
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            width: 80,

            color: colors.white,
            padding: 8,
            marginRight: 12,
          }}
        >
          A
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 50,
        borderRadius: 8,
        backgroundColor: colors.white,
        color: colors.accent,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "60vw",
        minWidth: 850,
        padding: 20,
      }}
    >
      <div className="report-row">
        <LabelledDatePicker
          date={
            posReport.date
              ? posReport.date.seconds
                ? moment(posReport.date.seconds * 1000).toDate()
                : new Date(posReport.date)
              : new Date()
          }
          setDate={(date) => setPosReport({ ...posReport, date: date })}
        />
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <UnderlineButton
              text={"delete"}
              onClick={() => {
                deleteReport();
              }}
              color={colors.accent}
            />
          </div>
          <Link to={`/display-report/${reportId}`}>
            <UnderlineButton
              text={"view report"}
              onClick={() => {}}
              color={colors.accent}
            />
          </Link>
        </div>
      </div>

      <div className="report-row">
        <TopLabelBox
          title="Future Position"
          value={posReport.roleFuturePosition}
          setValue={(val) => {
            setPosReport({ ...posReport, roleFuturePosition: val });
          }}
        />
        <TopLabelBox
          title="Round"
          value={posReport.round}
          setValue={(val) => {
            setPosReport({ ...posReport, round: val });
          }}
        />
      </div>
      <div className="report-row">
        <TopLabelBox
          title="Games"
          value={posReport.games}
          setValue={(val) => {
            setPosReport({ ...posReport, games: val });
          }}
        />
        <TopLabelBox
          title="Innings"
          value={posReport.innings}
          setValue={(val) => {
            setPosReport({ ...posReport, innings: val });
          }}
        />
        <TopLabelBox
          title="ABs"
          value={posReport.aBs}
          setValue={(val) => {
            setPosReport({ ...posReport, aBs: val });
          }}
        />
      </div>
      <div className="report-row">
        <TopLabelBox
          title="Raw"
          value={posReport.raw}
          setValue={(val) => {
            setPosReport({ ...posReport, raw: val });
          }}
        />
        <TopLabelBox
          title="Adj."
          value={posReport.adj}
          setValue={(val) => {
            setPosReport({ ...posReport, adj: val });
          }}
        />
        <TopLabelBox
          title="Current"
          value={posReport.current}
          setValue={(val) => {
            setPosReport({ ...posReport, current: val });
          }}
        />
        <TopLabelBox
          title="Future"
          value={posReport.future}
          setValue={(val) => {
            setPosReport({ ...posReport, future: val });
          }}
        />
      </div>

      <div className="report-row">
        <OneBoxLine
          title="Arm Angle"
          value={posReport.armAngle}
          setValue={(val) => {
            setPosReport({ ...posReport, armAngle: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLineWithComments
          title="Delivery"
          value={posReport.delivery}
          setValue={(val) => {
            setPosReport({ ...posReport, delivery: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLineWithComments
          title="Arm Action"
          value={posReport.armAction}
          setValue={(val) => {
            setPosReport({ ...posReport, armAction: val });
          }}
        />
      </div>

      <div className="report-row">
        <TopLabelMultilineBox
          skipRichText={true}
          title="Physical Description"
          value={posReport.physicalDescription}
          setValue={(val) => {
            setPosReport({ ...posReport, physicalDescription: val });
          }}
        />
      </div>
      <div className="report-row">
        <TopLabelMultilineBox
          title="Player Comparison"
          skipRichText={true}
          value={posReport.playerComparison}
          setValue={(val) => {
            setPosReport({ ...posReport, playerComparison: val });
          }}
        />
      </div>
      {renderLinesAndLabels()}
      <div className="report-row">
        <SixBoxLine
          title="FB"
          value={posReport.fB}
          setValue={(val) => {
            setPosReport({ ...posReport, fB: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="FBMVMT"
          value={posReport.fBMVMT}
          setValue={(val) => {
            setPosReport({ ...posReport, fBMVMT: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="FB Stretch"
          value={posReport.fBStretch}
          setValue={(val) => {
            setPosReport({ ...posReport, fBStretch: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="SL"
          value={posReport.sL}
          setValue={(val) => {
            setPosReport({ ...posReport, sL: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="CB"
          value={posReport.cB}
          setValue={(val) => {
            setPosReport({ ...posReport, cB: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="CH"
          value={posReport.cH}
          setValue={(val) => {
            setPosReport({ ...posReport, cH: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="Control"
          value={posReport.control}
          setValue={(val) => {
            setPosReport({ ...posReport, control: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="Command"
          value={posReport.command}
          setValue={(val) => {
            setPosReport({ ...posReport, command: val });
          }}
        />
      </div>
      <div className="report-row">
        <SixBoxLine
          title="Can He Pitch"
          value={posReport.canHePitch}
          setValue={(val) => {
            setPosReport({ ...posReport, canHePitch: val });
          }}
        />
      </div>

      {renderOFP()}

      <div className="report-row">
        <OneBoxLine
          title="Aggressiveness"
          value={posReport.aggressiveness}
          setValue={(val) => {
            setPosReport({ ...posReport, aggressiveness: val });
          }}
        />
      </div>

      <div className="report-row">
        <OneBoxLine
          title="Athleticism"
          value={posReport.athleticism}
          setValue={(val) => {
            setPosReport({ ...posReport, athleticism: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Aptitude"
          value={posReport.aptitude}
          setValue={(val) => {
            setPosReport({ ...posReport, aptitude: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Poise"
          value={posReport.poise}
          setValue={(val) => {
            setPosReport({ ...posReport, poise: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Baseball IQ"
          value={posReport.baseballIQ}
          setValue={(val) => {
            setPosReport({ ...posReport, baseballIQ: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Fielding"
          value={posReport.fielding}
          setValue={(val) => {
            setPosReport({ ...posReport, fielding: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Deception"
          value={posReport.deception}
          setValue={(val) => {
            setPosReport({ ...posReport, deception: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Release Times"
          value={posReport.releaseTimes}
          setValue={(val) => {
            setPosReport({ ...posReport, releaseTimes: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Outpitch"
          value={posReport.outpitch}
          setValue={(val) => {
            setPosReport({ ...posReport, outpitch: val });
          }}
        />
      </div>

      <div className="report-row" style={{ marginTop: 40 }}>
        <OneBoxLine
          title="Character"
          value={posReport.character}
          setValue={(val) => {
            setPosReport({ ...posReport, character: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="Entry Level"
          value={posReport.entryLevel}
          setValue={(val) => {
            setPosReport({ ...posReport, entryLevel: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLine
          title="ETA"
          value={posReport.eTA}
          setValue={(val) => {
            setPosReport({ ...posReport, eTA: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLineWithComments
          title="How Well Known"
          value={posReport.howWellKnown}
          setValue={(val) => {
            setPosReport({ ...posReport, howWellKnown: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLineWithComments
          title="How Well Seen"
          value={posReport.howWellSeen}
          setValue={(val) => {
            setPosReport({ ...posReport, howWellSeen: val });
          }}
        />
      </div>
      <div className="report-row">
        <OneBoxLineWithComments
          title="Signability"
          value={posReport.signability}
          setValue={(val) => {
            setPosReport({ ...posReport, signability: val });
          }}
        />
      </div>
      <div className="report-row">
        <TopLabelMultilineBox
          skipRichText={true}
          title="Summary"
          value={posReport.physicalDescription}
          setValue={(val) => {
            setPosReport({ ...posReport, physicalDescription: val });
          }}
        />
      </div>
      <ImagesPicker posReport={posReport} setPosReport={setPosReport} />
      <VideosPicker posReport={posReport} setPosReport={setPosReport} />
      <RelatedGamePicker posReport={posReport} setPosReport={setPosReport} />
    </div>
  );
}

export default PitcherReportForm;
