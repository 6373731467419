import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import { Player } from "models/Player";
import { User } from "models/User";
import React, { useEffect, useRef, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import ScoutTitle from "shared/scout-title";
import { PlayerService } from "services/PlayerService";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function AbsoluteScholarshipsHeader({
  sortedProspects,
  mode,
  year,
}: {
  sortedProspects: Player[];
  mode: "view" | "edit" | "breakdown_view" | "breakdown_edit" | "add";
  year: string;
}) {
  const [schAmount, setSchAmount] = useState(null);
  const [finAidAmount, setFinAidAmount] = useState(null);
  const previousMode = usePrevious(mode);
  const firestore = useFirestore();

  useEffect(() => {
    if (mode === "edit" && previousMode === "view") {
      setSchAmount(getTotalScholAmount());
      setFinAidAmount(getTotalFinAidAmount());
    } else if (mode === "view" && previousMode === "edit") {
      saveAmounts();
    }
  }, [mode]);

  const me = useMe<User>();
  const getUsedBothTotal = () => {
    let pl = (sortedProspects || [])
      .map((item) => ({
        ...item,
        totalVal: PlayerService.getScholarship(item, year, "both"),
      }))
      .filter((item) => item.totalVal > 0)
      .map((item) => item.totalVal);
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getUsedScholTotal = () => {
    let pl = (sortedProspects || [])
      .filter((item) => PlayerService.getScholarship(item, year, "schol", true) > 0)
      .map((item) => item.scholarshipByYear[year]);
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getUsedFinAidTotal = () => {
    let pl = (sortedProspects || [])
      .filter((item) => PlayerService.getScholarship(item, year, "finAid", true) > 0)
      .map((item) => item.financialAidByYear[year]);
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getTotalScholAmount = () => {
    if (me.totalScholarshipVal) {
      return me.totalScholarshipVal;
    }
    return 11.7;
  };

  const getTotalFinAidAmount = () => {
    if (me.totalFinAidVal) {
      return me.totalFinAidVal;
    }
    return 0;
  };

  const getTotalBothAmount = () => {
    return getTotalScholAmount() + getTotalFinAidAmount();
  };

  const getSchInputNum = () => {
    if (!isNaN(parseFloat(schAmount))) {
      return parseFloat(schAmount);
    }
    return 0;
  };

  const getFinAidInputNum = () => {
    if (!isNaN(parseFloat(finAidAmount))) {
      return parseFloat(finAidAmount);
    }
    return 0;
  };

  const saveAmounts = async () => {
    await firestore.collection("users").doc(me.id).update({
      totalScholarshipVal: getSchInputNum(),
      totalFinAidVal: getFinAidInputNum(),
    });
  };

  const getEditModeTotal = () => {
    return getSchInputNum() + getFinAidInputNum() - getUsedBothTotal();
  };
  const getEditModeSchol = () => {
    return getSchInputNum() - getUsedScholTotal();
  };
  const getEditModeFinAid = () => {
    return getUsedFinAidTotal();
  };

  if (mode === "edit") {
    return (
      <div
        style={{
          zIndex: 100,

          width: 260,

          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: getEditModeSchol() < 0 ? colors.red : colors.lightBlue,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 46,
            borderBottomStyle: "solid",
            borderBottomWidth: 2,
            borderBottomColor:
              getEditModeSchol() < 0 ? colors.red : colors.lightBlue,
            justifyContent: "center",
          }}
        >
          <ScoutTitle
            text="Total:"
            light={true}
            styles={{ padding: 0, margin: 0, marginRight: 10 }}
          />

          <p
            style={{
              textAlign: "center",
              color: colors.white,
            }}
          >
            {`${getEditModeSchol().toFixed(2)} (${getSchInputNum().toFixed(
              2
            )} total)`}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 46,
            backgroundColor: colors.white,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRightStyle: "solid",
              borderRightWidth: 2,
              height: 46,
              borderRightColor: colors.lightBlue,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: getEditModeSchol() < 0 ? colors.red : colors.accent,
                marginRight: 4,
                fontSize: 18,
              }}
            >
              {"S:"}
            </p>
            <p
              style={{
                marginRight: 4,
                color:
                  getEditModeSchol() < 0 ? colors.red : colors.softDarkText,
              }}
            >
              {`${getEditModeSchol().toFixed(2)}`}
            </p>
            <p
              style={{
                marginRight: 4,
                fontWeight: "bold",
                color: colors.accent,

                fontSize: 18,
              }}
            >
              {"TOTAL:"}
            </p>
            <input
              style={{
                margin: 5,
                display: "flex",
                flex: 1,
                width: 50,
                maxWidth: 50,
                border: "1px solid",
                borderColor: colors.lightBlue,
                color: colors.accent,
                backgroundColor: "transparent",
              }}
              type={"numeric"}
              value={schAmount}
              placeholder="0"
              onChange={(evt) => setSchAmount(evt.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 46,
            backgroundColor: colors.white,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRightStyle: "solid",
              borderRightWidth: 2,
              height: 46,
              borderRightColor: colors.lightBlue,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: getEditModeFinAid() < 0 ? colors.red : colors.accent,
                marginRight: 4,
                fontSize: 18,
              }}
            >
              {"F:"}
            </p>
            <p
              style={{
                marginRight: 4,
                color:
                  getEditModeFinAid() < 0 ? colors.red : colors.softDarkText,
              }}
            >
              {`${getEditModeFinAid().toFixed(2)}`}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        zIndex: 100,

        width: 240,

        backgroundColor: colors.accent,
        display: "flex",
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor:
          getTotalScholAmount() - getUsedScholTotal() < 0 // getTotalBothAmount() - getUsedBothTotal() < 0
            ? colors.red
            : colors.lightBlue,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 46,
          borderBottomStyle: "solid",
          borderBottomWidth: 2,
          borderBottomColor:
            getTotalScholAmount() - getUsedScholTotal() < 0 // getTotalBothAmount() - getUsedBothTotal() < 0
              ? colors.red
              : colors.lightBlue,
          justifyContent: "center",
        }}
      >
        <ScoutTitle
          text="Total:"
          light={true}
          styles={{ padding: 0, margin: 0, marginRight: 10 }}
        />

        <p style={{ color: colors.white, textAlign: "center" }}>
          {/* {`${(getTotalBothAmount() - getUsedBothTotal()).toFixed(2)}`} */}
          {`${getTotalScholAmount()}`}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: 46,
          backgroundColor: colors.white,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRightStyle: "solid",
            borderRightWidth: 2,
            height: 46,
            borderRightColor: colors.lightBlue,
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              color:
                getTotalScholAmount() - getUsedScholTotal() < 0
                  ? colors.red
                  : colors.accent,
              marginRight: 4,
              fontSize: 18,
            }}
          >
            {"S:"}
          </p>
          <p
            style={{
              color:
                getTotalScholAmount() - getUsedScholTotal() < 0
                  ? colors.red
                  : colors.softDarkText,
            }}
          >{`${(getTotalScholAmount() - getUsedScholTotal()).toFixed(2)}`}</p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            height: 46,
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              color: colors.accent,

              marginRight: 4,
              fontSize: 18,
            }}
          >
            {"F:"}
          </p>
          <p
            style={{
              color: colors.softDarkText,
            }}
          >
            {`${getUsedFinAidTotal().toFixed(2)}`}
          </p>
        </div>
      </div>
    </div>
  );
}
