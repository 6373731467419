import React from "react";
import colors from "constants/colors";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const ScholarshipsDropdown = ({ selectBoard, boards }) => {
  const onChange = (item) => {
    selectBoard([item.value]);
  };

  return (
    <Select
      options={(boards || []).map((item) => ({
        value: item,
        label: item.name,
      }))}
      isMulti={false}
      isClearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder="Shared Scholarships..."
    />
  );
};

export default ScholarshipsDropdown;
