/*****************************************************************************
 * Import
 *****************************************************************************/
import colors from "constants/colors";
import React, { useEffect, useRef, useState } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { Button, Modal, Spinner } from "react-bootstrap";
import "./play-analysis-modal.css";
import { Row, Column } from "components/basic";
import {
  AiOutlineClose,
} from "react-icons/ai";

/*****************************************************************************
 * Default Component
 *****************************************************************************/

export default function PlayAnalysisModal(props: {
  onHide: any;
  show?: any;
  play: any;
  setPlay: any;
  game: any;
}) {
  const [selectedPlayId, setSelectedPlayId] = useState(props.play?.id);
  
  const videoRef = useRef(null);
  const videoUrl = props.play?.videoGroup?.videoTracks?.[0]?.segments?.[0]?.video?.videoVariants?.[0]?.endpointRelativePath;
  const playIndex = props.game?.findIndex(play => play.id === props.play?.id);

  useEffect(() => {    
    videoRef.current?.load();
  }, [videoUrl]);

  useEffect(() => {
    if (props.play) {
      const element = document.getElementById(props.play.id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.play])

  const playNextVideo = () => {
    if (props.game?.length && playIndex < props.game.length - 1) {
      props.setPlay(props.game[playIndex + 1]);
    }
  }
  
  return (
    <Modal
      {...props}
      //@ts-ignore
      //size="lg"
      //aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="dialog"
      contentClassName="content"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          SYNERGY ANALYSIS
        </Modal.Title>
        <AiOutlineClose
          onClick={props.onHide}
          size="24px"
          style={{
            maxWidth: "24px",
            cursor: "pointer",
            flex: 1,
          }}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          margin: 0,
          background: undefined,
          height: "calc(100% - 68px)",
        }}
      >
        <Row
          gap="16px"
          style={{
            height: "calc(100% - 24px)",
          }}
        >
          <Column
            style={{
              height: "100%",
              overflow: "scroll",
            }}
            gap="8px"
          >
            {props.game?.map(play => (
              <PlayCard
                play={play}
                onClick={() => props.setPlay(play)}
                selected={props.play?.id === play.id}
              />
            ))}
          </Column>
          <video
            autoPlay
            controls
            ref={videoRef}
            onEnded={playNextVideo}
            style={{
              width: "calc(100% - 240px)",
              height: "100%",
            }}
          >
            <source src={videoUrl} />
          </video>
        </Row>

      </Modal.Body>
    </Modal>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const PlayCard = ({ play, onClick, selected }) => {
  return (
    <Column
      id={play.id}
      onClick={onClick}
      className="play-card"
      style={{
        padding: "4px",
        width: "200px",
        borderWidth: "2px",
        borderColor: !selected ? colors.lightestBlue : undefined,
        //background: colors.lightBlue,
        //color: colors.white,
        borderStyle: "solid",
        cursor: "pointer",
      }}
      gap="8px"
    >
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <p style={{ fontWeight: 700 }}>
          {new Date(play.dateUpdated).toLocaleDateString("en-US")}
        </p>
      </Row>

      <Row style={{ justifyContent: "space-between" }}>
        <Column>
          <Row>
            <p style={{ width: "40px" }}>
              LSU
            </p>
            <p>
              {play.awayTeamScore}
            </p>
          </Row>
          <Row>
            <p style={{ width: "40px" }}>
              UGA
            </p>
            <p>
              {play.homeTeamScore}
            </p>
          </Row>
        </Column>

        <Column style={{ justifyContent: "center" }}>
          <p style={{ fontSize: "14px", lineHeight: "14px" }}>
            {!!play.inningTop ? "Top" : "Bot"} {play.inning}, {play.outs} outs
          </p>
          <p style={{ lineHeight: "16px" }}>
            {play.count.balls}-{play.count.strikes}
          </p>
        </Column>
      </Row>

      <div style={{ height: "1px", backgroundColor: colors.lightestBlue , width: "100%" }} />

      <p style={{ fontSize: "14px", lineHeight: "14px" }}>
        {play.batter.nameFirst[0]}. {play.batter.nameLast} vs. {play.defense.lineup.pitcher.nameFirst[0]}. {play.defense.lineup.pitcher.nameLast}
      </p>


      <p style={{ lineHeight: "16px" }}>
        {play.pitch?.pitchKind} | {play.pitch?.pitchSpeedMph} MPH
      </p>

      <div style={{ height: "1px", backgroundColor: colors.lightestBlue , width: "100%" }} />

      <p style={{ lineHeight: "16px" }}>
        {play.pitch?.pitchResult}
      </p>
      
    </Column>
  );
}
