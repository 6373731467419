export interface PlayerQuery {
  height?: {
    min: number;
    max: number;
  };
  weight?: {
    min: number;
    max: number;
  };
  age?: {
    min: number;
    max: number;
  };
  graduatingclass?: {
    min: number;
    max: number;
  };
  statepositionranking?: {
    min: number;
    max: number;
  };
  stateoverallranking?: {
    min: number;
    max: number;
  };
  nationalpositionranking?: {
    min: number;
    max: number;
  };
  nationaloverallranking?: {
    min: number;
    max: number;
  };
  maxfastball?: {
    min: number;
    max: number;
  };
  curveball?: {
    min: number;
    max: number;
  };
  changeup?: {
    min: number;
    max: number;
  };
  slider?: {
    min: number;
    max: number;
  };
  cutter?: {
    min: number;
    max: number;
  };
  knuckleball?: {
    min: number;
    max: number;
  };
  sixtytime?: {
    min: number;
    max: number;
  };
  tenyardsplit?: {
    min: number;
    max: number;
  };
  catcherpoptime?: {
    min: number;
    max: number;
  };
  exitvelocity?: {
    min: number;
    max: number;
  };
  positionvelocityc?: {
    min: number;
    max: number;
  };
  positionvelocityif?: {
    min: number;
    max: number;
  };
  positionvelocityof?: {
    min: number;
    max: number;
  };
  positions?: ("RHP" | "LHP" | "1B" | "2B" | "SS" | "3B" | "C" | "OF")[];
  uncommitted?: "Uncommitted" | "Committed";
  conference?: string;
  states: string[];
  summerTeam?: string;
  commitment?: string;
  bat?: string;
  throw?: string;
  // college
  asCollege?: boolean;
  university?: string;
  classKey?: {
    min: number;
    max: number;
  };
  AVG?: {
    min: number;
    max: number;
  };
  RBI?: {
    min: number;
    max: number;
  };
  BB?: {
    min: number;
    max: number;
  };
  OPS?: {
    min: number;
    max: number;
  };
  HR?: {
    min: number;
    max: number;
  };
  H?: {
    min: number;
    max: number;
  };
  HP?: {
    min: number;
    max: number;
  };
  GP?: {
    min: number;
    max: number;
  };
  AB?: {
    min: number;
    max: number;
  };
  SO?: {
    min: number;
    max: number;
  };
  "2B"?: {
    min: number;
    max: number;
  };
  CS?: {
    min: number;
    max: number;
  };
  R?: {
    min: number;
    max: number;
  };
  SLG?: {
    min: number;
    max: number;
  };
  OBP?: {
    min: number;
    max: number;
  };
  SB?: {
    min: number;
    max: number;
  };
  GS?: {
    min: number;
    max: number;
  };
  IP?: {
    min: number;
    max: number;
  };
  APP?: {
    min: number;
    max: number;
  };
  WP?: {
    min: number;
    max: number;
  };
  CG?: {
    min: number;
    max: number;
  };
  L?: {
    min: number;
    max: number;
  };
  SV?: {
    min: number;
    max: number;
  };
  OBA?: {
    min: number;
    max: number;
  };
  ERA?: {
    min: number;
    max: number;
  };
  W?: {
    min: number;
    max: number;
  };
  ER?: {
    min: number;
    max: number;
  };
  SHO?: {
    min: number;
    max: number;
  };
  "3B"?: {
    min: number;
    max: number;
  };

  HBP?: {
    min: number;
    max: number;
  };

  PA?: {
    min: number;
    max: number;
  };

  K?: {
    min: number;
    max: number;
  };
}

export const EMPTY_QUERY: PlayerQuery = {
  states: [],
};
