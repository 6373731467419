import React, { useState } from "react";
import colors from "constants/colors";
import PlayerCalendarEvent from "popovers/player-calendar-event";
import { useHistory } from "react-router-dom";

function RelatedGamesList({ posReport }) {
  const history = useHistory();

  return (
    <div style={{ marginLeft: 10, marginTop: 20 }}>
      <table style={{ width: "100%", marginBottom: 10 }}>
        <th
          style={{
            width: "50%",
            border: "1px solid #467db3",
            borderCollapse: "collapse",
            backgroundColor: colors.lightBlue,
            color: colors.white,
            textAlign: "center",
          }}
        >
          <td>Related Games</td>
        </th>
        {(posReport.relatedGames || []).length === 0 ? (
          <tr>
            <td>{"N/A"}</td>
          </tr>
        ) : (
          <tr />
        )}
      </table>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
        <div style={{ flexDirection: "row", display: "flex" }}>
          {(posReport.relatedGames || []).map((item, index) => (
            <PlayerCalendarEvent
              event={item}
              light={true}
              prospects={[]}
              onViewCalendar={() => {
                history.push("/calendar");
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RelatedGamesList;
