import React, { useState } from "react";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PositionReport } from "models/PositionReport";
import { Link } from "react-router-dom";
import PDFPositionProReport from "./displays/pdf-position-pro-report";
import UnderlineButton from "shared/underline-button";
import colors from "constants/colors";
import PDFGeneralReport from "./displays/pdf-general-report";
import PDFPitcherReport from "./displays/pdf-pitcher-report";
import PDFNotesReport from "./displays/pdf-notes-report";
import LoadingView from "shared/loading-view";

function DisplayPDF(props: any) {
  let { reportId } = useParams();
  const [waitTime, setWaitTime] = useState(false);
  const [ready, setReady] = useState(false);
  const userId = useSelector((state) => state.firebase.auth.uid);
  const currentReport = useSelector<PositionReport>(
    (state) => state.firestore.data.currentReport
  );

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: reportId }],
      storeAs: "currentReport",
    },
  ]);

  if (!isLoaded(currentReport)) {
    return <LoadingView />;
  } else if (!waitTime) {
    setWaitTime(true);
    setTimeout((_) => {
      setReady(true);
    }, 2000);
  }

  if (!ready) {
    return <LoadingView />;
  }

  const renderPositionReport = () => {
    if (currentReport.kind === "PRO") {
      return (
        <PDFPositionProReport posReport={currentReport} fullScreen={false} />
      );
    }
    return <div />;
  };

  const renderPitcherReport = () => {
    if (currentReport.kind === "PITCHER") {
      return <PDFPitcherReport posReport={currentReport} fullScreen={false} />;
    }
    return <div />;
  };

  const renderGeneralReport = () => {
    if (currentReport.kind === "GENERAL") {
      return <PDFGeneralReport posReport={currentReport} fullScreen={false} />;
    }
    return <div />;
  };

  const renderNotes = () => {
    if (currentReport.kind === "NOTES") {
      return <PDFNotesReport posReport={currentReport} fullScreen={false} />;
    }
    return <div />;
  };

  return currentReport ? (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Link to={`/edit-report/${reportId}`}>
          <UnderlineButton
            text={"edit"}
            onClick={() => {}}
            color={colors.accent}
          />
        </Link>

        <Link to={`/display-report/${reportId}`}>
          <UnderlineButton
            text={"view report"}
            onClick={() => {}}
            color={colors.accent}
          />
        </Link>

        <Link
          to={
            currentReport && currentReport.playerId.includes("cl_")
              ? `/collegeplayer/${currentReport ? currentReport.playerId : ""}`
              : `/player/${currentReport ? currentReport.playerId : ""}`
          }
        >
          <UnderlineButton
            text={"view prospect"}
            onClick={() => {}}
            color={colors.accent}
          />
        </Link>
      </div>

      {renderPositionReport()}
      {renderPitcherReport()}
      {renderGeneralReport()}
      {renderNotes()}
    </div>
  ) : (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>Sorry, we can't find that report.</p>
    </div>
  );
}

export default DisplayPDF;
