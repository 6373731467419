import React, { useMemo, useState } from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import ResultHeader from "pages/search-page/components/result-header";
import ResultItem from "pages/search-page/components/result-item";
import colors from "constants/colors";
import LoadingView from "shared/loading-view";
import ScoutTitle from "shared/scout-title";
import HelpModal from "modals/help-modal";
import { useEffect } from "react";
import UnderlineButton from "shared/underline-button";
import CreateProspectModal from "modals/create-prospect-modal";
import { useMe } from "@tara-wilson/mcc-lib";
import RemovableChip from "pages/bigboard-page/components/removable-chip";
import YearDropdown from "dropdowns/year-dropdown";
import PositionDropdown from "dropdowns/position-dropdown";
import { Player } from "models/Player";
import * as XLSX from "xlsx";

function ProspectsPage(props: any) {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const myProspects = useSelector((state) => state.firestore.data.myProspects);
  const [editingYear, setEditingYear] = useState(false);
  const [filterYear, setFilterYear] = useState("ALL YEARS");
  const [editingPosition, setEditingPosition] = useState(false);
  const [filterPosition, setFilterPosition] = useState("ALL POSITIONS");
  const [currentSortType, setCurrentSortType] = useState(null);
  const [currentSortDirection, setCurrentSortDirection] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const mode = useSelector((state) => state.firestore.data.mode);
  const [showManualCreate, setShowManualCreate] = useState(false);
  const me = useMe();
  const [doneLoading, setDoneLoading] = useState(true);

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
    {
      collection: "mode",
      doc: "flags",
    },
  ]);

  const loadedProspects = useMemo(() => {
    if (isLoaded(myProspects)) {
      return myProspects
        ? Object.keys(myProspects)
            .map((key) =>
              myProspects ? { ...myProspects[key], id: key } : null
            )
            .filter((item) => item.id)
        : [];
    }
    return [];
  }, [myProspects]);

  const sortedProspects = React.useMemo(() => {
    let prospects = loadedProspects;

    prospects = prospects.filter((item) => item !== null && item.active);

    if (currentSortType) {
      if (currentSortDirection === "asc") {
        prospects.sort(function (a, b) {
          let sortingBya = a.college
            ? COLLEGE_SORT_MAP[currentSortType]
            : SORT_MAP[currentSortType];
          let sortingByb = b.college
            ? COLLEGE_SORT_MAP[currentSortType]
            : SORT_MAP[currentSortType];

          let aSort = a[sortingBya];
          let bSort = b[sortingByb];

          if (
            sortingBya === "national_overall_ranking" ||
            sortingBya === "state_overall_ranking" ||
            sortingByb === "national_overall_ranking" ||
            sortingByb === "state_overall_ranking"
          ) {
            if (!aSort) {
              aSort = 1000;
            }
            if (!bSort) {
              bSort = 1000;
            }
          }

          if (aSort && bSort) {
            let aUncommited = aSort === "Uncommited";
            let bUncommited = bSort === "Uncommited";
            if (aUncommited && !bUncommited) {
              return 1;
            }
            if (bUncommited && !aUncommited) {
              return -1;
            }

            if (aSort > bSort) return -1;
            if (aSort < bSort) return 1;
          } else if (aSort) {
            return -1;
          } else if (bSort) {
            return 1;
          }

          return 0;
        });
      } else {
        prospects.sort(function (a, b) {
          let sortingBya = a.college
            ? COLLEGE_SORT_MAP[currentSortType]
            : SORT_MAP[currentSortType];
          let sortingByb = b.college
            ? COLLEGE_SORT_MAP[currentSortType]
            : SORT_MAP[currentSortType];

          let aSort = a[sortingBya];
          let bSort = b[sortingByb];

          if (
            sortingBya === "national_overall_ranking" ||
            sortingBya === "state_overall_ranking" ||
            sortingByb === "national_overall_ranking" ||
            sortingByb === "state_overall_ranking"
          ) {
            if (!aSort) {
              aSort = 1000;
            }
            if (!bSort) {
              bSort = 1000;
            }
          }

          if (aSort && bSort) {
            let aUncommited = aSort === "Uncommited";
            let bUncommited = bSort === "Uncommited";
            if (aUncommited && !bUncommited) {
              return 1;
            }
            if (bUncommited && !aUncommited) {
              return -1;
            }

            if (aSort > bSort) return 1;
            if (aSort < bSort) return -1;
          } else if (aSort) {
            return 1;
          } else if (bSort) {
            return -1;
          }
          return 0;
        });
      }
    }

    if (filterYear && filterYear != "ALL YEARS") {
      prospects = prospects.filter(
        (item) => (item as Player).graduating_class == parseInt(filterYear)
      );
    }

    if (filterPosition && filterPosition != "ALL POSITIONS") {
      prospects = prospects.filter(
        (item) => (item as Player).primary_position == filterPosition
      );
    }

    return prospects;
  }, [
    loadedProspects,
    filterPosition,
    filterYear,
    currentSortDirection,
    currentSortType,
  ]);

  const selectYear = async (year) => {
    setEditingYear(false);
    setFilterYear(year);
  };

  const selectPosition = async (pos) => {
    setEditingPosition(false);
    setFilterPosition(pos);
  };

  if (!isLoaded(myProspects)) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: 300,
        }}
      >
        <LoadingView />
      </div>
    );
  }

  const flagMode = (): "pbr_disabled" | "pg_disabled" | "both" | "none" => {
    let hasPrepCreds = false;
    let hasPGCreds = false;
    //@ts-ignore
    let creds = me ? me.credentials : null;

    if (creds && creds.pg && creds.pg.approved) {
      hasPGCreds = true;
    }
    if (creds && creds.prep && creds.prep.approved) {
      hasPrepCreds = true;
    }
    let hasBoth = hasPGCreds && hasPrepCreds;
    let hasNone = !hasPGCreds && !hasPrepCreds;
    if (hasBoth) {
      return "none";
    }
    if (mode && mode.flags) {
      if (mode.flags.disable_pbr_rankings || mode.flags.disable_pg_rankings) {
        if (
          hasNone &&
          mode.flags.disable_pbr_rankings &&
          mode.flags.disable_pg_rankings
        ) {
          return "both";
        } else if (!hasPrepCreds && mode.flags.disable_pbr_rankings) {
          return "pbr_disabled";
        } else if (!hasPGCreds && mode.flags.disable_pg_rankings) {
          return "pg_disabled";
        }
      }
    }

    return "both";
  };

  const exportList = async () => {
    let data = [];
    sortedProspects.forEach((player) => {
      data.push({
        "FIRST NAME": player.first_name,
        "LAST NAME": player.last_name,
        CLASS: player.graduating_class,
        POSITION: player.position,
        "HIGH SCHOOL": player.high_school,
        STATE: player.state,
        BAT: player.bats,
        THROW: player.throws,
        HEIGHT: player.height,
        WEIGHT: player.weight,
        TWITTER: player.twitter_handle,
        "PHONE NUMBER": player.contact_phone,
        //"EMAIL": player.email,
        "SUMMER TEAM": player.summer_team,
      });
    });

    console.log(Object.keys(data[0]));
    //@ts-ignore
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    const widths = Object.keys(data[0]).map((key) =>
      Math.max(key.length, 10, (data[0][key] || "").length)
    );
    console.log(widths);
    worksheet["!cols"] = widths.map((width) => ({ wch: width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `player-export.xlsx`);
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,

        marginLeft: "1rem",
        marginBottom: "1rem",
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ScoutTitle
            text={"My Prospects"}
            withHelp={true}
            onClick={() => setShowHelp(true)}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {!editingYear ? (
              <RemovableChip
                name={
                  parseInt(filterYear)
                    ? `${filterYear}-${parseInt(filterYear) + 1}`
                    : filterYear
                }
                onClick={() => {
                  setEditingYear(true);
                }}
              />
            ) : (
              <div style={{ marginLeft: 12, zIndex: 99 }}>
                <YearDropdown
                  season
                  selectYear={(yr) => selectYear(yr[0])}
                  allowAll={true}
                />
              </div>
            )}
            <div style={{ marginLeft: 5 }}>
              {!editingPosition ? (
                <RemovableChip
                  name={filterPosition}
                  onClick={() => {
                    setEditingPosition(true);
                  }}
                />
              ) : (
                <div style={{ marginLeft: 12, zIndex: 99 }}>
                  <PositionDropdown
                    selectPosition={(pos) => selectPosition(pos[0])}
                    allowAll={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <UnderlineButton
            text="export list"
            color={colors.accent}
            onClick={() => exportList()}
          />

          <UnderlineButton
            text="manually create prospect"
            color={colors.accent}
            onClick={() => setShowManualCreate(true)}
          />
        </div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",

          overflowX: "scroll",
          minHeight: "65vh",
          marginBottom: "1rem",
          opacity: doneLoading ? 1 : 0,
        }}
      >
        {loadedProspects.length > 0 ? (
          <ResultHeader
            disabledMode={flagMode()}
            currentSortType={currentSortType}
            setCurrentSortType={setCurrentSortType}
            currentSortDirection={currentSortDirection}
            setCurrentSortDirection={setCurrentSortDirection}
            handleSort={() => {}}
            onlyCollege={false}
          />
        ) : (
          <div />
        )}

        {/* tara here export  */}

        {loadedProspects.length > 0
          ? (sortedProspects || []).map((player, index) => {
              return (
                <ResultItem
                  key={player.id}
                  player={player}
                  index={index}
                  onlyCollege={false}
                  shouldLoad={false} //tara here
                  disabledMode={flagMode()}
                />
              );
            })
          : loadedProspects.length === 0 && (
              <p
                style={{
                  fontStyle: "italic",
                  opacity: 0.8,
                  marginTop: 100,
                  textAlign: "center",
                }}
              >
                No prospects saved yet.
              </p>
            )}
      </div>

      <HelpModal
        show={showHelp}
        kind={"PROSPECTS"}
        onHide={() => setShowHelp(false)}
      />

      <CreateProspectModal
        show={showManualCreate}
        onHide={(pl) => {
          setShowManualCreate(false);
        }}
        sortedProspects={sortedProspects}
      />

      {doneLoading ? (
        <div />
      ) : (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: 300,
          }}
        >
          <LoadingView />
        </div>
      )}
    </div>
  );
}

export default ProspectsPage;

const SORT_MAP = {
  Player: "last_name",
  Height: "calculated_height",
  Weight: "weight",
  Age: "age",
  Position: "primary_position",
  Class: "graduating_class",
  Team: "summer_team",
  Commitment: "college_commitment",
  Location: "state",
  "National Rank": "national_overall_ranking",
  "State Rank": "state_overall_ranking",
};

const COLLEGE_SORT_MAP = {
  Player: "last_name",
  Height: "calculated_height",
  Weight: "weight",
  Age: "age",
  Position: "position",
  Class: "classKey",
  Team: "last_name",
  Commitment: "university",
  Location: "last_name",
  "National Rank": "last_name",
  "State Rank": "last_name",
};
