/*****************************************************************************
 * Import
 *****************************************************************************/
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { submitBugReport } from "services/bug-report-service";
import { BugReport } from "models/bug-report";
import { Column } from "components/basic";

/*****************************************************************************
 * Default Component
 *****************************************************************************/

export default function BugReportPage (props: any) {
  const [finished, setFinished] = useState(false);
  
  return (
    <div style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "16px",
    }}>
      <div
        style={{
          width: "500px",
          background: "white",
          padding: "16px",
          borderRadius: "5px",
        }}
      >
        {finished ? (
          <ThankYouMessage />
        ) : (
          <BugReportForm onFormSubmitted={() => setFinished(true)} />
        )}
      </div>
    </div>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const ThankYouMessage = () => {
  const history = useHistory();
  
  useEffect(() => {
    setTimeout(() => {
      history.replace("/");
    }, 5000)
  }, [])
  
  return (
    <Column style={{ alignItems: "center" }}>
      <h2>
        Bug Report Submitted!
      </h2>
      <p>
        Thanks for your help! You will be redirected in 5 seconds.
      </p>

      <div style={{ height: "32px" }} />
      
      <a href="/">
        Return Home
      </a>
    </Column>
  );
}

const BugReportForm = ({ onFormSubmitted }) => {
  const { register, handleSubmit, errors } = useForm();
  const [bugInfo, setBugInfo] = useState<BugReport>({
    description: "",
    email: "",
    browser: "",
    whatHappened: "",
    expectation: "",
    screenshot: null,
  });

  const fields = [
    {
      key: "description",
      placeholder: "Short Description *",
      options: { required: true, minLength: 5 },
      error: errors.description ? "Please provide a brief description" : undefined,
    },
    {
      key: "email",
      placeholder: "E-mail",
      options: { required: false, minLength: 5 },
      error: false,
    },
    {
      key: "browser",
      placeholder: "Browser *",
      options: { required: true, minLength: 5 },
      error: false,
    },
    {
      key: "whatHappened",
      placeholder: "What happened? *",
      options: { required: true, minLength: 5 },
      error: false,
      multiline: true,
    },
    {
      key: "expectation",
      placeholder: "What did you expect to happen? *",
      options: { required: true, minLength: 5 },
      error: false,
      multiline: true,
    },
  ]

  const updateBugInfoField = (key, val) => {
    setBugInfo({
      ...bugInfo,
      [key]: val,
    })
  }

  const submit = async () => {
    const response = await submitBugReport(bugInfo);
    onFormSubmitted();
  }

  const hasFormErrors = Object.values(errors || {}).reduce((accum, curr) => accum || curr, false);
  //const hasInputErrors = Object.values(inputErrors || {}).reduce((accum, curr) => accum || curr, false);
  const hasErrors = hasFormErrors;
  return (
    <form
      onSubmit={handleSubmit(submit)}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <h2>
        Report Bug
      </h2>
      {fields.map(field => (
        <FormField
          value={bugInfo[field.key]}
          placeholder={field.placeholder}
          onChange={(evt) => updateBugInfoField(field.key, evt.target.value)}
          innerRef={register(field.options)}
          error={field.error}
          name={field.key}
          multiline={field.multiline}
        />
      ))}

      <input
        disabled={hasErrors}
        className={"button-text signin-button"}
        style={{
          color: "#fff",
          border: "none",
          opacity: hasErrors ? 0.3 : undefined,
        }}
        type="submit"
        value="SUBMIT"
      />
    </form>
  );
}

const FormField = ({
  placeholder,
  innerRef,
  onChange,
  value,
  name,
  error,
  multiline,
} : {
  placeholder,
  innerRef,
  onChange,
  value,
  name,
  error?,
  multiline?,
}) => {
  const SelectedInput = multiline ? MultiLine : Input;
  
  return (
    <Column>
      <SelectedInput
        value={value}
        placeholder={placeholder}
        className="input"
        onChange={onChange}
        ref={innerRef}
        name={name}
      />
      {error && (
        <span style={{ color: "#B22222", marginBottom: "-24px" }}>
          {error}
        </span>
      )}
    </Column>
  );
}

const Input = (props) => <input {...props} />;
const MultiLine = (props) => <textarea {...props} />;
