import React from "react";
import { PlayerService } from "services/PlayerService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Player } from "models/Player";
import colors from "constants/colors";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import PlayerAvatar from "shared/player-avatar";
import { GameEvent } from "models/GameEvent";
import { useFirestore } from "react-redux-firebase";
import { useMe } from "@tara-wilson/mcc-lib";
import { toast } from "react-toastify";

function PlayerCalendarEvent(props: {
  event: GameEvent;
  prospects: Player[];
  onViewCalendar: any;
  light?: boolean;
  condensed?: boolean;
}) {
  const firestore = useFirestore();
  const me = useMe();
  let event = props.event;
  const relatedProspects = () => {
    if (event && event.teamOne) {
      return (props.prospects || []).filter(
        (pl) =>
          (event.teamOne !== "" && pl.summer_team === event.teamOne) ||
          (event.teamTwo !== "" && pl.summer_team === event.teamTwo) ||
          (event.addedProspectIds || []).includes(pl.external_id)
      );
    }
    return [];
  };

  let teamOne = event.teamOne || "Team One";
  let teamTwo = event.teamTwo || "Team Two";

  let location =
    event.game_title && event.game_title !== ""
      ? event.game_title.split(" No ")[0]
      : `${event.city && event.city !== "" ? event.city : ""}${
          event.city && event.city !== "" ? ", " : ""
        }${event.state || ""}`;

  let eventDate = moment(new Date(event.eventDate.seconds * 1000));

  const addToCalendar = async () => {
    const eventsRefConfig = {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "events" }],
    };

    await firestore.add(eventsRefConfig, {
      ...event,
      relatedId: event.id,
      shared: false,
    });

    toast.dark(`Event added to calendar!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <OverlayTrigger
      trigger={["click", "focus"]}
      placement={"right"}
      overlay={
        <Popover id={`cal-event`}>
          <Popover.Title
            as="h3"
            style={{
              backgroundColor: colors.accent,
              color: "#fff",
              textAlign: "center",
              padding: 10,
            }}
          >
            <p style={{ fontWeight: "bold" }}>{`${teamOne}`}</p>
            <p style={{ fontStyle: "italic" }}>{` vs. `}</p>
            <p style={{ fontWeight: "bold" }}> {`${teamTwo}`}</p>
          </Popover.Title>
          <Popover.Content style={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                padding: 14,
              }}
            >
              <p
                style={{
                  color: colors.accent,
                  fontWeight: 800,
                  marginBottom: 8,
                  marginTop: 4,
                }}
              >
                LOCATION
              </p>
              <p>{location}</p>
              {event.street ? (
                <p style={{}}>{`${event.street}, ${event.city}, ${
                  event.state
                } ${event.zip || ""}`}</p>
              ) : (
                <div />
              )}
              <p
                style={{
                  color: colors.accent,
                  fontWeight: 800,
                  marginBottom: 8,
                  marginTop: 16,
                }}
              >
                DATE
              </p>
              <p>{eventDate.format("MM/DD hh:mm a")}</p>
              {relatedProspects().length > 0 ? (
                <div>
                  <p
                    style={{
                      color: colors.accent,
                      fontWeight: 800,
                      marginBottom: 8,
                      marginTop: 16,
                    }}
                  >
                    MY PROSPECTS
                  </p>
                  {relatedProspects().map((option, index) => (
                    <Link
                      to={`/${option.college ? "collegeplayer" : "player"}/${
                        option.external_id
                      }`}
                      key={index}
                    >
                      <div
                        {...props}
                        style={{
                          display: "flex",
                          marginBottom: 3,
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <PlayerAvatar
                            player={option}
                            blueBackground={true}
                            containerStyles={{ marginRight: 12 }}
                          />
                          {PlayerService.getName(option)}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
            <div
              style={{
                padding: 8,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                backgroundColor: colors.accent,
              }}
            >
              {event.shared ? (
                <UnderlineButton
                  text="add to my calendar"
                  onClick={() => addToCalendar()}
                  color={colors.white}
                />
              ) : (
                <UnderlineButton
                  text="view calendar"
                  onClick={() => props.onViewCalendar(event)}
                  color={colors.white}
                />
              )}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <div
        style={{
          backgroundColor: props.light ? colors.white : colors.accent,
          borderWidth: props.light ? 2 : 0,
          borderColor: colors.lightBlue,
          borderStyle: "solid",
          marginRight: 12,
          minWidth: 350,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          marginBottom: 8,
          padding: 12,
          cursor: "pointer",
        }}
      >
        {props.condensed ? (
          <div />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontStyle: "italic",
              color: props.light ? colors.accent : "white",
              marginBottom: 6,
            }}
          >
            <p style={{ whiteSpace: "nowrap", marginRight: 12 }}>
              {moment(new Date(event.eventDate.seconds * 1000)).format(
                "MM/DD hh:mm a"
              )}
            </p>
            <p style={{ textAlign: "right", whiteSpace: "nowrap" }}>
              {location}
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {props.condensed ? (
            <div />
          ) : (
            <p
              style={{
                fontWeight: 800,
                fontSize: 18,
                margin: 4,
                color: props.light ? colors.accent : "white",
              }}
            >
              VS.
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 6,
              color: props.light ? colors.accent : "white",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: props.light ? colors.accent : "white",
              }}
            >
              {event.teamOne || "Team One"}
            </p>
            <p style={{ color: colors.lightBlue, fontWeight: "bold" }}>
              {event.teamTwo || "Team Two"}
            </p>
          </div>
        </div>
      </div>
    </OverlayTrigger>
  );
}

export default PlayerCalendarEvent;
