/*****************************************************************************
 * Import
 *****************************************************************************/
import React, { useEffect, useState, useRef, useMemo } from "react";
import "./player-page.css";
import { Box, Row, Stack } from "components/ui/containers";
import { uniq } from "lodash";

import colors from "constants/colors";
import GeorgiaLsuData from "assets/Synergy - Baseball API - Sample Game - 2023-05-18 Louisiana State at Georgia (1).json";
import { FiPlayCircle } from "react-icons/fi";
import { FaCheck, FaChevronUp, FaChevronDown, FaFilter } from "react-icons/fa";
import {MdOutlineFilterList } from "react-icons/md";

/*****************************************************************************
 * Public Components
 *****************************************************************************/

export const PitchAnalysis = ({
  player,
  setVisiblePlay,
  setVisibleGame,
}) => {
  const gameData = GeorgiaLsuData.result;

  // !blw: for now, we are just demoing pitchers
  const playerGameData = gameData.filter(play => {
    let pitcherName = `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`;
    let selectedPlayerName = `${player?.first_name} ${player?.last_name}`

    return selectedPlayerName === pitcherName;
  })


  const watchPlay = (playId, visibleRows) => {
    const visiblePlay = playerGameData.find(play => play.id === playId);
    const playIds = visibleRows.map(row => row.id)
    const game = playerGameData.filter(play => playIds.includes(play.id))
    
    setVisiblePlay(visiblePlay)
    setVisibleGame(game)
  };

  const columns = [
    {
      key: "playButton",
      title: "Watch",
      render: (play, uniques) => (
        <Box pl="16px">
          <PlayButton play={play} uniques={uniques} watchPlay={watchPlay} />
        </Box>
      ),
    },
    { key: "date", title: "Date" },
    { key: "inning", title: "Inn" },
    { key: "count", title: "Count", filter: true },
    { key: "outs", title: "Out" },
    { key: "pitcher", title: "Pitcher", filter: true },
    { key: "batter", title: "Batter", filter: true },
    { key: "pitchKind", title: "Type", filter: true },
    { key: "pitchResult", title: "PitchResult", filter: true },
    { key: "pitchSpeed", title: "Vel", filter: true },
    { key: "id", hidden: true }
  ];

  const rows = useMemo(() => (
    playerGameData.map(play => calcPlayRow(play))
  ), [playerGameData]);

  const uniques = useMemo(() => (
    columns.reduce((uniques, column) => {
      if (column.filter) {
        uniques[column.key] = [...new Set(rows.map(row => row[column.key]))].sort();
      }
      return uniques;
    }, {})
  ), [rows]);

  const [filters, setFilters] = useState(uniques)

  const updateColumnFilter = (key: string) => (value: any) => {
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  const filteredRows = useMemo(() => (
    rows.filter(row => (
      columns.reduce((rowValid, column) => {
        return !column.filter ? rowValid
          : rowValid && filters[column.key].includes(row[column.key])
      }, true)
    ))
  ), [rows]);

  return player && (
    <div
      style={{ overflow: "scroll", height: "400px" }}
      className="visibleScrollbar"
    >
      <table
        style={{
          width: "100%",
          borderColor: colors.lightestBlue,
          borderWidth: 1,
          borderStyle: "solid",
          padding: "4px",
        }}
      >
        {columns.filter(column => !column.hidden).map(column => (
          <TableHeader
            header={column}
            uniqueColValues={uniques[column.key]}
            filters={filters[column.key]}
            setFilters={updateColumnFilter(column.key)}
          />
        ))}

        {filteredRows.map((row, idx) => (
          <tr key={idx} style={{ backgroundColor: idx % 2 ? colors.lightGray : colors.white }}>
            {Object.entries(row).map(([key, value]) => {
              const column = columns.find(column => column.key === key);
              
              return !column.hidden && (
                <td key={key}>
                  {column?.render?.(row.id, filteredRows) || value}
                </td>
              );
            })}
          </tr>
        ))}
      </table>
    </div>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const PlayButton = ({ play, uniques, watchPlay }) => {
  return (
    <FiPlayCircle
      onClick={() => watchPlay(play, uniques)}
      style={{
        cursor: "pointer",
      }}
    />
  );
}

const PitchAnalysisRow = ({ play, onPressWatch }) => {
  const columns = [
    <FiPlayCircle
      onClick={onPressWatch}
      style={{
        cursor: "pointer",
      }}
    />,
    new Date(play.dateUpdated).toLocaleDateString("en-US"),
    `${!!play.inningTop ? "Top" : "Bot"} ${play.inning}`,
    `${play.count.balls}-${play.count.strikes}`,
    `${play.outs}`,
    `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`,
    `${play.batter.nameFirst} ${play.batter.nameLast}`,
    play.pitch?.pitchKind,
    play.pitch?.pitchResult,
    play.pitch?.pitchSpeedMph,
  ]
  
  return (
    <tr
      className="pitch-analysis-row"
    >
      {columns.map(column => (
        <PitchAnalysisEntry value={column || "-"} />
      ))}
    </tr>
  );
}

/****************************************************************************/

/* interface Header<T> {
*   value: T;
*   filter: boolean;
* } */
const TableHeader = ({
  header,
  uniqueColValues,
  filters,
  setFilters,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleClickHeader = () => {
    if (header.filter) {
      setShowFilter(true)
    }
  }

  const allSelected = header.filter && filters.length === uniqueColValues.length

  const FilterIcon = allSelected ? MdOutlineFilterList : FaFilter;
  
  return (
    <th
      //colSpan={header.colSpan}
      style={{
        //width: `${header.getSize()}px`
        color: "white",
        position: "sticky",
        top: 0,
      }}
    >
      <Row
        p="4px"
        align="center"
        color={colors.accent}
        gap="4px"
        interactColor={header.filter ? colors.lightBlue : undefined}
      >
        <Row
          flex={1}
          justify="space-between"
          align="center"
          onClick={handleClickHeader}
          style={{ cursor: "pointer" }}
        >
          {header.title}
        </Row>

        {header.filter && (
          <FilterIcon
            size={12}
            //onClick={() => setShowFilter(true)}
            style={{ minWidth: "12px" }}
          />
        )}
      </Row>
      
      {showFilter && (
        <FilterOverlay
          close={() => setShowFilter(false)}
          //column={value}
          unique={uniqueColValues}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </th>
  );
}

/****************************************************************************/

const FilterOverlay = ({
  close,
  unique,
  filters,
  setFilters,
}: {
  close: Function;
  unique: any[];
  filters: any;
  setFilters: Function;
}) => {
  const ref = useRef(null);
  useOutsideClickCallback(ref, close);

  //onChange={value => column.setFilterValue(value)}

  const clear = () => {
    setFilters([]);
  }

  const selectAll = () => {
    setFilters(unique);
  }
  
  return (
    <Stack
      ref={ref}
      pos="absolute"
      maxH="200px"
      color={colors.lightGray}
      border="2px solid black"
      style={{ overflowY: "scroll", color: "black" }}
    >
      <Row px="8px" justify="space-between" fullX>
        <p
          onClick={clear}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          clear
        </p>
        <p
          onClick={selectAll}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          select all
        </p>
      </Row>

      {unique.map((value, idx) => (
        <FilterCheckItem
          key={idx}
          value={value}
          filters={filters}
          setFilters={setFilters}
        />
      ))}
    </Stack>
  );
}

/****************************************************************************/

const FilterCheckItem = ({ value, filters, setFilters }) => {
  const filterSelected = filters.includes(value)

  const handleClick = () => {
    setFilters(filterSelected
      ? filters.filter(item => item !== value)
      : [...filters, value]
    )
  }

  return (
    <Row
      px="8px"
      gap="8px"
      align="center"
      interactColor={colors.lightestBlue}
      onClick={handleClick}
    >
      <FaCheck size={12} style={{ visibility: filterSelected ? undefined : "hidden" }} />
      <p style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        {value || "<blank>"}
      </p>
    </Row>
  );
}

/****************************************************************************/

const PitchAnalysisEntry = ({ value }) => {
  return (
    <td
      style={{
        padding: "4px",
        whiteSpace: "nowrap",
      }}
    >
      {value}
    </td>
  );
}

/*****************************************************************************
 * Helper Functions
 *****************************************************************************/

const calcPlayRow = (play) => {
  const columns = {
    /* playButton: (
*   <FiPlayCircle
*     onClick={() => watchPlay(play)}
*     style={{
*       cursor: "pointer",
*     }}
*   />
     * ), */
    playButton: "",
    date: new Date(play.dateUpdated).toLocaleDateString("en-US"),
    inning: `${!!play.inningTop ? "Top" : "Bot"} ${play.inning}`,
    count: `${play.count.balls}-${play.count.strikes}`,
    outs: `${play.outs}`,
    pitcher: `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`,
    batter: `${play.batter.nameFirst} ${play.batter.nameLast}`,
    pitchKind: play.pitch?.pitchKind,
    pitchResult: play.pitch?.pitchResult,
    pitchSpeed: play.pitch?.pitchSpeedMph,
    id: play.id
  }

  return columns;
}


/*****************************************************************************
 * Helper Hooks
 *****************************************************************************/

const useOutsideClickCallback = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
