import React, { useEffect, useState } from "react";
import { PlayerService } from "services/PlayerService";

export default function PlayerAvatar({
  player,
  blueBackground,
  containerStyles,
}) {
  let pic = PlayerService.getServerPhoto(player);
  return pic ? (
    <img
      src={pic}
      style={{
        ...containerStyles,
        width: 35,
        height: 35,
        overflow: "hidden",
        borderRadius: 35 / 2,
        objectFit: "cover",
        objectPosition: "0 10%",
      }}
    />
  ) : (
    <div
      style={{
        ...containerStyles,
        width: 35,
        height: 35,
        backgroundColor: blueBackground ? "#003366" : "white",
        borderRadius: 35 / 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: blueBackground ? "#fff" : "#003366",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        {PlayerService.getInitials(player)}
      </p>
    </div>
  );
}
