export function SAFE_toFixed(val: any, fixnum: number): string {
  let cleanVal = val && !isNaN(parseFloat(`${val}`)) ? parseFloat(`${val}`) : 0;
  return parseFloat(`${cleanVal || 0}`).toFixed(fixnum);
}

export const toArray = (map: Object = {}) => {
  return Object.keys(map).map((key) => {
    return { ...map[key], id: key };
  });
};

export const validateEmailFormat = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !!email && !!email.match(emailRegex);
}
