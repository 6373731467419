import { TEAMS } from "constants/data";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const AffiliatedTeamDropdown = ({ selectTeam }) => {
  return (
    <Typeahead
      labelKey="name"
      id="affiliated_team"
      options={TEAMS.map((item) => ({ name: item }))}
      onChange={(vals) => selectTeam(vals.map((item) => item.name))}
      placeholder="Select..."
    />
  );
};

export default AffiliatedTeamDropdown;
