import { MonitorQuery } from "models/MonitorQuery";
import { User } from "models/User";
let ENDPOINT_BASE = `https://scout-sense-dev-app.azurewebsites.net/api`;
const API_KEY = `8iJ904jQr8X8tm7gt/v6pRG2EjI9AtkFRGJrbPJYBvr991izUIWNDg==`;

const getFetchParams = () => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  return {
    method: "GET",
    headers: headers,
  };
};

const getPostParams = (body: any) => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  return {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  };
};

const getURL = (endpoint: string) => {
  // console.log(`${ENDPOINT_BASE}/${endpoint}?code=${API_KEY}`);
  return `https://tara-proxy.herokuapp.com/${ENDPOINT_BASE}/${endpoint}?code=${API_KEY}`;
};

export const createUser = (user: User) => {
  return fetch(
    getURL("users"),
    getPostParams({
      ss_user_id: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
    })
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

const getSearch = (userId: string, query: MonitorQuery, offset: number) => {
  let anyVal = [...(query.any || [])];
  if (query.text) {
    anyVal.push(query.text);
  }

  let search = {
    user_id: userId,
    sources: (query.source || []).map((item) => item.guid),
    days_back: parseInt(`${query.daysBack}`) || 60,
    all: query.all || [],
    any: anyVal,
    none: query.none || [],
    limit: 20,
    offset: offset,
  };

  return search;
};

export const basicSearch = (
  userId: string,
  query: MonitorQuery,
  offset: number
) => {
  let search = getSearch(userId, query, offset);

  return fetch(getURL("search"), getPostParams(search))
    .then((response) => {
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const getSources = () => {
  return fetch(getURL("sources"), getFetchParams())
    .then((response) => {
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const createDiscovery = (
  userId: string,
  term: string,
  sources: string[]
) => {
  let createBody = {
    user_id: userId,
    term: term,
    sources: sources,
  };

  return fetch(`${getURL("discoveries")}`, getPostParams(createBody))
    .then((response) => {
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const deleteDiscovery = (userId: string, discoveryId: string) => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");

  return fetch(getURL(`discoveries/${discoveryId}`), {
    method: "DELETE",
    headers: headers,
  }).then((response) => {
    if (response.status == 204) {
      return { ok: true };
    }
    return { ok: false };
  });
};

export const updateDiscovery = (
  userId: string,
  term: string,
  sources: string[],
  collection_active: boolean,
  discoveryId: string
) => {
  let createBody = {
    user_id: userId,
    term: term,
    sources: sources,
    collection_active: collection_active,
  };

  let params = {
    ...getPostParams(createBody),
    method: "PATCH",
  };

  return fetch(getURL(`discoveries/${discoveryId}`), params)
    .then((response) => {
      console.log("response", response);
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const fetchDocument = (userId: string, documentId: string) => {
  return fetch(
    getURL("search"),
    getPostParams({
      user_id: userId,
      query_string: `guid:${documentId}`,
      // tara later
      sources: [
        "fe975e740116cd7b90c5da74f58a6c9c3527898899efe6213d4551de6bf80e5d",
        "7e0442f5a0674357aaa7ec3fb932f672",
      ],
      days_back: 60,
    })
  )
    .then((response) => {
      console.log("got response");
      return response;
    })
    .then((response) => response.json());
};
