import React from "react";
import "../../App.css";
import "./settings.css";
import Switch from "react-switch";
import { MdChevronRight } from "react-icons/md";
import { compose } from "recompose";
import { toast } from "react-toastify";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
  useFirestore,
  useFirebase,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AiFillPlusCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { ScoutNotification } from "models/ScoutNotification";
import CreateRequestModal from "../../modals/create-request-modal";
import AcceptRequestModal from "../../modals/accept-request-modal";
import RemoveFriendModal from "../../modals/remove-friend-modal";
import FriendName from "../../popovers/friend-name";
import colors from "constants/colors";
import PendingRequestName from "../../popovers/pending-request-name";
import AreYouSureModal from "modals/are-you-sure-modal";
import { NotificationService } from "services/NotificationService";
import ScoutTitle from "shared/scout-title";
import HelpModal from "modals/help-modal";
import EditProfileModal from "modals/edit-profile-modal";
import UnderlineButton from "shared/underline-button";
import DeleteAccountModal from "modals/delete-account-modal";
import EditSavedSearchModal from "modals/edit-saved-search-modal";
import { SavedSearch } from "models/SavedSearch";
import PrepCredentialsModal from "modals/prep-credentials-modal";
import PerfectGameCredentialsModal from "modals/pg-credentials-modal";
import firebase from "firebase";
import MondayCredentialsModal from "modals/monday-credentials-modal";

function Settings(props: any) {
  const profile = useSelector((state) => state.firebase.profile);
  const userId = useSelector((state) => state.firebase.auth.uid);
  const mySearches = useSelector((state) => state.firestore.data.mySearches);

  const users = useSelector((state) => state.firestore.data.users);
  const pendingRequests = useSelector(
    (state) => state.firestore.data.pendingRequests
  );
  const recievedRequests = useSelector(
    (state) => state.firestore.data.recievedRequests
  );

  const fb = useFirebase();
  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId || "none",
      subcollections: [{ collection: "searches" }],
      storeAs: "mySearches",
    },
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
    { collection: "users", where: ["friends", "array-contains", userId] },
    {
      collection: "notifications",
      where: [
        ["category", "==", "friends"],
        ["sender", "==", userId],
        ["removed", "==", false],
      ],
      storeAs: `pendingRequests`,
    },
    {
      collection: "notifications",
      where: [
        ["category", "==", "friends"],
        ["recipient", "==", userId],
        ["removed", "==", false],
      ],
      storeAs: `recievedRequests`,
    },
  ]);
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [acceptRequestModalShow, setAcceptRequestModalShow] =
    React.useState(null);
  const [removeFriendModalShow, setRemoveFriendModalShow] =
    React.useState(null);
  const [editProfileModalShow, setEditProfileModalShow] = React.useState(false);

  const [showRevokeAccess, setShowRevokeAccess] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [showPrepCredentialsModal, setShowPrepCredentialsModal] =
    React.useState(false);
  const [showPerfectGameCredentialsModal, setShowPerfectGameCredentialsModal] =
    React.useState(false);
  const [showMondayCredentialsModal, setShowMondayCredentialsModal] =
    React.useState(false);

  const [showFriendsHelp, setShowFriendsHelp] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [editSavedSearch, setEditSavedSearch] = React.useState(null);

  React.useEffect(() => {
    if (
      profile &&
      profile.credentials &&
      profile.credentials.pg &&
      profile.credentials.pg.processing
    ) {
      const interval = setInterval(() => {
        checkPGCreds();
      }, 60000);
      return () => clearInterval(interval);
    } else {
      return () => {};
    }
  }, [profile]);

  React.useEffect(() => {
    if (
      profile &&
      profile.credentials &&
      profile.credentials.prep &&
      profile.credentials.prep.processing
    ) {
      const interval = setInterval(() => {
        checkPrepCreds();
      }, 60000);
      return () => clearInterval(interval);
    } else {
      return () => {};
    }
  }, [profile]);

  const checkPGCreds = () => {
    console.log("checkPGCreds");
    if (
      profile &&
      profile.credentials &&
      profile.credentials.pg &&
      profile.credentials.pg.processing
    ) {
      let callable = firebase
        .functions()
        .httpsCallable("checkCredentialsInTable");
      try {
        callable({
          email: profile.credentials.pg.email,
          domain: "Perfect Game",
        }).then((res) => {
          console.log("res");
          if (
            res &&
            res.data &&
            res.data.results &&
            res.data.results.length > 0
          ) {
            let success = false;
            let processing = false;

            res.data.results.forEach((result) => {
              if (result.validation == 1) {
                success = true;
              }
              if (result.status != "done") {
                processing = true;
              }
            });

            if (!processing) {
              let credentials = {
                ...profile.credentials,
                pg: {
                  ...profile.credentials.pg,
                  processing: false,
                  approved: success,
                },
              };

              const docRefConfig = {
                collection: "users",
                doc: userId,
              };

              firestore.update(docRefConfig, { credentials: credentials });
            }
          }
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      console.log("not processing");
    }
  };

  const checkPrepCreds = () => {
    console.log("checkPrepCreds");
    if (
      profile &&
      profile.credentials &&
      profile.credentials.prep &&
      profile.credentials.prep.processing
    ) {
      let callable = firebase
        .functions()
        .httpsCallable("checkCredentialsInTable");
      try {
        callable({
          email: profile.credentials.prep.email,
          domain: "Prep Baseball",
        }).then((res) => {
          if (
            res &&
            res.data &&
            res.data.results &&
            res.data.results.length > 0
          ) {
            let success = false;
            let processing = false;

            res.data.results.forEach((result) => {
              if (result.validation == 1) {
                success = true;
              }
              if (result.status != "done") {
                processing = true;
              }
            });

            if (!processing) {
              let credentials = {
                ...profile.credentials,
                prep: {
                  ...profile.credentials.prep,
                  processing: false,
                  approved: success,
                },
              };

              const docRefConfig = {
                collection: "users",
                doc: userId,
              };

              firestore.update(docRefConfig, { credentials: credentials });
            }
          }
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      console.log("not processing");
    }
  };

  if (!isLoaded(profile)) {
    return <div />;
  }

  const sortedFriends = () => {
    if (!isLoaded(users)) {
      return [];
    }

    let friends = users
      ? Object.keys(users).map((key) =>
          users ? { ...users[key], id: key } : null
        )
      : [];

    return friends.filter((item) => item !== null);
  };

  const pending = (): ScoutNotification[] => {
    if (!isLoaded(pendingRequests)) {
      return [];
    }

    let pending = pendingRequests
      ? Object.keys(pendingRequests).map((key) =>
          pendingRequests ? { ...pendingRequests[key], id: key } : null
        )
      : [];

    return pending.filter(
      (item) =>
        item &&
        item.recipientName &&
        !item.removed &&
        !(profile.friends || []).includes(item.recipient)
    );
  };

  const received = () => {
    if (!isLoaded(recievedRequests)) {
      return [];
    }

    let recieved = recievedRequests
      ? Object.keys(recievedRequests).map((key) =>
          recievedRequests ? { ...recievedRequests[key], id: key } : null
        )
      : [];

    return recieved.filter(
      (item) =>
        item !== null &&
        item.senderName !== null &&
        !item.removed &&
        !(profile.friends || []).includes(item.sender)
    );
  };

  const updateProfile = async (update: any) => {
    const meRefConfig = {
      collection: "users",
      doc: userId,
    };

    await props.firestore.update(meRefConfig, update);
  };

  const sortedSearches = () => {
    if (!isLoaded(mySearches)) {
      return [];
    }

    let searches = mySearches
      ? Object.keys(mySearches).map((key) =>
          mySearches ? { ...mySearches[key], id: key } : null
        )
      : [];

    return searches.filter((item) => item && item.id && item.name);
  };

  const deleteAccount = async (password) => {
    setShowDeleteModal(false);

    var user = fb.auth().currentUser;
    //@ts-ignore
    const credential = fb.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    user
      .reauthenticateWithCredential(credential)
      .then(async function () {
        let u = await firestore.collection("users").doc(userId).delete();

        let d = await user.delete();

        await fb.logout();
        history.push("/");
      })
      .catch(function (error) {
        toast.dark(`Incorrect password.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };

  const toggleSearch = (search: SavedSearch) => {
    if (!search.active && search.count > 150) {
      toast.dark(
        "You cannot get player notifications for searches with more than 150 results.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
    } else {
      firestore
        .collection("users")
        .doc(userId)
        .collection("searches")
        .doc(search.id)
        .update({ active: !search.active });
    }
  };

  const renderPlayerMatching = () => {
    return profile.notifyPlayerMatching ? (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ScoutTitle
          text="NEW PLAYER NOTIFICATIONS"
          styles={{ textAlign: "center" }}
        />

        <div className={"inner-container"}>
          {sortedSearches().length > 0 ? (
            sortedSearches().map((item, index) => {
              return (
                <div
                  key={index}
                  className={"row-container"}
                  style={{
                    borderBottomWidth:
                      index === sortedSearches().length - 1 ? 0 : 1,
                  }}
                >
                  <div
                    onClick={() => setEditSavedSearch(item)}
                    style={{ flex: 1 }}
                  >
                    <p className={"row-text"}>{item.name}</p>
                  </div>
                  <div style={{ flexDirection: "row" }}>
                    <Switch
                      onChange={() => toggleSearch(item)}
                      checked={item.active}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor={colors.lightBlue}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p
              className={"row-text"}
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 8,
                paddingRight: 8,
                textAlign: "center",
              }}
            >
              No saved searches yet.
            </p>
          )}
        </div>
      </div>
    ) : (
      <div />
    );
  };

  const renderFriendsColumn = () => {
    return (
      <div
        className="set-outer-container"
        style={{
          flex: 1,
          justifyContent: "flex-start",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ScoutTitle
            text={"CURRENT FRIENDS"}
            withPlus={true}
            onClick={() => {
              setModalShow(true);
            }}
          />
          <Button
            onClick={() => setShowFriendsHelp(true)}
            variant="secondary"
            style={{
              marginTop: -4,
              marginLeft: -16,
              color: colors.accent,
              fontSize: 20,
              backgroundColor: "transparent",
              borderWidth: 0,
              boxShadow: "none",
            }}
          >
            <AiOutlineQuestionCircle />
          </Button>
        </div>

        <div className="inner-container">
          {(sortedFriends() || []).map((item, index) => {
            return (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                key={index}
              >
                <FriendName
                  friend={item}
                  containerStyles={{
                    padding: "0.5rem",
                  }}
                  blueAvatar={true}
                  setShowRemoveFriend={setRemoveFriendModalShow}
                  setShowRevokeAccess={setShowRevokeAccess}
                />
                <div style={{ flex: 1 }} />
              </div>
            );
          })}
          {(sortedFriends() || []).length === 0 ? (
            <p
              style={{
                fontStyle: "italic",
                marginTop: 30,
                marginBottom: 30,
                opacity: 0.6,
                textAlign: "center",
              }}
            >
              No friends added yet.
            </p>
          ) : (
            <div />
          )}
        </div>

        <ScoutTitle text="PENDING FRIEND REQUESTS" />

        <div className="inner-container">
          {(pending() || []).map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: 9,

                  padding: "1rem",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 35,
                    height: 35,
                    backgroundColor: "#003366",
                    borderRadius: 35 / 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    {`${
                      item.recipientName &&
                      item.recipientName.split(" ").length > 0
                        ? `${item.recipientName
                            .split(" ")[0]
                            .charAt(0)}${item.recipientName
                            .split(" ")[1]
                            .charAt(0)}`
                        : ""
                    }`}
                  </p>
                </div>
                <p style={{ marginLeft: 8 }}>{`${item.recipientName}`}</p>
                <div style={{ flex: 1 }} />
                <UnderlineButton
                  text="remove"
                  onClick={() => {
                    NotificationService.markNotificationRemoved(
                      item,
                      props.firestore
                    );
                  }}
                />
              </div>
            );
          })}

          {(pending() || []).length === 0 ? (
            <p
              style={{
                fontStyle: "italic",
                marginTop: 30,
                marginBottom: 30,
                textAlign: "center",
                opacity: 0.6,
              }}
            >
              No pending requests.
            </p>
          ) : (
            <div />
          )}
        </div>

        <ScoutTitle text="RECEIVED FRIEND REQUESTS" />

        <div className="inner-container">
          {(received() || []).map((item, index) => {
            return (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                key={index}
              >
                <PendingRequestName
                  request={item}
                  containerStyles={{
                    padding: "0.5rem",
                  }}
                  fromMe={false}
                  setShowModal={setAcceptRequestModalShow}
                />
                <div style={{ flex: 1 }} />
              </div>
            );
          })}

          {(received() || []).length === 0 ? (
            <p
              style={{
                fontStyle: "italic",
                marginTop: 30,
                marginBottom: 30,
                opacity: 0.6,
                textAlign: "center",
              }}
            >
              No received requests.
            </p>
          ) : (
            <div />
          )}
        </div>

        <CreateRequestModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          friends={sortedFriends()}
          me={{ ...profile, id: userId }}
          pendingRequests={pending()}
        />
        <AcceptRequestModal
          show={acceptRequestModalShow !== null}
          onHide={() => setAcceptRequestModalShow(null)}
          me={{ ...profile, id: userId }}
          request={acceptRequestModalShow}
        />
        <RemoveFriendModal
          show={removeFriendModalShow !== null}
          onHide={() => setRemoveFriendModalShow(null)}
          me={{ ...profile, id: userId }}
          user={removeFriendModalShow}
        />
        <AreYouSureModal
          //@ts-ignore
          show={showRevokeAccess}
          onConfirm={async () => {
            setShowRevokeAccess(null);

            await NotificationService.revokeBoardAccess(
              [showRevokeAccess.id],
              { ...profile, id: userId },
              {
                senderId: userId,
                senderName: `${profile.firstName} ${profile.lastName}`,
              },
              props.firestore
            );
            toast.dark(`Access revoked.`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }}
          onHide={() => setShowRevokeAccess(null)}
          title={"Revoke access"}
          body={`Are you sure you want to revoke ${
            //@ts-ignore
            showRevokeAccess ? showRevokeAccess.firstName : ""
          }'s access to your Big Board?`}
        />
      </div>
    );
  };

  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      <div className="set-outer-container" style={{ flex: 1 }}>
        <ScoutTitle
          text="NOTIFICATION SETTINGS"
          withHelp={true}
          onClick={() => setShowHelp(true)}
        />

        <div className={"settings-container"}>
          {/* Top */}
          <div className={"inner-container"}>
            <div className={"row-container"}>
              <p
                className={"row-text"}
                style={{ fontFamily: "Raleway", fontStyle: "bold" }}
              >
                Friend Requests
              </p>
              <Switch
                onChange={() =>
                  updateProfile({
                    notifyFriendRequests: !profile.notifyFriendRequests,
                  })
                }
                checked={profile.notifyFriendRequests}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.lightBlue}
              />
            </div>
            <div className={"row-container"}>
              <p className={"row-text"}>In-App Notifications</p>
              <Switch
                onChange={() =>
                  updateProfile({
                    notifyMessages: !profile.notifyMessages,
                  })
                }
                checked={profile.notifyMessages}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.lightBlue}
              />
            </div>
            <div className={"row-container"}>
              <p className={"row-text"}>Email Notifications</p>
              <Switch
                onChange={() =>
                  updateProfile({
                    emailNotifications: !profile.emailNotifications,
                  })
                }
                checked={profile.emailNotifications}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.lightBlue}
              />
            </div>
            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px" }}
            >
              <p className={"row-text"}>Player Matching</p>
              <Switch
                onChange={() =>
                  updateProfile({
                    notifyPlayerMatching: !profile.notifyPlayerMatching,
                  })
                }
                checked={profile.notifyPlayerMatching}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.lightBlue}
              />
            </div>
          </div>

          {renderPlayerMatching()}

          {/* Middle */}
          <div
            className={"inner-container"}
            style={{ marginTop: 30, borderBottomWidth: "0px" }}
          >
            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px", cursor: "pointer" }}
              onClick={() => {
                setEditProfileModalShow(true);
              }}
            >
              <p className={"row-text"}>Edit Profile</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold", color: colors.accent }}>
                  {` ${profile.firstName} ${profile.lastName}`}
                </p>
                <MdChevronRight className={"chevron"} />
              </div>
            </div>
            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px", cursor: "pointer" }}
              onClick={() => {
                setShowPerfectGameCredentialsModal(true);
              }}
            >
              <div>
                <p className={"row-text"}>Perfect Game Credentials</p>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    color: colors.accent,
                  }}
                >
                  {profile.credentials && profile.credentials.pg
                    ? profile.credentials.pg.email
                    : ""}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {profile.credentials && profile.credentials.pg ? (
                  profile.credentials.pg.processing ? (
                    <p
                      style={{
                        fontWeight: "bold",

                        fontSize: 13,
                        textAlign: "right",
                        color: colors.lightBlue,
                      }}
                    >
                      PROCESSING...
                    </p>
                  ) : profile.credentials.pg.approved ? (
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "green",
                        fontSize: 13,
                        textAlign: "right",
                      }}
                    >
                      APPROVED
                    </p>
                  ) : (
                    <p
                      style={{
                        fontWeight: "bold",
                        color: colors.red,
                        fontSize: 13,
                        textAlign: "right",
                      }}
                    >
                      INVALID
                    </p>
                  )
                ) : (
                  <p></p>
                )}
                <MdChevronRight className={"chevron"} />
              </div>
            </div>
            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px", cursor: "pointer" }}
              onClick={() => {
                setShowPrepCredentialsModal(true);
              }}
            >
              <div>
                <p className={"row-text"}>Prep Baseball Credentials</p>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    color: colors.accent,
                  }}
                >
                  {profile.credentials && profile.credentials.prep
                    ? profile.credentials.prep.email
                    : ""}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {profile.credentials && profile.credentials.prep ? (
                  profile.credentials.prep.processing ? (
                    <p
                      style={{
                        fontWeight: "bold",

                        fontSize: 13,
                        textAlign: "right",
                        color: colors.lightBlue,
                      }}
                    >
                      PROCESSING...
                    </p>
                  ) : profile.credentials.prep.approved ? (
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "green",
                        fontSize: 13,
                        textAlign: "right",
                      }}
                    >
                      APPROVED
                    </p>
                  ) : (
                    <p
                      style={{
                        fontWeight: "bold",
                        color: colors.red,
                        fontSize: 13,
                        textAlign: "right",
                      }}
                    >
                      INVALID
                    </p>
                  )
                ) : (
                  <p></p>
                )}
                <MdChevronRight className={"chevron"} />
              </div>
            </div>

            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px", cursor: "pointer" }}
              onClick={() => {
                setShowMondayCredentialsModal(true);
              }}
            >
              <div>
                <p className={"row-text"}>Monday.com Credentials</p>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    color: colors.accent,
                  }}
                >
                  {profile.credentials && profile.credentials.monday
                    ? profile.credentials.monday.boardName
                    : ""}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {profile.credentials && profile.credentials.monday ? (
                  profile.credentials.monday.workgroup ? (
                    <p
                      style={{
                        fontWeight: "bold",
                        color: colors.lightBlue,
                        fontSize: 13,
                        textAlign: "right",
                      }}
                    >
                      {profile.credentials.monday.workgroup}
                    </p>
                  ) : (
                    <p></p>
                  )
                ) : (
                  <p></p>
                )}
                <MdChevronRight className={"chevron"} />
              </div>
            </div>
          </div>

          <div
            className={"inner-container"}
            style={{ marginTop: 30, borderBottomWidth: "0px" }}
          >
            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px", cursor: "pointer" }}
              onClick={async () => {
                history.replace("/report");
              }}
            >
              <p className={"row-text"} style={{ color: colors.red }}>
                Report Bug                
              </p>
              <MdChevronRight className={"chevron"} />
            </div>
          </div>

          {/* Bottom */}
          <div
            className={"inner-container"}
            style={{ marginTop: 30, borderBottomWidth: "0px" }}
          >
            <div
              className={"row-container"}
              style={{ borderBottomWidth: "0px", cursor: "pointer" }}
              onClick={async () => {
                await props.firebase.logout();
                history.replace("/home");
              }}
            >
              <p className={"row-text"}>Log Out</p>
              <MdChevronRight className={"chevron"} />
            </div>
          </div>

        </div>
      </div>
      {renderFriendsColumn()}

      <HelpModal
        show={showFriendsHelp}
        kind={"FRIENDS"}
        onHide={() => setShowFriendsHelp(false)}
      />

      <HelpModal
        show={showHelp}
        kind={"SETTINGS"}
        onHide={() => setShowHelp(false)}
      />
      <EditProfileModal
        show={editProfileModalShow}
        onHide={() => setEditProfileModalShow(false)}
        onClickDelete={() => {
          setEditProfileModalShow(false);
          setShowDeleteModal(true);
        }}
      />
      <PrepCredentialsModal
        show={showPrepCredentialsModal}
        onHide={() => setShowPrepCredentialsModal(false)}
      />
      <PerfectGameCredentialsModal
        show={showPerfectGameCredentialsModal}
        onHide={() => setShowPerfectGameCredentialsModal(false)}
      />
      <MondayCredentialsModal
        show={showMondayCredentialsModal}
        onHide={() => setShowMondayCredentialsModal(false)}
      />
      <EditSavedSearchModal
        show={editSavedSearch !== null}
        savedSearch={editSavedSearch}
        onHide={() => setEditSavedSearch(null)}
        title={"Edit Saved Search"}
      />
      <DeleteAccountModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={(pass) => deleteAccount(pass)}
        title={"Delete Account"}
        body={
          "Are you sure you want to delete your account? Your account data will not be recoverable."
        }
      />
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(Settings);
