import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { QueryHelper } from "services/QueryHelper";
import { Player } from "models/Player";

export default function CollegeCommitmentDropdown({
  selectCollege,
  transparentInput,
  placeholder,
  value,
  maxWidth,
}: {
  selectCollege: any;
  transparentInput?: any;
  placeholder?: string;
  value?: string;
  maxWidth?: number;
}) {
  const [search, setSearch] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Player[]>([]);
  const filterBy = () => true;
  const [newEntry, setNewEntry] = useState(true);
  const [focused, setFocused] = useState(false);

  if (transparentInput && value && newEntry && !focused) {
    setNewEntry(false);
  }

  const handleSearch = async (query) => {
    setIsLoading(true);
    setSearch(query);

    let url = QueryHelper.urlForNameAutocomplete(
      query,
      "college_commitment",
      false
    );
    let res: {
      data: Player[];
      count: number;
    } = await QueryHelper.fetchDataForURLwithCount(url);
    if (res && res.data) {
      let opts: Player[] = [];
      let keys: string[] = [];
      res.data.forEach((item: Player) => {
        if (!keys.includes(item.college_commitment)) {
          opts.push({ ...item, id: item.external_id });
          keys.push(item.college_commitment);
        }
      });
      setOptions(opts);
      setIsLoading(false);
    }
  };

  return transparentInput ? (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={isLoading}
      labelKey="college_commitment"
      minLength={2}
      onSearch={handleSearch}
      options={options}
      //@ts-ignore
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) =>
        newEntry ? (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            placeholder={placeholder || "Team..."}
            style={{
              width: maxWidth || 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        ) : (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            value={value}
            onFocus={() => {
              setFocused(true);
              setNewEntry(true);
            }}
            placeholder={placeholder || "Team..."}
            style={{
              width: maxWidth || 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        )
      }
      onChange={(vals) => selectCollege(vals[0].college_commitment)}
      renderMenuItemChildren={(option, props) => (
        <div
          {...props}
          style={{
            marginLeft: -8,
            display: "flex",
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {option.college_commitment}
          </p>
        </div>
      )}
    />
  ) : (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={isLoading}
      labelKey="college_commitment"
      minLength={2}
      onSearch={handleSearch}
      options={options}
      placeholder={placeholder || "Search..."}
      onChange={(vals) => selectCollege(vals[0].college_commitment)}
      renderMenuItemChildren={(option, props) => (
        <div
          {...props}
          style={{
            marginLeft: -8,
            display: "flex",
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {option.college_commitment}
          </p>
        </div>
      )}
    />
  );

  // return (
  //   <AsyncTypeahead
  //     filterBy={filterBy}
  //     isLoading={isLoading}
  //     labelKey="college_commitment"
  //     minLength={2}
  //     onSearch={handleSearch}
  //     options={options}
  //     placeholder={placeholder ? placeholder : "College commitment..."}
  //     onChange={(vals) => selectCollege(vals[0].college_commitment)}
  //     renderMenuItemChildren={(option, props) => (
  //       <div
  //         {...props}
  //         style={{
  //           marginLeft: -8,
  //           display: "flex",
  //         }}
  //       >
  //         <p
  //           style={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //           }}
  //         >
  //           {option.college_commitment}
  //         </p>
  //       </div>
  //     )}
  //   />
  // );
}
