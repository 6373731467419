import colors from "constants/colors";
import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";

function NotLoggedInHome(props: any) {
  return (
    <div
      className="container"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "80vh",
      }}
    >
      <p>You are not signed in.</p>
      <Link
        className="nav-link"
        style={{
          textDecoration: "none",
          borderColor: "white",
          borderWidth: 1,
          borderStyle: "solid",
          paddingLeft: 12,
          paddingRight: 12,
          borderRadius: 6,
          color: colors.accent,
        }}
        to="/login"
      >
        SIGN IN
      </Link>
    </div>
  );
}

export default NotLoggedInHome;
