import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { QueryHelper } from "services/QueryHelper";
import { Player } from "models/Player";
import { PlayerService } from "services/PlayerService";
import "./player-page.css";
import { Link } from "react-router-dom";
import StarButton from "shared/star-button";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
  withFirestore,
} from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import {
  AiFillLock,
  AiFillPlusCircle,
  AiFillTwitterCircle,
  AiOutlineRight,
} from "react-icons/ai";
import { IoMdBaseball } from "react-icons/io";
import { CollegePlayerIcon } from "components/smart-icons";
import { Box, Row, Stack } from "components/ui/containers";

import SharePlayerModal from "../../modals/share-player-modal";
import EditPlayerModal from "../../modals/edit-player-modal";
import EditRosterPlayerModal from "../../modals/edit-roster-player-modal";
import PlayAnalysisModal from "modals/play-analysis-modal";
import { PositionReport } from "models/PositionReport";
import ReportCard from "pages/reports-list-page/components/report-card";
import colors from "constants/colors";
import HelpModal from "modals/help-modal";
import ScoutTitle from "shared/scout-title";
import PlayerCalendarEvent from "popovers/player-calendar-event";

import UnderlineButton from "shared/underline-button";
import moment from "moment";
import NotifyMeModal from "modals/notify-me-modal";
import PlayerScholarshipEditor from "./components/scholarship-editor";
import { SAFE_toFixed } from "services/utils";
import firebase from "firebase";
import * as XLSX from "xlsx";
import GeorgiaLsuData from "assets/Synergy - Baseball API - Sample Game - 2023-05-18 Louisiana State at Georgia (1).json";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
/* import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme */
import { Table } from "components/ui/table";
import { PitchAnalysis } from "./synergy-table";

import { FiPlayCircle } from "react-icons/fi";
import { FaCheck, FaChevronUp, FaChevronDown, FaFilter } from "react-icons/fa";

function PlayerPage(props: any) {
  let { id } = useParams();
  const [tracked, setTracked] = useState(false);
  const [player, setPlayer] = useState<Player | null>(null);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const userId = useSelector((state) => state.firebase.auth.uid);
  const profile = useSelector((state) => state.firebase.profile);
  const users = useSelector((state) => state.firestore.data.users);
  const myReports = useSelector((state) => state.firestore.data.myReports);
  const myEvents = useSelector((state) => state.firestore.data.myEvents);
  const sharedEvents = useSelector(
    (state) => state.firestore.data.sharedEvents
  );
  const myProspects = useSelector((state) => state.firestore.data.myProspects);
  const myRoster = useSelector((state) => state.firestore.data.myRoster);
  const history = useHistory();
  const firestore = useFirestore();
  const [source, setSource] = useState("PREP");
  /* const [source, setSource] = useState("PITCH_ANALYSIS"); */
  const mode = useSelector((state) => state.firestore.data.mode);

  console.log(player);

  const DEMO_SHOW_ANALYSIS = !player
    ? false
    : [
        "Will Pearson",
        "Chandler Marsh",
        "Jarvis Evans",
        "Nolan Crisp",
        "Collin Caldwell",
        "Leighton Finley",
        "Dalton Rhadans",
        "Paul Skenes",
        "Riley Cooper",
        "Thatcher Hurd",
      ].includes(`${player?.first_name} ${player?.last_name}`);

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
    { collection: "users", where: ["friends", "array-contains", userId] },
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports" }],
      storeAs: `myReports`,
    },
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "roster" }],
      storeAs: "myRoster",
    },
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "events" }],
      storeAs: `myEvents`,
    },
    {
      collection: "mode",
      doc: "flags",
    },
  ]);
  const [modalShow, setModalShow] = React.useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRosterEdit, setShowRosterEdit] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [visiblePlay, setVisiblePlay] = useState(null);
  const [visibleGame, setVisibleGame] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const listenForSharedEvents = async () => {
    await firestore.setListener({
      collection: "events",
      where: ["teams", "array-contains", player.summer_team],
      storeAs: `sharedEvents`,
    });
  };

  useEffect(() => {
    if (player && player.summer_team) {
      listenForSharedEvents();
    }
  }, [player]);

  const fetchDataForURLParams = async () => {
    if (!fetching) {
      setFetching(true);
      let p = await QueryHelper.getPlayerForExternalId(id);
      if (p && p.data && p.data.length > 0) {
        let players = p.data.filter((item) => item.external_id === id);
        if (players.length > 0) {
          let pl = players[0];

          if (myProspects && Object.keys(myProspects).includes(id)) {
            let pros = myProspects[id];
            if (pros) {
              setPlayer({
                ...pros,
                ...pl,
                primary_position: pros.primary_position,
                summer_team: pros.summer_team,
              });
            }
          } else {
            setPlayer(pl);
          }

          setFetching(false);
        }
      } else {
        if (myProspects && Object.keys(myProspects).includes(id)) {
          let pros = myProspects[id];
          console.log(pros);
          setPlayer(pros);
        }
        setFetching(false);
      }
    }
  };

  const exportSpreadsheet = () => {
    if (!player) {
      return;
    }

    const data = [
      {
        "FIRST NAME": player.first_name,
        "LAST NAME": player.last_name,
        CLASS: player.graduating_class,
        POSITION: player.position,
        "HIGH SCHOOL": player.high_school,
        STATE: player.state,
        BAT: player.bats,
        THROW: player.throws,
        HEIGHT: player.height,
        WEIGHT: player.weight,
        TWITTER: player.twitter_handle,
        "PHONE NUMBER": player.contact_phone,
        //"EMAIL": player.email,
        "SUMMER TEAM": player.summer_team,
      },
    ];

    //@ts-ignore
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    const widths = Object.keys(data[0]).map((key) =>
      Math.max(key.length, 10, (data[0][key] || "").length)
    );
    console.log(widths);
    worksheet["!cols"] = widths.map((width) => ({ wch: width }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workbook,
      `${PlayerService.getName(player)}-${player.id}.xlsx`
    );
  };

  if (isLoaded(myProspects) && player === null) {
    fetchDataForURLParams();
  } else if (player && player.external_id && player.external_id !== id) {
    setPlayer(null);
  }

  const sortedProspects = () => {
    if (!isLoaded(myProspects)) {
      return [];
    }

    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  if (isLoaded(myProspects) && !tracked) {
    setTracked(true);
    if (
      sortedProspects()
        .map((item) => item.id)
        .includes(id)
    ) {
      firestore
        .collection("users")
        .doc(userId)
        .collection("prospects")
        .doc(id)
        .update({ lastViewed: new Date() });
    }
  }

  const sortedFriends = () => {
    if (!isLoaded(users)) {
      return [];
    }

    let friends = users
      ? Object.keys(users).map((key) =>
          users ? { ...users[key], id: key } : null
        )
      : [];

    return friends.filter((item) => item !== null);
  };

  const sortedReports = (): PositionReport[] => {
    if (!isLoaded(myReports)) {
      return [];
    }

    let reports = myReports
      ? Object.keys(myReports).map((key) =>
          myReports ? { ...myReports[key], id: key } : null
        )
      : [];

    return reports.filter(
      (item) =>
        item !== null && item.id && item.playerName && item.playerId === id
    );
  };

  const sortedEvents = () => {
    if (!isLoaded(myEvents) || !isLoaded(sharedEvents)) {
      return [];
    }

    let events = myEvents
      ? Object.keys(myEvents).map((key) =>
          myEvents ? { ...myEvents[key], id: key } : null
        )
      : [];

    let shared = sharedEvents
      ? Object.keys(sharedEvents).map((key) =>
          sharedEvents ? { ...sharedEvents[key], id: key } : null
        )
      : [];

    events = events.filter(
      (item) =>
        item.eventDate &&
        item.eventDate.seconds &&
        moment(new Date(item.eventDate.seconds * 1000)).isAfter(
          moment().subtract(1, "day").endOf("day")
        ) &&
        ((item.addedProspectIds && item.addedProspectIds.includes(id)) ||
          (player &&
            player.summer_team &&
            (item.teamOne === player.summer_team ||
              item.teamTwo === player.summer_team)))
    );

    let relatedEventIds = events
      .filter((item) => item.relatedId)
      .map((item) => item.relatedId);

    shared = shared.filter(
      (item) =>
        item.eventDate &&
        item.eventDate.seconds &&
        moment(new Date(item.eventDate.seconds * 1000)).isAfter(
          moment().subtract(1, "day").endOf("day")
        ) &&
        !(relatedEventIds || []).includes(item.id)
    );

    return [...events, ...shared];
  };

  const renderStatRow = (name, val, color) => {
    return (
      <div style={{ backgroundColor: color }}>
        <p
          className="stats-text-small"
          style={{
            opacity: val ? 1 : name !== "" ? 0.3 : 0,
            whiteSpace: "nowrap",
          }}
        >
          {name}
          {name !== "" ? ":" : " "}
          <p className="stats-text-bold">{val && name !== "" ? val : "N/A"}</p>
        </p>
      </div>
    );
  };

  const renderCommitmentLarge = () => {
    if (
      player &&
      player.college_commitment &&
      player.college_commitment !== "" &&
      player.college_commitment !== "Uncommited"
    ) {
      return (
        <div
          style={{
            padding: 4,
            backgroundColor: colors.white,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            color: colors.accent,
            marginRight: 8,
            marginBottom: 16,
            marginTop: 8,
          }}
        >
          <Link
            to={`/search-results?uncommitted=Committed&commitment=${player.college_commitment}`}
            className="row-p"
            style={{
              color: colors.accent,
              whiteSpace: "nowrap",
              fontWeight: 600,
            }}
          >
            <CollegePlayerIcon
              style={{ fontSize: 20, marginRight: 5, marginTop: -2 }}
            />
            {player.college_commitment}
            <AiOutlineRight style={{ marginLeft: 4 }} />
          </Link>
        </div>
      );
    }
    return <div />;
  };

  const renderSummerTeamLarge = () => {
    if (player && player.summer_team && player.summer_team !== "") {
      return (
        <div
          style={{
            padding: 4,
            backgroundColor: colors.white,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            color: colors.accent,
            marginRight: 8,
            marginBottom: 16,
            marginTop: 8,
          }}
        >
          <Link
            to={`/search-results?summerTeam=${player.summer_team}`}
            className="row-p"
            style={{
              color: colors.accent,
              whiteSpace: "nowrap",
              fontWeight: 600,
            }}
          >
            <IoMdBaseball
              style={{ fontSize: 20, marginRight: 5, marginTop: -2 }}
            />
            {player.summer_team}
            <AiOutlineRight style={{ marginLeft: 4 }} />
          </Link>
        </div>
      );
    }
    return <div />;
  };

  const renderSummerTeamSmall = () => {
    if (player && player.summer_team && player.summer_team !== "") {
      return <div />;
    }
    return (
      <p className="middle-text-small">
        Summer Team:
        <p className="middle-text-bold">
          {player && player.summer_team !== ""
            ? player.summer_team || "N/A"
            : "N/A"}
        </p>
      </p>
    );
  };

  const renderCommitmentSmall = () => {
    if (
      player &&
      player.college_commitment &&
      player.college_commitment !== "" &&
      player.college_commitment !== "Uncommited"
    ) {
      return <div />;
    }
    return (
      <p className="middle-text-small">
        Commitment:
        <p className="middle-text-bold">
          {player && player.college_commitment !== ""
            ? player.college_commitment || "N/A"
            : "N/A"}
        </p>
      </p>
    );
  };

  const renderStateSmall = (state) => {
    return (
      <div>
        <p className="middle-text-small">
          State:
          <p className="middle-text-bold">{state ? state : "N/A"}</p>
        </p>
      </div>
    );
  };

  const renderReports = () => {
    return sortedReports().length === 0 ? (
      <div style={{ minHeight: 100 }}>
        <p
          style={{
            fontStyle: "italic",
            opacity: 0.6,
            marginTop: 10,
            paddingLeft: 20,
          }}
        >
          No reports.
        </p>
      </div>
    ) : (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          paddingTop: 20,
          paddingLeft: 10,
          maxWidth: "calc(100vw - 390px)",
          overflow: "scroll",
        }}
      >
        {(sortedReports() || []).map((item, index) => (
          <ReportCard report={item} small={true} />
        ))}
      </div>
    );
  };

  const renderUpcomingEvents = () => {
    return sortedEvents().length === 0 ? (
      <div style={{ minHeight: 100 }}>
        <p
          style={{
            fontStyle: "italic",
            opacity: 0.6,
            marginTop: 10,
            paddingLeft: 20,
          }}
        >
          No upcoming games.
        </p>
      </div>
    ) : (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          paddingTop: 20,
          paddingLeft: 10,
          maxWidth: "calc(100vw - 390px)",
          overflow: "scroll",
        }}
      >
        {(sortedEvents() || []).map((item, index) => (
          <PlayerCalendarEvent
            event={item}
            prospects={sortedProspects()}
            onViewCalendar={() => {
              history.push("/calendar");
            }}
          />
        ))}
      </div>
    );
  };

  const playerFromRoster = () => {
    if (player && player.external_id) {
      let pl = [...sortedRoster()].find(
        (item) => item.external_id === player.external_id
      );

      return pl;
    }
    return null;
  };

  const onRoster = () => {
    return (
      player &&
      (sortedRoster() || [])
        .map((pl) => pl.external_id)
        .includes(player.external_id)
    );
  };

  const sortedRoster = () => {
    if (!isLoaded(myRoster)) {
      return [];
    }

    let prospects = myRoster
      ? Object.keys(myRoster).map((key) =>
          myRoster ? { ...myRoster[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null);
  };

  const addToRoster = async () => {
    if (player && !onRoster()) {
      firestore
        .collection("users")
        .doc(userId)
        .collection("roster")
        .doc(player.external_id)
        .set({
          ...player,
          scholarshipByYear: {},
          financialAidByYear: {},
          rosterNumber: sortedRoster().length + 1,
        });
    }
  };

  const removeFromRoster = async () => {
    if (player) {
      firestore
        .collection("users")
        .doc(userId)
        .collection("roster")
        .doc(player.external_id)
        .delete();
    }
  };

  const toggleOnBigBoard = async () => {
    // tara here
    // if (player && (player.hiddenBoards || []).includes(userId)) {
    //   firestore
    //     .collection("users")
    //     .doc(userId)
    //     .collection("prospects")
    //     .doc(player.external_id)
    //     .update({
    //       hiddenBoards: firebase.firestore.FieldValue.arrayRemove(userId),
    //     });
    //   setPlayer({
    //     ...player,
    //     hiddenBoards: (player.hiddenBoards || []).filter(
    //       (item) => item != userId
    //     ),
    //   });
    // } else {
    //   firestore
    //     .collection("users")
    //     .doc(userId)
    //     .collection("prospects")
    //     .doc(player.external_id)
    //     .update({
    //       hiddenBoards: firebase.firestore.FieldValue.arrayUnion(userId),
    //     });
    //   setPlayer({
    //     ...player,
    //     hiddenBoards: [...(player.hiddenBoards || []), userId],
    //   });
    // }
  };

  const renderNameRow = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
        }}
      >
        <h2
          style={{
            fontFamily: "Raleway",
            color: "#fff",
            textOverflow: "ellipsis",
            //maxWidth: "300px",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {player ? PlayerService.getName(player) : ""}
        </h2>
        <div>
          <StarButton
            player={player}
            gold={true}
            buttonStyles={{ marginLeft: 12, marginTop: -10 }}
          />
        </div>
        {player && player.twitter_handle ? (
          <a
            href={`http://www.twitter.com/${player.twitter_handle}`}
            target="_blank"
          >
            <AiFillTwitterCircle
              style={{ color: "#fff", width: 30, height: 30, marginLeft: 12 }}
            />
          </a>
        ) : (
          <div />
        )}

        <div style={{ flex: 1 }} />

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginRight: 6,
          }}
        >
          {saved ? (
            <UnderlineButton
              text={"edit"}
              onClick={() => setShowEdit(true)}
              color={"#fff"}
            />
          ) : (
            <div />
          )}

          {player && player.from_monitor ? (
            <div />
          ) : (
            <UnderlineButton
              text={"notify me"}
              onClick={() => setShowNotifyModal(true)}
              color={"#fff"}
            />
          )}

          <UnderlineButton
            text={"export"}
            onClick={exportSpreadsheet}
            color={"#fff"}
          />

          {player && player.from_monitor ? (
            <div />
          ) : (
            <UnderlineButton
              text={"share"}
              onClick={() => setModalShow(true)}
              color={"#fff"}
            />
          )}

          {onRoster() ? (
            <UnderlineButton
              text={"remove from roster"}
              onClick={removeFromRoster}
              color={"#fff"}
            />
          ) : (
            <UnderlineButton
              text={"add to roster"}
              onClick={addToRoster}
              color={"#fff"}
            />
          )}
          {/* tara here  */}
          {/* {player && (player.hiddenBoards || []).includes(userId) ? (
            <UnderlineButton
              text={"add to big board"}
              onClick={toggleOnBigBoard}
              color={"#fff"}
            />
          ) : (
            <UnderlineButton
              text={"remove from big board"}
              onClick={toggleOnBigBoard}
              color={"#fff"}
            />
          )} */}
          <ScoutTitle
            text=""
            withHelp={true}
            light={true}
            onClick={() => setShowHelp(true)}
          />
        </div>
      </div>
    );
  };

  const renderGeneralInfo = () => {
    return player ? (
      <div
        lang="en"
        className="middle-text-container"
        style={
          player && player.from_monitor
            ? { marginLeft: -85, marginTop: 10 }
            : {}
        }
      >
        <p className="middle-text-small">
          Class:
          <p className="middle-text-bold">
            {player ? player.graduating_class || "N/A" : ""}
          </p>
        </p>
        <p className="middle-text-small">
          Position:
          <p className="middle-text-bold">
            {player
              ? player.alt_position
                ? `${player.primary_position}, ${player.alt_position}`
                : player.primary_position || "N/A"
              : ""}
          </p>
        </p>

        <p className="middle-text-small">
          Height:
          <p className="middle-text-bold">
            {player && player.height !== "" ? player.height || "N/A" : "N/A"}
          </p>
        </p>
        <p className="middle-text-small">
          Weight:
          <p className="middle-text-bold">
            {player && player.weight !== null && player.weight != undefined
              ? `${player.weight}lbs`
              : "N/A"}
          </p>
        </p>

        <p
          lang="en"
          className="middle-text-small"
          style={{ maxWidth: "200px" }}
        >
          High School:
          <span
            className="middle-text-bold"
            style={{
              hyphens: "auto",
              WebkitHyphens: "auto",
              MozHyphens: "auto",
              msHyphens: "auto",
            }}
          >
            {player && player.high_school !== ""
              ? player.high_school || "N/A"
              : "N/A"}
          </span>
        </p>

        <p className="middle-text-small">
          Bats:
          <p className="middle-text-bold">
            {player && player.bats !== "" ? player.bats || "N/A" : "N/A"}
          </p>
        </p>

        <p className="middle-text-small">
          Throws:
          <p className="middle-text-bold">
            {player && player.throws !== "" ? player.throws || "N/A" : "N/A"}
          </p>
        </p>
        {renderSummerTeamSmall()}
        {renderCommitmentSmall()}
        {renderStateSmall(player ? player.state : null)}
        <div style={{ flexDirection: "row", display: "flex" }}>
          {player && player.collegeInterests ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement={"right"}
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Title
                    style={{
                      backgroundColor: "#003366",
                      color: "#fff",
                    }}
                  >
                    <p
                      className="middle-text-bold"
                      style={{ paddingLeft: 0, marginLeft: 0 }}
                    >
                      {"College Interests"}
                    </p>
                  </Popover.Title>

                  <Popover.Content style={{ margin: 0, padding: 0 }}>
                    <div>
                      {((player.collegeInterests || "").split(",") || []).map(
                        (item) => (
                          <p className="middle-text-bold">{item}</p>
                        )
                      )}
                    </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <p
                className="middle-text-small"
                style={{
                  textDecorationLine: "underline",
                  textDecorationColor: "#000",
                }}
              >
                College Interests
              </p>
            </OverlayTrigger>
          ) : (
            <div />
          )}
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  };

  const flagMode = (): "pbr_disabled" | "pg_disabled" | "both" | "none" => {
    let hasPrepCreds = false;
    let hasPGCreds = false;
    let creds = profile ? profile.credentials : null;

    if (creds && creds.pg && creds.pg.approved) {
      hasPGCreds = true;
    }
    if (creds && creds.prep && creds.prep.approved) {
      hasPrepCreds = true;
    }
    let hasBoth = hasPGCreds && hasPrepCreds;
    let hasNone = !hasPGCreds && !hasPrepCreds;
    if (hasBoth) {
      return "none";
    }
    if (mode && mode.flags) {
      if (mode.flags.disable_pbr_rankings || mode.flags.disable_pg_rankings) {
        if (
          hasNone &&
          mode.flags.disable_pbr_rankings &&
          mode.flags.disable_pg_rankings
        ) {
          return "both";
        } else if (!hasPrepCreds && mode.flags.disable_pbr_rankings) {
          return "pbr_disabled";
        } else if (!hasPGCreds && mode.flags.disable_pg_rankings) {
          return "pg_disabled";
        }
      }
    }

    return "both";
  };

  const renderRankings = () => {
    if (source == "MAX_PRP") {
      return <div />;
    }
    let sourceKey = source === "PREP" ? "prepData" : "pgData";
    const statsObj = player && player[sourceKey] ? player[sourceKey] : null;

    let rankOne =
      source === "PREP"
        ? flagMode() == "pbr_disabled" || flagMode() == "both"
          ? "LOCKED"
          : player && player.pbr_national_overall_ranking
          ? player.pbr_national_overall_ranking
          : "N/A"
        : flagMode() == "pg_disabled" || flagMode() == "both"
        ? "LOCKED"
        : player && player.pg_national_overall_ranking
        ? player.pg_national_overall_ranking
        : "N/A";
    let rankTwo =
      source === "PREP"
        ? flagMode() == "pbr_disabled" || flagMode() == "both"
          ? "LOCKED"
          : player && player.pbr_national_position_ranking
          ? player.pbr_national_position_ranking
          : "N/A"
        : flagMode() == "pg_disabled" || flagMode() == "both"
        ? "LOCKED"
        : player && player.pg_national_position_ranking
        ? player.pg_national_position_ranking
        : "N/A";
    let rankThree =
      source === "PREP"
        ? flagMode() == "pbr_disabled" || flagMode() == "both"
          ? "LOCKED"
          : player && player.pbr_state_overall_ranking
          ? player.pbr_state_overall_ranking
          : "N/A"
        : flagMode() == "pg_disabled" || flagMode() == "both"
        ? "LOCKED"
        : player && player.pg_state_overall_ranking
        ? player.pg_state_overall_ranking
        : "N/A";
    let rankFour =
      source === "PREP"
        ? flagMode() == "pbr_disabled" || flagMode() == "both"
          ? "LOCKED"
          : player && player.pbr_state_position_ranking
          ? player.pbr_state_position_ranking
          : "N/A"
        : flagMode() == "pg_disabled" || flagMode() == "both"
        ? "LOCKED"
        : player && player.pg_state_position_ranking
        ? player.pg_state_position_ranking
        : "N/A";

    return statsObj ? (
      <div
        style={{
          flex: 1,
          marginTop: 22,
          marginRight: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            backgroundColor: colors.accent,
          }}
        >
          <p
            onClick={() => setSource("PREP")}
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: colors.white,
              padding: 12,
              textAlign: "center",
              opacity: source === "PREP" ? 1 : 0.6,

              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
              textDecorationLine: source === "PREP" ? "underline" : "none",
            }}
          >
            {"Prep Baseball"}
          </p>
          <p
            onClick={() => setSource("PG")}
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: colors.white,
              padding: 12,
              textAlign: "center",
              opacity: source === "PREP" ? 0.6 : 1,
              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
              textDecorationLine: source === "PG" ? "underline" : "none",
            }}
          >
            {"Perfect Game"}
          </p>
        </div>

        <div
          className="stats-middle"
          style={{
            borderColor: colors.lightestBlue,
            borderWidth: 1,
            borderStyle: "solid",
          }}
        >
          <div className="stats-middle-left">
            <div style={{ backgroundColor: colors.lightBlue }}>
              <p
                className="stats-text-small"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <p
                  className="stats-text-bold"
                  style={{ color: "#fff", textAlign: "center" }}
                >
                  {"NATIONAL"}
                </p>
              </p>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {rankOne == "LOCKED" ? (
                  <AiFillLock
                    style={{ width: 35, height: 24, color: colors.accent }}
                  />
                ) : (
                  <p style={{ fontWeight: "bold" }}>{rankOne}</p>
                )}

                <p>(Overall)</p>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {rankTwo == "LOCKED" ? (
                  <AiFillLock
                    style={{ width: 35, height: 24, color: colors.accent }}
                  />
                ) : (
                  <p style={{ fontWeight: "bold" }}>{rankTwo}</p>
                )}

                <p>(Position)</p>
              </div>
            </div>
          </div>
          <div className="stats-middle-right">
            <div style={{ backgroundColor: colors.lightBlue }}>
              <p
                className="stats-text-small"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <p
                  className="stats-text-bold"
                  style={{ color: "#fff", textAlign: "center" }}
                >
                  {"STATE"}
                </p>
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {rankThree == "LOCKED" ? (
                  <AiFillLock
                    style={{ width: 35, height: 24, color: colors.accent }}
                  />
                ) : (
                  <p style={{ fontWeight: "bold" }}>{rankThree}</p>
                )}
                <p>(Overall)</p>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {rankFour == "LOCKED" ? (
                  <AiFillLock
                    style={{ width: 35, height: 24, color: colors.accent }}
                  />
                ) : (
                  <p style={{ fontWeight: "bold" }}>{rankFour}</p>
                )}
                <p>(Position)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  };

  const renderStats = () => {
    let sourceKey = source === "PREP" ? "prepData" : "pgData";
    const statsObj = player && player[sourceKey] ? player[sourceKey] : null;
    return statsObj ? (
      <div
        style={{
          flex: 1,
          marginTop: 22,
          marginRight: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            backgroundColor: colors.accent,
          }}
        >
          <p
            onClick={() => setSource("PREP")}
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: colors.white,
              padding: 12,
              textAlign: "center",
              opacity: source === "PREP" ? 1 : 0.6,

              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
              textDecorationLine: source === "PREP" ? "underline" : "none",
            }}
          >
            {"Prep Baseball"}
          </p>
          <p
            onClick={() => setSource("PG")}
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: colors.white,
              padding: 12,
              textAlign: "center",
              opacity: source === "PG" ? 1 : 0.6,
              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
              textDecorationLine: source === "PG" ? "underline" : "none",
            }}
          >
            {"Perfect Game"}
          </p>
          <p
            onClick={() => setSource("MAX_PRP")}
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: colors.white,
              padding: 12,
              textAlign: "center",
              opacity: source === "MAX_PRP" ? 1 : 0.6,
              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
              textDecorationLine: source === "MAX_PRP" ? "underline" : "none",
            }}
          >
            {"Max Prep"}
          </p>

          {DEMO_SHOW_ANALYSIS && (
            <p
              onClick={() => setSource("PITCH_ANALYSIS")}
              style={{
                cursor: "pointer",
                fontWeight: 700,
                color: colors.white,
                padding: 12,
                textAlign: "center",
                opacity: source === "PITCH_ANALYSIS" ? 1 : 0.6,
                textDecorationStyle: "solid",
                textDecorationColor: colors.white,
                textDecorationLine:
                  source === "PITCH_ANALYSIS" ? "underline" : "none",
              }}
            >
              {"Analysis"}
            </p>
          )}
        </div>

        {source == "MAX_PRP" ? (
          player != null ? (
            <div>
              {player.primary_position == "RHP" ||
              player.primary_position == "LHP" ? (
                <p
                  style={{
                    fontSize: 24,
                    color: colors.lightBlue,
                    fontWeight: "bold",
                  }}
                >
                  {"PITCHING STATS"}
                  {renderPitchingStats({ ...EMPTY_MP_STATS, ...player })}
                </p>
              ) : (
                <p>{player.position}</p>
              )}

              <p
                style={{
                  fontSize: 24,
                  color: colors.lightBlue,
                  fontWeight: "bold",
                }}
              >
                {"BATTING STATS"}
                {renderBattingStats({ ...EMPTY_MP_STATS, ...player })}
              </p>
            </div>
          ) : (
            <div />
          )
        ) : DEMO_SHOW_ANALYSIS && source === "PITCH_ANALYSIS" ? (
          <PitchAnalysis
            player={player}
            setVisiblePlay={setVisiblePlay}
            setVisibleGame={setVisibleGame}
          />
        ) : (
          <div
            className="stats-middle"
            style={{
              borderColor: colors.lightestBlue,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            <div className="stats-middle-left">
              {renderStatRow("60 Time", statsObj.sixty_yard, colors.lightGray)}

              {renderStatRow("10 Yard Split", statsObj.ten_yard, colors.white)}

              {renderStatRow(
                "Position Velocity (IF)",
                statsObj.infvelo,
                colors.lightGray
              )}

              {renderStatRow(
                "Position Velocity (OF)",
                statsObj.ofvelo,
                colors.white
              )}

              {renderStatRow(
                "Position Velocity",
                statsObj.cvelo,
                colors.lightGray
              )}

              {renderStatRow("Exit Velocity", statsObj.exitvelo, colors.white)}
              {renderStatRow(
                "Pop Time",

                statsObj.poptime && statsObj.poptime != 0
                  ? SAFE_toFixed(statsObj.poptime, 2)
                  : null,
                colors.lightGray
              )}
            </div>
            <div className="stats-middle-right">
              {renderStatRow(
                "Fastball",
                statsObj.fbRangeMin
                  ? statsObj.fbRangeMax == null ||
                    statsObj.fbRangeMin === statsObj.fbRangeMax
                    ? statsObj.fbRangeMin
                    : `${statsObj.fbRangeMin}-${statsObj.fbRangeMax}`
                  : null,
                colors.lightGray
              )}

              {renderStatRow("Max Fastball", statsObj.maxfb, colors.white)}

              {renderStatRow(
                "Curveball",
                statsObj.curveRangeMin
                  ? statsObj.curveRangeMax == null ||
                    statsObj.curveRangeMin === statsObj.curveRangeMax
                    ? statsObj.curveRangeMin
                    : `${statsObj.curveRangeMin}-${statsObj.curveRangeMax}`
                  : null,
                colors.lightGray
              )}

              {renderStatRow(
                "Changeup",
                statsObj.chRangeMin
                  ? statsObj.chRangeMax == null ||
                    statsObj.chRangeMin === statsObj.chRangeMax
                    ? statsObj.chRangeMin
                    : `${statsObj.chRangeMin}-${statsObj.chRangeMax}`
                  : null,
                colors.white
              )}

              {renderStatRow(
                "Slider",
                statsObj.slideRangeMin
                  ? statsObj.slideRangeMax == null ||
                    statsObj.slideRangeMin === statsObj.slideRangeMax
                    ? statsObj.slideRangeMin
                    : `${statsObj.slideRangeMin}-${statsObj.slideRangeMax}`
                  : null,
                colors.lightGray
              )}

              {renderStatRow(
                "Knuckleball",
                statsObj.knRangeMin
                  ? statsObj.knRangeMax == null ||
                    statsObj.knRangeMin === statsObj.knRangeMax
                    ? statsObj.knRangeMin
                    : `${statsObj.knRangeMin}-${statsObj.knRangeMax}`
                  : null,
                colors.white
              )}

              {renderStatRow("", "", colors.lightGray)}
            </div>
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  };

  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val) => {
    let stringVal = val;
    if (val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const renderBattingStats = (stats) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("AB")}
          {th("R")}
          {th("H")}
          {th("2B")}
          {th("3B")}
          {th("HR")}
          {th("RBI")}
          {th("BB")}
          {th("AVG")}
          {th("OBP")}
          {th("SLG")}
          {th("OPS")}
          {th("PA")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(stats.AB)}
          {td(stats.R)}
          {td(stats.H)}

          {td(SAFE_toFixed(`${stats["2B"]}`, 0))}
          {td(SAFE_toFixed(`${stats["3B"]}`, 0))}
          {td(stats.HR)}

          {td(stats.RBI)}

          {td(stats.BB)}

          {td(stats.AVG)}
          {td(stats.OBP)}
          {td(stats.SLG)}
          {td(stats.OPS)}
          {td(stats.PA)}
        </tr>
      </table>
    );
  };

  const renderPitchingStats = (stats) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("GP")}
          {th("GS")}

          {th("H")}
          {th("R")}

          {th("HR")}

          {th("HBP")}
          {th("BB")}

          {th("AVG")}

          {th("K")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(stats.GP)}
          {td(stats.GS)}

          {td(stats.H)}
          {td(stats.R)}
          {td(stats.ER)}

          {td(stats.HBP)}
          {td(stats.BB)}

          {td(stats.AVG)}
          {td(stats.K)}
        </tr>
      </table>
    );
  };

  let imgUrl = player ? PlayerService.getServerPhoto(player) : null;
  const saved =
    isLoaded(myProspects) &&
    myProspects &&
    Object.keys(myProspects).includes(id);

  const renderPhoto = () => {
    return (
      <div>
        <div
          style={{
            borderRadius: 9,
            flexDirection: "column",
            display: "flex",
            marginLeft: 15,
            marginTop: -85,
          }}
        >
          {imgUrl ? (
            player && player.from_monitor ? (
              <div>
                <img
                  //  @ts-ignore
                  src={imgUrl}
                  alt=""
                  className={"monitor-profile-pic"}
                />
              </div>
            ) : (
              <img
                //  @ts-ignore
                src={imgUrl}
                alt=""
                className={"profile-picture"}
              />
            )
          ) : (
            <div
              className="profile-picture"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.accent,
              }}
            >
              <p
                style={{
                  color: colors.white,
                  fontSize: 100,
                  fontWeight: 800,
                }}
              >
                {player ? PlayerService.getInitials(player) : ""}
              </p>
            </div>
          )}
        </div>

        {/* {player && <PlayerScholarshipEditor player={player} />} */}
        {onRoster() && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <UnderlineButton
              text={"roster edit"}
              onClick={() => setShowRosterEdit(true)}
              color={"#000"}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        marginLeft: "1rem",
        marginRight: width < 1400 ? "1rem" : 0,
        // padding: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        minHeight: "90vh",
        flexDirection: "column",
        borderColor: colors.lightBlue,
        borderWidth: 5,
        borderStyle: "solid",
      }}
    >
      <div
        style={{
          backgroundColor: colors.lightBlue,
          height: 130,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: player && player.from_monitor ? 120 : 275,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {renderSummerTeamLarge()}
          {renderCommitmentLarge()}
        </div>

        {renderNameRow()}
      </div>

      <div
        style={{
          flexDirection: "column",
          display: "flex",
        }}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          {renderPhoto()}
          {renderGeneralInfo()}
          <div style={{ minWidth: player && player.from_monitor ? 0 : 20 }} />
          <div style={{ flex: 1, overflow: "hidden" }}>
            {renderStats()}
            {renderRankings()}
          </div>
        </div>

        <div className="reports-container">
          <div
            className="reports-top-row"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <p className="reports-title">REPORTS</p>
            <Link
              to={`/create-report/${id}`}
              style={{
                marginTop: -6,
                color: colors.accent,
                fontSize: 25,
                backgroundColor: "transparent",
                padding: 6,
                marginLeft: 3,
                borderWidth: 0,
                boxShadow: "none",
              }}
            >
              <AiFillPlusCircle />
            </Link>
          </div>
          {renderReports()}
        </div>

        <div className="upcoming-container">
          <div
            className="upcoming-top-row"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <p className="upcoming-title">UPCOMING GAMES</p>
            <Link
              to={`/calendar?player=${id}`}
              style={{
                marginTop: -6,
                color: colors.accent,
                fontSize: 25,
                backgroundColor: "transparent",
                padding: 6,
                marginLeft: 3,
                borderWidth: 0,
                boxShadow: "none",
              }}
            >
              <AiFillPlusCircle />
            </Link>
          </div>
          {renderUpcomingEvents()}
        </div>
      </div>

      <SharePlayerModal
        show={modalShow}
        friends={sortedFriends()}
        onHide={() => setModalShow(false)}
        me={{ ...profile, id: userId }}
        player={player}
      />

      <HelpModal
        show={showHelp}
        kind={"PLAYER PROFILE"}
        onHide={() => setShowHelp(false)}
      />

      <NotifyMeModal
        show={showNotifyModal}
        onHide={() => setShowNotifyModal(false)}
        currentPlayer={player}
      />

      <PlayAnalysisModal
        show={!!visiblePlay}
        onHide={() => {
          setVisiblePlay(null);
          setVisibleGame(null);
        }}
        play={visiblePlay}
        setPlay={setVisiblePlay}
        game={visibleGame}
      />

      {player ? (
        <EditPlayerModal
          show={showEdit}
          onHide={() => setShowEdit(false)}
          editPlayer={(pl) => setPlayer(pl)}
          player={player}
        />
      ) : (
        <div />
      )}

      {player ? (
        <EditRosterPlayerModal
          show={showRosterEdit}
          onHide={() => setShowRosterEdit(false)}
          player={player}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(PlayerPage);

const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
];

const dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
];

const EMPTY_MP_STATS = {
  LOB: null,
  RBI: null,
  BB: null,
  HR: null,
  FC: null,
  HBP: null,
  ROE: null,
  PA: null,
  AB: null,
  GS: null,
  GP: null,
  H: null,
  K: null,
  R: null,
  SLG: null,
  OBP: null,
  OPS: null,
  AVG: null,
  SF: null,
  "2B": null,
  "SH/B": null,
  "3B": null,
};

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

export const NewPitchAnalysis = ({
  player,
  setVisiblePlay,
  setVisibleGame,
}) => {
  const gameData = GeorgiaLsuData.result;

  // !blw: for now, we are just demoing pitchers
  const playerGameData = gameData.filter((play) => {
    let pitcherName = `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`;
    let selectedPlayerName = `${player?.first_name} ${player?.last_name}`;

    return selectedPlayerName === pitcherName;
  });

  const watchPlay = (play) => {
    setVisiblePlay(play);
    setVisibleGame(playerGameData);
  };

  const rows = playerGameData.map((play) => calcPlayRow(play, watchPlay));

  const columns = [
    {
      accessorKey: "playButton",
      header: "Video",
      //cellRenderer: ({ value }) => value,
      //width: 50,
    },
    { accessorKey: "date", header: "Date" },
    { accessorKey: "inning", header: "Inn" },
    { accessorKey: "count", header: "Count" },
    { accessorKey: "outs", header: "Out" },
    { accessorKey: "pitcher", header: "Pitcher" },
    { accessorKey: "batter", header: "Batter" },
    { accessorKey: "pitchKind", header: "Type" },
    { accessorKey: "pitchResult", header: "PitchResult" },
    { accessorKey: "pitchSpeed", header: "Vel" },
  ];

  return (
    player && (
      <div
        style={{ overflow: "scroll", height: "400px" }}
        className="visibleScrollbar"
      >
        <Table columns={columns} rows={rows} />
      </div>
    )
  );
};

const NewPitchAnalysisRow = ({ play, onPressWatch }) => {
  const videoUrl =
    play?.videoGroup?.videoTracks?.[0]?.segments?.[0]?.video?.videoVariants?.[0]
      ?.endpointRelativePath;

  const columns = {
    playButton: (
      <FiPlayCircle
        onClick={onPressWatch}
        style={{
          cursor: "pointer",
        }}
      />
    ),
    date: new Date(play.dateUpdated).toLocaleDateString("en-US"),
    inning: `${!!play.inningTop ? "Top" : "Bot"} ${play.inning}`,
    count: `${play.count.balls}-${play.count.strikes}`,
    outs: `${play.outs}`,
    pitcher: `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`,
    batter: `${play.batter.nameFirst} ${play.batter.nameLast}`,
    pitchKind: play.pitch?.pitchKind,
    pitchResult: play.pitch?.pitchResult,
    pitchSpeed: play.pitch?.pitchSpeedMph,
  };

  return (
    <>
      <tr className="pitch-analysis-row">
        {Object.values(columns).map((column) => (
          <PitchAnalysisEntry value={column || "-"} />
        ))}
      </tr>
    </>
  );
};

const PitchAnalysisEntry = ({ value }) => {
  return (
    <td
      style={{
        padding: "4px",
        whiteSpace: "nowrap",
      }}
    >
      {value}
    </td>
  );
};

const ColorRow = ({ color, children }) => {
  return (
    <div style={{ backgroundColor: color, width: "100%" }}>{children}</div>
  );
};

const calcPlayRow = (play, watchPlay) => {
  const videoUrl =
    play?.videoGroup?.videoTracks?.[0]?.segments?.[0]?.video?.videoVariants?.[0]
      ?.endpointRelativePath;

  const columns = {
    playButton: (
      <FiPlayCircle
        onClick={() => watchPlay(play)}
        style={{
          cursor: "pointer",
        }}
      />
    ),
    date: new Date(play.dateUpdated).toLocaleDateString("en-US"),
    inning: `${!!play.inningTop ? "Top" : "Bot"} ${play.inning}`,
    count: `${play.count.balls}-${play.count.strikes}`,
    outs: `${play.outs}`,
    pitcher: `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`,
    batter: `${play.batter.nameFirst} ${play.batter.nameLast}`,
    pitchKind: play.pitch?.pitchKind,
    pitchResult: play.pitch?.pitchResult,
    pitchSpeed: play.pitch?.pitchSpeedMph,
  };

  return columns;
};

/****************************************************************************/

export const OldPitchAnalysis = ({
  player,
  setVisiblePlay,
  setVisibleGame,
}) => {
  const gameData = GeorgiaLsuData.result;

  // !blw: for now, we are just demoing pitchers
  const playerGameData = gameData.filter((play) => {
    let pitcherName = `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`;
    let selectedPlayerName = `${player?.first_name} ${player?.last_name}`;

    return selectedPlayerName === pitcherName;
  });

  const headings = [
    "",
    "Date",
    "Inn",
    "Count",
    "Out",
    "Pitcher",
    "Batter",
    "Type",
    "PitchResult",
    "Vel",
  ];

  return (
    player && (
      <div
        style={{ overflow: "scroll", height: "400px" }}
        className="visibleScrollbar"
      >
        <table
          style={{
            width: "100%",
            borderColor: colors.lightestBlue,
            borderWidth: 1,
            borderStyle: "solid",
            padding: "4px",
          }}
        >
          {headings.map((heading) => (
            <th
              style={{
                padding: "4px",
                fontWeight: 700,
                whiteSpace: "nowrap",
              }}
            >
              {heading}
            </th>
          ))}
          {playerGameData.map((play) => (
            <PitchAnalysisRow
              onPressWatch={() => {
                setVisiblePlay(play);
                setVisibleGame(playerGameData);
              }}
              play={play}
            />
          ))}
        </table>
      </div>
    )
  );
};

const PitchAnalysisRow = ({ play, onPressWatch }) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoUrl =
    play?.videoGroup?.videoTracks?.[0]?.segments?.[0]?.video?.videoVariants?.[0]
      ?.endpointRelativePath;

  const columns = [
    <FiPlayCircle
      onClick={onPressWatch}
      //onClick={() => setShowVideo(true)}
      style={{
        cursor: "pointer",
      }}
    />,
    new Date(play.dateUpdated).toLocaleDateString("en-US"),
    `${!!play.inningTop ? "Top" : "Bot"} ${play.inning}`,
    `${play.count.balls}-${play.count.strikes}`,
    `${play.outs}`,
    `${play.defense.lineup.pitcher.nameFirst} ${play.defense.lineup.pitcher.nameLast}`,
    `${play.batter.nameFirst} ${play.batter.nameLast}`,
    play.pitch?.pitchKind,
    play.pitch?.pitchResult,
    play.pitch?.pitchSpeedMph,
  ];

  return (
    <>
      <tr className="pitch-analysis-row">
        {columns.map((column) => (
          <PitchAnalysisEntry value={column || "-"} />
        ))}
      </tr>
      {showVideo && !!videoUrl && (
        <tr>
          <td colSpan={columns.length} style={{ paddingLeft: "16px" }}>
            <video src={videoUrl} style={{ height: "250px" }} />
          </td>
        </tr>
      )}
    </>
  );
};

interface Header<T> {
  value: T;
  filter: boolean;
}
const TableHeader = ({ header }) => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <th
      //colSpan={header.colSpan}
      style={{
        //width: `${header.getSize()}px`
        color: "white",
        position: "relative",
      }}
    >
      {header.isPlaceholder ? null : (
        <Row
          p="4px"
          align="center"
          color={colors.accent}
          gap="4px"
          interactColor={colors.lightBlue}
        >
          <Row
            w="150px"
            justify="space-between"
            align="center"
            onClick={() => setShowFilter(true)}
            style={{ cursor: "pointer" }}
          >
            {header.value}
          </Row>

          <FaFilter
            size={12}
            //onClick={() => setShowFilter(true)}
            style={{ zIndex: 10 }}
          />
          {/* {header.column.getCanFilter() && (
            <Filter column={header.column} table={table} />
          )} */}
        </Row>
      )}

      {showFilter && (
        <FilterOverlay
          close={() => setShowFilter(false)}
          column={header.value}
          //filters={filters}
          //setFilters={setFilters}
        />
      )}
    </th>
  );
};

const FilterOverlay = ({ close, column }) => {
  const [filters, setFilters] = useState([]);
  const ref = useRef(null);
  useOutsideClickCallback(ref, close);

  //onChange={value => column.setFilterValue(value)}

  const sortedUniqueValues = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  const clear = () => {
    setFilters([]);
  };

  const selectAll = () => {
    setFilters(sortedUniqueValues);
  };

  return (
    <Stack
      ref={ref}
      pos="absolute"
      maxH="200px"
      color={colors.lightGray}
      border="2px solid black"
      style={{ overflowY: "scroll", color: "black" }}
    >
      <Row px="8px" justify="space-between" fullX>
        <p
          onClick={clear}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          clear
        </p>
        <p
          onClick={selectAll}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          select all
        </p>
      </Row>

      {sortedUniqueValues.map((value, idx) => {
        const filterSelected = filters.includes(value);

        const handleClick = () => {
          setFilters(
            filterSelected
              ? filters.filter((item) => item !== value)
              : [...filters, value]
          );
        };

        return (
          <Row
            key={idx}
            px="8px"
            gap="8px"
            align="center"
            interactColor={colors.lightestBlue}
            onClick={handleClick}
          >
            <FaCheck
              size={12}
              style={{ visibility: filterSelected ? undefined : "hidden" }}
            />
            <p
              style={{
                width: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </p>
          </Row>
        );
      })}
    </Stack>
  );
};

function useOutsideClickCallback(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
