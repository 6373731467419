import React, { useEffect } from "react";
import "./home.css";
import SidebarEvents from "shared/sidebar-events";
import SidebarPlayers from "shared/sidebar-players";
import SidebarNotifications from "shared/sidebar-notifications";
import { useMe } from "@tara-wilson/mcc-lib";
import { FBAnalytics } from "store/store";
import colors from "constants/colors";

function Home(props: any) {
  const me = useMe();

  useEffect(() => {
    FBAnalytics.setUserId(me.id);
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",

        marginLeft: "1rem",
        marginRight: "1rem",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: 12,
        }}
      >
        <p
          style={{ fontWeight: "bold", color: colors.accent, fontSize: 30 }}
        >{`Hello${me.firstName ? `, ${me.firstName}` : ``}!`}</p>
        <p style={{ color: colors.accent }}>{me.email}</p>
      </div>

      <div style={{ display: "flex", flexDirection: "row", minHeight: "60vh" }}>
        <div style={{ flex: 1, display: "flex" }}>
          <SidebarPlayers />
        </div>
        <div
          style={{ flex: 1, marginLeft: 20, marginRight: 20, display: "flex" }}
        >
          <SidebarNotifications />
        </div>
        <div style={{ flex: 1, display: "flex" }}>
          <SidebarEvents />
        </div>
      </div>
    </div>
  );
}

export default Home;
