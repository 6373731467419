import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { Button, Modal, Spinner } from "react-bootstrap";

function AreYouSureModal(props: {
  onHide: any;
  onConfirm: any;
  title: string;
  body: string;
  show?: any;
}) {
  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        <p>{props.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => props.onHide()}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onConfirm();
          }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AreYouSureModal;
