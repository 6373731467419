import React from "react";
import moment from "moment";
import { ScoutNotification } from "models/ScoutNotification";
import { PlayerService } from "services/PlayerService";
import colors from "constants/colors";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { Link } from "react-router-dom";
import AcceptRequestModal from "../modals/accept-request-modal";
import { User } from "models/User";
import { NotificationService } from "services/NotificationService";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {
  notification: ScoutNotification;
  me: User;
  firestore: any;
}
function NotificationItem(props: Props) {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);

  const getMainText = (notification: ScoutNotification) => {
    if (notification.kind === "friendRequest") {
      return "New friend request from ";
    } else if (notification.kind === "sharedProspect") {
      return " shared the prospect profile for ";
    } else if (notification.kind === "sharedReport") {
      return " shared a new ";
    } else if (notification.kind === "sharedBoard") {
      return " shared a ";
    } else if (notification.kind === "playerMatch") {
      return ` has a new player match: `;
    }
    return notification.text;
  };

  const getStartingText = (notification: ScoutNotification) => {
    if (
      notification.kind === "sharedProspect" ||
      notification.kind === "sharedBoard" ||
      notification.kind === "sharedReport" ||
      notification.kind === "playerMatch"
    ) {
      return notification.senderName;
    }
    return "";
  };

  // Notification

  let notification = props.notification;
  return notification ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: 8,
          flexDirection: "row",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        <p
          style={{
            fontSize: 16,
            marginLeft: 10,
            marginRight: 10,
            textAlign: "left",
          }}
        >
          <span style={{ fontWeight: 600 }}>
            {getStartingText(notification)}
          </span>
          {getMainText(notification)}

          {notification.kind === "sharedProspect" ? (
            <Link
              to={`/${
                notification.prospect.college ? "collegeplayer" : "player"
              }/${
                notification.prospect
                  ? notification.prospect.external_id
                  : "error"
              }`}
              onClick={() => {
                NotificationService.markNotificationRead(
                  notification,
                  props.me,
                  props.firestore
                );
              }}
              style={{
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
                textDecorationColor: colors.accent,
                color: colors.accent,
              }}
            >
              {notification.prospect
                ? PlayerService.getName(notification.prospect)
                : ""}
            </Link>
          ) : (
            <span />
          )}
          {notification.kind === "sharedBoard" ? (
            <span>
              <Link
                to={`/bigboard/${
                  notification.object
                    ? notification.object.id
                    : notification.sender
                }/${notification.sender}/ALL YEARS`}
                onClick={() => {
                  NotificationService.markNotificationRead(
                    notification,
                    props.me,
                    props.firestore
                  );
                }}
                style={{
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                  textDecorationColor: colors.accent,
                  color: colors.accent,
                }}
              >
                {"Big Board"}
              </Link>
              {` with you`}
            </span>
          ) : (
            <span />
          )}
          {notification.kind === "playerMatch" ? (
            <Link
              to={`/${
                notification.prospect.college ? "collegeplayer" : "player"
              }/${notification.prospect.external_id}`}
              onClick={() => {
                NotificationService.markNotificationRead(
                  notification,
                  props.me,
                  props.firestore
                );
              }}
              style={{
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
                textDecorationColor: colors.accent,
                color: colors.accent,
              }}
            >
              {notification.prospect
                ? PlayerService.getName(notification.prospect)
                : ""}
            </Link>
          ) : (
            <span />
          )}
          {notification.kind === "sharedReport" ? (
            <span>
              <Link
                to={`/shared-report/${notification.id}`}
                onClick={() => {
                  NotificationService.markNotificationRead(
                    notification,
                    props.me,
                    props.firestore
                  );
                }}
                style={{
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                  textDecorationColor: colors.accent,
                  color: colors.accent,
                }}
              >
                {"report"}
              </Link>

              <span>{` with you`}</span>
            </span>
          ) : (
            <span />
          )}
          {notification.kind === "friendRequest" ? (
            <span
              style={{
                marginLeft: 3,
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
                textDecorationColor: colors.accent,
                color: colors.accent,
                cursor: "pointer",
              }}
              onClick={() => {
                if ((props.me.friends || []).includes(notification.sender)) {
                  history.push("/settings");
                } else {
                  NotificationService.markNotificationRead(
                    notification,
                    props.me,
                    props.firestore
                  );
                  setModalShow(true);
                }
              }}
            >
              {notification.senderName}
            </span>
          ) : (
            <span />
          )}
        </p>
      </div>

      <div
        style={{
          marginLeft: 10,
          marginBottom: 7,
          paddingTop: 2,
          marginRight: 10,
          display: "flex",
          flexDirection: "column",
          opacity: 0.7,
        }}
      >
        <p style={{ color: colors.darkGray }}>
          {props.notification && props.notification.timeCreated
            ? moment(
                new Date(props.notification.timeCreated.seconds * 1000)
              ).format("M/D, h:mm A")
            : ""}
        </p>
      </div>

      <AcceptRequestModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        me={props.me}
        request={props.notification}
      />
    </div>
  ) : (
    <span />
  );
}

const enhance = compose(withFirestore);

export default enhance(NotificationItem);
