export default {
  accent: "#003366",
  lightBlue: "#467DB3",
  lightGreen: "#B9D153",
  white: "#fff",
  darkGray: "rgba(0, 0, 0, 0.7)",
  mediumGray: "#E6E6E6",
  lightGray: "#f2f2f2",
  red: "#A61911",
  transparentContrast3: "rgba(0, 0, 0, 0.3)",
  transparentContrast5: "rgba(0, 0, 0, 0.5)",
  lightestBlue: "#CDD7DD",

  softDarkText: "#282828",
};

export const TAG_COLORS = {
  tagA: "#FFC09F",
  tagB: "#FFEE93",
  tagC: "#A0CED9",
  tagD: "#ADF7B6",
};
