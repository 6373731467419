import React, { useState } from "react";
import colors from "constants/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import LabelValueBox from "../components/label-value-box";
import LabelValueFlexBox from "../components/label-value-flex-box";
import LabelDescription from "../components/label-description";
import ThreeBoxRow from "../components/three-box-row";
import OneBoxRow from "../components/one-box-row";
import OneBoxRowComments from "../components/one-box-row-comments";
import ReportTop from "./report-top";
import SingleVideoRow from "../components/single-video-row";

function PositionProReport({ posReport }) {
  const renderComments = (item) => {
    return (
      <tr>
        <td
          style={{
            textAlign: "left",
            padding: "2px 4px",
            border: "none",
            fontStyle: item && item.comments ? "normal" : "italic",
            opacity: item && item.comments ? 1 : 0.4,
          }}
          colSpan={4}
        >
          {item && item.comments ? item.comments : "N/A"}
        </td>
      </tr>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF",
        padding: 10,
      }}
    >
      <ReportTop posReport={posReport} />

      <LabelDescription
        label="Physical Description"
        value={posReport.physicalDescription}
      />

      <LabelDescription
        label="Player Comparison"
        value={posReport.playerComparison}
      />

      <table style={{ width: "100%", marginTop: 20 }}>
        <tr>
          <td
            style={{ width: "50%", border: "none", textAlign: "center" }}
          ></td>
          <td
            style={{
              width: "16.6%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"P"}
          </td>
          <td
            style={{
              width: "16.6%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"F"}
          </td>
          <td
            style={{
              width: "16.6%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"Adj."}
          </td>
        </tr>

        <ThreeBoxRow
          label={"Hitting Ability"}
          p={posReport.hittingAbility.p}
          f={posReport.hittingAbility.f}
          adj={posReport.hittingAbility.adj}
          comments={posReport.hittingAbility.comments}
        />
        {renderComments(posReport.hittingAbility)}

        <ThreeBoxRow
          label={"Hit App/Type"}
          p={posReport.hitAppType.p}
          f={posReport.hitAppType.f}
          adj={posReport.hitAppType.adj}
          comments={posReport.hitAppType.comments}
        />
        {renderComments(posReport.hitAppType)}
        <ThreeBoxRow
          label={"Power Freq."}
          p={posReport.powerFreq.p}
          f={posReport.powerFreq.f}
          adj={posReport.powerFreq.adj}
          comments={posReport.powerFreq.comments}
        />
        {renderComments(posReport.powerFreq)}
        <ThreeBoxRow
          label={"Raw Power"}
          p={posReport.rawPower.p}
          f={posReport.rawPower.f}
          adj={posReport.rawPower.adj}
          comments={posReport.rawPower.comments}
        />
        {renderComments(posReport.rawPower)}
        <ThreeBoxRow
          label={"Running Ability"}
          p={posReport.runningAbility.p}
          f={posReport.runningAbility.f}
          adj={posReport.runningAbility.adj}
          comments={posReport.runningAbility.comments}
        />

        <ThreeBoxRow
          label={"Base Stealer"}
          p={posReport.baseStealer.p}
          f={posReport.baseStealer.f}
          adj={posReport.baseStealer.adj}
          comments={posReport.baseStealer.comments}
        />
        {renderComments(posReport.baseStealer)}
        <ThreeBoxRow
          label={"Arm Strength"}
          p={posReport.armStrength.p}
          f={posReport.armStrength.f}
          adj={posReport.armStrength.adj}
          comments={posReport.armStrength.comments}
        />
        {renderComments(posReport.armStrength)}
        <ThreeBoxRow
          label={"Fielding Ability"}
          p={posReport.fieldingAbility.p}
          f={posReport.fieldingAbility.f}
          adj={posReport.fieldingAbility.adj}
          comments={posReport.fieldingAbility.comments}
        />
        {renderComments(posReport.fieldingAbility)}
        <ThreeBoxRow
          label={"Hands"}
          p={posReport.hands.p}
          f={posReport.hands.f}
          adj={posReport.hands.adj}
          comments={posReport.hands.comments}
        />
        {renderComments(posReport.hands)}
        <ThreeBoxRow
          label={"Range"}
          p={posReport.range.p}
          f={posReport.range.f}
          adj={posReport.range.adj}
          comments={posReport.range.comments}
        />
        {renderComments(posReport.range)}
        <ThreeBoxRow
          label={"Feet"}
          p={posReport.feet.p}
          f={posReport.feet.f}
          adj={posReport.feet.adj}
          comments={posReport.feet.comments}
        />
        {renderComments(posReport.feet)}
        <ThreeBoxRow
          label={"Can He Play"}
          p={posReport.canHePlay.p}
          f={posReport.canHePlay.f}
          adj={posReport.canHePlay.adj}
          comments={posReport.canHePlay.comments}
        />
        {renderComments(posReport.canHePlay)}
        <ThreeBoxRow
          label={"OFP"}
          p={posReport.oFP.p}
          f={posReport.oFP.f}
          adj={posReport.oFP.adj}
          comments={posReport.oFP.comments}
        />
        {renderComments(posReport.oFP)}
      </table>

      <table style={{ marginTop: 20, width: "100%" }}>
        <OneBoxRow label="Aggressiveness" value={posReport.aggressiveness} />
        <OneBoxRow label="Athleticism" value={posReport.athleticism} />
        <OneBoxRow label="Body Control" value={posReport.bodyControl} />
        <OneBoxRow label="Instincts" value={posReport.instincts} />
        <OneBoxRow label="Competitiveness" value={posReport.competitiveness} />
        <OneBoxRow label="Baseball IQ" value={posReport.baseballIQ} />
        <OneBoxRow label="Hand-eye Control" value={posReport.handEyeControl} />
        <OneBoxRow label="OFP Offense" value={posReport.oFPOffense} />
        <OneBoxRow label="OFP Defense" value={posReport.oFPDefense} />
        <OneBoxRow label="Home to First" value={posReport.homeToFirst} />
        <OneBoxRow label="60 Yard" value={posReport.sixtyYard} />
        <OneBoxRow label="40 Yard" value={posReport.fortyYard} />
      </table>

      <table style={{ marginTop: 40, width: "100%" }}>
        <OneBoxRow label="Pos. in Order" value={posReport.posInOrder} />
        <OneBoxRow label="Character" value={posReport.character} />
        <OneBoxRow label="Entry Level" value={posReport.entryLevel} />
        <OneBoxRow label="ETA" value={posReport.eTA} />
      </table>

      <table style={{ marginTop: 40, marginBottom: 20, width: "100%" }}>
        <OneBoxRow
          label="How Well Known"
          value={posReport.howWellKnown.value}
        />
        {renderComments(posReport.howWellKnown)}
        <OneBoxRow label="How Well Seen" value={posReport.howWellSeen.value} />
        {renderComments(posReport.howWellSeen)}
        <OneBoxRow label="Signablility" value={posReport.signability.value} />
        {renderComments(posReport.signability)}
      </table>

      <SingleVideoRow title="BP" value={posReport.bp} />
      <SingleVideoRow title="Infield" value={posReport.infield} />

      <div className="report-row">
        <LabelDescription label="Summary" value={posReport.summary} />
      </div>
    </div>
  );
}

export default PositionProReport;
