import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";

function NotesReport({ posReport }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
        paddingLeft: 10,
      }}
    >
      <p style={{ fontWeight: 700 }}>Notes</p>

      <div style={{}}>{ReactHtmlParser(posReport.summary)}</div>

      <p style={{ fontWeight: 700, marginTop: 20 }}>Scholarship</p>
      <p>{posReport.scholarship}</p>
    </div>
  );
}

export default NotesReport;
