import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";

function RangeSelector({ valmin, valmax, setValues, min, max, step, units }) {
  const COLORS = [
    "rgba(256, 256, 256, 0.4)",
    "#fff",
    "rgba(256, 256, 256, 0.4)",
  ];

  const valWithUnits = (val) => {
    console.log("valWithUnits", units);
    if (units === "lbs") {
      return `${val} lbs`;
    } else if (units === "in") {
      return `${Math.floor(val / 12)}'${val % 12}`;
    } else if (units === "collegeClass") {
      switch (val) {
        case 1:
          return "Freshman";
        case 2:
          return "Sophomore";
        case 3:
          return "Junior   ";
        case 4:
          return "Senior   ";
      }
    } else {
      console.log("no match");
    }
    return val;
  };

  let calcMin = valmin;
  let calcMax = valmax;
  if (valmax >= max && valmax !== 2029 && units !== "collegeClass") {
    calcMax = max - step;
  }
  if (valmin <= min && valmin !== 2022 && units !== "collegeClass") {
    calcMin = min + step;
  }
  console.log("min", valmin, "max", valmax);
  let values = [calcMin, calcMax];
  console.log("values", values);
  return (
    <div className="range-component">
      <div className="data-box">
        <p className="data-text">{valWithUnits(valmin)}</p>
      </div>

      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(values) => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: [valmin, valmax],
                  colors: COLORS,
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "20px",
              width: "20px",
              backgroundColor: "white",
              borderRadius: "300px",
            }}
          />
        )}
      />

      <div className="data-box">
        <p className="data-text">{valWithUnits(valmax)}</p>
      </div>
    </div>
  );
}

export default RangeSelector;
