import colors from "constants/colors";
import React from "react";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { ScoutNotification } from "models/ScoutNotification";
import { NotificationService } from "services/NotificationService";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";

function PendingRequestName(props: {
  request: ScoutNotification;
  fromMe: boolean;
  containerStyles: any;
  setShowModal: any;
  firestore: any;
}) {
  return (
    <OverlayTrigger
      trigger={"focus"}
      placement={"right"}
      overlay={
        <Popover id={`popover-positioned-right`}>
          <Popover.Title
            as="h3"
            style={{
              backgroundColor: "#003366",
              color: "#fff",
              textAlign: "center",
            }}
          >
            Friend Request
          </Popover.Title>
          <Popover.Content>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 250,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: 20,
                  marginRight: 20,
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="secondary"
                  style={{
                    flex: 1,
                    borderColor: colors.accent,
                    fontWeight: 800,
                    fontSize: 15,
                    color: colors.accent,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  onClick={async () => {
                    await NotificationService.markNotificationRemoved(
                      props.request,
                      props.firestore
                    );
                  }}
                >
                  {"Deny"}
                </Button>
                <Button
                  style={{
                    flex: 1,
                    fontWeight: 800,
                    fontSize: 15,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  onClick={() => {
                    props.setShowModal(props.request);
                  }}
                >
                  {props.fromMe ? "Cancel" : "Accept"}
                </Button>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <div
        style={{
          ...props.containerStyles,
          display: "flex",
          flexDirection: "row",

          borderStyle: "solid",
          borderRadius: 9,

          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 35,
            height: 35,
            backgroundColor: "#003366",
            borderRadius: 35 / 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "#fff",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {`${
              props.request.senderName &&
              props.request.senderName.split(" ").length > 0
                ? `${props.request.senderName
                    .split(" ")[0]
                    .charAt(0)}${props.request.senderName
                    .split(" ")[1]
                    .charAt(0)}`
                : ""
            }`}
          </p>
        </div>
        <Link
          to={`/settings`}
          style={{
            marginLeft: 8,
            color: "#003366",
          }}
        >
          {`${props.request.senderName}`}
        </Link>
      </div>
    </OverlayTrigger>
  );
}

const enhance = compose(withFirestore);

export default enhance(PendingRequestName);
