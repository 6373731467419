import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { useFirestore, withFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TeamDropdown from "dropdowns/team-dropdown";
import { Player } from "models/Player";
import SmallButton from "pages/search-page/components/small-button";
import { useMe } from "@tara-wilson/mcc-lib";
import { User } from "models/User";
import YearDropdown from "dropdowns/year-dropdown";
import StateDropdown from "dropdowns/state-dropdown";
import CollegeCommitmentDropdown from "dropdowns/college-commitment-dropdown";
import { STATES, STATE_MAP } from "constants/data";

export default function CreateProspectModal(props: {
  sortedProspects: Player[];
  onHide: any;
  show: any;
}) {
  const me = useMe<User>();
  const firestore = useFirestore();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [graduatingClass, setGraduatingClass] = useState("2021");
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [weight, setWeight] = useState("");
  const [state, setState] = useState("");
  const [summerTeam, setSummerTeam] = useState("");
  const [commitment, setCommitment] = useState("");
  const [bats, setBats] = useState("");
  const [throws, setThrows] = useState("");

  const save = async () => {
    let parsedFeet = isNaN(parseInt(feet)) ? 0 : parseInt(feet);
    let parsedInches = isNaN(parseInt(inches)) ? 0 : parseInt(inches);
    let calculatedHeight = parsedFeet * 12 + parsedInches;

    let upd = {
      manual: true,
      active: true,
      primary_position: position,
      first_name: firstName,
      last_name: lastName,
      graduating_class: graduatingClass,
      height: parsedFeet > 0 ? `${parsedFeet}-${parsedInches}` : null,
      calculated_height: calculatedHeight,
      weight: weight ? parseInt(weight) : null,
      state: state ? STATE_MAP[state] : null,
      summer_team: summerTeam,
      college_commitment: commitment,
      college: false,
      bats: bats,
      throws: throws,
    };

    let pl = await firestore
      .collection("users")
      .doc(me.id)
      .collection("prospects")
      .add(upd);
    let playerId = pl.id;

    await firestore
      .collection("users")
      .doc(me.id)
      .collection("prospects")
      .doc(playerId)
      .update({ external_id: playerId });

    toast.dark(`Player added!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

    props.onHide({ ...upd, external_id: playerId, id: playerId });
  };

  const renderPositions = (pos: string, updatePos: any) => {
    return (
      <div>
        <div className="position-row" style={{ marginLeft: 0 }}>
          <SmallButton
            title="RHP"
            selected={pos === "RHP"}
            onClick={() => updatePos("RHP")}
          />
          <SmallButton
            title="LHP"
            selected={pos === "LHP"}
            onClick={() => updatePos("LHP")}
          />
          <SmallButton
            title="1B"
            selected={pos === "1B"}
            onClick={() => updatePos("1B")}
          />
          <SmallButton
            title="2B"
            selected={pos === "2B"}
            onClick={() => updatePos("2B")}
          />
          <SmallButton
            title="SS"
            selected={pos === "SS"}
            onClick={() => updatePos("SS")}
          />
          <SmallButton
            title="3B"
            selected={pos === "3B"}
            onClick={() => updatePos("3B")}
          />
        </div>
        <div className="position-row" style={{ marginLeft: 0 }}>
          <SmallButton
            title="C"
            selected={pos === "C"}
            onClick={() => updatePos("C")}
          />
          <SmallButton
            title="LF"
            selected={pos === "LF"}
            onClick={() => updatePos("LF")}
          />
          <SmallButton
            title="CF"
            selected={pos === "CF"}
            onClick={() => updatePos("CF")}
          />
          <SmallButton
            title="RF"
            selected={pos === "RF"}
            onClick={() => updatePos("RF")}
          />
          <SmallButton
            title="MIF"
            selected={pos === "MIF"}
            onClick={() => updatePos("MIF")}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Create Prospect
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          marginTop: 0,
        }}
        className="searching"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={firstName}
            placeholder="First Name"
            onChange={(evt) => setFirstName(evt.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={lastName}
            placeholder="Last Name"
            onChange={(evt) => setLastName(evt.target.value)}
          />
        </div>

        <p style={{ textAlign: "left" }}>Position:</p>
        {renderPositions(position, setPosition)}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: colors.accent,
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>Class:</p>
            <YearDropdown
              customYears={[
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
              ]}
              selectYear={(yr) => {
                setGraduatingClass(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={"2021"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>State:</p>
            <YearDropdown
              customYears={STATES}
              selectYear={(yr) => {
                setState(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={"State"}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Height:</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                type="text"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                  border: "0px solid",
                  color: "#fff",
                  width: 190 / 2 - 5,
                  marginRight: 10,
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                }}
                value={feet}
                placeholder="Feet"
                onChange={(evt) => setFeet(evt.target.value)}
              />
              <input
                type="text"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                  border: "0px solid",
                  color: "#fff",
                  width: 190 / 2 - 5,
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                }}
                value={inches}
                placeholder="Inches"
                onChange={(evt) => setInches(evt.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Weight:</p>
            <input
              type="text"
              style={{
                marginBottom: 10,
                display: "flex",
                flex: 1,
                border: "0px solid",
                color: "#fff",
                width: 190,
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              value={weight}
              placeholder="0"
              onChange={(evt) => setWeight(evt.target.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Bats:</p>
            <input
              type="text"
              style={{
                marginBottom: 10,
                display: "flex",
                flex: 1,
                border: "0px solid",
                color: "#fff",
                width: 190,
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              value={bats}
              placeholder="0"
              onChange={(evt) => setBats(evt.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Throws:</p>
            <input
              type="text"
              style={{
                marginBottom: 10,
                display: "flex",
                flex: 1,
                border: "0px solid",
                color: "#fff",
                width: 190,
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              value={throws}
              placeholder="0"
              onChange={(evt) => setThrows(evt.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TeamDropdown
            value={summerTeam}
            transparentInput={true}
            placeholder={"Summer Team"}
            selectTeam={(st) => {
              setSummerTeam(st);
            }}
            maxWidth={400}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CollegeCommitmentDropdown
            placeholder={"Commitment"}
            selectCollege={(val) => setCommitment(val)}
            value={commitment}
            transparentInput={true}
            maxWidth={400}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
