import React from "react";
import { useSelector } from "react-redux";
import {
  isLoaded,
  useFirestoreConnect,
  withFirestore,
} from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { compose } from "recompose";
import LoadingView from "shared/loading-view";
import "./bigboard.css";
import PdfDocBigBoard from "./pdf-doc-bigboard";
import { Link } from "react-router-dom";
import UnderlineButton from "shared/underline-button";
import colors from "constants/colors";
import { PlayerService } from "services/PlayerService";

function PDFBigBoard(props: any) {
  let { boardId, boardOwnerId, boardYear } = useParams();

  const userId = useSelector((state) => state.firebase.auth.uid);
  const myProspects = useSelector(
    (state) => state.firestore.data.bigBoardProspects
  );
  const sharedBoards = useSelector(
    (state) => state.firestore.data.sharedBoards
  );

  useFirestoreConnect([
    {
      collection: "boards",
      doc: boardId,
      subcollections: [
        {
          collection: "prospects",
        },
      ],
      storeAs: "bigBoardProspects",
    },
    {
      collection: "boards",
      where: ["recipientIds", "arrayContains", userId],
      storeAs: `sharedBoards`,
    },
    {
      collection: "users",
      where: ["friends", "array-contains", userId],
      storeAs: "boardFriends",
    },
  ]);

  const currentBoard = React.useMemo(() => {
    if (Object.keys(sharedBoards || {}).includes(boardId)) {
      return sharedBoards[boardId];
    }
    return null;
  }, [boardId, sharedBoards]);

  const sortedProspects = () => {
    if (!isLoaded(myProspects)) {
      return [];
    }

    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    prospects = prospects.map((item) => ({
      ...item,
      ordering: Object.keys(item.customOrdering || {}).includes(boardId)
        ? item.customOrdering[boardId]
        : item.ordering,
    }));

    prospects.sort(function (a, b) {
      if (a.ordering && b.ordering) {
        if (a.ordering > b.ordering) return 1;
        if (a.ordering < b.ordering) return -1;
      }
      return 0;
    });

    if (boardYear && boardYear !== "ALL YEARS") {
      prospects = prospects.filter(
        (item) => parseInt(PlayerService.getGraduatingYear(item)) === parseInt(boardYear)
      );
    }
    return prospects.filter((item) => item !== null && item.active);
  };

  if (!isLoaded(myProspects)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 12,
          paddingBottom: 200,
        }}
      >
        <LoadingView />
      </div>
    );
  }

  return (
    <div
      style={{
        paddingLeft: "0.5rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Link to={`/bigboard/${boardId}/${boardOwnerId}/${boardYear}`}>
          <UnderlineButton
            text={"back to big board"}
            onClick={() => {}}
            color={colors.accent}
          />
        </Link>
      </div>
      <PdfDocBigBoard
        boardName={currentBoard ? currentBoard.name : ""}
        fullScreen={false}
        boardYear={boardYear}
        sortedProspects={sortedProspects()}
      />
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(PDFBigBoard);
