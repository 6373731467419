import React from "react";
import { PlayerService } from "services/PlayerService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Player } from "models/Player";
import colors from "constants/colors";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import PlayerAvatar from "shared/player-avatar";

function CalendarEvent(props: {
  eventInfo: any;
  prospects: Player[];
  onEdit: any;
  onDelete: any;
}) {
  let item = props.eventInfo.event.extendedProps.source;

  const relatedProspects = () => {
    let event = item;
    if (event && event.teamOne) {
      return (props.prospects || []).filter(
        (pl) =>
          (event.teamOne !== "" && pl.summer_team === event.teamOne) ||
          (event.teamTwo !== "" && pl.summer_team === event.teamTwo) ||
          (event.addedProspectIds || []).includes(pl.external_id)
      );
    }
    return [];
  };

  let teams = props.eventInfo.event.title.split(" V. ");
  let teamOne = teams[0];
  let teamTwo = teams.length > 1 ? teams[1] : "";

  let location =
    item.game_title && item.game_title !== ""
      ? item.game_title.split(" No ")[0]
      : `${item.city && item.city !== "" ? item.city : ""}${
          item.city && item.city !== "" ? ", " : ""
        }${item.state || ""}`;

  let eventDate = moment(new Date(item.eventDate.seconds * 1000));

  const calEvent = () => {
    const startDatetime = eventDate.utc();
    const endDatetime = item.reminder
      ? startDatetime.clone().add(30, "minutes")
      : startDatetime.clone().add(3, "hours");
    const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();
    return {
      description: item.reminder ? item.note : "",
      duration,
      location: item.reminder
        ? ""
        : `${location ? location : ""} ${item.street}, ${item.city}, ${
            item.state
          } ${item.zip || ""}`,
      endDatetime: endDatetime.format("YYYYMMDDTHHmmssZ"),
      startDatetime: startDatetime.format("YYYYMMDDTHHmmssZ"),
      title: item.reminder
        ? `Reminder: ${PlayerService.getName(item.player)}`
        : props.eventInfo.event.title,
    };
  };

  const ATCDropdown = (args) => (
    <div className={item.reminder ? "atc-dropdown-red" : "atc-dropdown"}>
      {args.children.map((link, i) => (
        <p style={{ marginTop: 12 }} key={i}>
          {link}
        </p>
      ))}
    </div>
  );

  const ATCWrapper = (args) => (
    <a
      onClick={args.onClick}
      className={item.reminder ? "atc-item-red" : "atc-item"}
      href="/"
    >
      {args.children}
    </a>
  );

  const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);

  const eventDropdown = () => {
    return (
      <div
        style={{
          marginBottom: 20,
          marginTop: item.reminder ? 16 : 8,
          marginLeft: item.reminder ? 0 : 14,
          maxWidth: 139,
        }}
      >
        <AddToCalendarDropdown
          linkProps={{
            className: "atc-dropdown-title",
          }}
          event={calEvent()}
        />
      </div>
    );
  };

  if (item.reminder) {
    return (
      <OverlayTrigger
        trigger={["click", "focus"]}
        placement={eventDate && eventDate.weekday() < 2 ? "right" : "left"}
        overlay={
          <Popover id={`cal-event`}>
            <Popover.Title
              as="h3"
              style={{
                backgroundColor: colors.red,
                color: "#fff",
                textAlign: "center",
                padding: 10,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  minWidth: 150,
                }}
              >{`${PlayerService.getName(item.player)}`}</p>
            </Popover.Title>
            <Popover.Content style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  padding: 14,
                }}
              >
                <p style={{ fontWeight: "bold" }}>Time:</p>
                <p>{eventDate.format("h:mm")}</p>
                <p style={{ fontWeight: "bold" }}>Note:</p>
                <p style={{ marginBottom: 20 }}>{item.note}</p>
                {eventDropdown()}
              </div>
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  backgroundColor: colors.red,
                }}
              >
                <UnderlineButton
                  text="delete"
                  onClick={() => props.onDelete(item)}
                  color={colors.white}
                />
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <div
          style={{
            backgroundColor: colors.red,
            padding: 4,
            margin: 4,
            borderRadius: 6,
          }}
        >
          <p
            style={{
              width: "100%",
              minWidth: 100,
              whiteSpace: "normal",
              color: colors.white,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {"Reminder"}
          </p>
          <p
            style={{
              color: colors.white,
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            {PlayerService.getName(item.player)}
          </p>
        </div>
      </OverlayTrigger>
    );
  }
  return (
    <OverlayTrigger
      trigger={["click", "focus"]}
      placement={eventDate && eventDate.weekday() < 2 ? "right" : "left"}
      overlay={
        <Popover id={`cal-event`}>
          <Popover.Title
            as="h3"
            style={{
              backgroundColor: colors.accent,
              color: "#fff",
              textAlign: "center",
              padding: 10,
            }}
          >
            <p style={{ fontWeight: "bold" }}>{`${teamOne}`}</p>
            <p style={{ fontStyle: "italic" }}>{` vs. `}</p>
            <p style={{ fontWeight: "bold" }}> {`${teamTwo}`}</p>
          </Popover.Title>
          <Popover.Content style={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                padding: 14,
              }}
            >
              <p
                style={{
                  color: colors.accent,
                  fontWeight: 800,
                  marginBottom: 8,
                  marginTop: 4,
                }}
              >
                LOCATION
              </p>
              <p>{location}</p>
              {item.street ? (
                <p style={{}}>{`${item.street}, ${item.city}, ${item.state} ${
                  item.zip || ""
                }`}</p>
              ) : (
                <div />
              )}
              <p
                style={{
                  color: colors.accent,
                  fontWeight: 800,
                  marginBottom: 8,
                  marginTop: 16,
                }}
              >
                DATE
              </p>
              <p>{eventDate.format("MM/DD hh:mm a")}</p>
              {relatedProspects().length > 0 ? (
                <div>
                  <p
                    style={{
                      color: colors.accent,
                      fontWeight: 800,
                      marginBottom: 8,
                      marginTop: 16,
                    }}
                  >
                    MY PROSPECTS
                  </p>
                  {relatedProspects().map((option, index) => (
                    <Link
                      to={`/${option.college ? "collegeplayer" : "player"}/${
                        option.external_id
                      }`}
                      key={index}
                    >
                      <div
                        {...props}
                        style={{
                          display: "flex",
                          marginBottom: 3,
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <PlayerAvatar
                            player={option}
                            blueBackground={true}
                            containerStyles={{ marginRight: 12 }}
                          />
                          {PlayerService.getName(option)}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
            {eventDropdown()}
            <div
              style={{
                padding: 8,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                backgroundColor: colors.accent,
              }}
            >
              <UnderlineButton
                text="edit"
                onClick={() => props.onEdit(item)}
                color={colors.white}
              />
              <UnderlineButton
                text="delete"
                onClick={() => props.onDelete(item)}
                color={colors.white}
              />
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <div
        style={{
          backgroundColor: colors.lightBlue,
          padding: 4,
          margin: 4,
          borderRadius: 6,
        }}
      >
        <p
          style={{
            width: "100%",
            whiteSpace: "normal",
            color: colors.white,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {teamOne}
        </p>
        <p
          style={{
            color: colors.white,
            textAlign: "center",
            fontStyle: "italic",
          }}
        >
          vs.
        </p>
        <p
          style={{
            width: "100%",
            whiteSpace: "normal",
            color: colors.white,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {teamTwo}
        </p>
      </div>
    </OverlayTrigger>
  );
}

export default CalendarEvent;
