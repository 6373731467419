import React from "react";

export default function PrivacyPolicy(props: any) {
  const styles = {
    headerOne: {
      fontSize: 24,
      marginBottom: 6,
      fontWeight: "bold" as const,
    },
    headerTwo: {
      fontWeight: "bold" as const,
      fontSize: 20,
      marginBottom: 4,
    },
    body: {
      marginBottom: 4,
    },
  };

  return (
    <div style={{ flex: 1 }}>
      <div style={{ padding: 50 }}>
        <p style={styles.headerOne}>Privacy Policy</p>
        <p style={styles.body}>Last updated: October 05, 2020</p>
        <p style={styles.body}>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p style={styles.body}>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <p style={styles.headerOne}>Interpretation and Definitions</p>
        <p style={styles.headerTwo}>Interpretation</p>
        <p style={styles.body}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <p style={styles.headerTwo}>Definitions</p>
        <p style={styles.body}>For the purposes of this Privacy Policy:</p>
        <p style={styles.body}>
          • Account means a unique account created for You to access our Service
          or parts of our Service.
        </p>
        <p style={styles.body}>
          • Affiliate means an entity that controls, is controlled by or is
          under common control with a party, where "control" means ownership of
          50% or more of the shares, equity interest or other securities
          entitled to vote for election of directors or other managing
          authority.
        </p>
        <p style={styles.body}>
          • Application means the software program provided by the Company
          downloaded by You on any electronic device, named ScoutSense
        </p>
        <p style={styles.body}>
          • Business, for the purpose of the CCPA (California Consumer Privacy
          Act), refers to the Company as the legal entity that collects
          Consumers' personal information and determines the purposes and means
          of the processing of Consumers' personal information, or on behalf of
          which such information is collected and that alone, or jointly with
          others, determines the purposes and means of the processing of
          consumers' personal information, that does business in the State of
          California.
        </p>
        <p style={styles.body}>
          • Company (referred to as either "the Company", "We", "Us" or "Our" in
          this Agreement) refers to Scout Net LLC, 2920 Gatsby Lane, Willoughby
          Hills, OH 44092. For the purpose of the GDPR, the Company is the Data
          Controller.
        </p>
        <p style={styles.body}>
          • Consumer, for the purpose of the CCPA (California Consumer Privacy
          Act), means a natural person who is a California resident. A resident,
          as defined in the law, includes (1) every individual who is in the USA
          for other than a temporary or transitory purpose, and (2) every
          individual who is domiciled in the USA who is outside the USA for a
          temporary or transitory purpose.
        </p>
        <p style={styles.body}>
          • Cookies are small files that are placed on Your computer, mobile
          device or any other device by a website, containing the details of
          Your browsing history on that website among its many uses.
        </p>
        <p style={styles.body}>• Country refers to: Ohio, United States</p>
        <p style={styles.body}>
          • Data Controller, for the purposes of the GDPR (General Data
          Protection Regulation), refers to the Company as the legal person
          which alone or jointly with others determines the purposes and means
          of the processing of Personal Data.
        </p>
        <p style={styles.body}>
          • Device means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
        </p>
        <p style={styles.body}>
          • Do Not Track (DNT) is a concept that has been promoted by US
          regulatory authorities, in particular the U.S. Federal Trade
          Commission (FTC), for the Internet industry to develop and implement a
          mechanism for allowing internet users to control the tracking of their
          online activities across websites.
        </p>
        <p style={styles.body}>
          • Personal Data is any information that relates to an identified or
          identifiable individual.
        </p>
        <p style={styles.body}>
          For the purposes for GDPR, Personal Data means any information
          relating to You such as a name, an identification number, location
          data, online identifier or to one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural or social
          identity.
        </p>
        <p style={styles.body}>
          For the purposes of the CCPA, Personal Data means any information that
          identifies, relates to, describes or is capable of being associated
          with, or could reasonably be linked, directly or indirectly, with You.
        </p>
        <p style={styles.body}>
          • Sale, for the purpose of the CCPA (California Consumer Privacy Act),
          means selling, renting, releasing, disclosing, disseminating, making
          available, transferring, or otherwise communicating orally, in
          writing, or by electronic or other means, a Consumer's Personal
          information to another business or a third party for monetary or other
          valuable consideration.
        </p>
        <p style={styles.body}>
          • Service refers to the Application or the Website or both.
        </p>
        <p style={styles.body}>
          • Service Provider means any natural or legal person who processes the
          data on behalf of the Company. It refers to third-party companies or
          individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the
          Service is used. For the purpose of the GDPR, Service Providers are
          considered Data Processors.
        </p>
        <p style={styles.body}>
          • Third-party Social Media Service refers to any website or any social
          network website through which a User can log in or create an account
          to use the Service.
        </p>
        <p style={styles.body}>
          • Usage Data refers to data collected automatically, either generated
          by the use of the Service or from the Service infrastructure itself
          (for example, the duration of a page visit).
        </p>
        <p style={styles.body}>
          • Website refers to ScoutSense, accessible from scoutnetllc@gmail.com
        </p>
        <p style={styles.body}>
          • You means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </p>
        <p style={styles.body}>
          Under GDPR (General Data Protection Regulation), You can be referred
          to as the Data Subject or as the User as you are the individual using
          the Service.
        </p>
        <p style={styles.headerOne}>Collecting and Using Your Personal Data</p>
        <p style={styles.headerTwo}>Types of Data Collected</p>
        <p style={styles.body}>Personal Data</p>
        <p style={styles.body}>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <p style={styles.body}>• Email address</p>
        <p style={styles.body}>• First name and last name</p>
        <p style={styles.body}>• Phone number</p>
        <p style={styles.body}>
          • Address, State, Province, ZIP/Postal code, City
        </p>
        <p style={styles.body}>• Usage Data</p>
        <p style={styles.body}>Usage Data</p>
        <p style={styles.body}>
          Usage Data is collected automatically when using the Service.
        </p>
        <p style={styles.body}>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p style={styles.body}>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p style={styles.body}>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <p style={styles.body}>
          Information from Third-Party Social Media Services
        </p>
        <p style={styles.body}>
          The Company allows You to create an account and log in to use the
          Service through the following Third-party Social Media Services:
        </p>
        <p style={styles.body}>• Google</p>
        <p style={styles.body}>• Facebook</p>
        <p style={styles.body}>• Twitter</p>
        <p style={styles.body}>
          If You decide to register through or otherwise grant us access to a
          Third-Party Social Media Service, We may collect Personal data that is
          already associated with Your Third-Party Social Media Service's
          account, such as Your name, Your email address, Your activities or
          Your contact list associated with that account.
        </p>
        <p style={styles.body}>
          You may also have the option of sharing additional information with
          the Company through Your Third-Party Social Media Service's account.
          If You choose to provide such information and Personal Data, during
          registration or otherwise, You are giving the Company permission to
          use, share, and store it in a manner consistent with this Privacy
          Policy.
        </p>
        <p style={styles.body}>
          Information Collected while Using the Application
        </p>
        <p style={styles.body}>
          While using Our Application, in order to provide features of Our
          Application, We may collect, with your prior permission:
        </p>
        <p style={styles.body}>• Information regarding your location</p>
        <p style={styles.body}>
          • Information from your Device's phone book (contacts list)
        </p>
        <p style={styles.body}>
          • Pictures and other information from your Device's camera and photo
          library
        </p>
        <p style={styles.body}>
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it be simply
          stored on Your device.
        </p>
        <p style={styles.body}>
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>
        <p style={styles.body}>Tracking Technologies and Cookies</p>
        <p style={styles.body}>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service.
        </p>
        <p style={styles.body}>
          You can instruct Your browser to refuse all Cookies or to indicate
          when a Cookie is being sent. However, if You do not accept Cookies,
          You may not be able to use some parts of our Service.
        </p>
        <p style={styles.body}>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close your web
          browser. Learn more about cookies: All About Cookies.
        </p>
        <p style={styles.body}>
          We use both session and persistent Cookies for the purposes set out
          below:
        </p>
        <p style={styles.body}>• Necessary / Essential Cookies</p>
        <p style={styles.body}>Type: Session Cookies</p>
        <p style={styles.body}>Administered by: Us</p>
        <p style={styles.body}>
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </p>
        <p style={styles.body}>• Cookies Policy / Notice Acceptance Cookies</p>
        <p style={styles.body}>Type: Persistent Cookies</p>
        <p style={styles.body}>Administered by: Us</p>
        <p style={styles.body}>
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </p>
        <p style={styles.body}>• Functionality Cookies</p>
        <p style={styles.body}>Type: Persistent Cookies</p>
        <p style={styles.body}>Administered by: Us</p>
        <p style={styles.body}>
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </p>
        <p style={styles.body}>• Tracking and Performance Cookies</p>
        <p style={styles.body}>Type: Persistent Cookies</p>
        <p style={styles.body}>Administered by: Third-Parties</p>
        <p style={styles.body}>
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new pages, features or new functionality of the Website to see
          how our users react to them.
        </p>
        <p style={styles.body}> • Targeting and Advertising Cookies</p>
        <p style={styles.body}>Type: Persistent Cookies</p>
        <p style={styles.body}>Administered by: Third-Parties</p>
        <p style={styles.body}>
          Purpose: These Cookies track your browsing habits to enable Us to show
          advertising which is more likely to be of interest to You. These
          Cookies use information about your browsing history to group You with
          other users who have similar interests. Based on that information, and
          with Our permission, third party advertisers can place Cookies to
          enable them to show adverts which We think will be relevant to your
          interests while You are on third party websites.
        </p>
        <p style={styles.body}>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
        <p style={styles.headerTwo}>Use of Your Personal Data</p>
        <p style={styles.body}>
          The Company may use Personal Data for the following purposes:
        </p>
        <p style={styles.body}>
          • To provide and maintain our Service, including to monitor the usage
          of our Service.
        </p>
        <p style={styles.body}>
          • To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </p>
        <p style={styles.body}>
          • For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </p>
        <p style={styles.body}>
          • To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </p>
        <p style={styles.body}>
          • To provide You with news, special offers and general information
          about other goods, services and events which we offer that are similar
          to those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </p>
        <p style={styles.body}>
          • To manage Your requests: To attend and manage Your requests to Us.
        </p>
        <p style={styles.body}>
          We may share your personal information in the following situations:
        </p>
        <p style={styles.body}>
          • With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          show advertisements to You to help support and maintain Our Service,
          to advertise on third party websites to You after You visited our
          Service, for payment processing, to contact You.
        </p>
        <p style={styles.body}>
          • For Business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </p>
        <p style={styles.body}>
          • With Affiliates: We may share Your information with Our affiliates,
          in which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </p>
        <p style={styles.body}>
          • With Business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </p>
        <p style={styles.body}>
          • With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social
          Media Service, Your contacts on the Third-Party Social Media Service
          may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your
          activity, communicate with You and view Your profile.
        </p>
        <p style={styles.headerTwo}>Retention of Your Personal Data</p>
        <p style={styles.body}>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p style={styles.body}>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <p style={styles.headerTwo}>Transfer of Your Personal Data</p>
        <p style={styles.body}>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p style={styles.body}>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p style={styles.body}>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <p style={styles.headerTwo}>Disclosure of Your Personal Data</p>
        <p style={styles.body}>Business Transactions</p>
        <p style={styles.body}>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <p style={styles.body}>Law enforcement</p>
        <p style={styles.body}>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <p style={styles.body}>Other legal requirements</p>
        <p style={styles.body}>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <p style={styles.body}>• Comply with a legal obligation</p>
        <p style={styles.body}>
          • Protect and defend the rights or property of the Company
        </p>
        <p style={styles.body}>
          • Prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p style={styles.body}>
          • Prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p style={styles.body}>• Protect against legal liability</p>
        <p style={styles.headerTwo}>Security of Your Personal Data</p>
        <p style={styles.body}>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <p style={styles.headerOne}>
          Detailed Information on the Processing of Your Personal Data
        </p>
        <p style={styles.body}>
          Service Providers have access to Your Personal Data only to perform
          their tasks on Our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
        <p style={styles.headerTwo}>Analytics</p>
        <p style={styles.body}>
          We may use third-party Service providers to monitor and analyze the
          use of our Service.
        </p>
        <p style={styles.body}>• Google Analytics</p>
        <p style={styles.body}>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
        </p>
        <p style={styles.body}>
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics
          about visits activity.
        </p>
        <p style={styles.body}>
          You may opt-out of certain Google Analytics features through your
          mobile device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy
          Policy: https://policies.google.com/privacy
        </p>
        <p style={styles.body}>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web
          page: https://policies.google.com/privacy
        </p>
        {/* tara later hyperlink^ */}
        <p style={styles.body}>• Firebase</p>
        <p style={styles.body}>
          Firebase is an analytics service provided by Google Inc.
        </p>
        <p style={styles.body}>
          You may opt-out of certain Firebase features through your mobile
          device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy
          Policy: https://policies.google.com/privacy
        </p>
        {/* tara later hyperlink^ */}
        <p
          style={styles.body}
          onClick={() => {
            // tara later
            // Linking.openURL(
            //   "https://support.google.com/analytics/answer/6004245"
            // );
          }}
        >
          We also encourage you to review the Google's policy for safeguarding
          your data: https://support.google.com/analytics/answer/6004245
        </p>
        {/* tara later hyperlink^ */}
        <p style={styles.body}>
          For more information on what type of information Firebase collects,
          please visit the Google Privacy & Terms web
          page: https://policies.google.com/privacy
        </p>
        {/* tara later hyperlink^ */}
        <p style={styles.headerTwo}>Advertising</p>
        <p style={styles.body}>
          We may use Service providers to show advertisements to You to help
          support and maintain Our Service.
        </p>
        <p style={styles.body}>• Google AdSense & DoubleClick Cookie</p>
        <p style={styles.body}>
          Google, as a third party vendor, uses cookies to serve ads on our
          Service. Google's use of the DoubleClick cookie enables it and its
          partners to serve ads to our users based on their visit to our Service
          or other websites on the Internet.
        </p>
        <p style={styles.body}>
          You may opt out of the use of the DoubleClick Cookie for
          interest-based advertising by visiting the Google Ads Settings web
          page: http://www.google.com/ads/preferences/
        </p>
        {/* tara later hyperlink^ */}
        <p style={styles.headerTwo}>Email Marketing</p>
        <p style={styles.body}>
          We may use Your Personal Data to contact You with newsletters,
          marketing or promotional materials and other information that may be
          of interest to You. You may opt-out of receiving any, or all, of these
          communications from Us by following the unsubscribe link or
          instructions provided in any email We send or by contacting Us.
        </p>
        <p style={styles.body}>
          We may use Email Marketing Service Providers to manage and send emails
          to You.
        </p>
        <p style={styles.body}>• SendGrid/Twilio</p>
        <p style={styles.body}>
          Their Privacy Policy can be viewed
          at https://www.twilio.com/legal/privacy
        </p>
        <p style={styles.headerTwo}>Payments</p>
        <p style={styles.body}>
          We may provide paid products and/or services within the Service. In
          that case, we may use third-party services for payment processing
          (e.g. payment processors).
        </p>
        <p style={styles.body}>
          We will not store or collect Your payment card details. That
          information is provided directly to Our third-party payment processors
          whose use of Your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>
        <p style={styles.body}>• Apple Store In-App Payments</p>
        <p style={styles.body}>
          Their Privacy Policy can be viewed
          at https://www.apple.com/legal/privacy/en-ww/
        </p>
        <p style={styles.body}>• Google Play In-App Payments</p>
        <p style={styles.body}>
          Their Privacy Policy can be viewed
          at https://www.google.com/policies/privacy/
        </p>
        <p style={styles.body}>• PayPal</p>
        <p style={styles.body}>
          Their Privacy Policy can be viewed
          at https://www.paypal.com/webapps/mpp/ua/privacy-full
        </p>
        <p style={styles.headerTwo}>Behavioral Remarketing</p>
        <p style={styles.body}>
          The Company uses remarketing services to advertise on third party
          websites to You after You visited our Service. We and Our third-party
          vendors use cookies to inform, optimize and serve ads based on Your
          past visits to our Service.
        </p>
        <p style={styles.body}>• Google Ads (AdWords)</p>
        <p style={styles.body}>
          Google Ads (AdWords) remarketing service is provided by Google Inc.
        </p>
        <p style={styles.body}>
          You can opt-out of Google Analytics for Display Advertising and
          customise the Google Display Network ads by visiting the Google Ads
          Settings page: http://www.google.com/settings/ads
        </p>
        <p style={styles.body}>
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on - https://tools.google.com/dlpage/gaoptout - for your web
          browser. Google Analytics Opt-out Browser Add-on provides visitors
          with the ability to prevent their data from being collected and used
          by Google Analytics.
        </p>
        <p style={styles.body}>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web
          page: https://policies.google.com/privacy
        </p>
        <p style={styles.body}>• Bing Ads Remarketing</p>
        <p style={styles.body}>
          Bing Ads remarketing service is provided by Microsoft Inc.
        </p>
        <p style={styles.body}>
          You can opt-out of Bing Ads interest-based ads by following their
          instructions: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
        </p>
        <p style={styles.body}>
          You can learn more about the privacy practices and policies of
          Microsoft by visiting their Privacy Policy
          page: https://privacy.microsoft.com/en-us/PrivacyStatement
        </p>
        <p style={styles.body}>• Twitter</p>
        <p style={styles.body}>
          Twitter remarketing service is provided by Twitter Inc.
        </p>
        <p style={styles.body}>
          You can opt-out from Twitter's interest-based ads by following their
          instructions: https://support.twitter.com/articles/20170405
        </p>
        <p style={styles.body}>
          You can learn more about the privacy practices and policies of Twitter
          by visiting their Privacy Policy page: https://twitter.com/privacy
        </p>
        <p style={styles.body}>• Facebook</p>
        <p style={styles.body}>
          Facebook remarketing service is provided by Facebook Inc.
        </p>
        <p style={styles.body}>
          You can learn more about interest-based advertising from Facebook by
          visiting this page: https://www.facebook.com/help/164968693837950
        </p>
        <p style={styles.body}>
          To opt-out from Facebook's interest-based ads, follow these
          instructions from
          Facebook: https://www.facebook.com/help/568137493302217
        </p>
        <p style={styles.body}>
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioural Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the
          USA http://www.aboutads.info/choices/, the Digital Advertising
          Alliance of Canada in Canada http://youradchoices.ca/ or the European
          Interactive Digital Advertising Alliance in
          Europe http://www.youronlinechoices.eu/, or opt-out using your mobile
          device settings.
        </p>
        <p style={styles.body}>
          For more information on the privacy practices of Facebook, please
          visit Facebook's Data
          Policy: https://www.facebook.com/privacy/explanation
        </p>
        <p style={styles.body}>• Pinterest</p>
        <p style={styles.body}>
          Pinterest remarketing service is provided by Pinterest Inc.
        </p>
        <p style={styles.body}>
          You can opt-out from Pinterest's interest-based ads by enabling the
          "Do Not Track" functionality of your web browser or by following
          Pinterest
          instructions: http://help.pinterest.com/en/articles/personalization-and-data
        </p>
        <p style={styles.body}>
          You can learn more about the privacy practices and policies of
          Pinterest by visiting their Privacy Policy
          page: https://about.pinterest.com/en/privacy-policy
        </p>
        <p style={styles.body}>• AdRoll</p>
        <p style={styles.body}>
          AdRoll remarketing service is provided by Semantic Sugar, Inc.
        </p>
        <p style={styles.body}>
          You can opt-out of AdRoll remarketing by visiting this AdRoll
          Advertising Preferences web
          page: http://info.evidon.com/pub_info/573?v=1&nt=1&nw=false
        </p>
        <p style={styles.body}>
          For more information on the privacy practices of AdRoll, please visit
          the AdRoll Privacy Policy web
          page: http://www.adroll.com/about/privacy
        </p>
        <p style={styles.body}>• Perfect Audience</p>
        <p style={styles.body}>
          Perfect Audience remarketing service is provided by NowSpots Inc.
        </p>
        <p style={styles.body}>
          You can opt-out of Perfect Audience remarketing by visiting these
          pages: Platform Opt-out (http://pixel.prfct.co/coo) and Partner
          Opt-out (http://ib.adnxs.com/optout).
        </p>
        <p style={styles.body}>
          For more information on the privacy practices of Perfect Audience,
          please visit the Perfect Audience Privacy Policy & Opt-out web
          page: https://www.perfectaudience.com/privacy/
        </p>
        <p style={styles.body}>• AppNexus</p>
        <p style={styles.body}>
          AppNexus remarketing service is provided by AppNexus Inc.
        </p>
        <p style={styles.body}>
          You can opt-out of AppNexus remarketing by visiting the Privacy & the
          AppNexus Platform web
          page: https://www.appnexus.com/platform-privacy-policy
        </p>
        <p style={styles.body}>
          For more information on the privacy practices of AppNexus, please
          visit the AppNexus Platform Privacy Policy web
          page: https://www.appnexus.com/platform-privacy-policy
        </p>
        <p style={styles.headerOne}>GDPR Privacy</p>
        <p style={styles.headerTwo}>
          Legal Basis for Processing Personal Data under GDPR
        </p>
        <p style={styles.body}>
          We may process Personal Data under the following conditions:
        </p>
        <p style={styles.body}>
          • Consent: You have given Your consent for processing Personal Data
          for one or more specific purposes.
        </p>
        <p style={styles.body}>
          • Performance of a contract: Provision of Personal Data is necessary
          for the performance of an agreement with You and/or for any
          pre-contractual obligations thereof.
        </p>
        <p style={styles.body}>
          • Legal obligations: Processing Personal Data is necessary for
          compliance with a legal obligation to which the Company is subject.
        </p>
        <p style={styles.body}>
          • Vital interests: Processing Personal Data is necessary in order to
          protect Your vital interests or of another natural person.
        </p>
        <p style={styles.body}>
          • Public interests: Processing Personal Data is related to a task that
          is carried out in the public interest or in the exercise of official
          authority vested in the Company.
        </p>
        <p style={styles.body}>
          • Legitimate interests: Processing Personal Data is necessary for the
          purposes of the legitimate interests pursued by the Company.
        </p>
        <p style={styles.body}>
          In any case, the Company will gladly help to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Data is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </p>
        <p style={styles.headerTwo}>Your Rights under the GDPR</p>
        <p style={styles.body}>
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </p>
        <p style={styles.body}>
          You have the right under this Privacy Policy, and by law if You are
          within the EU, to:
        </p>
        <p style={styles.body}>
          • Request access to Your Personal Data. The right to access, update or
          delete the information We have on You. Whenever made possible, you can
          access, update or request deletion of Your Personal Data directly
          within Your account settings section. If you are unable to perform
          these actions yourself, please contact Us to assist You. This also
          enables You to receive a copy of the Personal Data We hold about You.
        </p>
        <p style={styles.body}>
          • Request correction of the Personal Data that We hold about You. You
          have the right to to have any incomplete or inaccurate information We
          hold about You corrected.
        </p>
        <p style={styles.body}>
          • Object to processing of Your Personal Data. This right exists where
          We are relying on a legitimate interest as the legal basis for Our
          processing and there is something about Your particular situation,
          which makes You want to object to our processing of Your Personal Data
          on this ground. You also have the right to object where We are
          processing Your Personal Data for direct marketing purposes.
        </p>
        <p style={styles.body}>
          • Request erasure of Your Personal Data. You have the right to ask Us
          to delete or remove Personal Data when there is no good reason for Us
          to continue processing it.
        </p>
        <p style={styles.body}>
          • Request the transfer of Your Personal Data. We will provide to You,
          or to a third-party You have chosen, Your Personal Data in a
          structured, commonly used, machine-readable format. Please note that
          this right only applies to automated information which You initially
          provided consent for Us to use or where We used the information to
          perform a contract with You.
        </p>
        <p style={styles.body}>
          • Withdraw Your consent. You have the right to withdraw Your consent
          on using your Personal Data. If You withdraw Your consent, We may not
          be able to provide You with access to certain specific functionalities
          of the Service.
        </p>
        <p style={styles.headerTwo}>
          Exercising of Your GDPR Data Protection Rights
        </p>
        <p style={styles.body}>
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </p>
        <p style={styles.body}>
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </p>
        <p style={styles.headerOne}>CCPA Privacy</p>
        <p style={styles.headerTwo}>Your Rights under the CCPA</p>
        <p style={styles.body}>
          Under this Privacy Policy, and by law if You are a resident of
          California, You have the following rights:
        </p>
        <p style={styles.body}>
          • The right to notice. You must be properly notified which categories
          of Personal Data are being collected and the purposes for which the
          Personal Data is being used.
        </p>
        <p style={styles.body}>
          • The right to access / the right to request. The CCPA permits You to
          request and obtain from the Company information regarding the
          disclosure of Your Personal Data that has been collected in the past
          12 months by the Company or its subsidiaries to a third-party for the
          third party's direct marketing purposes.
        </p>
        <p style={styles.body}>
          • The right to say no to the sale of Personal Data. You also have the
          right to ask the Company not to sell Your Personal Data to third
          parties. You can submit such a request by visiting our "Do Not Sell My
          Personal Information" section or web page.
        </p>
        <p style={styles.body}>
          • The right to know about Your Personal Data. You have the right to
          request and obtain from the Company information regarding the
          disclosure of the following:
        </p>
        <p style={styles.body}>• The categories of Personal Data collected</p>
        <p style={styles.body}>
          • The sources from which the Personal Data was collected
        </p>
        <p style={styles.body}>
          • The business or commercial purpose for collecting or selling the
          Personal Data
        </p>
        <p style={styles.body}>
          • Categories of third parties with whom We share Personal Data
        </p>
        <p style={styles.body}>
          • The specific pieces of Personal Data we collected about You
        </p>
        <p style={styles.body}>
          • The right to delete Personal Data. You also have the right to
          request the deletion of Your Personal Data that have been collected in
          the past 12 months.
        </p>
        <p style={styles.body}>
          • The right not to be discriminated against. You have the right not to
          be discriminated against for exercising any of Your Consumer's rights,
          including by:
        </p>
        <p style={styles.body}>• Denying goods or services to You</p>
        <p style={styles.body}>
          • Charging different prices or rates for goods or services, including
          the use of discounts or other benefits or imposing penalties
        </p>
        <p style={styles.body}>
          • Providing a different level or quality of goods or services to You
        </p>
        <p style={styles.body}>
          • Suggesting that You will receive a different price or rate for goods
          or services or a different level or quality of goods or services.
        </p>
        <p style={styles.headerTwo}>
          Exercising Your CCPA Data Protection Rights
        </p>
        <p style={styles.body}>
          In order to exercise any of Your rights under the CCPA, and if you are
          a California resident, You can email or call us or visit our "Do Not
          Sell My Personal Information" section or web page.
        </p>
        <p style={styles.body}>
          The Company will disclose and deliver the required information free of
          charge within 45 days of receiving Your verifiable request. The time
          period to provide the required information may be extended once by an
          additional 45 days when reasonable necessary and with prior notice.
        </p>
        <p style={styles.headerTwo}>Do Not Sell My Personal Information</p>
        <p style={styles.body}>
          We do not sell personal information. However, the Service Providers we
          partner with (for example, our advertising partners) may use
          technology on the Service that "sells" personal information as defined
          by the CCPA law.
        </p>
        <p style={styles.body}>
          If you wish to opt out of the use of your personal information for
          interest-based advertising purposes and these potential sales as
          defined under CCPA law, you may do so by following the instructions
          below.
        </p>
        <p style={styles.body}>
          Please note that any opt out is specific to the browser You use. You
          may need to opt out on every browser that you use.
        </p>
        <p style={styles.body}>Website</p>
        <p style={styles.body}>
          You can opt out of receiving ads that are personalized as served by
          our Service Providers by following our instructions presented on the
          Service:
        </p>
        <p style={styles.body}>• From Our "Cookie Consent" notice banner</p>
        <p style={styles.body}>• Or from Our "CCPA Opt-out" notice banner</p>
        <p style={styles.body}>
          • Or from Our "Do Not Sell My Personal Information" notice banner
        </p>
        <p style={styles.body}>
          • Or from Our "Do Not Sell My Personal Information" link
        </p>
        <p style={styles.body}>
          The opt out will place a cookie on Your computer that is unique to the
          browser You use to opt out. If you change browsers or delete the
          cookies saved by your browser, you will need to opt out again.
        </p>
        <p style={styles.body}>Mobile Devices</p>
        <p style={styles.body}>
          Your mobile device may give you the ability to opt out of the use of
          information about the apps you use in order to serve you ads that are
          targeted to your interests:
        </p>
        <p style={styles.body}>
          • "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization"
          on Android devices
        </p>
        <p style={styles.body}>• "Limit Ad Tracking" on iOS devices</p>
        <p style={styles.body}>
          You can also stop the collection of location information from Your
          mobile device by changing the preferences on your mobile device.
        </p>
        <p style={styles.headerOne}>
          "Do Not Track" Policy as Required by California Online Privacy
          Protection Act (CalOPPA)
        </p>
        <p style={styles.body}>
          Our Service does not respond to Do Not Track signals.
        </p>
        <p style={styles.body}>
          However, some third party websites do keep track of Your browsing
          activities. If You are visiting such websites, You can set Your
          preferences in Your web browser to inform websites that You do not
          want to be tracked. You can enable or disable DNT by visiting the
          preferences or settings page of Your web browser.
        </p>
        <p style={styles.headerOne}>Children's Privacy</p>
        <p style={styles.body}>
          The Service may contain content appropriate for children under the age
          of 13. As a parent, you should know that through the Service children
          under the age of 13 may participate in activities that involve the
          collection or use of personal information. We use reasonable efforts
          to ensure that before we collect any personal information from a
          child, the child's parent receives notice of and consents to our
          personal information practices.
        </p>
        <p style={styles.body}>
          We also may limit how We collect, use, and store some of the
          information of Users between 13 and 18 years old. In some cases, this
          means We will be unable to provide certain functionality of the
          Service to these Users. If We need to rely on consent as a legal basis
          for processing Your information and Your country requires consent from
          a parent, We may require Your parent's consent before We collect and
          use that information.
        </p>
        <p style={styles.body}>
          We may ask a User to verify its date of birth before collecting any
          personal information from them. If the User is under the age of 13,
          the Service will be either blocked or redirected to a parental consent
          process.
        </p>
        <p style={styles.headerTwo}>
          Information Collected from Children Under the Age of 13
        </p>
        <p style={styles.body}>
          The Company may collect and store persistent identifiers such as
          cookies or IP addresses from Children without parental consent for the
          purpose of supporting the internal operation of the Service.
        </p>
        <p style={styles.body}>
          We may collect and store other personal information about children if
          this information is submitted by a child with prior parent consent or
          by the parent or guardian of the child.
        </p>
        <p style={styles.body}>
          The Company may collect and store the following types of personal
          information about a child when submitted by a child with prior
          parental consent or by the parent or guardian of the child:
        </p>
        <p style={styles.body}>• First and/or last name</p>
        <p style={styles.body}>• Date of birth</p>
        <p style={styles.body}>• Gender</p>
        <p style={styles.body}>• Grade level</p>
        <p style={styles.body}>• Email address</p>
        <p style={styles.body}>• Telephone number</p>
        <p style={styles.body}>• Parent's or guardian's name</p>
        <p style={styles.body}>• Parent's or guardian's email address</p>
        <p style={styles.body}>
          For further details on the information We might collect, You can refer
          to the "Types of Data Collected" section of this Privacy Policy. We
          follow our standard Privacy Policy for the disclosure of personal
          information collected from and about children.
        </p>
        <p style={styles.headerTwo}>Parental Access</p>
        <p style={styles.body}>
          A parent who has already given the Company permission to collect and
          use his child personal information can, at any time:
        </p>
        <p style={styles.body}>
          • Review, correct or delete the child's personal information
        </p>
        <p style={styles.body}>
          • Discontinue further collection or use of the child's personal
          information
        </p>
        <p style={styles.body}>
          To make such a request, You can write to Us using the contact
          information provided in this Privacy Policy.
        </p>
        <p style={styles.headerOne}>
          Your California Privacy Rights (California's Shine the Light law)
        </p>
        <p style={styles.body}>
          Under California Civil Code Section 1798 (California's Shine the Light
          law), California residents with an established business relationship
          with us can request information once a year about sharing their
          Personal Data with third parties for the third parties' direct
          marketing purposes.
        </p>
        <p style={styles.body}>
          If you'd like to request more information under the California Shine
          the Light law, and if you are a California resident, You can contact
          Us using the contact information provided below.
        </p>
        <p style={styles.headerOne}>
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </p>
        <p style={styles.body}>
          California Business and Professions Code section 22581 allow
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </p>
        <p style={styles.body}>
          To request removal of such data, and if you are a California resident,
          You can contact Us using the contact information provided below, and
          include the email address associated with Your account.
        </p>
        <p style={styles.body}>
          Be aware that Your request does not guarantee complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal in certain circumstances.
        </p>
        <p style={styles.headerOne}>Links to Other Websites</p>
        <p style={styles.body}>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p style={styles.body}>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <p style={styles.headerOne}>Changes to this Privacy Policy</p>
        <p style={styles.body}>
          We may update our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p style={styles.body}>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p style={styles.body}>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <p style={styles.headerOne}>Contact Us</p>
        <p style={styles.body}>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <p style={styles.body}>• By email: scoutnetllc@gmail.com</p>
        <p style={styles.body}>Privacy Policy for ScoutSense</p>
      </div>
    </div>
  );
}
