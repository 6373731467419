import React from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import "../../App.css";
import "./login.css";

function ResetPass(props: any) {
  return (
    <div className="outer-container">
      <div
        className={"login-container"}
        style={{ minWidth: "30%", minHeight: "40vh" }}
      >
        <p className={"signin-text"}>RESET PASSWORD</p>
        <p style={{ color: "black" }}>coming soon</p>
      </div>
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(ResetPass);
