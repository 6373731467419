import React from "react";
import SidebarPlayers from "./sidebar-players";
import SidebarFriends from "./sidebar-friends";
import { useLocation } from "react-router-dom";
import SidebarReports from "./sidebar-reports";
import SidebarEvents from "./sidebar-events";
import UnderlineButton from "./underline-button";
import { AiOutlineCloseCircle } from "react-icons/ai";

function ScoutSidebar(props: { setSidebarOpen: any; setSidebarDocked: any }) {
  let location = useLocation();

  return location.pathname.includes("search-results") ? (
    <div />
  ) : (
    <div
      style={{
        flex: 1,
        minWidth: 370,
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: "9.5rem",
        marginBottom: 30,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <UnderlineButton
          text={"close sidebar"}
          onClick={() => {
            props.setSidebarDocked(false);
            props.setSidebarOpen(false);
          }}
        />
        <AiOutlineCloseCircle
          onClick={() => {
            props.setSidebarDocked(false);
            props.setSidebarOpen(false);
          }}
          style={{ marginTop: 10 }}
        />
      </div>

      {location.pathname.includes("prospects") ? (
        <div />
      ) : (
        <SidebarPlayers {...props} />
      )}

      {location.pathname.includes("calendar") ? (
        <div />
      ) : (
        <SidebarEvents {...props} sidebar={true} />
      )}

      {location.pathname.includes("report") ? (
        <div />
      ) : (
        <SidebarReports {...props} />
      )}

      <SidebarFriends {...props} />
    </div>
  );
}

export default ScoutSidebar;
