import React, { useState } from "react";
import colors from "constants/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import LabelValueBox from "../components/label-value-box";
import LabelValueFlexBox from "../components/label-value-flex-box";
import LabelDescription from "../components/label-description";
import OneBoxRow from "../components/one-box-row";
import OneBoxRowComments from "../components/one-box-row-comments";
import SixBoxRow from "../components/six-box-row";
import ThreeBoxRow from "../components/three-box-row";
import ReportTop from "./report-top";

function PitcherReport({ posReport }) {
  const renderComments = (item) => {
    return (
      <tr>
        <td
          style={{
            textAlign: "left",
            padding: "2px 4px",
            border: "none",
            fontStyle: item && item.comments ? "normal" : "italic",
            opacity: item && item.comments ? 1 : 0.4,
          }}
          colSpan={4}
        >
          {item && item.comments ? item.comments : "N/A"}
        </td>
      </tr>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
        backgroundColor: "#FFF",
        padding: 10,
      }}
    >
      <ReportTop posReport={posReport} />

      <table style={{ width: "100%", marginBottom: 20 }}>
        <OneBoxRow label="Arm Angle" value={posReport.armAngle} />
        {renderComments(posReport.armAngle)}

        <OneBoxRow label="Delivery" value={posReport.delivery.value} />
        {renderComments(posReport.delivery)}

        <OneBoxRow label="Arm Action" value={posReport.armAction.value} />
        {renderComments(posReport.armAction)}
      </table>

      <LabelDescription
        label="Physical Description"
        value={posReport.physicalDescription}
      />

      <LabelDescription
        label="Player Comparison"
        value={posReport.playerComparison}
      />

      <table style={{ width: "100%", marginTop: 20 }}>
        <tr>
          <td
            style={{ width: "40%", border: "none", textAlign: "center" }}
          ></td>
          <td
            style={{
              width: "10%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"P"}
          </td>
          <td
            style={{
              width: "10%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"F"}
          </td>
          <td
            style={{
              width: "10%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"Adj."}
          </td>
          <td
            style={{
              width: "10%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"H"}
          </td>
          <td
            style={{
              width: "10%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"L"}
          </td>
          <td
            style={{
              width: "10%",
              fontWeight: "bold",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {"A"}
          </td>
        </tr>

        <SixBoxRow
          label={"FB"}
          p={posReport.fB.p}
          f={posReport.fB.f}
          adj={posReport.fB.adj}
          h={posReport.fB.h}
          l={posReport.fB.l}
          a={posReport.fB.a}
          comments={posReport.fB.comments}
        />
        {renderComments(posReport.fB)}

        <SixBoxRow
          label={"FBMVMT"}
          p={posReport.fBMVMT.p}
          f={posReport.fBMVMT.f}
          adj={posReport.fBMVMT.adj}
          h={posReport.fBMVMT.h}
          l={posReport.fBMVMT.l}
          a={posReport.fBMVMT.a}
          comments={posReport.fBMVMT.comments}
        />
        {renderComments(posReport.fBMVMT)}
        <SixBoxRow
          label={"FB Stretch"}
          p={posReport.fBStretch.p}
          f={posReport.fBStretch.f}
          adj={posReport.fBStretch.adj}
          h={posReport.fBStretch.h}
          l={posReport.fBStretch.l}
          a={posReport.fBStretch.a}
          comments={posReport.fBStretch.comments}
        />
        {renderComments(posReport.fBStretch)}
        <SixBoxRow
          label={"SL"}
          p={posReport.sL.p}
          f={posReport.fBStretch.f}
          adj={posReport.fBStretch.adj}
          h={posReport.fBStretch.h}
          l={posReport.fBStretch.l}
          a={posReport.fBStretch.a}
          comments={posReport.fBStretch.comments}
        />
        {renderComments(posReport.fBStretch)}
        <SixBoxRow
          label={"CB"}
          p={posReport.cB.p}
          f={posReport.cB.f}
          adj={posReport.cB.adj}
          h={posReport.cB.h}
          l={posReport.cB.l}
          a={posReport.cB.a}
          comments={posReport.cB.comments}
        />
        {renderComments(posReport.cB)}
        <SixBoxRow
          label={"CH"}
          p={posReport.cH.p}
          f={posReport.cH.f}
          adj={posReport.cH.adj}
          h={posReport.cH.h}
          l={posReport.cH.l}
          a={posReport.cH.a}
          comments={posReport.cH.comments}
        />
        {renderComments(posReport.cH)}
        <SixBoxRow
          label={"Control"}
          p={posReport.control.p}
          f={posReport.control.f}
          adj={posReport.control.adj}
          h={posReport.control.h}
          l={posReport.control.l}
          a={posReport.control.a}
          comments={posReport.control.comments}
        />
        {renderComments(posReport.control)}
        <SixBoxRow
          label={"Command"}
          p={posReport.command.p}
          f={posReport.command.f}
          adj={posReport.command.adj}
          h={posReport.command.h}
          l={posReport.command.l}
          a={posReport.command.a}
          comments={posReport.command.comments}
        />
        {renderComments(posReport.command)}
        <SixBoxRow
          label={"Can He Pitch"}
          p={posReport.canHePitch.p}
          f={posReport.canHePitch.f}
          adj={posReport.canHePitch.adj}
          h={posReport.canHePitch.h}
          l={posReport.canHePitch.l}
          a={posReport.canHePitch.a}
          comments={posReport.canHePitch.comments}
        />
        {renderComments(posReport.canHePitch)}

        <SixBoxRow
          label={"OFP"}
          p={posReport.oFP.p}
          f={posReport.oFP.f}
          adj={posReport.oFP.adj}
          h={posReport.oFP.h}
          l={posReport.oFP.l}
          a={posReport.oFP.a}
          comments={posReport.oFP.comments}
        />
        {renderComments(posReport.oFP)}
      </table>

      <table style={{ marginTop: 20, width: "100%" }}>
        <OneBoxRow label="Aggressiveness" value={posReport.aggressiveness} />
        <OneBoxRow label="Athleticism" value={posReport.athleticism} />
        <OneBoxRow label="Aptitude" value={posReport.aptitude} />
        <OneBoxRow label="Poise" value={posReport.poise} />
        <OneBoxRow label="Baseball IQ" value={posReport.baseballIQ} />
        <OneBoxRow label="Fielding" value={posReport.fielding} />
        <OneBoxRow label="Deception" value={posReport.deception} />
        <OneBoxRow label="Release Times" value={posReport.releaseTimes} />
        <OneBoxRow label="Outpitch" value={posReport.outpitch} />
      </table>
      <table style={{ marginTop: 40, width: "100%" }}>
        <OneBoxRow label="Character" value={posReport.character} />
        <OneBoxRow label="Entry Level" value={posReport.entryLevel} />
        <OneBoxRow label="ETA" value={posReport.eTA} />
      </table>
      <table style={{ marginTop: 40, marginBottom: 20 }}>
        <OneBoxRow
          label="How Well Known"
          value={posReport.howWellKnown.value}
        />
        {renderComments(posReport.howWellKnown)}
        <OneBoxRow label="How Well Seen" value={posReport.howWellSeen.value} />
        {renderComments(posReport.howWellSeen)}
        <OneBoxRow label="Signablility" value={posReport.signability.value} />
        {renderComments(posReport.signability)}
      </table>

      <div className="report-row">
        <LabelDescription label="Summary" value={posReport.summary} />
      </div>
    </div>
  );
}

export default PitcherReport;
