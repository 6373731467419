import React, { useState } from "react";
import { Text, View } from "@react-pdf/renderer";
import colors from "constants/colors";

function PDFLabelDescription({ label, value }) {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Text
        style={{
          fontSize: 10,
          padding: 4,

          borderColor: colors.softDarkText,
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        {label}
      </Text>
      {value && value !== "" ? (
        <Text
          style={{
            fontWeight: "normal",
            fontSize: 10,
            backgroundColor: colors.lightestBlue,
            padding: 4,
            color: colors.softDarkText,
          }}
        >
          {value}
        </Text>
      ) : (
        <Text
          style={{
            fontStyle: "italic",
            fontSize: 10,
            backgroundColor: colors.lightestBlue,
            fontWeight: "normal",
            padding: 4,
            color: colors.softDarkText,
          }}
        >
          N/A
        </Text>
      )}
    </View>
  );
}

export default PDFLabelDescription;
