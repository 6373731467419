import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import colors from "../constants/colors";
import { AiFillPlusCircle, AiOutlineQuestionCircle } from "react-icons/ai";

const ScoutTitle = (props: {
  text: string;
  styles?: any;
  withPlus?: boolean;
  onClick?: any;
  withHelp?: boolean;
  light?: boolean;
}) => {
  if (props.withHelp) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p
          style={{
            color: props.light ? "#fff" : colors.accent,
            fontSize: 20,
            fontWeight: 700,
            padding: "1rem",
            paddingRight: 0,
            ...props.styles,
          }}
        >
          {props.text.toUpperCase()}
        </p>
        <Button
          onClick={props.onClick}
          variant="secondary"
          style={{
            marginTop: -4,
            marginLeft: -6,
            color: props.light ? "#fff" : colors.accent,
            fontSize: 20,
            backgroundColor: "transparent",
            borderWidth: 0,
            boxShadow: "none",
          }}
        >
          <AiOutlineQuestionCircle />
        </Button>
      </div>
    );
  }
  return props.withPlus ? (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <p
        style={{
          color: props.light ? "#fff" : colors.accent,
          fontSize: 20,
          fontWeight: 700,
          padding: "1rem",
          paddingRight: 0,
          ...props.styles,
        }}
      >
        {props.text.toUpperCase()}
      </p>
      <Button
        onClick={props.onClick}
        variant="secondary"
        style={{
          marginTop: -4,
          color: props.light ? "#fff" : colors.accent,
          fontSize: 20,
          backgroundColor: "transparent",
          borderWidth: 0,
          boxShadow: "none",
        }}
      >
        <AiFillPlusCircle />
      </Button>
    </div>
  ) : (
    <p
      style={{
        color: props.light ? "#fff" : colors.accent,
        fontSize: 20,
        fontWeight: 700,
        padding: "1rem",
        ...props.styles,
      }}
    >
      {props.text.toUpperCase()}
    </p>
  );
};

export default ScoutTitle;
