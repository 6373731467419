import React, { useState } from "react";
import colors from "constants/colors";
import { Image, View, Text } from "@react-pdf/renderer";

function PDFImagesList({ posReport, fancyText }) {
  return (
    <View style={{ marginTop: 20 }}>
      {fancyText ? (
        <Text
          style={{
            fontSize: 12,
            fontFamily: "RalewayBold",
            textDecoration: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: colors.accent,
            color: colors.accent,
            fontWeight: 800,
            marginBottom: 4,
          }}
        >
          IMAGES
        </Text>
      ) : (
        <Text
          style={{
            fontSize: 10,
            marginBottom: 4,
          }}
        >
          Images
        </Text>
      )}

      {(posReport.photos || []).map((item, index) => (
        <View>
          <Image
            source={`https://tara-proxy.herokuapp.com/${item}`}
            style={{
              width: 200,
              marginBottom: 20,
            }}
          />
        </View>
      ))}

      {(posReport.photos || []).length === 0 ? (
        <Text style={{ fontSize: 10, opacity: 0.8 }}>N/A</Text>
      ) : (
        <View />
      )}
    </View>
  );
}

export default PDFImagesList;
