import React, { useState } from "react";
import {
  useFirestoreConnect,
  isLoaded,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PositionReport } from "models/PositionReport";
import ReportCard from "./components/report-card";
import LoadingView from "shared/loading-view";
import colors from "constants/colors";
import { AiOutlineSearch } from "react-icons/ai";
import ScoutTitle from "shared/scout-title";
import HelpModal from "modals/help-modal";
import UnderlineButton from "shared/underline-button";
import { FaAddressCard, FaListUl } from "react-icons/fa";
import ReportRow from "./components/report-row";
import CTAButton from "shared/cta-button";
import AreYouSureModal from "modals/are-you-sure-modal";

function Reports(props: any) {
  const [showCards, setShowCards] = useState(true);
  const userId = useSelector((state) => state.firebase.auth.uid);
  const myReports = useSelector((state) => state.firestore.data.myReports);
  const [search, setSearch] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports" }],
      storeAs: `myReports`,
    },
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  if (!isLoaded(myReports)) {
    return <LoadingView />;
  }

  const deleteSelectedReports = async () => {
    selected.forEach(async (reportId) => {
      await firestore
        .collection("users")
        .doc(userId)
        .collection("reports")
        .doc(reportId)
        .delete();
    });
    setSelected([]);
    setShowDeleteConfirmation(false);
  };

  const sortedReports = (): PositionReport[] => {
    let reports = myReports
      ? Object.keys(myReports).map((key) =>
          myReports ? { ...myReports[key], id: key } : null
        )
      : [];

    if (search !== "") {
      reports = reports.filter(
        (item) =>
          item &&
          item.playerName &&
          item.playerName.toLowerCase().includes(search.toLowerCase())
      );
    }
    reports = reports.filter(
      (item) =>
        item !== null && item.kind !== "NOTES" && item.id && item.playerName
    );

    reports.sort(function (a, b) {
      if (a.lastUpdated && b.lastUpdated) {
        if (a.lastUpdated.seconds > b.lastUpdated.seconds) return -1;
        if (a.lastUpdated.seconds < b.lastUpdated.seconds) return 1;
      } else if (a.lastUpdated) {
        return -1;
      } else if (b.lastUpdated) {
        return 1;
      }
      return 0;
    });

    return reports;
  };

  const sortedNotes = (): PositionReport[] => {
    let reports = myReports
      ? Object.keys(myReports).map((key) =>
          myReports ? { ...myReports[key], id: key } : null
        )
      : [];

    if (search !== "") {
      reports = reports.filter(
        (item) =>
          item &&
          item.playerName &&
          item.playerName.toLowerCase().includes(search.toLowerCase())
      );
    }

    reports = reports.filter((item) => item !== null && item.kind === "NOTES");

    reports.sort(function (a, b) {
      if (a.lastUpdated && b.lastUpdated) {
        if (a.lastUpdated.seconds > b.lastUpdated.seconds) return -1;
        if (a.lastUpdated.seconds < b.lastUpdated.seconds) return 1;
      } else if (a.lastUpdated) {
        return -1;
      } else if (b.lastUpdated) {
        return 1;
      }
      return 0;
    });

    return reports;
  };

  return (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,

        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              marginBottom: "-0.7rem",
              marginLeft: "-1rem",
            }}
          >
            <ScoutTitle
              text="Reports"
              withHelp={true}
              onClick={() => setShowHelp(true)}
            />
          </div>

          {selected.length > 0 && !showCards ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CTAButton
                text={`Delete ${selected.length} Report${
                  selected.length === 1 ? "" : "s"
                }`}
                styles={{
                  backgroundColor: colors.red,
                  borderColor: "transparent",
                  padding: 4,
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginRight: 12,
                }}
                fontStyles={{ fontSize: 14 }}
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
              />
              <UnderlineButton
                text="clear selection"
                onClick={() => {
                  setSelected([]);
                }}
              />
            </div>
          ) : (
            <UnderlineButton
              text="create report"
              onClick={() => {
                history.push("/create-report/any");
              }}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            className="searching"
            style={{
              flexDirection: "row",
              display: "flex",
              backgroundColor: colors.lightBlue,
              margin: 20,
              marginRight: 20,
              borderRadius: 4,
              marginBottom: 14,
            }}
          >
            <input
              type="text"
              placeholder="search reports..."
              style={{
                backgroundColor: "transparent",
                color: colors.white,
                boxShadow: "none",
                borderWidth: 0,
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 4,
                paddingBottom: 4,
              }}
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
            />
            <AiOutlineSearch
              style={{
                fontSize: 24,
                marginTop: 4,
                marginLeft: 4,
                marginRight: 8,
                opacity: 0.7,
                color: colors.white,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderWidth: 2,
              borderColor: colors.accent,
              borderStyle: "solid",
              borderRadius: 8,
              width: 100,
              marginBottom: -4,
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                backgroundColor: showCards ? colors.accent : "transparent",
                padding: 2,
                paddingLeft: 15,
                paddingRight: 15,
                width: 50,
              }}
              onClick={() => setShowCards(true)}
            >
              <FaAddressCard
                style={{ color: showCards ? colors.white : colors.accent }}
              />
            </div>
            <div
              onClick={() => setShowCards(false)}
              style={{
                backgroundColor: showCards ? "transparent" : colors.accent,
                padding: 2,
                paddingLeft: 15,
                paddingRight: 15,
                width: 50,
              }}
            >
              <FaListUl
                style={{ color: showCards ? colors.accent : colors.white }}
              />
            </div>
          </div>
        </div>
      </div>

      {showCards ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {(sortedReports() || []).map((item, index) => (
            <ReportCard report={item} index={index} />
          ))}
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(sortedReports() || []).map((item, index) => (
            <ReportRow
              report={item}
              index={index}
              selected={selected.includes(item.id)}
              onSelect={(reportId) => {
                if (selected.includes(reportId)) {
                  setSelected(
                    selected.filter((selItem) => selItem !== reportId)
                  );
                } else {
                  setSelected([...selected, reportId]);
                }
              }}
            />
          ))}
        </div>
      )}

      {(sortedReports() || []).length === 0 ? (
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        >
          <p style={{ fontStyle: "italic", opacity: 0.6 }}>
            No reports created yet.
          </p>
        </div>
      ) : (
        <div />
      )}

      <ScoutTitle text="Notes" />

      {showCards ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {(sortedNotes() || []).map((item, index) => (
            <ReportCard report={item} />
          ))}
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(sortedNotes() || []).map((item, index) => (
            <ReportRow
              report={item}
              index={index}
              selected={selected.includes(item.id)}
              onSelect={(reportId) => {
                if (selected.includes(reportId)) {
                  setSelected(
                    selected.filter((selItem) => selItem !== reportId)
                  );
                } else {
                  setSelected([...selected, reportId]);
                }
              }}
            />
          ))}
        </div>
      )}

      {(sortedNotes() || []).length === 0 ? (
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        >
          <p style={{ fontStyle: "italic", opacity: 0.6 }}>
            No notes created yet.
          </p>
        </div>
      ) : (
        <div />
      )}

      <HelpModal
        show={showHelp}
        kind={"REPORTS"}
        onHide={() => setShowHelp(false)}
      />

      <AreYouSureModal
        show={showDeleteConfirmation}
        onConfirm={deleteSelectedReports}
        onHide={() => setShowDeleteConfirmation(false)}
        title={"Delete Reports"}
        body={`Are you sure you want to delete ${selected.length} report${
          selected.length === 1 ? "" : "s"
        }?`}
      />
    </div>
  );
}

export default Reports;
