import { REPORT_KINDS } from "constants/data";
import colors from "constants/colors";
import React from "react";
import Select from "react-select";

const options = REPORT_KINDS.map((item) => ({ value: item, label: item }));

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const ReportKindDropDown = ({ selectReportKind }) => {
  const onChange = (items) => {
    selectReportKind(items.map((item) => item.value));
  };

  return (
    <Select
      options={options}
      isMulti={true}
      isClearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder="Select..."
    />
  );
};

export default ReportKindDropDown;
