import React from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import NotificationItem from "./notification-item";
import colors from "constants/colors";
import { useMe, useNotifications } from "@tara-wilson/mcc-lib";
import { ScoutNotification } from "models/ScoutNotification";
import { NotificationService } from "services/NotificationService";
import UnderlineButton from "shared/underline-button";

function NotificationsList(props: { limit?: number; includeClear?: boolean }) {
  const me = useMe();
  const notifications = useNotifications();
  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: "notifications",
      where: ["recipient", "==", me.id],
    },
  ]);

  const clearAll = () => {
    let nots = filteredNotifications();

    nots.forEach((notification) => {
      NotificationService.markNotificationRemoved(notification, firestore);
    });
  };

  const filteredNotifications = () => {
    let nots = ((notifications || []) as ScoutNotification[]).filter(
      (item) => item !== null
    );

    let n = nots.filter((item) => item && item.text);

    n.sort(function (a, b) {
      if (
        a.timeCreated &&
        a.timeCreated.seconds &&
        b.timeCreated &&
        b.timeCreated.seconds
      ) {
        if (a.timeCreated.seconds > b.timeCreated.seconds) return -1;
        if (a.timeCreated.seconds < b.timeCreated.seconds) return 1;
      }
      return 0;
    });

    if (props.limit) {
      n = n
        .filter((item) => item && !item.removed && !item.read)
        .splice(0, props.limit);
    }

    return n.filter((item) => item && !item.removed);
  };

  return (
    <div>
      {props.includeClear && filteredNotifications().length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ marginRight: 12, marginTop: -40 }}>
            <UnderlineButton text="clear all" onClick={clearAll} />
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flex: 1,
          paddingLeft: 12,
          paddingRight: 12,
          marginBottom: 30,
          flexDirection: "column",
        }}
      >
        {filteredNotifications().map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                backgroundColor:
                  index % 2 === 1 ? colors.white : colors.lightGray,
                paddingLeft: 9,
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {item.read ? (
                  <div
                    style={{
                      backgroundColor: colors.mediumGray,
                      width: 13,
                      minWidth: 13,
                      height: 13,
                      borderRadius: 13 / 2,
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      backgroundColor: colors.lightGreen,
                      width: 13,
                      minWidth: 13,
                      height: 13,
                      borderRadius: 13 / 2,
                    }}
                  ></div>
                )}
              </div>
              <NotificationItem notification={item} me={me} />
            </div>
          );
        })}

        {!props.limit && filteredNotifications().length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontStyle: "italic",
                opacity: 0.6,
              }}
            >
              No notifications.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationsList;
