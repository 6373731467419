import React, { useEffect } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import "../../App.css";
import "./login.css";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FBAnalytics } from "store/store";
import colors from "constants/colors";

import { GoogleLoginButton } from "./shared";
import { useFlags } from "hooks/flags";

function LogIn(props: any) {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const flags = useFlags();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [err, setErr] = React.useState("");

  useEffect(() => {
    let userEmail = localStorage.getItem("scoutEmail");
    console.log("userEmail", userEmail);
    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);

  const submit = async () => {
    try {
      localStorage.setItem("scoutEmail", email);
      await props.firebase.login({ email, password });
      onSignIn();
    } catch (err) {
      if ((err as any).message.includes("may have been deleted")) {
        setErr("There is no user record corresponding to this email.");
      } else {
        setErr((err as any).message);
      }
    }
  };

  const onSignIn = () => {
    //@ts-ignore
    FBAnalytics.logEvent("login");
    history.replace("home");
  };

  const renderEmailPass = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>

        <GoogleLoginButton
          onCreateNewUser={(userData) => {
            return history.replace({
              pathname: "/signup",
              state: {
                phase: "DETAILS",
                ...userData
              },
            });
          }}
        />

        <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "16px", padding: "0 16px" }}>
          <div style={{ height: "2px", background: colors.lightestBlue, flex: 1 }} />
          <div style={{ color: colors.lightBlue }}>or</div>
          <div style={{ height: "2px", background: colors.lightestBlue, flex: 1 }} />
        </div>

        <form onSubmit={handleSubmit(submit)}>        
          <div className={"textinput-container"}>
            <input
              placeholder="Email"
              className="input"
              onChange={(evt) => setEmail(evt.target.value)}
              ref={register({ required: true, minLength: 5 })}
              name="email"
              value={email}
            />

            <input
              type="password"
              placeholder="Password"
              name="password"
              className={"input"}
              onChange={(evt) => setPassword(evt.target.value)}
              ref={register({ required: true, minLength: 5 })}
            />
            
            {err && err !== "" ? (
              <span
                style={{
                  color: "#B22222",
                  width: 280,
                  opacity: 0.8,
                  textAlign: "center",
                  marginTop: 4,
                  fontStyle: "italic",
                }}
              >
                {err}
              </span>
            ) : (
              <div />
            )}
          </div>

          <div className={"forgot-container"} style={{ alignSelf: "center" }}>
            <p className={"forgot-left-text"}>Forgot Password?</p>
            <div className={"forgot-right-text-link"}>
              <Link
                className="forgot-right-text"
                style={{ textDecoration: "none" }}
                to="/reset-password"
              >
                Reset Password
              </Link>
            </div>
          </div>
          <input
            className={"button-text signin-button"}
            style={{ color: "#fff", border: "none", marginTop: -8 }}
            type="submit"
            value="SIGN IN"
          />

          <div
            className={"havent-container"}
            style={{ marginTop: 10, alignSelf: "center" }}
          >
            <p className={"havent-left-text"}>Haven't got an account?</p>
            <div className={"havent-right-text-link"}>
              <Link
                className="havent-right-text"
                style={{ textDecoration: "none" }}
                to="/signup"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="outer-container-login">
      <div
        style={{
          display: "flex",
          flex: 6,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p className={"signin-text"} style={{ marginBottom: 40 }}>
          BECOME A PART OF THE TEAM
        </p>

        <div className={"login-container"}>{renderEmailPass()}</div>
      </div>
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <Link
          className="navbar-brand"
          style={{ textDecoration: "none", fontWeight: 700 }}
          to="/"
        >
          <div
            style={{ display: "flex", flexDirection: "row", color: "white" }}
          >
            <h3 style={{ fontWeight: 800 }}>Scout</h3>
            <h3 style={{ fontWeight: 300 }}>NET</h3>
          </div>
        </Link>
      </div>
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(LogIn);
