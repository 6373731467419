import React from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { PlayerService } from "services/PlayerService";
import PlayerAvatar from "shared/player-avatar";

const ProspectMultiDropdown = (props: {
  selectProspect: any;
  value: string[];
}) => {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const myProspects = useSelector((state) => state.firestore.data.myProspects);

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  if (!isLoaded(myProspects)) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }

  const sortedProspects = () => {
    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  return sortedProspects().length > 0 ? (
    <Typeahead
      multiple={true}
      labelKey="name"
      id="prospects"
      options={sortedProspects().map((item) => ({
        ...item,
        name: PlayerService.getName(item),
      }))}
      onChange={(vals) => {
        props.selectProspect(vals);
      }}
      placeholder="Select Players..."
      selected={sortedProspects()
        .map((item) => ({
          ...item,
          name: PlayerService.getName(item),
        }))
        .filter((item) => props.value.includes(item.external_id))}
      renderMenuItemChildren={(option, props) => (
        <div
          {...props}
          style={{
            marginLeft: -8,
            display: "flex",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PlayerAvatar
              player={option}
              blueBackground={true}
              containerStyles={{ marginRight: 6 }}
            />
            {option.name}
          </span>
        </div>
      )}
    />
  ) : (
    <div>
      <p>No prospects.</p>
    </div>
  );
};

export default ProspectMultiDropdown;
