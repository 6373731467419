import colors from "../constants/colors";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const CTALink = ({ linkTo, text, styles }) => {
  let history = useHistory();

  return (
    <Button
      style={{
        justifySelf: "center",
        alignSelf: "center",
        backgroundColor: colors.lightBlue,
        paddingRight: 50,
        paddingTop: 0,
        paddingLeft: 50,
        paddingBottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...styles,
      }}
      onClick={() => {
        history.push(linkTo);
      }}
    >
      <p style={{ color: colors.white, fontSize: 20 }}>{text}</p>
    </Button>
  );
};

export default CTALink;
