import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import AreYouSureModal from "modals/are-you-sure-modal";
import CreateDiscoveryModal from "modals/create-discovery-modal";
import EditDiscoveryModal from "modals/edit-discovery-modal";
import "rc-slider/assets/index.css";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  AiFillTwitterCircle,
  AiOutlineInfoCircle,
  AiFillGoogleCircle,
} from "react-icons/ai";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteDiscovery, updateDiscovery } from "services/MonitorService";
import UnderlineButton from "shared/underline-button";
function RightFilters({
  stoplightId,
  sources,
}: {
  stoplightId: string;
  sources: {
    active: boolean;

    guid: string;
    name: string;
  }[];
}) {
  const me = useMe();
  const history = useHistory();
  const firestore = useFirestore();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingDiscovery, setEditingDiscovery] = useState(null);
  const [deletingDiscovery, setDeletingDiscovery] = useState(null);
  const [loading, setLoading] = useState(null);
  const myDiscoveries = useSelector(
    (state) => state.firestore.data.myDiscoveries
  );

  const twitterSource = () => {
    let tw = (sources || []).find((item) => item.name == "Twitter");
    if (tw) {
      return tw.guid;
    }
    return null;
  };

  const googleSource = () => {
    let tw = (sources || []).find((item) => item.name == "Google");
    if (tw) {
      return tw.guid;
    }
    return null;
  };

  useFirestoreConnect([
    {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "discoveries" }],
      storeAs: `myDiscoveries`,
    },
  ]);

  const sortedDiscoveries = () => {
    let events = myDiscoveries
      ? Object.keys(myDiscoveries).map((key) =>
          myDiscoveries ? { ...myDiscoveries[key], id: key } : null
        )
      : [];

    return events.filter((item) => item !== null && item.term);
  };

  const finishDeleteDiscovery = async () => {
    let disc = { ...deletingDiscovery };
    setDeletingDiscovery(null);
    setLoading(disc);
    let res = await deleteDiscovery(stoplightId, disc.guid);

    await firestore
      .collection("users")
      .doc(me.id)
      .collection("discoveries")
      .doc(disc.id)
      .delete();

    toast.dark(`Discovery removed.`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

    setLoading(null);
  };

  const toggleActive = async (discovery: any) => {
    setLoading(discovery);
    let res = await updateDiscovery(
      stoplightId,
      discovery.term,
      discovery.sources,
      !discovery.collection_active,
      discovery.guid
    );
    await firestore
      .collection("users")
      .doc(me.id)
      .collection("discoveries")
      .doc(discovery.id)
      .update(res);
    setLoading(null);
    toast.dark(`Discovery updated!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const renderDiscoveries = () => {
    return sources.length > 0 ? (
      <div>
        {sortedDiscoveries().map((item, index) => (
          <div
            key={index}
            style={{
              minWidth: 240,

              backgroundColor: colors.white,
              padding: 8,
              paddingBottom: 0,
              display: "flex",
              flexDirection: "column",
              borderRadius: 6,
              border: "1px solid",
              borderColor: colors.lightBlue,
              marginBottom: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",

                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {item.collection_active ? (
                  <BsCheckSquare
                    style={{
                      cursor: "pointer",
                      color: colors.accent,
                      fontSize: 20,
                      marginRight: 8,
                    }}
                    onClick={() => toggleActive(item)}
                  />
                ) : (
                  <BsSquare
                    style={{
                      cursor: "pointer",
                      color: colors.accent,
                      fontSize: 20,
                      marginRight: 8,
                    }}
                    onClick={() => toggleActive(item)}
                  />
                )}
                <p style={{ color: colors.accent }}>{item.term}</p>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                {(item.sources || []).map((item, index) => {
                  if (item == twitterSource()) {
                    return (
                      <div>
                        <AiFillTwitterCircle
                          style={{ fontSize: 20, color: colors.lightBlue }}
                        />
                      </div>
                    );
                  }
                  if (item == googleSource()) {
                    return (
                      <div>
                        <AiFillGoogleCircle
                          style={{ fontSize: 20, color: colors.lightBlue }}
                        />
                      </div>
                    );
                  }
                  return (
                    <div>
                      <AiOutlineInfoCircle
                        style={{ fontSize: 20, color: colors.lightBlue }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {loading && loading.guid == item.guid ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 4,
                  color: colors.accent,
                }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <UnderlineButton
                  text={"edit"}
                  onClick={() => {
                    setEditingDiscovery(item);
                  }}
                />

                <UnderlineButton
                  text={"delete"}
                  onClick={() => {
                    setDeletingDiscovery(item);
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          color: colors.accent,
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        padding: 12,
        paddingTop: 40,
        width: 300,
        maxWidth: 300,
        minWidth: 300,
        borderLeftColor: colors.lightBlue,
        borderLeftStyle: "solid",
        borderLeftWidth: 3,
      }}
    >
      <div
        className="monitor-advanced-container"
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "80vh",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          <h4
            style={{
              fontWeight: "bold",
              fontSize: 20,
              color: colors.accent,
              textAlign: "center",
            }}
          >
            DISCOVERY TERMS
          </h4>

          {renderDiscoveries()}
        </div>

        <div
          style={{
            color: "white",
            backgroundColor: "transparent",
            padding: 6,
            borderWidth: 0,
            boxShadow: "none",
          }}
          onClick={() => {
            setShowCreateModal(true);
          }}
        >
          <p
            style={{
              color: colors.lightBlue,
              textAlign: "center",
              opacity: 0.6,
              textDecorationColor: colors.lightBlue,
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              cursor: "pointer",
            }}
          >
            add discovery
          </p>
        </div>
      </div>

      <CreateDiscoveryModal
        stoplightId={stoplightId}
        sources={sources}
        onHide={() => setShowCreateModal(false)}
        show={showCreateModal}
      />
      <EditDiscoveryModal
        stoplightId={stoplightId}
        sources={sources}
        onHide={() => setEditingDiscovery(null)}
        show={editingDiscovery != null}
        discovery={editingDiscovery}
      />
      <AreYouSureModal
        show={deletingDiscovery != null}
        onConfirm={finishDeleteDiscovery}
        onHide={() => setDeletingDiscovery(null)}
        title={"Delete Discovery"}
        body={`Are you sure you want to delete the discovery term "${
          deletingDiscovery ? deletingDiscovery.term : ""
        }"?`}
      />
    </div>
  );
}

export default RightFilters;
