import React, { useState } from "react";
import colors from "constants/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function ImagesList({ posReport, large }) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  if (large) {
    return (
      <div style={{ marginLeft: 10 }}>
        <table style={{ width: "100%", marginBottom: 10 }}>
          <th
            style={{
              width: "50%",
              border: "1px solid #467db3",
              borderCollapse: "collapse",
              backgroundColor: colors.lightBlue,
              color: colors.white,
              textAlign: "center",
            }}
          >
            <td>Images</td>
          </th>
          {(posReport.photos || []).length === 0 ? (
            <tr>
              <td>{"N/A"}</td>
            </tr>
          ) : (
            <tr />
          )}
        </table>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
            {(posReport.photos || []).map((item, index) => (
              <div
                style={{ marginRight: 5 }}
                onClick={() => {
                  setIndex(index);
                  setOpen(true);
                }}
              >
                <img
                  src={item}
                  style={{
                    height: 300,

                    marginTop: 12,

                    backgroundColor: colors.accent,
                    border: "none",
                    marginRight: 0,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginLeft: 10 }}>
      <table style={{ width: "100%", marginBottom: 10 }}>
        <th
          style={{
            width: "50%",
            border: "1px solid #467db3",
            borderCollapse: "collapse",
            backgroundColor: colors.lightBlue,
            color: colors.white,
            textAlign: "center",
          }}
        >
          <td>Images</td>
        </th>
        {(posReport.photos || []).length === 0 ? (
          <tr>
            <td>{"N/A"}</td>
          </tr>
        ) : (
          <tr />
        )}
      </table>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
        <div style={{ flexDirection: "row", display: "flex" }}>
          {(posReport.photos || []).map((item, index) => (
            <div
              style={{ marginRight: 5 }}
              onClick={() => {
                setIndex(index);
                setOpen(true);
              }}
            >
              <img
                src={item}
                className="custom-file-upload"
                style={{
                  backgroundColor: colors.accent,
                  border: "none",
                  marginRight: 0,
                }}
              />
            </div>
          ))}
        </div>
        {open && (
          <Lightbox
            imagePadding={100}
            mainSrc={posReport.photos[index]}
            nextSrc={posReport.photos[(index + 1) % posReport.photos.length]}
            prevSrc={
              posReport.photos[
                (index + posReport.photos.length - 1) % posReport.photos.length
              ]
            }
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() =>
              setIndex(
                (index + posReport.photos.length - 1) % posReport.photos.length
              )
            }
            onMoveNextRequest={() =>
              setIndex((index + 1) % posReport.photos.length)
            }
          />
        )}
      </div>
    </div>
  );
}

export default ImagesList;
