import colors from "../constants/colors";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CTAButton = ({
  onClick,
  text,
  styles,
  fontStyles,
}: {
  onClick: any;
  text: string;
  styles: any;
  fontStyles?: any;
}) => {
  return (
    <Button
      style={{
        justifySelf: "center",
        alignSelf: "center",
        backgroundColor: colors.lightBlue,
        paddingRight: 50,
        paddingTop: 0,
        paddingLeft: 50,
        paddingBottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...styles,
      }}
      onClick={onClick}
    >
      <p style={{ color: colors.white, fontSize: 20, ...fontStyles }}>{text}</p>
    </Button>
  );
};

export default CTAButton;
