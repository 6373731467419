import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import { MonitorDocument } from "models/MonitorDocument";
import { Player } from "models/Player";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsStar, BsStarFill } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import Linkify from "react-linkify";
import { useFirestore } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import UnderlineButton from "shared/underline-button";
import { AiFillGoogleCircle } from "react-icons/ai";

function DocumentItem({
  doc,
  prospects,
  sources,
}: {
  doc: MonitorDocument;
  prospects: Player[];
  sources: {
    active: boolean;

    guid: string;
    name: string;
  }[];
}) {
  const history = useHistory();
  const [saved, setSaved] = useState(false);
  const me = useMe();
  const firestore = useFirestore();

  useEffect(() => {
    if (
      (prospects || []).filter(
        (item) =>
          item && item.name && item.external_id == doc._source.author_identifier
      ).length > 0
    ) {
      setSaved(true);
    }
  }, [prospects]);

  const saveAsProspect = async () => {
    if (!saved) {
      setSaved(true);
      const docRefConfig = {
        collection: "users",
        doc: me.id,
        subcollections: [
          { collection: "prospects", doc: doc._source.author_identifier },
        ],
      };

      await firestore.set(docRefConfig, {
        from_monitor: true,
        manual: true,
        name: `@${doc._source.author_name}`,
        external_id: doc._source.author_identifier,
        image_url:
          doc._source.images.length > 0
            ? doc._source.images[doc._source.images.length - 1]
            : null,
        twitter_handle: `${doc._source.author_twitter_handle}`,

        college: false,

        state: doc._source.author_region,

        active: true,
        id: doc._source.author_identifier,
      });

      toast.dark(`${doc._source.author_name} saved to prospects!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      setSaved(false);
      const docRefConfig = {
        collection: "users",
        doc: me.id,
        subcollections: [
          { collection: "prospects", doc: doc._source.author_identifier },
        ],
      };

      await firestore.delete(docRefConfig);

      toast.dark(`${doc._source.author_name} removed from prospects!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const renderHeader = () => {
    if (doc._source.pai_source_name == "Google" && doc._source.title) {
      return (
        <Link to={`/post-detail/${doc._id}`} style={{ textDecoration: "none" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 12,
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "bold", marginRight: 12 }}>
              {doc._source.title}
            </p>

            <p style={{ whiteSpace: "nowrap" }}>
              {moment(doc._source.created_on).format("MM/DD hh:mm a")}
            </p>
          </div>
        </Link>
      );
    }

    return (
      <Link to={`/post-detail/${doc._id}`} style={{ textDecoration: "none" }}>
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}
        >
          <p style={{ fontWeight: "bold", marginRight: 12 }}>
            {doc._source.author_name}
          </p>

          <p style={{ marginRight: 12 }}>{doc._source.author_twitter_handle}</p>

          <p>{moment(doc._source.created_on).format("MM/DD hh:mm a")}</p>
        </div>
      </Link>
    );
  };

  const hasOverflow = () => {
    return doc._source.content.length > 400;
  };

  return (
    <div
      style={{
        flex: 1,
        border: "1px solid",
        borderColor: colors.accent,
        borderRadius: 8,
        backgroundColor: colors.lightGray,
        margin: 8,
        padding: 8,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {(doc._source.images || []).length > 0 && (
        <Link to={`/post-detail/${doc._id}`} style={{ textDecoration: "none" }}>
          {doc._source.pai_source_name == "Google" && doc._source.title ? (
            <img
              src={doc._source.images[0]}
              style={{
                width: 160,
                objectFit: "contain",
                borderRadius: 8,
              }}
            />
          ) : (
            <div
              style={{
                width: 160,
                height: 160,
                backgroundColor: colors.lightBlue,
                borderRadius: 8,
              }}
            >
              <img
                src={doc._source.images[0]}
                style={{
                  width: 160,
                  height: 160,
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
            </div>
          )}
        </Link>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 12,
          marginRight: 8,
          flex: 1,
        }}
      >
        {renderHeader()}
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <p>
            {hasOverflow()
              ? `${doc._source.content.slice(0, 400)}...`
              : doc._source.content}
          </p>
        </Linkify>

        {hasOverflow() && (
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: -25,
              marginBottom: 4,
            }}
          >
            <UnderlineButton
              text={"Read More"}
              color={colors.accent}
              onClick={() => history.push(`/post-detail/${doc._id}`)}
              style={{ fontWeight: "bold" }}
            />
          </div>
        )}

        <div style={{ flex: 1 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Link to={`/post-detail/${doc._id}`} style={{ marginRight: 6 }}>
            <GrDocumentText style={{ fontSize: 24, color: colors.accent }} />
          </Link>

          <a
            href={doc._source.url}
            target="_blank"
            style={{ textDecoration: "none", marginRight: 6 }}
          >
            {doc._source.pai_source_name == "Google" && doc._source.title ? (
              <AiFillGoogleCircle
                style={{ fontSize: 24, color: colors.lightBlue }}
              />
            ) : (
              <AiFillTwitterCircle
                style={{ fontSize: 24, color: colors.lightBlue }}
              />
            )}
          </a>

          {!(doc._source.pai_source_name == "Google" && doc._source.title) && (
            <div
              onClick={() => {
                saveAsProspect();
              }}
            >
              {saved ? (
                <BsStarFill color={"gold"} size={24} />
              ) : (
                <BsStar color={"gold"} size={24} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DocumentItem;
