import { useMe } from "@tara-wilson/mcc-lib";
import colors, { TAG_COLORS } from "constants/colors";
import AreYouSureModal from "modals/are-you-sure-modal";
import EditRosterPlayerModal from "modals/edit-roster-player-modal";
import { Player } from "models/Player";
import React, { FC, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { AiFillMinusCircle } from "react-icons/ai";
import { FaTshirt } from "react-icons/fa";
import { MdDragHandle, MdModeEdit } from "react-icons/md";
import { RiTShirt2Line } from "react-icons/ri";
import { useFirestore } from "react-redux-firebase";
import { PlayerService } from "services/PlayerService";
import { usePlayersMeta, usePlayerMeta } from "hooks/player-meta";

export const ItemTypes = {
  BOX: "box",
  CARD: "card",
};

export interface BoxProps {
  player: Player;
  year: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  hidden: boolean;
}

interface DropResult {
  allowedDropEffect: string;
  dropEffect: string;
  name: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const EditablePlayerItem: FC<BoxProps> = ({
  player,
  year,
  index,
  moveCard,
  hidden,
}) => {
  const [schAmount, setSchAmount] = useState(null);
  const [finAidAmount, setFinAidAmount] = useState(null);
  const [showManualEdit, setShowManualEdit] = useState(false);
  const [confirmRemoveModalShow, setConfirmRemoveModalShow] = useState(false);
  const firestore = useFirestore();
  const me = useMe();

  const playerMeta = usePlayerMeta(player?.id);

  const setPlayerPosition = async (player: Player, position: string) => {
    if (player.position != position || player.primary_position != position) {
      await firestore
        .collection("users")
        .doc(me.id)
        .collection("roster")
        .doc(player.external_id)
        .update({ position: position, primary_position: position });
    }
  };

  const getSchInputNum = () => {
    if (!isNaN(parseFloat(schAmount))) {
      return parseFloat(schAmount);
    }
    return 0;
  };

  const getFinAidInputNum = () => {
    if (!isNaN(parseFloat(finAidAmount))) {
      return parseFloat(finAidAmount);
    }
    return 0;
  };

  const updateScholarship = () => {
    firestore
      .collection("users")
      .doc(me.id)
      .collection("roster")
      .doc(player.id)
      .set(
        {
          scholarshipByYear: { [year]: getSchInputNum() },
          financialAidByYear: { [year]: getFinAidInputNum() },
        },
        { merge: true }
      );
  };

  useEffect(() => {
    resetValues();
  }, []);

  const resetValues = () => {
    if (player.scholarshipByYear?.[year]) {
      setSchAmount(player.scholarshipByYear[year].toFixed(2));
    }
    if (player.financialAidByYear?.[year]) {
      setFinAidAmount(player.financialAidByYear[year].toFixed(2));
    }
  };

  const setRedShirt = (kind: "none" | "redshirt" | "medical" | "covid") => {
    firestore
      .collection("users")
      .doc(me.id)
      .collection("roster")
      .doc(player.id)
      .update({
        redShirt: kind,
        redShirtYears:
          kind == "none"
            ? (player.redShirtYears || []).filter(
                (item) => item != parseInt(year)
              )
            : [...(player.redShirtYears || []), parseInt(year)],
      });
  };

  const renderShirtButton = (
    kind: "none" | "redshirt" | "medical" | "covid",
    title: string,
    styles?: any
  ) => {
    let hasInactive = (player.redShirtYears || []).length > 0;
    let current = player.redShirt ? player.redShirt : "none";
    if (current == "none" && hasInactive) {
      current = "redshirt";
    }
    let selected = current === kind;
    return (
      <div
        style={{
          flex: 1,
          cursor: "pointer",
          height: 42,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: selected ? colors.lightBlue : colors.white,

          ...styles,
        }}
        onClick={() => {
          setRedShirt(kind);
        }}
      >
        <p
          style={{
            fontFamily: "Raleway",
            fontWeight: "bold",
            margin: 0,
            flex: 1,
            textAlign: "center",

            color: selected ? colors.white : colors.accent,
          }}
        >
          {title}
        </p>
      </div>
    );
  };

  const renderRedShirt = () => {
    let hasInactive = (player.redShirtYears || []).length > 0;
    let current =
      player.redShirtYear == parseInt(year) ||
      (player.redShirtYears || []).includes(parseInt(year))
        ? player.redShirt == "none" && hasInactive
          ? "redshirt"
          : player.redShirt
        : "none";

    return (
      <OverlayTrigger
        rootClose
        trigger={["click"]}
        placement={"right"}
        overlay={
          <Popover id={`popover-positioned-right`}>
            <Popover.Content
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "2px solid",
                  borderColor: colors.lightBlue,
                }}
              >
                {renderShirtButton("redshirt", "RED SHIRT")}
                {renderShirtButton("medical", "MEDICAL RED SHIRT", {
                  borderLeft: "1px solid",
                  borderLeftColor: colors.lightBlue,
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "2px solid",
                  borderTopWidth: 0,
                  borderColor: colors.lightBlue,
                }}
              >
                {renderShirtButton("covid", "COVID RED SHIRT", {
                  borderRight: "1px solid",
                  borderRightColor: colors.lightBlue,
                })}
                {renderShirtButton("none", "NONE")}
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: 4,
          }}
        >
          {current === "none" ? (
            <RiTShirt2Line
              color={colors.lightBlue}
              style={{ width: 15, height: 15 }}
            />
          ) : (
            <FaTshirt color={colors.red} style={{ width: 15, height: 15 }} />
          )}
        </div>
      </OverlayTrigger>
    );
  };

  const renderScholInput = () => {
    return (
      <OverlayTrigger
        rootClose
        trigger={["click"]}
        placement={"right"}
        onToggle={(val) => {
          if (!val) {
            updateScholarship();
          } else {
            resetValues();
          }
        }}
        overlay={
          <Popover id={`popover-positioned-right`}>
            <Popover.Title
              style={{
                backgroundColor: "#003366",
                color: "#fff",
                padding: 0,
                margin: 0,
                paddingTop: 4,
                paddingBottom: 4,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: 250 }}
              >
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {"Scholarship"}
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {"Financial Aid"}
                  </p>
                </div>
              </div>
            </Popover.Title>
            <Popover.Content
              style={{
                padding: 0,
                margin: 0,
                paddingTop: 4,
                paddingBottom: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{
                      border: "2px solid",
                      borderColor: colors.lightBlue,
                      color: colors.accent,
                      textAlign: "center",
                      width: 115,
                    }}
                    placeholder={"0"}
                    value={schAmount}
                    onChange={(evt) => setSchAmount(evt.target.value)}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{
                      border: "2px solid",
                      borderColor: colors.lightBlue,
                      color: colors.accent,
                      textAlign: "center",
                      width: 115,
                    }}
                    placeholder={"0"}
                    value={finAidAmount}
                    onChange={(evt) => setFinAidAmount(evt.target.value)}
                  />
                </div>
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <div
          style={{
            width: 45,
            borderLeftStyle: "solid",
            borderLeftWidth: 1,
            borderLeftColor: colors.lightBlue,

            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: colors.accent,
              fontWeight: "bold",
            }}
          >
            {PlayerService.redShirtYears(player).includes(parseInt(year))
              ? "--"
              : PlayerService.getScholarship(player, year, "both").toFixed(2)}
          </p>
        </div>
      </OverlayTrigger>
    );
  };

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { ...player, id: player.external_id, index: index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item, monitor) {
      const dropResult = monitor.getDropResult() as DropResult;
      if (item && dropResult) {
        const isDropAllowed =
          dropResult.allowedDropEffect === "any" ||
          dropResult.allowedDropEffect === dropResult.dropEffect;

        if (isDropAllowed) {
          setPlayerPosition(item, dropResult.name);
        }
      }
    },
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "transparent",
        marginBottom: 4,
        opacity: opacity,
      }}
      data-handler-id={handlerId}
      ref={ref}
      // ref={drag}
    >
      <div
        onClick={() => setConfirmRemoveModalShow(true)}
        style={{ cursor: "pointer" }}
      >
        <AiFillMinusCircle
          color={colors.accent}
          style={{ width: 22, height: 22 }}
        />
      </div>
      <div
        style={{
          flex: 1,
          borderTopLeftRadius: 2,
          borderBottomLeftRadius: 2,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: colors.lightBlue,
          backgroundColor: TAG_COLORS[playerMeta?.tagColor] || colors.white,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              backgroundColor: colors.accent,
              width: 40,
              borderBottomStyle: "solid",
              borderBottomWidth: 1,
              borderBottomColor: colors.lightBlue,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: colors.white,
                width: 40,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {`#${player.jerseyNumber ? player.jerseyNumber : ""}`}
            </p>
          </div>
          <p
            style={{
              color:
                player.bats == "S"
                  ? colors.lightBlue
                  : player.bats == "L"
                  ? colors.red
                  : "black",
              paddingTop: 1,
              paddingBottom: 1,
              marginRight: 5,
              paddingLeft: 5,
            }}
          >
            {PlayerService.getName(player)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{ cursor: "pointer", marginRight: 6 }}
            onClick={() => setShowManualEdit(true)}
          >
            <MdModeEdit
              color={colors.lightBlue}
              style={{ width: 15, height: 15, opacity: 0.8 }}
            />
          </div>
          {renderRedShirt()}
          {renderScholInput()}
        </div>
        <AreYouSureModal
          //@ts-ignore
          show={confirmRemoveModalShow}
          onConfirm={async () => {
            firestore
              .collection("users")
              .doc(me.id)
              .collection("roster")
              .doc(player.external_id)
              .delete();
            setConfirmRemoveModalShow(false);
          }}
          onHide={() => setConfirmRemoveModalShow(false)}
          title={"Remove Player"}
          body={`Are you sure you want to remove ${PlayerService.getName(
            player
          )} from the roster?`}
        />
      </div>

      <div
        style={{
          backgroundColor: colors.lightBlue,
          borderTopRightRadius: 2,
          borderBottomRightRadius: 2,
          // borderRightStyle: "solid",
          // borderRightWidth: 1,
          // borderColor: colors.lightBlue,
        }}
      >
        <MdDragHandle
          style={{
            color: colors.white,
            alignSelf: "flex-end",
            justifySelf: "flex-end",
          }}
        />
      </div>

      {showManualEdit && (
        <EditRosterPlayerModal
          show={showManualEdit}
          onHide={(pl) => {
            setShowManualEdit(false);
          }}
          player={player}
        />
      )}
    </div>
  );
};

export default EditablePlayerItem;
