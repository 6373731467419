import colors, { TAG_COLORS } from "constants/colors";
import React, { useEffect, useState, useRef } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TeamDropdown from "dropdowns/team-dropdown";
import { Player } from "models/Player";
import SmallButton from "pages/search-page/components/small-button";
import CollegeCommitmentDropdown from "dropdowns/college-commitment-dropdown";
import YearDropdown from "dropdowns/year-dropdown";
import { STATES, STATE_MAP } from "constants/data";
import { Box, Row } from "components/ui";
import { ColorTagSelector } from "components/color-tags";
import { usePlayerMeta } from "hooks/player-meta";

function EditPlayerModal(props: {
  firestore: any;
  onHide: any;
  editPlayer: any;
  show: any;
  player: Player;
}) {
  const userId = useSelector((state) => state.firebase.auth.uid);

  const [position, setPosition] = useState("");
  const [altPosition, setAltPosition] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [graduatingClass, setGraduatingClass] = useState("2021");
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [weight, setWeight] = useState("");
  const [state, setState] = useState("");
  const [summerTeam, setSummerTeam] = useState("");
  const [commitment, setCommitment] = useState("");
  const [bats, setBats] = useState("");
  const [throws, setThrows] = useState("");
  const [selectedTag, setSelectedTag] = useState<(keyof(typeof TAG_COLORS))|null>(null);

  const playerMeta = usePlayerMeta(props.player?.id);
  const playerMetaLoadedRef = useRef(false);
  if (!playerMetaLoadedRef.current && playerMeta?.tagColor && !selectedTag) {
    setSelectedTag(playerMeta?.tagColor);
    playerMetaLoadedRef.current = true;
  }

  useEffect(() => {
    setPosition(props.player.primary_position);
    if (props.player.alt_position) {
      setAltPosition(props.player.alt_position);
    }
    setSummerTeam(props.player.summer_team);
    setFirstName(props.player.first_name);
    setLastName(props.player.last_name);

    if (props.player.graduating_class) {
      setGraduatingClass(`${props.player.graduating_class}`);
    }
    if (props.player.state) {
      setState(props.player.state);
    }
    if (props.player.calculated_height && props.player.calculated_height > 0) {
      let parsedFeet = Math.floor(props.player.calculated_height / 12);
      setFeet(`${parsedFeet}`);
      let parsedInches = Math.floor(props.player.calculated_height % 12);
      setInches(`${parsedInches}`);
    }

    if (props.player.weight) {
      setWeight(`${props.player.weight}`);
    }

    if (props.player.summer_team) {
      setSummerTeam(props.player.summer_team);
    }
    if (props.player.college_commitment) {
      setCommitment(props.player.college_commitment);
    }
    if (props.player.bats) {
      setBats(props.player.bats);
    }
    if (props.player.throws) {
      setThrows(props.player.throws);
    }
  }, []);

  const save = () => {
    let parsedFeet = isNaN(parseInt(feet)) ? 0 : parseInt(feet);
    let parsedInches = isNaN(parseInt(inches)) ? 0 : parseInt(inches);
    let calculatedHeight = parsedFeet * 12 + parsedInches;

    const docRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", doc: `${props.player.external_id}` },
      ],
    };

    let upd = {
      primary_position: position,
      summer_team: summerTeam,
      first_name: firstName,
      last_name: lastName,
      graduating_class: graduatingClass,
      height: parsedFeet > 0 ? `${parsedFeet}-${parsedInches}` : null,
      calculated_height: calculatedHeight,
      weight: weight ? parseInt(weight) : null,
      state:
        state && Object.keys(STATE_MAP).includes(state)
          ? STATE_MAP[state]
          : state || null,
      college_commitment: commitment,
      college: false,
      bats: bats,
      throws: throws,
    };

    if (altPosition) {
      //@ts-ignore
      upd = { ...upd, alt_position: altPosition };
    }

    props.firestore.update(docRefConfig, upd);

    toast.dark(`Player updated!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

    props.editPlayer({
      ...props.player,
      ...upd,
    });

    /* Tag Color */
    if (selectedTag !== playerMeta?.tagColor) {
      const metaRef = {
        collection: "users",
        doc: userId,
        subcollections: [
          { collection: "playerMeta", doc: `${props.player.external_id}` },
        ],
      };

      props.firestore.set(metaRef, { tagColor: selectedTag }, { merge: true });
    }
    
    props.onHide();
  };

  const renderPositions = (pos: string, updatePos: any) => {
    return (
      <div>
        <div className="position-row" style={{ marginLeft: 0 }}>
          <SmallButton
            title="RHP"
            selected={pos === "RHP"}
            onClick={() => updatePos("RHP")}
          />
          <SmallButton
            title="LHP"
            selected={pos === "LHP"}
            onClick={() => updatePos("LHP")}
          />
          <SmallButton
            title="1B"
            selected={pos === "1B"}
            onClick={() => updatePos("1B")}
          />
          <SmallButton
            title="2B"
            selected={pos === "2B"}
            onClick={() => updatePos("2B")}
          />
          <SmallButton
            title="SS"
            selected={pos === "SS"}
            onClick={() => updatePos("SS")}
          />
          <SmallButton
            title="3B"
            selected={pos === "3B"}
            onClick={() => updatePos("3B")}
          />
        </div>
        <div className="position-row" style={{ marginLeft: 0 }}>
          <SmallButton
            title="C"
            selected={pos === "C"}
            onClick={() => updatePos("C")}
          />
          <SmallButton
            title="LF"
            selected={pos === "LF"}
            onClick={() => updatePos("LF")}
          />
          <SmallButton
            title="CF"
            selected={pos === "CF"}
            onClick={() => updatePos("CF")}
          />
          <SmallButton
            title="RF"
            selected={pos === "RF"}
            onClick={() => updatePos("RF")}
          />
          <SmallButton
            title="MIF"
            selected={pos === "MIF"}
            onClick={() => updatePos("MIF")}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
          <Row full justify="space-between">
            Edit Player
            <ColorTagSelector
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
            />
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        {props.player && props.player.manual && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input
                type="text"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                  border: "0px solid",
                  color: "#fff",
                  width: 400,
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                }}
                value={firstName}
                placeholder="First Name"
                onChange={(evt) => setFirstName(evt.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              <input
                type="text"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                  border: "0px solid",
                  color: "#fff",
                  width: 400,
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                }}
                value={lastName}
                placeholder="Last Name"
                onChange={(evt) => setLastName(evt.target.value)}
              />
            </div>
          </div>
        )}
        <p style={{ textAlign: "left" }}>Primary Position:</p>
        {renderPositions(position, setPosition)}
        <p style={{ textAlign: "left", marginTop: 20 }}>Alternate Position:</p>
        {renderPositions(altPosition, setAltPosition)}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: colors.accent,
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>Class:</p>
            <YearDropdown
              customYears={[
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
              ]}
              selectYear={(yr) => {
                setGraduatingClass(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={graduatingClass || "Class"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>State:</p>
            <YearDropdown
              customYears={STATES}
              selectYear={(yr) => {
                setState(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={state || "State"}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Height:</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                type="text"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                  border: "0px solid",
                  color: "#fff",
                  width: 190 / 2 - 5,
                  marginRight: 10,
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                }}
                value={feet}
                placeholder="Feet"
                onChange={(evt) => setFeet(evt.target.value)}
              />
              <input
                type="text"
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                  border: "0px solid",
                  color: "#fff",
                  width: 190 / 2 - 5,
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                }}
                value={inches}
                placeholder="Inches"
                onChange={(evt) => setInches(evt.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Weight:</p>
            <input
              type="text"
              style={{
                marginBottom: 10,
                display: "flex",
                flex: 1,
                border: "0px solid",
                color: "#fff",
                width: 190,
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              value={weight}
              placeholder="0"
              onChange={(evt) => setWeight(evt.target.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Bats:</p>
            <input
              type="text"
              style={{
                marginBottom: 10,
                display: "flex",
                flex: 1,
                border: "0px solid",
                color: "#fff",
                width: 190,
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              value={bats}
              placeholder="0"
              onChange={(evt) => setBats(evt.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 12,
            }}
          >
            <p style={{ textAlign: "left" }}>Throws:</p>
            <input
              type="text"
              style={{
                marginBottom: 10,
                display: "flex",
                flex: 1,
                border: "0px solid",
                color: "#fff",
                width: 190,
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              value={throws}
              placeholder="0"
              onChange={(evt) => setThrows(evt.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            maxWidth: 400,
          }}
        >
          <TeamDropdown
            value={summerTeam}
            transparentInput={true}
            placeholder={"Summer Team"}
            selectTeam={(st) => {
              setSummerTeam(st);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            maxWidth: 400,
          }}
        >
          <CollegeCommitmentDropdown
            placeholder={"Commitment"}
            selectCollege={(val) => setCommitment(val)}
            value={commitment}
            transparentInput={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(EditPlayerModal);
