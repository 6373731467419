import React from "react";
import { PlayerService } from "services/PlayerService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Player } from "models/Player";
import colors from "constants/colors";
import { useHistory } from "react-router-dom";
import { CollegePlayer } from "models/CollegePlayer";
import { SAFE_toFixed } from "services/utils";

function PlayerName(props: {
  player: Player;
  skipPosition?: boolean;
  disabledMode: "pbr_disabled" | "pg_disabled" | "both" | "none";
}) {
  const history = useHistory();

  const renderStatRow = (name, val) => {
    return (
      <p className="stats-text-small" style={{ opacity: val ? 1 : 0.3 }}>
        {name}:<p className="stats-text-bold">{val ? val : "N/A"}</p>
      </p>
    );
  };

  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val) => {
    let stringVal = val;
    if (val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const renderPitchingStats = (player: CollegePlayer) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("W")}
          {th("L")}
          {th("ERA")}

          {th("GS")}
          {th("GC")}
          {th("SHO")}

          {th("SV")}

          {th("IP")}
          {th("H")}
          {th("R")}
          {th("ER")}
          {th("HR")}

          {th("HB")}
          {th("BB")}

          {th("SO")}
          {th("AVG")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(player.W)}
          {td(player.L)}
          {td(SAFE_toFixed(player.ERA, 2))}

          {td(player.GS)}
          {td(player.CG)}
          {td(player.SHO)}

          {td(player.SV)}

          {td(player.IP)}
          {td(player.H)}
          {td(player.R)}
          {td(player.ER)}
          {td(player.HR)}

          {td(player.HP)}
          {td(player.BB)}

          {td(player.SO)}

          {td(player.AVG)}
        </tr>
      </table>
    );
  };

  const renderBattingStats = (player: CollegePlayer) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("G")}
          {th("AB")}
          {th("R")}
          {th("H")}

          {th("2B")}
          {th("3B")}
          {th("HR")}

          {th("RBI")}

          {th("BB")}

          {th("SO")}
          {th("SB")}
          {th("CS")}

          {th("AVG")}
          {th("OBP")}
          {th("SLG")}
          {th("OPS")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(player.GP)}
          {td(player.AB)}
          {td(player.R)}
          {td(player.H)}

          {td(SAFE_toFixed(`${player["2B"]}`, 0))}
          {td(SAFE_toFixed(`${player["3B"]}`, 0))}

          {td(player.HR)}

          {td(player.RBI)}

          {td(player.BB)}

          {td(player.SO)}
          {td(player.SB)}
          {td(player.CS)}

          {td(player.AVG)}
          {td(player.OBP)}
          {td(player.SLG)}
          {td(player.OPS)}
        </tr>
      </table>
    );
  };

  const renderCollegeStats = () => {
    //@ts-ignore
    let player = props.player as CollegePlayer;
    return player.position === "Pitcher"
      ? renderPitchingStats(player)
      : renderBattingStats(player);
  };

  const renderPlayerStats = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: 8 }}>
        {renderStatRow(
          "60 Time",
          props.player ? props.player.sixty_yard : null
        )}

        {renderStatRow(
          "10 Yard Split",
          props.player ? props.player.ten_yard : null
        )}

        {renderStatRow(
          "Position Velocity (IF)",
          props.player ? props.player.infvelo : null
        )}

        {renderStatRow(
          "Position Velocity (OF)",
          props.player ? props.player.ofvelo : null
        )}

        {renderStatRow(
          "Position Velocity",
          props.player ? props.player.cvelo : null
        )}

        {renderStatRow(
          "Exit Velocity",
          props.player ? props.player.exitvelo : null
        )}
        {renderStatRow(
          "Pop Time",

          props.player && props.player.poptime && props.player.poptime != 0
            ? SAFE_toFixed(props.player.poptime, 2)
            : null
        )}

        {renderStatRow(
          "Fastball",
          props.player && props.player.fbRangeMin
            ? props.player.fbRangeMin === props.player.fbRangeMax ||
              props.player.fbRangeMax == null
              ? props.player.fbRangeMin
              : `${props.player.fbRangeMin}-${props.player.fbRangeMax}`
            : null
        )}

        {renderStatRow(
          "Curveball",
          props.player && props.player.curveRangeMin
            ? props.player.curveRangeMin === props.player.curveRangeMax ||
              props.player.curveRangeMax == null
              ? props.player.curveRangeMin
              : `${props.player.curveRangeMin}-${props.player.curveRangeMax}`
            : null
        )}

        {renderStatRow(
          "Changeup",
          props.player && props.player.chRangeMin
            ? props.player.chRangeMin === props.player.chRangeMax ||
              props.player.chRangeMax == null
              ? props.player.chRangeMin
              : `${props.player.chRangeMin}-${props.player.chRangeMax}`
            : null
        )}

        {renderStatRow(
          "Slider",
          props.player && props.player.slideRangeMin
            ? props.player.slideRangeMin === props.player.slideRangeMax ||
              props.player.slideRangeMax == null
              ? props.player.slideRangeMin
              : `${props.player.slideRangeMin}-${props.player.slideRangeMax}`
            : null
        )}

        {renderStatRow(
          "Knuckleball",
          props.player && props.player.knRangeMin
            ? props.player.knRangeMin === props.player.knRangeMax ||
              props.player.knRangeMax == null
              ? props.player.knRangeMin
              : `${props.player.knRangeMin}-${props.player.knRangeMax}`
            : null
        )}

        {/* tara here today */}

        {props.player &&
          props.player.RBI &&
          props.player.RBI > 0 &&
          renderStatRow("RBI", props.player.RBI)}
        {props.player &&
          props.player.BB &&
          props.player.BB > 0 &&
          renderStatRow("BB", props.player.BB)}
        {props.player &&
          props.player.HR &&
          props.player.HR > 0 &&
          renderStatRow("HR", props.player.HR)}

        {props.player &&
          props.player.HBP &&
          props.player.HBP > 0 &&
          renderStatRow("HBP", props.player.HBP)}

        {props.player &&
          props.player.PA &&
          props.player.PA > 0 &&
          renderStatRow("PA", props.player.PA)}
        {props.player &&
          props.player.AB &&
          props.player.AB > 0 &&
          renderStatRow("AB", props.player.AB)}
        {props.player &&
          props.player.GS &&
          props.player.GS > 0 &&
          renderStatRow("GS", props.player.GS)}
        {props.player &&
          props.player.GP &&
          props.player.GP > 0 &&
          renderStatRow("GP", props.player.GP)}
        {props.player &&
          props.player.H &&
          props.player.H > 0 &&
          renderStatRow("H", props.player.H)}
        {props.player &&
          props.player.K &&
          props.player.K > 0 &&
          renderStatRow("K", props.player.K)}
        {props.player &&
          props.player.R &&
          props.player.R > 0 &&
          renderStatRow("R", props.player.R)}
        {props.player &&
          props.player.SLG &&
          props.player.SLG > 0 &&
          renderStatRow("SLG", props.player.SLG)}
        {props.player &&
          props.player.OBP &&
          props.player.OBP > 0 &&
          renderStatRow("OBP", props.player.OBP)}
        {props.player &&
          props.player.OPS &&
          props.player.OPS > 0 &&
          renderStatRow("OPS", props.player.OPS)}
        {props.player &&
          props.player.AVG &&
          props.player.AVG > 0 &&
          renderStatRow("AVG", props.player.AVG)}

        {props.player &&
          props.player["2B"] &&
          props.player["2B"] > 0 &&
          renderStatRow("2B", props.player["2B"])}
        {props.player &&
          props.player["3B"] &&
          props.player["3B"] > 0 &&
          renderStatRow("3B", props.player["3B"])}

        {props.disabledMode == "both" ? (
          <div />
        ) : props.disabledMode == "pbr_disabled" ? (
          renderStatRow(
            "National Overall Ranking",
            props.player && props.player.pg_national_overall_ranking
              ? props.player.pg_national_overall_ranking
              : null
          )
        ) : props.disabledMode == "pg_disabled" ? (
          renderStatRow(
            "National Overall Ranking",
            props.player && props.player.pbr_national_overall_ranking
              ? props.player.pbr_national_overall_ranking
              : null
          )
        ) : (
          renderStatRow(
            "National Overall Ranking",
            props.player && props.player.national_overall_ranking
              ? props.player.national_overall_ranking
              : null
          )
        )}

        {props.disabledMode == "both" ? (
          <div />
        ) : props.disabledMode == "pbr_disabled" ? (
          renderStatRow(
            "National Position Ranking",
            props.player && props.player.pg_national_position_ranking
              ? props.player.pg_national_position_ranking
              : null
          )
        ) : props.disabledMode == "pg_disabled" ? (
          renderStatRow(
            "National Position Ranking",
            props.player && props.player.pbr_national_position_ranking
              ? props.player.pbr_national_position_ranking
              : null
          )
        ) : (
          renderStatRow(
            "National Position Ranking",
            props.player && props.player.national_position_ranking
              ? props.player.national_position_ranking
              : null
          )
        )}

        {props.disabledMode == "both" ? (
          <div />
        ) : props.disabledMode == "pbr_disabled" ? (
          renderStatRow(
            "State Overall Ranking",
            props.player && props.player.pg_state_overall_ranking
              ? props.player.pg_state_overall_ranking
              : null
          )
        ) : props.disabledMode == "pg_disabled" ? (
          renderStatRow(
            "State Overall Ranking",
            props.player && props.player.pbr_state_overall_ranking
              ? props.player.pbr_state_overall_ranking
              : null
          )
        ) : (
          renderStatRow(
            "State Overall Ranking",
            props.player && props.player.state_overall_ranking
              ? props.player.state_overall_ranking
              : null
          )
        )}

        {props.disabledMode == "both" ? (
          <div />
        ) : props.disabledMode == "pbr_disabled" ? (
          renderStatRow(
            "State Position Ranking",
            props.player && props.player.pg_state_position_ranking
              ? props.player.pg_state_position_ranking
              : null
          )
        ) : props.disabledMode == "pg_disabled" ? (
          renderStatRow(
            "State Position Ranking",
            props.player && props.player.pbr_state_position_ranking
              ? props.player.pbr_state_position_ranking
              : null
          )
        ) : (
          renderStatRow(
            "State Position Ranking",
            props.player && props.player.state_position_ranking
              ? props.player.state_position_ranking
              : null
          )
        )}
      </div>
    );
  };

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={"right"}
      overlay={
        <Popover id={`popover-positioned-right`}>
          {props.player.college ? (
            <div />
          ) : (
            <Popover.Title
              as="h3"
              style={{ backgroundColor: "#003366", color: "#fff" }}
            >
              {PlayerService.getName(props.player)}
            </Popover.Title>
          )}

          <Popover.Content style={{ margin: 0, padding: 0 }}>
            {props.player.college ? renderCollegeStats() : renderPlayerStats()}
          </Popover.Content>
        </Popover>
      }
    >
      <Link
        to={`/${props.player.college ? "collegeplayer" : "player"}/${
          props.player.external_id
        }`}
        className="row-p"
        style={{
          color: colors.accent,
          textDecoration: "underline",
          textDecorationColor: colors.accent,
          textDecorationStyle: "solid",
          whiteSpace: "nowrap",
        }}
      >
        {PlayerService.getName(props.player)}
        <span style={{ fontWeight: 700 }}>
          {props.skipPosition
            ? ""
            : ` (${props.player.primary_position}${
                props.player.alt_position
                  ? `, ${props.player.alt_position}`
                  : ""
              })`}
        </span>
      </Link>
    </OverlayTrigger>
  );
}

export default PlayerName;
