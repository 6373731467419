/*****************************************************************************
 * Import
 *****************************************************************************/
import colors from "constants/colors";
import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";

/*****************************************************************************
 * Default Function
 *****************************************************************************/

export default function NotVerifiedHome(props: any) {
  return (
    <div
      className="container"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "80vh",
      }}
    >
      <p>Your account has not been verified. Please hold tight, we're on it!</p>
    </div>
  );
}
