import { useMe } from "@tara-wilson/mcc-lib";
import colors, { TAG_COLORS } from "constants/colors";
import YearDropdown from "dropdowns/year-dropdown";
import { Player } from "models/Player";
import { User } from "models/User";
import SmallButton from "pages/search-page/components/small-button";
import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import { toast } from "react-toastify";
import { PlayerService } from "services/PlayerService";
import Switch from "react-switch";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { Box, Row } from "components/ui";
import { ColorTagSelector } from "components/color-tags";
import { usePlayerMeta } from "hooks/player-meta";

export default function EditRosterPlayerModal(props: {
  onHide: any;
  show: any;
  player: Player;
}) {
  const me = useMe<User>();
  const firestore = useFirestore();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [startYear, setStartYear] = useState("2022");
  const [endYear, setEndYear] = useState("2026");
  const [jerseyNumber, setJerseyNumber] = useState("");
  const [preserveSch, setPreserveSch] = useState(props.player?.preserveSch || false);
  const [preserveFinAid, setPreserveFinAid] = useState(props.player?.preserveFinAid || false);
  const [years, setYears] = useState([
    { year: "2022", active: true, schAmount: "", finAidAmount: "", },
    { year: "2023", active: true, schAmount: "", finAidAmount: "", },
    { year: "2024", active: true, schAmount: "", finAidAmount: "", },
    { year: "2025", active: true, schAmount: "", finAidAmount: "", },
  ]);
  const [selectedTag, setSelectedTag] = useState<(keyof(typeof TAG_COLORS))|null>(null);

  const playerMeta = usePlayerMeta(props.player?.id);
  const playerMetaLoadedRef = useRef(false);
  if (!playerMetaLoadedRef.current && playerMeta?.tagColor && !selectedTag) {
    setSelectedTag(playerMeta?.tagColor);
    playerMetaLoadedRef.current = true;
  }

  useEffect(() => {
    let player = {
      ...props.player,
      redShirtYears: PlayerService.redShirtYears(props.player),
    };
    if (player && player.first_name) {
      setFirstName(player.first_name);
    }
    if (player && player.last_name) {
      setLastName(player.last_name);
    }
    if (player && player.jerseyNumber) {
      setJerseyNumber(player.jerseyNumber);
    }
    if (player && player.primary_position) {
      setPosition(player.primary_position);
    }
    if (player && player.position) {
      if (player.position === "Infield") {
        setPosition("IF");
      } else if (player.position === "Outfield") {
        setPosition("OF");
      } else if (player.position === "Catcher") {
        setPosition("C");
      } else if (player.position === "Pitcher") {
        setPosition("RHP");
      } else {
        setPosition(player.position);
      }
    }

    if (player) {
      setStartYear(`${PlayerService.getStartYear(player)}`);
      let lastYear = PlayerService.getEndYear(player);
      setEndYear(lastYear);
      let years = [];
      for (var i = PlayerService.getStartYear(player); i < lastYear + 1; i++) {
        years.push({
          year: `${i}`,
          active: (player.redShirtYears || []).includes(i) ? false : true,
          schAmount: (player.scholarshipByYear || [])[i] || 0,
          finAidAmount: (player.financialAidByYear || [])[i] || 0,
        });
        setYears(years);
      }
    }
  }, []);

  const toggleYearActive = (idx) => {
    const copy = [...years];
    copy[idx].active = !copy[idx].active;
    setYears(copy);
  }

  const updateSchAmount = (idx, value) => {
    const copy = [...years];
    copy[idx].schAmount = value;
    setYears(copy);
  }

  const updatePreservedSchAmount = (value) => {
    setYears(years.map(year => ({ ...year, schAmount: value })));
  }

  const updateFinAidAmount = (idx, value) => {
    const copy = [...years];
    copy[idx].finAidAmount = value;
    setYears(copy);
  }

  const updatePreservedFinAidAmount = (value) => {
    setYears(years.map(year => ({ ...year, finAidAmount: value })));
  }

  const save = async () => {
    let activeYears = years.filter((item) => item.active).length;
    if (activeYears > 4) {
      toast.dark(`Error: too many active years.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      let upd = props.player.college
              ? {
                position: position,
                primary_position: position,
                first_name: firstName,
                last_name: lastName,
                startYear: parseInt(startYear),
                redShirtYears: years
                  .filter((item) => !item.active)
                  .map((item) => parseInt(item.year)),
                endYear: parseInt(endYear),
                jerseyNumber:
              jerseyNumber && jerseyNumber != "" ? jerseyNumber : null,
                preserveSch: preserveSch,
                preserveFinAid: preserveFinAid,
                tagColor: selectedTag,
              }
              : {
                position: position,
                primary_position: position,
                first_name: firstName,
                last_name: lastName,
                startYear: parseInt(startYear),
                redShirtYears: years
                  .filter((item) => !item.active)
                  .map((item) => parseInt(item.year)),
                scholarshipByYear: years
                  .reduce((accum, item) => {
                    if (parseFloat(item.schAmount)) {
                      accum[item.year] = parseFloat(item.schAmount);
                    }
                    return accum;
                  }, {}),
                financialAidByYear: years
                  .reduce((accum, item) => {
                    if (parseFloat(item.finAidAmount)) {
                      accum[item.year] = parseFloat(item.finAidAmount);
                    }
                    return accum;
                  }, {}),
                endYear: parseInt(endYear),
                graduating_class: startYear,
                jerseyNumber:
              jerseyNumber && jerseyNumber != "" ? jerseyNumber : null,
                preserveSch: preserveSch,
                preserveFinAid: preserveFinAid,
                tagColor: selectedTag,
              };

      await firestore
        .collection("users")
        .doc(me.id)
        .collection("roster")
        .doc(props.player.external_id)
        .update(upd);

      toast.dark(`Player updated!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });

      /* Tag Color */
      if (selectedTag !== playerMeta?.tagColor){ 
        const metaRef = {
          collection: "users",
          doc: me?.id || "-1",
          subcollections: [
            { collection: "playerMeta", doc: `${props.player.external_id}` },
          ],
        };
        await firestore.set(metaRef, { tagColor: selectedTag }, { merge: true });
      }

      props.onHide();
    }
  };

  const renderPositions = (pos: string, updatePos: any) => {
    return (
      <div>
        <div className="position-row">
          <SmallButton
            title="RHP"
            selected={pos === "RHP"}
            onClick={() => updatePos("RHP")}
          />
          <SmallButton
            title="LHP"
            selected={pos === "LHP"}
            onClick={() => updatePos("LHP")}
          />
          <SmallButton
            title="1B"
            selected={pos === "1B"}
            onClick={() => updatePos("1B")}
          />
          <SmallButton
            title="2B"
            selected={pos === "2B"}
            onClick={() => updatePos("2B")}
          />
          <SmallButton
            title="SS"
            selected={pos === "SS"}
            onClick={() => updatePos("SS")}
          />
        </div>
        <div className="position-row">
          <SmallButton
            title="3B"
            selected={pos === "3B"}
            onClick={() => updatePos("3B")}
          />
          <SmallButton
            title="C"
            selected={pos === "C"}
            onClick={() => updatePos("C")}
          />
          <SmallButton
            title="LF"
            selected={pos === "LF"}
            onClick={() => updatePos("LF")}
          />
          <SmallButton
            title="CF"
            selected={pos === "CF"}
            onClick={() => updatePos("CF")}
          />
          <SmallButton
            title="RF"
            selected={pos === "RF"}
            onClick={() => updatePos("RF")}
          />
        </div>
      </div>
    );
  };

  const onSetStartYear = (year) => {
    setStartYear(year);
    let firstYear = parseInt(year);
    const newYears = [];
    for (var i = firstYear; i < parseInt(endYear) + 1; i++) {
      const oldYear = years.find(year => year.year === `${i}`) || null;
      
      newYears.push({
        year: `${i}`,
        active: oldYear ? oldYear.active : true,
        schAmount: oldYear?.schAmount || "0",
        finAidAmount: oldYear?.finAidAmount || "0",
      });
    }
    setYears(newYears);
  };

  const onSetEndYear = (year) => {
    setEndYear(year);
    let lastYear = parseInt(year);
    let newYears = [];
    for (var i = parseInt(startYear); i < lastYear + 1; i++) {
      const oldYear = years.find(year => year.year === `${i}`) || null;
      
      newYears.push({
        year: `${i}`,
        active: oldYear ? oldYear.active : true,
        schAmount: oldYear?.schAmount || "0",
        finAidAmount: oldYear?.finAidAmount || "0",
      });
    }
    setYears(newYears);
  };

  const onTogglePreserveSch = () => {
    setPreserveSch(!preserveSch);
    if (years.length) {
      updatePreservedSchAmount(years[0].schAmount);
    }
  }

  const onTogglePreserveFinAid = () => {
    setPreserveFinAid(!preserveFinAid);
    if (years.length) {
      updatePreservedFinAidAmount(years[0].finAidAmount);
    }
  }

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
          <Row full justify="space-between">
            Edit Player
            <ColorTagSelector
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
            />
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          marginTop: 0,
        }}
        className="searching"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={firstName}
            placeholder="First Name"
            onChange={(evt) => setFirstName(evt.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={lastName}
            placeholder="Last Name"
            onChange={(evt) => setLastName(evt.target.value)}
          />
        </div>
        {/* </div> */}

        <p style={{ textAlign: "left" }}>Position:</p>
        {renderPositions(position, setPosition)}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: colors.accent,
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>Starting Year:</p>
            <YearDropdown
              season
              customYears={[
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
              ]}
              selectYear={(yr) => {
                onSetStartYear(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={`${startYear}-${parseInt(startYear) + 1}`}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>Ending Year:</p>
            <YearDropdown
              season
              customYears={[
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
              ]}
              selectYear={(yr) => {
                onSetEndYear(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={`${endYear}-${parseInt(endYear) + 1}`}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 14,
          }}
        >
          <div style={{ display: "flex", marginBottom: 8, alignItems: "center" }}>
            <p style={{
              textAlign: "left",
              color: "#fff",
              marginBottom: 4,
              whiteSpace: "nowrap",
              width: "250px",
            }}>
              Active Years:
            </p>
            <div style={{ display: "flex", width: "180px", paddingLeft: "13px", gap: "35px", justifyContent: "center"}}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                <p style={{ fontSize: "10px", whiteSpace: "nowrap", textAlign: "center" }}>
                  scholarship<br/>same all years
                </p>
                <CheckBox checked={preserveSch} onClick={onTogglePreserveSch} />
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                <p style={{ fontSize: "10px", whiteSpace: "nowrap", textAlign: "center" }}>
                  financial aid<br/>same all years
                </p>
                <CheckBox checked={preserveFinAid} onClick={onTogglePreserveFinAid} />
              </div>
            </div>
          </div>
          {years.map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: 40,
                alignItems: "center"
              }}
            >
              <div style={{ width: 250, display: "flex", justifyContent: "space-between" }}>
                <p style={{ marginRight: 12, fontWeight: "bold", whiteSpace: "nowrap" }}>
                  {item.year}-{parseInt(item.year) + 1}
                </p>
                <div style={{ display: "flex" }}>
                  <Switch
                    checked={item.active}
                    onChange={() => toggleYearActive(index)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor={colors.lightBlue}
                  />
                  <p
                    style={{
                      color: colors.white,
                      width: "64px",
                      marginLeft: 12,
                    }}
                  >
                    {item.active ? "active" : "inactive"}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", width: "180px" }}>
                <div style={{ display: "flex", width: 80 }}>
                  {(!preserveSch || index === 0) && (
                    <React.Fragment>
                      <p style={{ marginRight: "4px" }}>
                        S:
                      </p>
                      <input
                        disabled={!item.active}
                        style={{
                          border: "2px solid",
                          borderColor: colors.lightBlue,
                          color: colors.accent,
                          textAlign: "center",
                          width: 60,
                        }}
                        placeholder={"0"}
                        value={item.active ? item.schAmount : "--"}
                        onChange={(evt) =>
                          preserveSch ?
                          updatePreservedSchAmount(evt.target.value) :
                          updateSchAmount(index, evt.target.value)
                        }
                      />
                    </React.Fragment>
                  )}
                </div>
                <div style={{ display: "flex", width: 80 }}>
                  {(!preserveFinAid || index === 0) && (
                    <React.Fragment>
                      <p style={{ marginRight: "4px", marginLeft: "8px" }}>
                        FA:
                      </p>
                      <input
                        disabled={!item.active}
                        style={{
                          border: "2px solid",
                          borderColor: colors.lightBlue,
                          color: colors.accent,
                          textAlign: "center",
                          width: 60,
                        }}
                        placeholder={"0"}
                        value={item.active ? item.finAidAmount : "--"}
                        onChange={(evt) =>
                          preserveFinAid ?
                          updatePreservedFinAidAmount(evt.target.value) :
                          updateFinAidAmount(index, evt.target.value)
                        }
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 12,
          }}
        >
          <p style={{ textAlign: "left" }}>Jersey Number:</p>
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 150,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={jerseyNumber}
            placeholder="0"
            onChange={(evt) => setJerseyNumber(evt.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const CheckBox = ({ checked, onClick }) => {
  return checked ? (
    <ImCheckboxChecked
      style={{
        cursor: "pointer",
        color: colors.white,
        fontSize: 20,
        marginRight: 8,
      }}
      onClick={onClick}
    />
  ) : (
    <ImCheckboxUnchecked
      style={{
        cursor: "pointer",
        color: colors.white,
        fontSize: 20,
        marginRight: 8,
      }}
      onClick={onClick}
    />
  );
}
