import React, { useEffect, useState } from "react";
import LabelDescription from "../components-pdf/pdf-label-description";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import PDFImagesList from "../components-pdf/pdf-images-list";
import colors from "constants/colors";
import RichText from "../components-pdf/pdf-rich-text";

function PDFGeneralReport({ posReport, fullScreen }) {
  useEffect(() => {
    Font.register({
      family: "Raleway",
      fonts: [
        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf",
          fontWeight: "bold",
        },

        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf",
          fontWeight: "normal",
          fontStyle: "normal",
        },

        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf",
          fontStyle: "italic",
        },
      ],
    });
    Font.register({
      family: "RalewayBold",
      fonts: [
        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf",
          fontWeight: 800,
          fontStyle: "normal",
        },
      ],
    });
  }, []);

  const MyDocument = () => (
    <Document>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
          marginLeft: 10,
          marginTop: 10,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: "RalewayBold",
            textDecoration: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: colors.accent,
            color: colors.accent,
            fontWeight: 800,
            marginBottom: 4,
          }}
        >
          {posReport.playerName}
        </Text>
        <Text style={{ marginBottom: 20, fontSize: 10 }}>
          Report
          {posReport.date
            ? moment(new Date(posReport.date.seconds * 1000)).format(
                " MM/DD/YYYY"
              )
            : ""}
        </Text>

        <Text
          style={{
            fontSize: 12,
            fontFamily: "RalewayBold",
            textDecoration: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: colors.accent,
            color: colors.accent,
            fontWeight: 800,
            marginBottom: 4,
          }}
        >
          NOTES
        </Text>

        <RichText note={posReport.summary} />

        <PDFImagesList posReport={posReport} fancyText={true} />
      </Page>
    </Document>
  );

  return (
    <PDFViewer
      style={
        fullScreen
          ? { minWidth: "100vw", minHeight: "100vh" }
          : {
              minWidth: "70vw",
              minHeight: "90vh",
            }
      }
    >
      <MyDocument />
    </PDFViewer>
  );
}

export default PDFGeneralReport;
