import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { QueryHelper } from "services/QueryHelper";
import { useLocation } from "react-router-dom";
import { useMe } from "@tara-wilson/mcc-lib";

function SaveMonitorFilterModal(props: {
  onHide: any;
  url: string;
  query: any;
  show?: boolean;
}) {
  const me = useMe();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const firestore = useFirestore();

  const save = () => {
    setLoading(true);

    const docRefConfig = {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "monitorFilters" }],
    };

    let search = {
      name: name,
      userId: me.id,
      timeCreated: new Date(),
      query: props.query,
      webUrl: props.url,
    };
    firestore.add(docRefConfig, search);

    toast.dark(`Search saved!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
    setLoading(false);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Save Filter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <input
            type="text"
            style={{
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            placeholder="Enter Filter Name"
            onChange={(evt) => setName(evt.target.value)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveMonitorFilterModal;
