/*****************************************************************************
 * Import
 *****************************************************************************/
import React, { useEffect, useState, useMemo } from "react";
import firebase from "firebase/app";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment
} from "@material-ui/core";

import { AiOutlineSearch } from "react-icons/ai";
import { Column, Row } from "components/basic";
import { Player } from "models/Player";

const elasticSearch = firebase.functions().httpsCallable("elasticSearch");
const elasticCount = firebase.functions().httpsCallable("elasticCount");

/*****************************************************************************
 * Default Component
 *****************************************************************************/

export default function TournamentDataPage() {
  const [selectedPlayer, setSelectedPlayer] = useState<Player>(null);
  const [selectedTeam, setSelectedTeam] = useState("");
  
  const numTournaments = useNumTournaments();
  const numTeams = useNumTeams();
  const numPlayers = useNumPlayers();

  return (
    <Box p="32px">
      <Column gap="16px">
        
        <p>
          {numTournaments} Tournies | {numTeams} Teams | {numPlayers} Players
        </p>
        <Row>
          <Box flex={1}>
            <TeamSearch
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
              setSelectedPlayer={setSelectedPlayer}
            />
          </Box>
          <Box flex={1}>
            <PlayerSearch
              selectedPlayer={selectedPlayer}
              setSelectedPlayer={setSelectedPlayer}
              setSelectedTeam={setSelectedTeam}
            />
          </Box>
        </Row>
      </Column>
    </Box>
  );
}

/*****************************************************************************
 * Helper Hooks
 *****************************************************************************/

const usePlayersForTeam = (team) => {
  const [players, setPlayers] = useState<Player[]>([]);

  useEffect(() => {
    (async () => {
      /* Clear on every change */
      setPlayers([]);

      /* Search if team is selected */
      if (team) {
        const players = await elasticSearch({
          index: "players",
          body: {
            query: {
              terms: {
                "summer_team.keyword": [team],
              }
            }
          }
        });
        setPlayers(players.data?.data || []);
      }
    })();
  }, [team]);  

  return players;
}


const useNumTournaments = () => {
  const [numTournaments, setNumTournaments] = useState(0);

  useEffect(() => {
    (async () => {
      var response = await elasticCount("tournaments");
      setNumTournaments(parseInt(response.data.count));
    })();
  }, []);

  return numTournaments;
}

const useNumTeams = () => {
  const [numTeams, setNumTeams] = useState(0);

  useEffect(() => {
    (async () => {
      var response = await elasticCount("teams");
      setNumTeams(parseInt(response.data.count));
    })();
  }, []);

  return numTeams;
}

const useNumPlayers = () => {
  const [numPlayers, setNumPlayers] = useState(0);

  useEffect(() => {
    (async () => {
      var response = await elasticCount("players");
      setNumPlayers(parseInt(response.data.count));
    })();
  }, []);

  return numPlayers;
}

const useTournamentsForTeam = (team) => {
  const [tournaments, setTournaments] = useState([]);
  
  useEffect(() => {
    (async () => {
      /* Clear on every change */
      setTournaments([]);

      /* Search if team is selected */
      if (team) {
        const tournaments = await elasticSearch({
          index: "tournaments",
          body: {
            query: {
              terms: {
                "teams.keyword": [team],
              }
            }
          }
        });
        setTournaments(tournaments.data?.data || []);
      }
    })();
  }, [team]);

  return tournaments;
}

const useTournamentsForPlayer = (player: Player) => {
  const [tournaments, setTournaments] = useState([]);
  
  useEffect(() => {
    (async () => {
      /* Clear on every change */
      setTournaments([]);

      /* Search if team is selected */
      if (player) {
        const tournaments = await elasticSearch({
          index: "tournaments",
          body: {
            query: {
              terms: {
                "teams.keyword": [player.summer_team],
              }
            }
          }
        });
        setTournaments(tournaments.data?.data || []);
      }
    })();
  }, [player]);

  return tournaments;
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const PlayerSearch = ({ selectedPlayer, setSelectedPlayer, setSelectedTeam }) => {
  const tournaments = useTournamentsForTeam(selectedPlayer?.summer_team);

  const playerYears = selectedPlayer?.graduating_class
    ? [... new Array(4)].map((_, idx) => selectedPlayer.graduating_class - 4 + idx)
    : [];
  
  const { tournamentsByYear, numPlayerTournaments } = useMemo(() => (
    playerYears.reduce((accum, year) => {
      const tournamentsForYear = tournaments.filter(tournament =>
        tournament.date.split("-")[0] === `${year}`
      );
      
      return {
        numPlayerTournaments: accum.numPlayerTournaments + tournamentsForYear.length,
        tournamentsByYear: {
          ...accum.tournamentsByYear,
          [year]: tournamentsForYear,
        }
      }
    }, { tournamentsByYear: {}, numPlayerTournaments: 0 })
  ), [playerYears, tournaments]);
  
  return (
    <Column gap="16px">
      <ElasticSearchBar
        index="players"
        placeholder="Player Name"
        onSelectItem={(item) => setSelectedPlayer(item[0])}
      />
      {selectedPlayer && (
        <Column>
          <p style={{ fontWeight: 700, fontSize: "24px" }}>
            {selectedPlayer.name} ({selectedPlayer.primary_position})
            <span style={{ fontSize: "18px" }}>
              {" "}class of {selectedPlayer.graduating_class}
            </span>
          </p>
          <p style={{ fontSize: "16px" }}>
            TEAM:{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: 700,
              }}
              onClick={() => setSelectedTeam(selectedPlayer.summer_team)}
            >
              {selectedPlayer.summer_team}
            </span>
          </p>
          <p style={{ fontWeight: 700, fontSize: "16px" }}>
            Played in {numPlayerTournaments} Tournaments
          </p>
          {playerYears.map((year, idx) => (
            <Column key={idx}>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                {year} ({tournamentsByYear[year].length})
              </p>
              {((tournamentsByYear[year] || []).sort()).map((tournament) => (
                <p key={tournament.name}>
                  {tournament.name}
                </p>
              ))}
            </Column>
          ))}
        </Column>
      )}
    </Column>
  );
}

const TeamSearch = ({ selectedTeam, setSelectedTeam, setSelectedPlayer }) => {
  const tournaments = useTournamentsForTeam(selectedTeam);
  const players = usePlayersForTeam(selectedTeam);

  const tournamentsByYear = useMemo(() => (
    tournaments.reduce((accum, tournament) => {
      const year = tournament.date.split("-")[0];
      if (!(year in accum)) {
        accum[year] = []
      }
      
      accum[year].push(tournament);
      return accum;
    }, {})
  ), [tournaments]);

  const tournamentYears = Object.keys(tournamentsByYear).sort();

  const playersByYear = useMemo(() => (
    tournamentYears.reduce((accum, year) => {      
      return {
        ...accum,
        [year]: players.filter(player =>
          parseInt(year) >= player.graduating_class - 4
          && parseInt(year) < player.graduating_class
        )
      }
    }, {})
  ), [players, tournamentYears]);

  return (
    <Column gap="16px">
      <ElasticSearchBar
        index="teams"
        placeholder="Team Name"
        onSelectItem={(item) => setSelectedTeam(item[0].name)}
      />
      {selectedTeam && (
        <Column>
          <p style={{ fontWeight: 700, fontSize: "24px" }}>
            {selectedTeam} {tournaments.length ? `(${tournaments.length} tournaments)` : ""}
          </p>
          {tournamentYears.map((year, idx) => (
            <Column key={idx}>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                {year} ({tournamentsByYear[year].length})
              </p>
              <Roster
                players={(playersByYear[year] || []).sort()}
                onSelectPlayer={setSelectedPlayer}
              />
              {((tournamentsByYear[year] || []).sort()).map((tournament) => (
                <p key={tournament.name}>
                  {tournament.name}
                </p>
              ))}
            </Column>
          ))}
        </Column>
      )}
    </Column>
  );
}

const Roster = ({ players, onSelectPlayer }) => {
  const [expanded, setExpanded] = useState(false);
  
  return (
    <Column>
      <p
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: "pointer", textDecoration: "underline", fontSize: "16px" }}
      >
        ROSTER {expanded ? "-" : "+"}
      </p>
      {expanded && (
        <Column style={{ paddingLeft: "16px" }}>
          {players.map((player: Player) => (
            <p
              key={player.name}
              onClick={() => onSelectPlayer(player)}
              style={{ cursor: "pointer" }}
            >
              {player.name} ({player.primary_position})
            </p>
          ))}
        </Column>
      )}
    </Column>
  );
}
const ElasticSearchBar = ({ index, onSelectItem, placeholder }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSearch = async (query: string) => {
    setIsLoading(true);
    const response = await elasticSearch({
      index: index,
      body: {
        size: 500,
        query: {
          match: {
            "name": {
              query: query,
              fuzziness: "AUTO",
            }
          }
        }
      }
    });

    setOptions(response.data?.data || []);
    setIsLoading(false);
  }
  
  return (
    <AsyncTypeahead
      id="teams-search"
      style={{ flex: 1, marginRight: 10 }}
      filterBy={() => true}
      isLoading={isLoading}
      labelKey="name"
      inputProps={{
        name: "search",
      }}
      minLength={2}
      onSearch={handleSearch}
      selected={[]}
      options={options}
      onChange={onSelectItem}
      placeholder={placeholder}
      renderMenuItemChildren={(option) => (
        <SearchResult option={option} />
      )}
    />
  );
}

const SearchResult = ({ option }) => {
  return (
    <div
      style={{
        marginLeft: -8,

        display: "flex",
      }}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {option.name}
      </span>
    </div>
  );
}
