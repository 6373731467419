import { STATES, TEAMS } from "constants/data";
import React, { Fragment, useEffect, useState } from "react";

import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";

const EditAffTeamDropdown = (props: { selectTeam: any; value: any }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const filterBy = () => true;
  const [newEntry, setNewEntry] = useState(true);
  const [focused, setFocused] = useState(false);

  if (props.value && newEntry && !focused) {
    setNewEntry(false);
  }

  const handleSearch = async (query) => {
    let newOptions = TEAMS.map((item) => ({ name: item })).filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );

    setOptions(newOptions);
  };

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      minLength={1}
      isLoading={isLoading}
      onSearch={handleSearch}
      labelKey="name"
      options={options as { name: string }[]}
      onChange={(vals) => props.selectTeam(vals.map((item) => item.name))}
      //@ts-ignore
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) =>
        newEntry ? (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            placeholder={"Affiliated Team"}
            style={{
              width: 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        ) : (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            value={props.value}
            onFocus={() => {
              setFocused(true);
              setNewEntry(true);
            }}
            placeholder={"Affiliated Team"}
            style={{
              width: 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        )
      }
    />
  );
};

export default EditAffTeamDropdown;
