import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { QueryHelper } from "services/QueryHelper";
import { Player } from "models/Player";

export default function TeamDropdown(props: {
  selectTeam: any;
  transparentInput?: any;
  placeholder?: string;
  value?: string;
  maxWidth?: number;
}) {
  const [search, setSearch] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Player[]>([]);
  const filterBy = () => true;
  const [newEntry, setNewEntry] = useState(true);
  const [focused, setFocused] = useState(false);

  if (props.transparentInput && props.value && newEntry && !focused) {
    setNewEntry(false);
  }

  const handleSearch = async (query) => {
    setIsLoading(true);
    setSearch(query);

    let url = QueryHelper.urlForNameAutocomplete(query, "summer_team", false);
    let res: {
      data: Player[];
      count: number;
    } = await QueryHelper.fetchDataForURLwithCount(url);
    if (res && res.data) {
      let opts: Player[] = [];
      let keys: string[] = [];
      res.data.forEach((item: Player) => {
        if (!keys.includes(item.summer_team)) {
          opts.push({ ...item, id: item.external_id });
          keys.push(item.summer_team);
        }
      });
      setOptions(opts);
      setIsLoading(false);
    }
  };

  return props.transparentInput ? (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={isLoading}
      labelKey="summer_team"
      minLength={2}
      onSearch={handleSearch}
      options={options}
      //@ts-ignore
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) =>
        newEntry ? (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            placeholder={props.placeholder || "Team..."}
            style={{
              width: props.maxWidth || 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        ) : (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            value={props.value}
            onFocus={() => {
              setFocused(true);
              setNewEntry(true);
            }}
            placeholder={props.placeholder || "Team..."}
            style={{
              width: props.maxWidth || 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        )
      }
      onChange={(vals) => {
        if (vals && vals.length > 0) {
          props.selectTeam(vals[0].summer_team);
        }
      }}
      renderMenuItemChildren={(option, props) => (
        <div
          {...props}
          style={{
            marginLeft: -8,
            display: "flex",
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {option.summer_team}
          </p>
        </div>
      )}
    />
  ) : (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={isLoading}
      labelKey="summer_team"
      minLength={2}
      onSearch={handleSearch}
      options={options}
      placeholder={props.placeholder || "Search..."}
      onChange={(vals) => {
        if (vals && vals.length > 0) {
          props.selectTeam(vals[0].summer_team);
        }
      }}
      renderMenuItemChildren={(option, props) => (
        <div
          {...props}
          style={{
            marginLeft: -8,
            display: "flex",
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {option.summer_team}
          </p>
        </div>
      )}
    />
  );
}
