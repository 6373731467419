import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import StateDropdown from "dropdowns/state-dropdown";
import TeamDropdown from "dropdowns/team-dropdown";
import LabelledDatePicker from "pages/reports-create-page/components/labelled-date-picker";
import ProspectMultiDropdown from "dropdowns/prospect-multi-dropdown";

function CreateEventModal(props: {
  firestore: any;
  onHide: any;
  eventDate: Date;
  editing: any;
  currentPlayerId: string;
}) {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [teamOne, setTeamOne] = useState("");
  const [teamTwo, setTeamTwo] = useState("");
  const [addedProspectIds, setAddedPropsectIds] = useState([]);

  useEffect(() => {
    if (props.editing) {
      if (props.editing.eventDate) {
        setDate(new Date(props.editing.eventDate.seconds * 1000));
      }
      if (props.editing.game_title) {
        setLocationName(props.editing.game_title);
      }
      if (props.editing.street) {
        setStreet(props.editing.street);
      }
      if (props.editing.city) {
        setCity(props.editing.city);
      }
      if (props.editing.state) {
        setState(props.editing.state);
      }
      if (props.editing.zip) {
        setZip(props.editing.zip);
      }
      if (props.editing.teamOne) {
        setTeamOne(props.editing.teamOne);
      }
      if (props.editing.teamTwo) {
        setTeamTwo(props.editing.teamTwo);
      }
      if (props.editing.addedProspectIds) {
        setAddedPropsectIds(props.editing.addedProspectIds);
      }
    } else if (props.currentPlayerId) {
      setAddedPropsectIds([props.currentPlayerId]);
    }
  }, []);

  useEffect(() => {
    if (props.eventDate && date === null) {
      setDate(props.eventDate);
    }
  });

  const save = () => {
    setLoading(true);

    let ev = {
      eventDate: date,
      street: street,
      city: city,
      state: state,
      zip: zip,
      teamOne: teamOne,
      teamTwo: teamTwo,
      addedProspectIds: addedProspectIds,
      game_title: locationName,
    };

    if (props.editing) {
      let docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "events", doc: props.editing.id }],
      };
      props.firestore.update(docRefConfig, ev);

      toast.dark(`Event updated!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      let docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "events" }],
      };
      let sharedRefConfig = {
        collection: "events",
      };
      props.firestore.add(docRefConfig, ev);
      props.firestore.add(sharedRefConfig, { ...ev, shared: true });

      toast.dark(`Event created!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

    setLoading(false);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Create Event
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        <LabelledDatePicker
          showTime={true}
          date={date}
          setDate={(date) => {
            setDate(date);
          }}
          containerStyles={{ flex: 1 }}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={locationName}
          placeholder="Location Name..."
          onChange={(evt) => setLocationName(evt.target.value)}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={street}
          placeholder="Street..."
          onChange={(evt) => setStreet(evt.target.value)}
        />
        <input
          type="text"
          placeholder="City..."
          value={city}
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          onChange={(evt) => setCity(evt.target.value)}
        />

        <StateDropdown
          value={state}
          selectStates={(st) => {
            setState(st[0]);
          }}
          transparentInput={true}
          placeholder={"State..."}
        />

        <input
          type="text"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={zip}
          placeholder="Zipcode..."
          onChange={(evt) => setZip(evt.target.value)}
        />

        <TeamDropdown
          value={teamOne}
          transparentInput={true}
          placeholder={"Team One..."}
          selectTeam={(st) => {
            setTeamOne(st);
          }}
        />

        <TeamDropdown
          value={teamTwo}
          transparentInput={true}
          placeholder={"Team Two..."}
          selectTeam={(st) => {
            setTeamTwo(st);
          }}
        />

        <div
          style={{
            marginTop: 20,
            marginBottom: 30,
          }}
        >
          <ProspectMultiDropdown
            value={addedProspectIds}
            selectProspect={(st) => {
              setAddedPropsectIds(st.map((item) => item.external_id));
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(CreateEventModal);
