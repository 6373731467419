import React from "react";
import colors from "constants/colors";
import ScoutTitle from "./scout-title";
import NotificationsList from "header/notifications-list";
import CTALink from "./cta-link";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { NotificationService } from "services/NotificationService";
import UnderlineButton from "./underline-button";

function SidebarNotifications(props: any) {
  const notifications = useSelector(
    (state) => state.firestore.data.notifications
  );

  const firestore = useFirestore();

  const clearAll = () => {
    let nots = Object.keys(notifications || {})
      .map((key) => (notifications ? { ...notifications[key], id: key } : null))
      .filter((item) => item !== null);

    nots.forEach((notification) => {
      NotificationService.markNotificationRemoved(notification, firestore);
    });
  };

  const filteredNotifications = () => {
    let nots = Object.keys(notifications || {})
      .map((key) => (notifications ? { ...notifications[key], id: key } : null))
      .filter((item) => item !== null);

    let n = nots.filter((item) => item && item.text && !item.removed);

    return n;
  };

  return (
    <div
      style={{
        backgroundColor: colors.white,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingRight: 8,
        }}
      >
        <Link to={"/notifications"}>
          <ScoutTitle text={"Notifications"} styles={{}} />
        </Link>

        <UnderlineButton text="clear all" onClick={clearAll} />
      </div>

      <NotificationsList limit={7} />
      {filteredNotifications().length > 0 ? (
        <CTALink
          linkTo={"/notifications"}
          text={"VIEW ALL"}
          styles={{ marginTop: -5, marginBottom: 10 }}
        />
      ) : (
        <p
          style={{
            fontStyle: "italic",
            opacity: 0.6,
            paddingLeft: 20,
            marginTop: -20,
          }}
        >
          No new notifications
        </p>
      )}
    </div>
  );
}

export default SidebarNotifications;
