import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import { withFirestore } from "react-redux-firebase";
import { Button, Modal, Spinner } from "react-bootstrap";

function DeleteAccountModal(props: {
  onHide: any;
  onConfirm: any;
  title: string;
  body: string;
  show?: any;
}) {
  const [password, setPassword] = useState("");
  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: colors.white, backgroundColor: colors.accent }}
      >
        <div className="searching">
          <p>{props.body}</p>
          <input
            type="password"
            style={{
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={password}
            placeholder="Re-enter your password to confirm."
            onChange={(evt) => setPassword(evt.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => props.onHide()}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onConfirm(password);
          }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAccountModal;
