import colors from "constants/colors";
import AreYouSureModal from "modals/are-you-sure-modal";
import ShareReportModal from "modals/share-report-modal";
import { useHistory } from "react-router-dom";
import { PositionReport } from "models/PositionReport";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Link } from "react-router-dom";
import LargePlayerImage from "shared/large-player-image";
import StarButton from "shared/star-button";
import UnderlineButton from "shared/underline-button";
import XLSX from "xlsx";
import { toast } from "react-toastify";

function ReportRow(props: {
  report: PositionReport;
  index?: number;
  selected: boolean;
  onSelect: any;
}) {
  const [modalShow, setModalShow] = useState(false);
  const [copyModalShow, setCopyModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const history = useHistory();

  const userId = useSelector((state) => state.firebase.auth.uid);
  const prospect = useSelector((state) =>
    state.firestore.data.myProspects &&
    Object.keys(state.firestore.data.myProspects).includes(
      props.report.playerId
    )
      ? state.firestore.data.myProspects[props.report.playerId]
      : null
  );

  const firestore = useFirestore();

  const copyReport = async () => {
    let newReport = { ...props.report };
    newReport.date = new Date();
    delete newReport.relatedNotificationId;
    delete newReport.authorName;

    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports" }],
    };

    let r = await firestore.add(boardsDocRefConfig, newReport);
    history.replace(`/edit-report/${r.id}`);
  };

  const deleteReport = async () => {
    setDeleteModalShow(false);
    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: props.report.id }],
    };
    await firestore.delete(boardsDocRefConfig);
    toast.dark(`Report deleted.`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <div key={props.index ? props.index : props.report.id}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: props.selected
            ? "rgba(70,125,179, 0.1)"
            : colors.white,
          borderStyle: "solid",
          borderColor: props.selected ? colors.lightBlue : "transparent",
          borderWidth: 2,
          borderRadius: 8,
          marginBottom: 4,
          padding: 6,
        }}
      >
        <input
          type="checkbox"
          style={{ marginRight: 10, marginLeft: 10 }}
          checked={props.selected}
          onClick={() => {
            props.onSelect(props.report.id);
          }}
        />

        <Link
          to={`/display-report/${props.report.id}`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: colors.accent,
              }}
            >
              {props.report.playerName}
            </p>

            <span style={{ marginLeft: 4, fontWeight: 800 }}>
              {props.report.kind === "GENERAL"
                ? "(GEN)"
                : props.report.kind === "NOTES"
                ? "(notes)"
                : props.report.kind === "PITCHER"
                ? "(PRO)"
                : "(PRO)"}
            </span>

            <p
              style={{
                color: colors.accent,
                marginLeft: 8,
              }}
            >
              {props.report.date && props.report.date.seconds
                ? moment(new Date(props.report.date.seconds * 1000)).format(
                    "MM/DD/YYYY"
                  )
                : "N/A"}
            </p>
          </div>
        </Link>

        <div style={{ flex: 1 }} />

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {props.report.relatedNotificationId && props.report.authorName ? (
            <p
              style={{
                padding: 6,
                color: colors.lightBlue,
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >{`Shared by ${props.report.authorName}`}</p>
          ) : (
            <div />
          )}
          {props.report.relatedNotificationId && props.report.authorName ? (
            <UnderlineButton
              text={"copy"}
              onClick={() => {
                setCopyModalShow(true);
              }}
              color={colors.accent}
            />
          ) : (
            <Link to={`/edit-report/${props.report.id}`}>
              <UnderlineButton
                text={"edit"}
                onClick={() => {}}
                color={colors.accent}
              />
            </Link>
          )}

          <UnderlineButton
            text={"delete"}
            onClick={() => {
              setDeleteModalShow(true);
            }}
            color={colors.accent}
          />
          <UnderlineButton
            text={"share"}
            color={colors.accent}
            onClick={() => {
              setModalShow(true);
            }}
          />
          <Link to={`/display-pdf/${props.report.id}`}>
            <UnderlineButton
              text={"report.pdf"}
              onClick={() => {}}
              color={colors.accent}
            />
          </Link>
        </div>
      </div>

      <ShareReportModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        report={props.report}
      />
      <AreYouSureModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onConfirm={deleteReport}
        title={"Delete Report"}
        body={"Are you sure you want to delete this report?"}
      />
      <AreYouSureModal
        show={copyModalShow}
        onHide={() => setCopyModalShow(false)}
        onConfirm={copyReport}
        title={"Copy Report"}
        body={"Do you want to make an editable copy of this shared report?"}
      />
    </div>
  );
}

export default ReportRow;
