import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { useFirestore, withFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TeamDropdown from "dropdowns/team-dropdown";
import { Player } from "models/Player";
import SmallButton from "pages/search-page/components/small-button";
import { useMe } from "@tara-wilson/mcc-lib";
import { User } from "models/User";
import YearDropdown from "dropdowns/year-dropdown";
import Switch from "react-switch";
import UnderlineButton from "shared/underline-button";

export default function CreateRosterPlayerModal(props: {
  sortedProspects: Player[];
  onHide: any;
  show: any;
}) {
  const me = useMe<User>();
  const firestore = useFirestore();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [jerseyNumber, setJerseyNumber] = useState("");
  const [startYear, setStartYear] = useState("2022");
  const [years, setYears] = useState([
    { year: "2021", active: true },
    { year: "2022", active: true },
    { year: "2023", active: true },
    { year: "2024", active: true },
  ]);

  const save = async () => {
    let activeYears = years.filter((item) => item.active).length;
    if (activeYears > 4) {
      toast.dark(`Error: too many active years.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      let endYear = years[years.length - 1].year;
      let upd = {
        manual: true,
        rosterNumber: (props.sortedProspects || []).length + 1,
        jerseyNumber: jerseyNumber && jerseyNumber != "" ? jerseyNumber : null,
        primary_position: position,
        first_name: firstName,
        last_name: lastName,
        startYear: parseInt(startYear),
        endYear: parseInt(endYear),
        redShirtYears: years
          .filter((item) => !item.active)
          .map((item) => parseInt(item.year)),
        graduating_class: startYear,
        commited: me.college || "",
      };

      let pl = await firestore
        .collection("users")
        .doc(me.id)
        .collection("roster")
        .add(upd);
      let playerId = pl.id;

      await firestore
        .collection("users")
        .doc(me.id)
        .collection("roster")
        .doc(playerId)
        .update({ external_id: playerId });

      toast.dark(`Player added!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });

      props.onHide({ ...upd, external_id: playerId, id: playerId });
    }
  };

  const renderPositions = (pos: string, updatePos: any) => {
    return (
      <div>
        <div className="position-row">
          <SmallButton
            title="RHP"
            selected={pos === "RHP"}
            onClick={() => updatePos("RHP")}
          />
          <SmallButton
            title="LHP"
            selected={pos === "LHP"}
            onClick={() => updatePos("LHP")}
          />
          <SmallButton
            title="1B"
            selected={pos === "1B"}
            onClick={() => updatePos("1B")}
          />
          <SmallButton
            title="2B"
            selected={pos === "2B"}
            onClick={() => updatePos("2B")}
          />
          <SmallButton
            title="SS"
            selected={pos === "SS"}
            onClick={() => updatePos("SS")}
          />
        </div>
        <div className="position-row">
          <SmallButton
            title="3B"
            selected={pos === "3B"}
            onClick={() => updatePos("3B")}
          />
          <SmallButton
            title="C"
            selected={pos === "C"}
            onClick={() => updatePos("C")}
          />
          <SmallButton
            title="LF"
            selected={pos === "LF"}
            onClick={() => updatePos("LF")}
          />
          <SmallButton
            title="CF"
            selected={pos === "CF"}
            onClick={() => updatePos("CF")}
          />
          <SmallButton
            title="RF"
            selected={pos === "RF"}
            onClick={() => updatePos("RF")}
          />
        </div>
      </div>
    );
  };

  const addAnotherYear = () => {
    let lastYear = parseInt(years[years.length - 1].year);
    setYears([...years, { year: `${lastYear + 1}`, active: true }]);
  };

  const onSetStartYear = (startYear) => {
    setStartYear(startYear);
    let firstYear = parseInt(startYear);
    let years = [];
    for (var i = 0; i < 4; i++) {
      years.push({
        year: `${firstYear + i}`,
        active: true,
      });
    }
    setYears(years);
  };

  const removeLastYear = () => {
    let lastYear = years[years.length - 1].year;
    setYears(years.filter((item) => item.year != lastYear));
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Create Player
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          marginTop: 0,
        }}
        className="searching"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={firstName}
            placeholder="First Name"
            onChange={(evt) => setFirstName(evt.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={lastName}
            placeholder="Last Name"
            onChange={(evt) => setLastName(evt.target.value)}
          />
        </div>
        {/* </div> */}

        <p style={{ textAlign: "left" }}>Position:</p>
        {renderPositions(position, setPosition)}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            color: colors.accent,
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <p style={{ textAlign: "left", color: "#fff" }}>Starting Year:</p>
            <YearDropdown
              season
              customYears={[
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
              ]}
              selectYear={(yr) => {
                onSetStartYear(yr[0]);
              }}
              allowAll={false}
              minWidth={140}
              maxWidth={140}
              placeholder={`${startYear}-${parseInt(startYear) + 1}`}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 14,
          }}
        >
          <p style={{ textAlign: "left", color: "#fff", marginBottom: 4 }}>
            Active Years:
          </p>
          {years.map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ marginRight: 12, fontWeight: "bold", whiteSpace: "nowrap" }}>
                {item.year}-{parseInt(item.year) + 1}
              </p>
              <div
                onClick={() => {}}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 20,
                  marginBottom: 8,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Switch
                  checked={item.active}
                  onChange={() =>
                    setYears(
                      years.map((year) =>
                        year.year == item.year
                          ? { year: year.year, active: !year.active }
                          : year
                      )
                    )
                  }
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={colors.lightBlue}
                />
                <p
                  style={{
                    color: colors.white,

                    marginLeft: 12,
                  }}
                >
                  {item.active ? "active" : "inactive"}
                </p>
                <div style={{ flex: 1 }} />
                {index > 3 && index == years.length - 1 ? (
                  <UnderlineButton
                    color="white"
                    text="remove"
                    onClick={removeLastYear}
                  ></UnderlineButton>
                ) : (
                  <div />
                )}
              </div>
            </div>
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <UnderlineButton
              color="white"
              text="+ add another year"
              onClick={addAnotherYear}
            ></UnderlineButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 12,
          }}
        >
          <p style={{ textAlign: "left" }}>Jersey Number:</p>
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 150,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={jerseyNumber}
            placeholder="0"
            onChange={(evt) => setJerseyNumber(evt.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
