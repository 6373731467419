import React, { useState } from "react";
import TopLabelMultilineBox from "../components/top-label-multiline-box";
import colors from "constants/colors";
import "react-datepicker/dist/react-datepicker.css";
import LabelledDatePicker from "../components/labelled-date-picker";
import moment from "moment";
import { Link } from "react-router-dom";
import UnderlineButton from "shared/underline-button";
import { useParams } from "react-router-dom";
import TopLabelBox from "../components/top-label-box";
import ImagesPicker from "../components/images-picker";
import VideosPicker from "../components/videos-picker";
import RelatedGamePicker from "../components/related-game-picker";

function NotesReportForm({ posReport, setPosReport, deleteReport }) {
  let { reportId } = useParams();

  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 50,
        borderRadius: 8,
        backgroundColor: colors.white,
        color: colors.accent,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "60vw",
        padding: 20,
      }}
    >
      <div className="report-row">
        <LabelledDatePicker
          date={
            posReport.date
              ? posReport.date.seconds
                ? moment(posReport.date.seconds * 1000).toDate()
                : new Date(posReport.date)
              : new Date()
          }
          setDate={(date) => setPosReport({ ...posReport, date: date })}
        />
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <UnderlineButton
              text={"delete"}
              onClick={() => {
                deleteReport();
              }}
              color={colors.accent}
            />
          </div>
          <Link to={`/display-report/${reportId}`}>
            <UnderlineButton
              text={"view report"}
              onClick={() => {}}
              color={colors.accent}
            />
          </Link>
        </div>
      </div>

      <div className="report-row">
        <TopLabelMultilineBox
          title="Notes"
          value={posReport.summary}
          minHeight={250}
          setValue={(val) => {
            let skip = val === "<p><br></p>";
            if (skip) {
              console.log("skip");
            } else {
              setPosReport({ ...posReport, summary: val });
            }
          }}
        />
      </div>

      <div className="report-row">
        <TopLabelBox
          title="Scholarship"
          value={posReport.scholarship}
          setValue={(val) => {
            setPosReport({ ...posReport, scholarship: val });
          }}
        />

        <div style={{ flex: 1 }} />
      </div>

      <RelatedGamePicker posReport={posReport} setPosReport={setPosReport} />
    </div>
  );
}

export default NotesReportForm;
