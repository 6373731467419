import { STATES } from "constants/data";
import React, { Fragment, useEffect, useState } from "react";

import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";

const StateDropdown = (props: {
  selectStates: any;
  transparentInput?: any;
  placeholder?: string;
  value?: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const filterBy = () => true;
  const [newEntry, setNewEntry] = useState(true);
  const [focused, setFocused] = useState(false);

  if (props.transparentInput && props.value && newEntry && !focused) {
    setNewEntry(false);
  }

  const handleSearch = async (query) => {
    let newOptions = STATES.map((item) => ({ name: item })).filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );

    setOptions(newOptions);
  };

  return props.transparentInput ? (
    <AsyncTypeahead
      filterBy={filterBy}
      minLength={1}
      isLoading={isLoading}
      onSearch={handleSearch}
      id="rendering-example"
      labelKey="name"
      options={options}
      onChange={(vals) => props.selectStates(vals.map((item) => item.name))}
      //@ts-ignore
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) =>
        newEntry ? (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            placeholder={props.placeholder || "Select State..."}
            style={{
              width: 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        ) : (
          //@ts-ignore
          <input
            {...inputProps}
            ref={(input) => {
              //@ts-ignore
              inputRef(input);
              referenceElementRef(input);
            }}
            type="text"
            value={props.value}
            onFocus={() => {
              setFocused(true);
              setNewEntry(true);
            }}
            placeholder={props.placeholder || "Team..."}
            style={{
              width: 465,
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
          />
        )
      }
    />
  ) : (
    <Typeahead
      multiple={true}
      flip={true}
      id="state"
      labelKey="name"
      options={STATES.map((item) => ({ name: item }))}
      onChange={(vals) => props.selectStates(vals.map((item) => item.name))}
      placeholder="Choose a state..."
    />
  );
};

export default StateDropdown;
