import { Image, Text, View } from "@react-pdf/renderer";
import colors from "constants/colors";
import { Player } from "models/Player";
import { User } from "models/User";
import React from "react";
import { PlayerService } from "services/PlayerService";

function PdfField({
  sortedProspects,
  me,
  year,
}: {
  sortedProspects: Player[];
  me: User;
  year: string;
}) {
  const renderProspect = (player: Player) => {
    return (
      <View
        style={{
          flexDirection: "row",
          display: "flex",
          width: 150,
          alignItems: "center",
          borderBottomColor: colors.lightBlue,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <View
          style={{
            width: 25,
            backgroundColor: colors.accent,
            paddingTop: 3,
            paddingBottom: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{ fontSize: 10, color: colors.white, textAlign: "center" }}
          >
            {`#${player.rosterNumber}`}
          </Text>
        </View>
        <Text
          style={{
            fontSize: 10,
            color: colors.accent,
            marginLeft: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {PlayerService.getName(player)}
        </Text>

        <View style={{ flex: 1 }} />
        <View
          style={{
            width: 30,
            borderLeftColor: colors.accent,
            borderLeftStyle: "solid",
            borderLeftWidth: 1,
            paddingTop: 3,
            paddingBottom: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{ fontSize: 10, color: colors.accent, textAlign: "center" }}
          >
            {PlayerService.getScholarship(player, year, "both").toFixed(2)}
          </Text>
        </View>
      </View>
    );
  };

  const getPlayer = (position: string, withAlt: boolean) => {
    if (position === "P") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "LHP" ||
          item.primary_position === "RHP" ||
          item.primary_position === "Pitcher" ||
          item.primary_position === "P" ||
          item.position === "LHP" ||
          item.position === "RHP" ||
          item.position === "Pitcher" ||
          item.position === "P" ||
          (withAlt && item.alt_position === "RHP") ||
          (withAlt && item.alt_position === "LHP")
      );
    }
    if (position === "SS") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "SS" ||
          item.primary_position === "MIF" ||
          (withAlt && item.alt_position === "MIF") ||
          (withAlt && item.alt_position === "SS")
      );
    }
    if (position === "1B") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "1B" ||
          item.position === "First Base" ||
          (withAlt && item.alt_position === "1B")
      );
    }
    if (position === "LF") {
      return sortedProspects.filter(
        (item) => item.primary_position === "LF" || item.position === "LF"
      );
    }
    if (position === "CF") {
      return sortedProspects.filter(
        (item) => item.position === "CF" || item.primary_position === "CF"
      );
    }
    if (position === "RF") {
      return sortedProspects.filter(
        (item) => item.primary_position === "RF" || item.position === "RF"
      );
    }
    if (position === "C") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "C" ||
          item.position === "Catcher" ||
          (withAlt && item.alt_position === "C")
      );
    }
    let pros = sortedProspects.filter(
      (item) =>
        item.primary_position === position ||
        item.position === position ||
        (withAlt && item.alt_position === position)
    );

    return pros;
  };

  const renderPosition = (pos: string) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <View style={{ backgroundColor: "#fff" }}>
          {getPlayer(pos, true).map((item, index) => renderProspect(item))}
        </View>
      </View>
    );
  };

  const renderTitle = (pos: string, title: string, marginTop: number) => {
    return (
      <Text
        style={{
          fontSize: 10,
          color: colors.accent,
          fontFamily: "RalewayBold",
          marginTop: marginTop,
        }}
      >
        {getPlayer(pos, true).length > 0 ? title : ""}
      </Text>
    );
  };

  const getUsedScholTotal = () => {
    let pl = (sortedProspects || [])
      .filter((item) => PlayerService.getScholarship(item, year, "schol", true) > 0)
      .map((item) => PlayerService.getScholarship(item, year, "schol"));
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getUsedFinAidTotal = () => {
    let pl = (sortedProspects || [])
      .filter((item) => PlayerService.getScholarship(item, year, "finAid", true) > 0)
      .map((item) => PlayerService.getScholarship(item, year, "finAid"));
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getUsedBothTotal = () => {
    let pl = (sortedProspects || [])
      .map((item) => ({
        ...item,
        totalVal: PlayerService.getScholarship(item, year, "both"),
      }))
      .filter((item) => item.totalVal > 0)
      .map((item) => item.totalVal);
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getTotalScholAmount = () => {
    if (me.totalScholarshipVal) {
      return me.totalScholarshipVal;
    }
    return 11.7;
  };

  const getTotalFinAidAmount = () => {
    if (me.totalFinAidVal) {
      return me.totalFinAidVal;
    }
    return 0;
  };

  const getTotalBothAmount = () => {
    return getTotalScholAmount() + getTotalFinAidAmount();
  };

  return (
    <View
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <Image
        source={require("../../../assets/bball-field.png")}
        style={{ height: 530 }}
      />
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={{ flex: 1, marginTop: -520 }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              borderColor: colors.lightBlue,
              width: 120,
              marginLeft: 10,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            <View
              style={{
                height: 25,
                backgroundColor: colors.accent,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: 120,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  width: 120,
                  color: colors.white,
                  fontFamily: "RalewayBold",
                  textAlign: "center",
                }}
              >
                {`TOTAL: ${(
                  getTotalScholAmount() - getUsedScholTotal()
                ).toFixed(2)}`}
              </Text>
            </View>
            <View
              style={{
                height: 25,
                backgroundColor: colors.white,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    color: colors.accent,
                    fontFamily: "RalewayBold",
                    marginRight: 4,
                  }}
                >
                  {"S:"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: colors.accent,
                  }}
                >
                  {`${(getTotalScholAmount() - getUsedScholTotal()).toFixed(
                    2
                  )}`}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    color: colors.accent,
                    fontFamily: "RalewayBold",
                    marginRight: 4,
                  }}
                >
                  {"F:"}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: colors.accent,
                  }}
                >
                  {`${getUsedFinAidTotal().toFixed(2)}`}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              borderColor: colors.lightBlue,
              width: 120,
              marginLeft: 10,
              borderWidth: 1,
              borderStyle: "solid",
              marginTop: 10,
            }}
          >
            <View
              style={{
                height: 25,
                backgroundColor: colors.accent,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: 120,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  width: 120,
                  color: colors.white,
                  fontFamily: "RalewayBold",
                  textAlign: "center",
                }}
              >
                {`ROSTER: ${(sortedProspects || []).length}`}
              </Text>
            </View>
            <View
              style={{
                height: 25,
                backgroundColor: colors.accent,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: 120,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  width: 120,
                  color: colors.white,
                  fontFamily: "RalewayBold",
                  textAlign: "center",
                }}
              >
                YEAR: {parseInt(year) ? `${year}-${parseInt(year) + 1}` : year}
              </Text>
            </View>
          </View>

          <View style={{ marginLeft: 12, marginTop: 12 }}>
            {renderTitle("P", "P", 0)}
            {renderPosition("P")}
          </View>
        </View>
        <View
          style={{
            marginTop: -440,
            flex: 1,
            height: 340,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View>
            {renderTitle("LF", "LF", 0)}
            {renderPosition("LF")}
          </View>
          <View style={{ marginLeft: 50, marginTop: 20 }}>
            {renderTitle("SS", "SS", 0)}
            {renderPosition("SS")}
          </View>

          <View style={{ marginLeft: -20 }}>
            {renderTitle("3B", "3B", 40)}
            {renderPosition("3B")}
          </View>
        </View>
        <View
          style={{
            marginTop: -490,
            height: 430,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <View>
            {renderTitle("CF", "CF", 0)}
            {renderPosition("CF")}
          </View>

          <View>
            {renderTitle("C", "C", 20)}
            {renderPosition("C")}
          </View>
        </View>

        <View
          style={{
            marginTop: -440,
            flex: 1,

            display: "flex",
            flexDirection: "column",
          }}
        >
          <View>
            {renderTitle("RF", "RF", 0)}
            {renderPosition("RF")}
          </View>
          <View style={{ marginLeft: -140, marginTop: 20 }}>
            {renderTitle("2B", "2B", 0)}
            {renderPosition("2B")}
          </View>
          <View>
            {renderTitle("1B", "1B", 40)}
            {renderPosition("1B")}
          </View>
        </View>
      </View>
    </View>
  );
}

export default PdfField;
