import colors from "constants/colors";
import React, { useState } from "react";
import { Text, View } from "@react-pdf/renderer";

function PDFSixBoxRow({ label, p, f, adj, h, l, a, comments }) {
  const colorForValue = (value: string) => {
    if (label && label !== "") {
      if (value) {
        switch (parseInt(value)) {
          case 2:
            return "#4A3D3D";
          case 3:
            return "#DB2330";
          case 4:
            return "#DB8E33";
          case 5:
            return "#80B304";
          case 6:
            return "#6690FF";
          case 7:
            return "#F5EE48";
          case 8:
            return colors.accent;

          default:
            return colors.lightGray;
        }
      }
      return colors.lightGray;
    }
    return colors.lightGray;
  };

  return (
    <View style={{ display: "flex", flexDirection: "column" }}>
      <View
        style={{
          flexDirection: "row",
          display: "flex",
          flex: 1,
        }}
      >
        {label && label !== "" ? (
          <Text
            style={{
              borderColor: colors.softDarkText,
              borderWidth: 1,
              flex: 2,
              padding: 4,
              borderRightWidth: 0,
              borderStyle: "solid",
              fontSize: 10,
            }}
          >
            {label}
          </Text>
        ) : (
          <View style={{ flex: 2 }} />
        )}

        <View
          style={{
            flex: 1,
            borderColor: colors.softDarkText,
            borderWidth: 1,
            padding: 4,

            borderStyle: "solid",
            backgroundColor: colorForValue(p),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {p}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            borderTopColor: colors.softDarkText,
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderBottomColor: colors.softDarkText,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            padding: 4,

            backgroundColor: colorForValue(f),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {f}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            borderColor: colors.softDarkText,
            borderWidth: 1,
            padding: 4,

            borderStyle: "solid",
            backgroundColor: colorForValue(adj),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {adj}
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            borderTopColor: colors.softDarkText,
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderBottomColor: colors.softDarkText,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            padding: 4,

            borderStyle: "solid",
            backgroundColor: colorForValue(h),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {h}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            borderTopColor: colors.softDarkText,
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderBottomColor: colors.softDarkText,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderLeftColor: colors.softDarkText,
            borderLeftWidth: 1,
            borderLeftStyle: "solid",
            padding: 4,

            borderStyle: "solid",
            backgroundColor: colorForValue(l),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {l}
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            borderColor: colors.softDarkText,
            borderWidth: 1,
            padding: 4,

            borderStyle: "solid",
            backgroundColor: colorForValue(a),
          }}
        >
          <Text style={{ textAlign: "center", fontWeight: 800, fontSize: 10 }}>
            {a}
          </Text>
        </View>
      </View>
      {label && label !== "" ? (
        comments && comments !== "" ? (
          <Text
            style={{
              fontWeight: "normal",
              fontSize: 10,
              backgroundColor: colors.lightestBlue,
              padding: 4,
              marginBottom: 10,
            }}
          >
            {comments}
          </Text>
        ) : (
          <Text
            style={{
              fontStyle: "italic",
              fontSize: 10,
              backgroundColor: colors.lightestBlue,
              padding: 4,
              fontWeight: "normal",
              marginBottom: 10,
              color: colors.softDarkText,
            }}
          >
            N/A
          </Text>
        )
      ) : (
        <View style={{ marginBottom: 10 }} />
      )}
    </View>
  );
}

export default PDFSixBoxRow;
