import React, { useEffect, useState } from "react";
import colors from "constants/colors";
import LabelValueBox from "../components-pdf/pdf-label-value-box";
import LabelValueFlexBox from "../components-pdf/pdf-label-value-flex-box";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

export default function ProReportTop({ posReport }) {
  const styles = StyleSheet.create({
    reportRow: {
      flexDirection: "row",
      display: "flex",
      flex: 1,
      marginBottom: 8,
    },
    reportCol: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
      marginBottom: 10,
    },
  });

  return (
    <View
      style={{
        ...styles.reportRow,
        marginBottom: 20,
        height: 90,
        maxHeight: 90,
      }}
    >
      <View style={{ ...styles.reportRow, marginRight: 20 }}>
        <LabelValueBox
          label="Future Position"
          value={posReport.roleFuturePosition}
        />
        <LabelValueBox label="Round" value={posReport.round} />
      </View>

      <View style={styles.reportCol}>
        <View style={{ ...styles.reportRow, margin: 0, padding: 0 }}>
          <LabelValueFlexBox
            labels={["Games", "Innings", "ABs"]}
            values={[posReport.games, posReport.innings, posReport.aBs]}
          />
        </View>
        <View style={{ ...styles.reportRow, padding: 0, marginTop: 30 }}>
          <LabelValueFlexBox
            labels={["Raw", "Adj.", "Current", "Future"]}
            values={[
              posReport.raw,
              posReport.adj,
              posReport.current,
              posReport.future,
            ]}
          />
        </View>
      </View>
    </View>
  );
}
