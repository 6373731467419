import React, { useState } from "react";
import colors from "constants/colors";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

function PDFLabelValueBox({ label, value }) {
  return (
    <View
      style={{
        borderColor: colors.softDarkText,
        borderWidth: 1,

        borderStyle: "solid",

        flex: 1,
        height: 90,
      }}
    >
      <View
        style={{
          borderBottomColor: colors.softDarkText,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          backgroundColor: colors.mediumGray,
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 10 }}>{label}</Text>
      </View>
      <View
        style={{
          padding: 14,
        }}
      >
        <Text style={{ fontWeight: 800, fontSize: 24, textAlign: "center" }}>
          {value}
        </Text>
      </View>
    </View>
  );
}

export default PDFLabelValueBox;
