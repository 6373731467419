import React, { useEffect, useState } from "react";

import { Provider } from "react-redux";
import { createHashHistory } from "history";
import firebase from "firebase";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import createStore from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import ScoutRouter from "routing/ScoutRouter";
import colors from "constants/colors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const history = createHashHistory();

const initialState = {};

const { store, persistor } = createStore(initialState, history);

const rfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
};

const contextClass = {
  success: "bg-white-600",
  error: "bg-blue-600",
  info: "bg-blue-600",
  warning: "bg-blue-600",
  default: "bg-blue-600",
  dark: "bg-dark-600",
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={rfConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}
        >
          <ScoutRouter />

          <ToastContainer
            //@ts-ignore
            toastClassName={({ type }) =>
              contextClass[type || "default"] +
              " flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
            }
            draggable={false}
            autoClose={false}
            bodyClassName={() => "text-sm font-white font-med p-3"}
          />
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
