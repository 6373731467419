import { ALL_CONFERENCES } from "constants/conferences";
import colors from "constants/colors";
import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const ConferenceDropwdown = ({ selectItem, allowAll }) => {
  const onChange = (item) => {
    selectItem([item.value]);
  };

  return (
    <Select
      options={ALL_CONFERENCES.filter((item) =>
        allowAll ? true : item !== "ALL CONFERENCES"
      ).map((item) => ({ value: item, label: item }))}
      isMulti={false}
      isClearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder="Select conference..."
    />
  );
};

export default ConferenceDropwdown;
