import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import CreateRosterPlayerModal from "modals/create-roster-player-modal";
import { Player } from "models/Player";
import { EMPTY_QUERY } from "models/Query";
import ResultItem from "pages/search-page/components/result-item";
import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { IoIosAddCircle } from "react-icons/io";
import { useFirestore } from "react-redux-firebase";
import { PlayerService } from "services/PlayerService";
import { QueryHelper } from "services/QueryHelper";
import CTAButton from "shared/cta-button";
import PlayerAvatar from "shared/player-avatar";
import ScoutTitle from "shared/scout-title";
import UnderlineButton from "shared/underline-button";
import { CollegePlayerIcon } from "components/smart-icons";

function AddToRoster({
  sortedProspects,
  setMode,
}: {
  sortedProspects: Player[];
  setMode: any;
}) {
  const me = useMe();
  const [didFetchReccs, setDidFetchReccs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = React.useState("");
  const [options, setOptions] = useState([]);
  const [currentReccs, setCurrentReccs] = useState([]);
  const [committed, setCommited] = useState([]);
  const firestore = useFirestore();
  const [added, setAdded] = useState([]);
  const [showManualCreate, setShowManualCreate] = useState(false);

  useEffect(() => {
    //@ts-ignore
    let college = me && me.college ? me.college : null;

    if (college && !didFetchReccs) {
      setDidFetchReccs(true);
      fetchCommitedReccs(college);
      fetchActiveReccs(college);
    }
  }, [me]);

  console.log(
    "roster",
    (sortedProspects || []).map((pl) => pl.external_id)
  );

  const addToRoster = async (player) => {
    console.log("addToRoster");
    if (
      player &&
      !(sortedProspects || [])
        .map((pl) => pl.external_id)
        .includes(player.external_id)
    ) {
      console.log("do add", player.external_id);
      firestore
        .collection("users")
        .doc(me.id)
        .collection("roster")
        .doc(player.external_id)
        .set({
          ...player,
          scholarshipByYear: {},
          financialAidByYear: {},
          rosterNumber: sortedProspects.length + 1,
        });
    } else {
      console.log("tara here not in prospects");
    }
  };

  const fetchCommitedReccs = async (college: string) => {
    let commitmentQuery = { ...EMPTY_QUERY };
    commitmentQuery.commitment = college;
    let url = QueryHelper.urlForQuery(commitmentQuery);
    QueryHelper.getAllForURL(url).then((commited) => {
      setCommited([
        ...commited.data.filter(
          (item) =>
            !(sortedProspects || [])
              .map((pl) => pl.external_id)
              .includes(item.external_id)
        ),
      ]);
    });
  };

  const fetchActiveReccs = async (college: string) => {
    let activeQuery = { ...EMPTY_QUERY };
    activeQuery.asCollege = true;
    activeQuery.university = college;
    let activeUrl = QueryHelper.urlForQuery(activeQuery);
    QueryHelper.getAllForURL(activeUrl).then((active) => {
      setCurrentReccs([
        ...active.data.filter(
          (item) =>
            !(sortedProspects || [])
              .map((pl) => pl.external_id)
              .includes(item.external_id)
        ),
      ]);
    });
  };

  const getOptionPosition = (option: any) => {
    if (option.college) {
      return option.position ? ` (${option.position})` : "";
    }
    return option.primary_position ? ` (${option.primary_position})` : "";
  };

  const handleSearch = async (query) => {
    setIsLoading(true);
    setSearch(query);

    let url = QueryHelper.urlForNameAutocomplete(query, "name", false);
    let res = await QueryHelper.fetchDataForURLwithCount(url);

    let url2 = QueryHelper.urlForNameAutocomplete(query, "name", true);
    let res2 = await QueryHelper.fetchDataForURLwithCount(url2);

    if (res && res.data) {
      let resItems = res.data;

      if (res2 && res2.data) {
        resItems = [...resItems, ...res2.data];
      }

      resItems = resItems.filter((item) =>
        PlayerService.getName(item)
          // .slice(0, query.length)
          .toLowerCase()
          .includes(query.toLowerCase())
      );

      resItems.sort(function (a, b) {
        if (a.score && b.score) {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
        }
        if (a.name && b.name) {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
        }
        return 0;
      });

      setOptions(
        resItems
          .map((item) => ({
            ...item,
            id: item.external_id,
            name: PlayerService.getName(item),
          }))
          .filter((item) => item.name && item.name !== undefined)
      );
      setIsLoading(false);
    }
  };

  const renderDropdown = () => {
    return (
      <div style={{ flex: 1, marginLeft: 20, marginRight: 20 }}>
        <AsyncTypeahead
          //@ts-ignore
          style={{ flex: 1 }}
          filterBy={() => true}
          isLoading={isLoading}
          labelKey="name"
          inputProps={{
            name: "search",
          }}
          minLength={2}
          onSearch={handleSearch}
          selected={[]}
          options={options}
          onChange={(items) => {
            if (items.length > 0) {
              setAdded([...added, items[0]]);
              addToRoster(items[0]);
            }
          }}
          placeholder="Name..."
          renderMenuItemChildren={(option, props) => {
            return (
              <div
                {...props}
                style={{
                  marginLeft: -8,

                  display: "flex",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PlayerAvatar
                      player={option}
                      blueBackground={true}
                      containerStyles={{ marginRight: 6 }}
                    />
                    {option.name}
                    {getOptionPosition(option)}
                    {option.college ? (
                      <CollegePlayerIcon
                        player={option}
                        style={{ marginLeft: 4 }}
                      />
                    ) : (
                      <div />
                    )}
                  </span>
                  <IoIosAddCircle
                    color={colors.accent}
                    style={{ width: 26, height: 26 }}
                  />
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: "90vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 12,
          backgroundColor: colors.lightBlue,
          zIndex: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
          }}
        >
          <ScoutTitle
            text="ADD PLAYERS TO ROSTER:"
            withHelp={false}
            light={true}
            onClick={() => {}}
          />

          {renderDropdown()}
          <div style={{ marginLeft: 12 }}>
            <UnderlineButton
              text="manually create player"
              color={"#fff"}
              onClick={() => setShowManualCreate(true)}
            />
          </div>
          <div style={{ flex: 1 }} />

          <div style={{ marginRight: 12 }}>
            <CTAButton
              text={"DONE"}
              styles={{ backgroundColor: colors.accent }}
              onClick={() => {
                setMode("view");
              }}
            />
          </div>
        </div>
      </div>
      {added.length > 0 && (
        <div>
          <ScoutTitle
            text="Recently Added:"
            withHelp={false}
            light={false}
            onClick={() => {}}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2rem",
              marginRight: "2rem",
              overflow: "hidden",
            }}
          >
            {(added || []).map((item, index) => (
              <ResultItem
                addToRoster={true}
                disabledMode={"none"}
                player={item}
                key={index}
                index={index}
                shouldLoad={false}
                onlyCollege={false}
                onAddToRoster={(pl) => addToRoster(pl)}
                onRemoveFromRoster={(pl) => {
                  firestore
                    .collection("users")
                    .doc(me.id)
                    .collection("roster")
                    .doc(pl.external_id)
                    .delete();
                }}
                activeRosterIds={(sortedProspects || []).map(
                  (pros) => pros.external_id
                )}
              />
            ))}
          </div>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <ScoutTitle
            text="Current Players:"
            withHelp={false}
            light={false}
            onClick={() => {}}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2rem",
              marginRight: "2rem",
              overflow: "hidden",
            }}
          >
            {(currentReccs || []).map((item, index) => (
              <ResultItem
                addToRoster={true}
                disabledMode={"none"}
                player={item}
                key={index}
                index={index}
                shouldLoad={false}
                onlyCollege={false}
                onAddToRoster={(pl) => addToRoster(pl)}
                onRemoveFromRoster={(pl) => {
                  firestore
                    .collection("users")
                    .doc(me.id)
                    .collection("roster")
                    .doc(pl.external_id)
                    .delete();
                }}
                activeRosterIds={(sortedProspects || []).map(
                  (pros) => pros.external_id
                )}
              />
            ))}
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <ScoutTitle
            text="Commited Players:"
            withHelp={false}
            light={false}
            onClick={() => {}}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2rem",
              marginRight: "2rem",
              overflow: "hidden",
            }}
          >
            {(committed || []).map((item, index) => (
              <ResultItem
                addToRoster={true}
                disabledMode={"none"}
                player={item}
                key={index}
                index={index}
                shouldLoad={false}
                onlyCollege={false}
                onAddToRoster={(pl) => addToRoster(pl)}
                onRemoveFromRoster={(pl) => {
                  firestore
                    .collection("users")
                    .doc(me.id)
                    .collection("roster")
                    .doc(pl.external_id)
                    .delete();
                }}
                activeRosterIds={(sortedProspects || []).map(
                  (pros) => pros.external_id
                )}
              />
            ))}
          </div>
        </div>
      </div>

      <CreateRosterPlayerModal
        show={showManualCreate}
        onHide={(pl) => {
          if (pl) {
            setAdded([...added, pl]);
          }
          setShowManualCreate(false);
        }}
        sortedProspects={sortedProspects}
      />
    </div>
  );
}

export default AddToRoster;
