import colors from "constants/colors";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { PlayerService } from "services/PlayerService";
import LoadingView from "shared/loading-view";
import AbsoluteScholarshipsHeader from "./components/absolute-scholarships-header";
import AbsoluteYearPicker from "./components/absolute-year-picker";
import AddToRoster from "./components/add-to-roster";
import Breakdown from "./components/breakdown";
import { PlayersList } from "./components/players-list";
import ScholarshipHeader from "./components/scholarship-header";
import ScholarshipsDropdown from "dropdowns/scholarships-dropdown";
import { useMe } from "@tara-wilson/mcc-lib";
import "./scholarships-page.scss";

function ScholarshipsPage(props: any) {
  const me = useMe();
  const myRoster = useSelector((state) => state.firestore.data.myRoster);
  const [year, setYear] = useState(
    `${moment().subtract(5, "month").format("YYYY")}`
  );
  const _sharedScholarships = useSelector(
    (state) => state.firestore.data.sharedScholarships
  );
  const sharedScholarships = Object.keys(_sharedScholarships || []).map((key) =>
    _sharedScholarships ? { ..._sharedScholarships[key], id: key } : null
  );
  const [scholarshipBoard, setScholarshipBoard] = useState(null);

  const [mode, setMode] = useState<
    "view" | "edit" | "breakdown_view" | "breakdown_edit" | "add"
  >("view");

  useEffect(() => {
    if (!scholarshipBoard) {
      setScholarshipBoard(me.id);
    }
  }, [me]);

  useFirestoreConnect([
    {
      collection: "users",
      doc: scholarshipBoard || "",
      subcollections: scholarshipBoard && [{ collection: "roster" }],
      storeAs: "myRoster",
    },
    {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "sharedScholarships" }],
      storeAs: "sharedScholarships",
    },
  ]);

  const sortedProspects = (includeRedshirt?: boolean) => {
    if (!isLoaded(myRoster)) {
      return [];
    }

    let prospects = myRoster
      ? Object.keys(myRoster).map((key) =>
          myRoster ? { ...myRoster[key], id: key } : null
        )
      : [];

    prospects.sort(function (a, b) {
      if (a.rosterNumber > b.rosterNumber) return 1;
      if (a.rosterNumber < b.rosterNumber) return -1;
      return 0;
    });

    if (mode != "add") {
      prospects = prospects
        .filter((item) => {
          if (item) {
            let startYear = PlayerService.getStartYear(item);
            let endYear = PlayerService.getEndYear(item);
            if (startYear <= parseInt(year) && endYear >= parseInt(year)) {
              return item;
            }
          }
          return null;
        })
        .filter((item) => item !== null);
    }

    if (!includeRedshirt && mode !== "edit") {
      prospects = prospects.filter((item) => {
        if (
          item &&
          ((item.redShirt && item.redShirt !== "none") ||
            (item.redShirtYears || []).length > 0)
        ) {
          if (
            parseInt(item.redShirtYear) === parseInt(year) ||
            (item.redShirtYears || []).includes(parseInt(year))
          ) {
            return null;
          }
        }
        return item;
      });
    }

    let final = [];

    prospects.forEach((pros, index) => {
      final.push({ ...pros, rosterNumber: index + 1 });
    });
    return final;
  };

  const getPlayer = (position: string, withAlt: boolean) => {
    if (position === "P") {
      return sortedProspects().filter(
        (item) =>
          item.primary_position === "LHP" ||
          item.primary_position === "RHP" ||
          item.primary_position === "Pitcher" ||
          item.primary_position === "P" ||
          item.position === "LHP" ||
          item.position === "RHP" ||
          item.position === "Pitcher" ||
          item.position === "P" ||
          (withAlt && item.alt_position === "RHP") ||
          (withAlt && item.alt_position === "LHP")
      );
    }
    if (position === "SS") {
      return sortedProspects().filter(
        (item) =>
          item.primary_position === "SS" ||
          item.primary_position === "MIF" ||
          (withAlt && item.alt_position === "MIF") ||
          (withAlt && item.alt_position === "SS")
      );
    }
    if (position === "1B") {
      return sortedProspects().filter(
        (item) =>
          item.primary_position === "1B" ||
          item.position === "First Base" ||
          (withAlt && item.alt_position === "1B")
      );
    }
    if (position === "LF") {
      return sortedProspects().filter(
        (item) => item.primary_position === "LF" || item.position === "LF"
      );
    }
    if (position === "CF") {
      return sortedProspects().filter(
        (item) => item.position === "CF" || item.primary_position === "CF"
      );
    }
    if (position === "RF") {
      return sortedProspects().filter(
        (item) => item.primary_position === "RF" || item.position === "RF"
      );
    }
    if (position === "C") {
      return sortedProspects().filter(
        (item) =>
          item.primary_position === "C" ||
          item.position === "Catcher" ||
          (withAlt && item.alt_position === "C")
      );
    }
    let pros = sortedProspects().filter(
      (item) =>
        item.primary_position === position ||
        item.position === position ||
        (withAlt && item.alt_position === position)
    );

    return pros;
  };

  if (!isLoaded(myRoster)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.white,
          marginLeft: "1rem",
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 12,
          paddingBottom: 200,
        }}
      >
        <LoadingView />
      </div>
    );
  }

  if (mode === "add") {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          backgroundColor: colors.white,
          marginRight: "1rem",
        }}
      >
        <AddToRoster sortedProspects={sortedProspects()} setMode={setMode} />
      </div>
    );
  }

  if (mode === "breakdown_view" || mode === "breakdown_edit") {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          backgroundColor: colors.white,
          marginRight: "1rem",
        }}
      >
        <ScholarshipHeader
          year={year}
          mode={mode}
          setMode={setMode}
          scholarshipBoard={scholarshipBoard}
          setScholarshipBoard={setScholarshipBoard}
        />

        <Breakdown
          year={year}
          sortedProspects={sortedProspects()}
          mode={mode}
        />
      </div>
    );
  }

  const unPositionedPlayers = () => {
    let all = [
      ...getPlayer("P", true),
      ...getPlayer("LF", true),
      ...getPlayer("SS", true),
      ...getPlayer("3B", true),
      ...getPlayer("CF", true),
      ...getPlayer("C", true),
      ...getPlayer("RF", true),
      ...getPlayer("2B", true),
      ...getPlayer("1B", true),
    ].map((item) => item.external_id);
    let un = sortedProspects().filter(
      (item) => !all.includes(item.external_id)
    );
    //console.log("un", un);
    return un;
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "green",
        minHeight: "100vh",
        marginLeft: "1rem",
        marginRight: "1rem",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <ScholarshipHeader
          year={year}
          mode={mode}
          setMode={setMode}
          scholarshipBoard={scholarshipBoard}
          setScholarshipBoard={setScholarshipBoard}
        />
      </div>

      <div style={{ zIndex: 99 }}>
        <div id="field" className="mowed-grass"></div>
        <div id="in-field"></div>
        <div id="in-field-grass" className="mowed-grass"></div>
        <div id="batting-circle"></div>
        <div id="base-lines"></div>
        <div id="first-base" className="base">
          <div className="pulse"></div>
          <div className="action-area"></div>
        </div>
        <div id="second-base" className="base">
          <div className="pulse"></div>
          <div className="action-area"></div>
        </div>
        <div id="thrid-base" className="base">
          <div className="pulse"></div>
          <div
            className="action-area undo-trans"
            style={{
              marginTop: -60,
              marginLeft: -140,
              width: mode === "edit" ? 300 : 270,
            }}
          ></div>
        </div>
        <div id="home-plate">
          <div className="action-area"></div>
        </div>
        <div className="home-plate-pulse pulse"></div>

        <div id="pitchers-mound"></div>
        <div id="pitchers-plate"></div>

        <div id="third-circle" className="half-circle"></div>
        <div id="batters-box-right" className="batters-box"></div>
        <div id="batters-box-left" className="batters-box"></div>
      </div>

      <div
        style={{
          position: "absolute",
          top: 100,

          height: 750,
          //zIndex: 100,
        }}
        className="players-overlay"
      >
        <DndProvider backend={HTML5Backend}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                marginRight: "3vw",
                display: "flex",
                flexDirection: "column",

                marginTop: mode == "edit" ? 250 : 200,
                overflow: "hidden",
                clear: "both",
              }}
            >
              <PlayersList
                year={year}
                mode={mode}
                players={getPlayer("P", true)}
                playerKind={"P"}
              />

              {unPositionedPlayers().length > 0 && (
                <PlayersList
                  year={year}
                  mode={mode}
                  players={unPositionedPlayers()}
                  playerKind={"POSITION NOT SET"}
                />
              )}
            </div>
            <div
              style={{
                marginRight: "3vw",
                display: "flex",
                flexDirection: "column",

                marginTop: 100,

                clear: "both",
                minHeight: "70vh",
              }}
            >
              <PlayersList
                year={year}
                players={getPlayer("LF", true)}
                mode={mode}
                playerKind={"LF"}
              />

              <div
                style={{
                  marginRight: -140,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PlayersList
                  year={year}
                  mode={mode}
                  players={getPlayer("SS", true)}
                  playerKind={"SS"}
                />
              </div>

              <PlayersList
                year={year}
                mode={mode}
                players={getPlayer("3B", true)}
                playerKind={"3B"}
              />
            </div>
            <div
              style={{
                marginRight: "3vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
                clear: "both",
              }}
            >
              <PlayersList
                year={year}
                players={getPlayer("CF", true)}
                mode={mode}
                playerKind={"CF"}
              />

              <PlayersList
                year={year}
                mode={mode}
                players={getPlayer("C", true)}
                playerKind={"C"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                clear: "both",
                marginTop: 100,
              }}
            >
              <PlayersList
                year={year}
                players={getPlayer("RF", true)}
                mode={mode}
                playerKind={"RF"}
              />

              <div
                style={{
                  marginLeft: -120,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <PlayersList
                  year={year}
                  mode={mode}
                  players={getPlayer("2B", true)}
                  playerKind={"2B"}
                />
              </div>
              <PlayersList
                year={year}
                mode={mode}
                players={getPlayer("1B", true)}
                playerKind={"1B"}
              />
            </div>
          </div>
        </DndProvider>
      </div>
      <div
        style={{
          maxWidth: 250,
          marginLeft: 20,
          zIndex: 1000,
          marginTop: -130,
          marginBottom: 20,
        }}
      >
        {/* <div
            style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 12,
            marginRight: 20,
            marginBottom: 20,
            }}
            >
            <ScholarshipsDropdown boards={sharedScholarships} selectBoard={() => {}} />
            </div> */}
        <AbsoluteScholarshipsHeader
          year={year}
          mode={mode}
          sortedProspects={sortedProspects()}
        />
        <AbsoluteYearPicker
          year={year}
          setYear={setYear}
          sortedProspects={sortedProspects()}
          mode={mode}
        />
      </div>
    </div>
  );
}

export default ScholarshipsPage;
