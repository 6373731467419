import React, { useEffect, useState } from "react";
import colors from "constants/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import { PlayerService } from "services/PlayerService";
import { Player } from "models/Player";
import { CollegePlayer } from "models/CollegePlayer";

function PdfDocBigBoard({ boardName, boardYear, fullScreen, sortedProspects }) {
  let POSITIONS = ["RHP", "LHP", "1B", "2B", "SS", "3B", "C", "OF", "MIF"];
  const [totalRows, setTotalRows] = useState(-1);
  const [activePositionsOne, setActivePositionsOne] = useState([]);
  const [activePositionsTwo, setActivePositionsTwo] = useState([]);

  useEffect(() => {
    Font.register({
      family: "Raleway",
      fonts: [
        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf",
          fontWeight: "bold",
        },

        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf",
          fontWeight: "normal",
          fontStyle: "normal",
        },

        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf",
          fontStyle: "italic",
        },
      ],
    });
    Font.register({
      family: "RalewayBold",
      fonts: [
        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf",
          fontWeight: 800,
          fontStyle: "normal",
        },
      ],
    });
    Font.registerHyphenationCallback((word) => [word]);
  }, []);

  useEffect(() => {
    if (totalRows < 0) {
      calculateTotalRows();
    }
  }, [sortedProspects]);

  const calculateTotalRows = () => {
    let max = 0;
    let allPos = [];
    POSITIONS.forEach((pos) => {
      let players = playersForPosition(pos);
      if (players.length > 0) {
        allPos.push(pos);
      }
      if (players.length > max) {
        max = players.length;
      }
    });
    if (allPos.length > 6) {
      setActivePositionsOne(allPos.slice(0, 5));
      setActivePositionsTwo(allPos.slice(5, allPos.length));
    } else {
      setActivePositionsOne(allPos);
    }

    setTotalRows(max);
  };

  const playersForPosition = (pos: string) => {
    let players = sortedProspects.filter((item) => {
      if ((item.bbPositions || []).length) {
        return item.bbPositions.includes(pos);
      } else {
        return item.primary_position === pos || item.alt_position === pos || item.position === pos || item.POS === pos
      }
    });
    for (var i = players.length; i < totalRows; i++) {
      players.push(null);
    }
    console.log(players.map(item => item?.university))
    return players;
  };

  const ranks = () => {
    let ranks = [];
    for (var i = 0; i < totalRows; i++) {
      ranks.push(i + 1);
    }
    return ranks;
  };

  const MyDocument = () => (
    <Document>
      <Page
        size="A4"
        orientation={"landscape"}
        style={{
          fontFamily: "Raleway",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
          marginLeft: 10,
          paddingRight: 30,
          marginTop: 10,
          marginRight: 10,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: "RalewayBold",
            textDecoration: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: colors.accent,
            color: colors.accent,
            fontWeight: 800,
          }}
        >
          {boardName} ({parseInt(boardYear) ? `${boardYear}-${parseInt(boardYear) + 1}` : boardYear})
        </Text>
        <Text style={{ marginBottom: 20, fontSize: 10 }}>
          {moment().format(" MM/DD/YYYY")}
        </Text>

        <View
          style={{
            flexDirection: "row",
            display: "flex",
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              display: "flex",

              flex: 0.3,
            }}
          >
            <View
              style={{
                flex: 1,
                borderColor: colors.softDarkText,
                padding: 4,
                borderLeftWidth: 1,

                borderBottomWidth: 1,
                borderTopWidth: 1,
                borderStyle: "solid",
                backgroundColor: colors.lightBlue,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 800,
                  fontSize: 10,
                  color: colors.white,
                }}
              >
                {" "}
              </Text>
            </View>

            {ranks().map((item, index) => (
              <View
                key={index}
                style={{
                  flex: 1,
                  borderColor: colors.softDarkText,
                  borderLeftWidth: 1,

                  borderBottomWidth: 1,
                  borderRightWidth: 1,
                  padding: 4,

                  borderStyle: "solid",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: 10,
                    color: colors.softDarkText,
                  }}
                >
                  {`#${item}`}
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: 10,
                    color: colors.white,
                  }}
                >
                  {" "}
                </Text>
              </View>
            ))}
          </View>
          {activePositionsOne.map((pos, index) => (
            <View
              style={{
                flexDirection: "column",
                display: "flex",

                flex: 1,
              }}
            >
              <View
                key={index}
                style={{
                  flex: 1,
                  borderColor: colors.softDarkText,
                  borderLeftWidth: 1,

                  borderBottomWidth: 1,
                  borderTopWidth: 1,
                  padding: 4,

                  borderStyle: "solid",
                  backgroundColor: colors.lightBlue,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: 10,
                    color: colors.white,
                  }}
                >
                  {pos}
                </Text>
              </View>
              {playersForPosition(pos).map((player: Player|CollegePlayer , plInd) => (
                <View
                  key={plInd}
                  style={
                    plInd === totalRows - 1
                      ? {
                          flex: 1,
                          borderColor: colors.softDarkText,
                          borderBottomWidth: 1,
                          padding: 4,

                          borderStyle: "solid",
                        }
                      : {
                          flex: 1,

                          padding: 4,
                        }
                  }
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 10,
                      color: "#000",
                      fontFamily: "RalewayBold",
                    }}
                  >
                    {player
                      ? `${PlayerService.getName(player)} (${
                          PlayerService.getGraduatingYear(player)
                        })`
                      : ""}
                  </Text>
                  {player && (
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: 800,
                        fontSize: 10,
                        color: colors.softDarkText,
                      }}
                    >
                      {player.bats || "NA"}/{player.throws || "NA"}{" "}
                      {player.college ? (
                        (player as CollegePlayer).university
                      ) : player && (
                        `${(player as Player).high_school}, ${(player as Player).state}`
                      )}
                    </Text>
                  )}
                </View>
              ))}
            </View>
          ))}

          <View
            style={{
              flexDirection: "column",
              display: "flex",

              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                borderColor: colors.softDarkText,
                borderLeftWidth: 1,

                borderBottomWidth: 1,
                borderTopWidth: 1,
                padding: 4,

                borderStyle: "solid",
                backgroundColor: colors.lightBlue,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: 800,
                  fontSize: 10,
                  color: colors.white,
                }}
              >
                {"Rank"}
              </Text>
            </View>

            {ranks().map((item, index) => (
              <View
                key={index}
                style={{
                  flex: 1,
                  borderColor: colors.softDarkText,
                  borderLeftWidth: 1,

                  borderBottomWidth: index === totalRows - 1 ? 1 : 0,
                  borderRightWidth: 1,
                  padding: 4,

                  borderStyle: "solid",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: 10,
                    color: colors.white,
                  }}
                >
                  {" "}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ flex: 1 }} />
      </Page>
      {activePositionsTwo.length > 0 && (
        <Page
          size="A4"
          orientation={"landscape"}
          style={{
            fontFamily: "Raleway",
            flexDirection: "column",
            backgroundColor: "#FFF",
            padding: 20,
            marginLeft: 10,
            paddingRight: 30,
            marginTop: 10,
            marginRight: 10,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "RalewayBold",
              textDecoration: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: colors.accent,
              color: colors.accent,
              fontWeight: 800,
            }}
          >
            {`${boardName} (${boardYear})`}
          </Text>
          <Text style={{ marginBottom: 20, fontSize: 10 }}>
            {moment().format(" MM/DD/YYYY")}
          </Text>

          <View
            style={{
              flexDirection: "row",
              display: "flex",
              flex: 1,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                display: "flex",

                flex: 0.3,
              }}
            >
              <View
                style={{
                  flex: 1,
                  borderColor: colors.softDarkText,
                  padding: 4,
                  borderLeftWidth: 1,

                  borderBottomWidth: 1,
                  borderTopWidth: 1,
                  borderStyle: "solid",
                  backgroundColor: colors.lightBlue,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: 10,
                    color: colors.white,
                  }}
                >
                  {" "}
                </Text>
              </View>

              {ranks().map((item, index) => (
                <View
                  key={index}
                  style={{
                    flex: 1,
                    borderColor: colors.softDarkText,
                    borderLeftWidth: 1,

                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    padding: 4,

                    borderStyle: "solid",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 10,
                      color: colors.softDarkText,
                    }}
                  >
                    {`#${item}`}
                  </Text>
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 10,
                      color: colors.white,
                    }}
                  >
                    {" "}
                  </Text>
                </View>
              ))}
            </View>
            {activePositionsTwo.map((pos, index) => (
              <View
                style={{
                  flexDirection: "column",
                  display: "flex",

                  flex: 1,
                }}
              >
                <View
                  key={index}
                  style={{
                    flex: 1,
                    borderColor: colors.softDarkText,
                    borderLeftWidth: 1,

                    borderBottomWidth: 1,
                    borderTopWidth: 1,
                    padding: 4,

                    borderStyle: "solid",
                    backgroundColor: colors.lightBlue,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 10,
                      color: colors.white,
                    }}
                  >
                    {pos}
                  </Text>
                </View>
                {playersForPosition(pos).map((player, plInd) => (
                  <View
                    key={plInd}
                    style={
                      plInd === totalRows - 1
                        ? {
                            flex: 1,
                            borderColor: colors.softDarkText,
                            borderBottomWidth: 1,
                            padding: 4,

                            borderStyle: "solid",
                          }
                        : {
                            flex: 1,

                            padding: 4,
                          }
                    }
                  >
                    <View>
                      <Text
                        style={{
                          textAlign: "center",
                          fontWeight: 800,
                          fontSize: 10,
                          color: "#000",
                          fontFamily: "RalewayBold",
                        }}
                      >
                        {player
                          ? `${PlayerService.getName(player)} (${
                              PlayerService.getGraduatingYear(player)
                            })`
                          : ""}
                      </Text>
                    </View>
                    <View>
                      {player && (
                        <Text
                          style={{
                            textAlign: "center",
                            fontWeight: 800,
                            fontSize: 10,
                            color: colors.softDarkText,
                          }}
                        >
                          {player.bats || "NA"}/{player.throws || "NA"}{" "}
                          {player.college ? (
                            (player as CollegePlayer).university
                          ) : player && (
                            `${(player as Player).high_school}, ${(player as Player).state}`
                          )}
                      </Text>
                      )}
                    </View>
                  </View>
                ))}
              </View>
            ))}

            <View
              style={{
                flexDirection: "column",
                display: "flex",

                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  borderColor: colors.softDarkText,
                  borderLeftWidth: 1,

                  borderBottomWidth: 1,
                  borderTopWidth: 1,
                  padding: 4,

                  borderStyle: "solid",
                  backgroundColor: colors.lightBlue,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    fontSize: 10,
                    color: colors.white,
                  }}
                >
                  {"Rank"}
                </Text>
              </View>

              {ranks().map((item, index) => (
                <View
                  key={index}
                  style={{
                    flex: 1,
                    borderColor: colors.softDarkText,
                    borderLeftWidth: 1,

                    borderBottomWidth: index === totalRows - 1 ? 1 : 0,
                    borderRightWidth: 1,
                    padding: 4,

                    borderStyle: "solid",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 10,
                      color: colors.white,
                    }}
                  >
                    {" "}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={{ flex: 1 }} />
        </Page>
      )}
    </Document>
  );

  return totalRows > 0 ? (
    <PDFViewer
      style={
        fullScreen
          ? { minWidth: "100vw", minHeight: "100vh" }
          : {
              minWidth: "90vw",
              minHeight: "110vh",
            }
      }
    >
      <MyDocument />
    </PDFViewer>
  ) : (
    <div />
  );
}

export default PdfDocBigBoard;
