import React, { useState } from "react";
import colors from "constants/colors";
import "react-datepicker/dist/react-datepicker.css";
import { FBStorage } from "../../../store/store";
import { Button, Spinner } from "react-bootstrap";
import {
  AiOutlinePlus,
  AiFillCloseCircle,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import MP4Box from "mp4box";
import fileToArrayBuffer from "file-to-array-buffer";
import { toast } from "react-toastify";

function VideosPicker({ posReport, setPosReport }) {
  const [loading, setLoading] = useState(false);
  const handleImageAsFile = (e) => {
    setLoading(true);
    const image = e.target.files[0];
    var mp4boxfile = MP4Box.createFile();
    mp4boxfile.onReady = function (info) {
      console.log("Received File Information", info.tracks.length);
      if (info.tracks.length === 1) {
        handleFireBaseUpload(image);
      } else {
        setLoading(false);
        toast.dark(`This type of video is not supported.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    };

    fileToArrayBuffer(image).then((data) => {
      //@ts-ignore
      data.fileStart = 0;
      mp4boxfile.appendBuffer(data);
    });
  };

  const handleFireBaseUpload = (imageAsFile) => {
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }

    const uploadTask = FBStorage.ref(`/images/${imageAsFile.name}`).put(
      imageAsFile
    );
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        FBStorage.ref("images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setPosReport({
              ...posReport,
              videos: [...(posReport.videos || []), fireBaseUrl],
            });

            setTimeout((_) => {
              setLoading(false);
            }, 500);
          });
      }
    );
  };

  return (
    <div className="report-row">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>Videos</p>
        <div style={{ flexDirection: "row", display: "flex" }}>
          {(posReport.videos || []).map((item) => (
            <div style={{ marginRight: 0 }}>
              <div
                className="custom-file-upload"
                style={{
                  backgroundColor: colors.accent,
                  color: "white",
                  fontSize: 35,
                  display: "inline-block",
                  marginRight: 0,
                }}
              >
                <AiOutlineVideoCamera
                  style={{ marginLeft: 20, marginTop: 20 }}
                />
              </div>
              <Button
                onClick={() =>
                  setPosReport({
                    ...posReport,
                    videos: posReport.videos.filter((pic) => pic !== item),
                  })
                }
                variant="info"
                style={{
                  color: colors.red,
                  backgroundColor: "#fff",
                  position: "relative",
                  border: "none",
                  boxShadow: "none",
                  top: -28,
                  fontSize: 28,
                  height: 28,
                  width: 28,
                  padding: 0,
                  left: -16,
                  borderRadius: 16,
                }}
              >
                <AiFillCloseCircle style={{ marginTop: -18 }} />
              </Button>
            </div>
          ))}
          <form>
            <label className="custom-file-upload">
              <input type="file" onChange={handleImageAsFile} />
              {loading ? (
                <Spinner
                  animation="border"
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                />
              ) : (
                <AiOutlinePlus
                  style={{
                    width: 80,
                    height: 80,
                    padding: "15px 20px 20px 15px",
                  }}
                />
              )}
            </label>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VideosPicker;
