import React, { useState } from "react";
import colors from "constants/colors";
import "react-datepicker/dist/react-datepicker.css";
import { FBStorage } from "../../../store/store";
import { AiOutlinePlus, AiFillCloseCircle } from "react-icons/ai";
import { Button, Spinner } from "react-bootstrap";

function ImagesPicker({ posReport, setPosReport }) {
  const [loading, setLoading] = useState(false);
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    handleFireBaseUpload(image);
  };

  const handleFireBaseUpload = (imageAsFile) => {
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }

    const uploadTask = FBStorage.ref(`/images/${imageAsFile.name}`).put(
      imageAsFile
    );
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        FBStorage.ref("images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setPosReport({
              ...posReport,
              photos: [...(posReport.photos || []), fireBaseUrl],
            });

            setTimeout((_) => {
              setLoading(false);
            }, 500);
          });
      }
    );
  };

  return (
    <div className="report-row">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>Images</p>
        <div style={{ flexDirection: "row", display: "flex" }}>
          {(posReport.photos || []).map((item) => (
            <div style={{ marginRight: 5 }}>
              <img
                src={item}
                className="custom-file-upload"
                style={{
                  backgroundColor: colors.accent,
                  border: "none",
                  marginRight: 0,
                }}
              />
              <Button
                onClick={() =>
                  setPosReport({
                    ...posReport,
                    photos: posReport.photos.filter((pic) => pic !== item),
                  })
                }
                variant="info"
                style={{
                  color: colors.red,
                  backgroundColor: "#fff",
                  position: "relative",
                  border: "none",
                  boxShadow: "none",
                  top: -28,
                  fontSize: 28,
                  height: 28,
                  width: 28,
                  padding: 0,
                  left: -16,
                  borderRadius: 16,
                }}
              >
                <AiFillCloseCircle style={{ marginTop: -18 }} />
              </Button>
            </div>
          ))}
          <form>
            <label className="custom-file-upload">
              <input type="file" onChange={handleImageAsFile} />
              {loading ? (
                <Spinner
                  animation="border"
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                />
              ) : (
                <AiOutlinePlus
                  style={{
                    width: 80,
                    height: 80,
                    padding: "15px 20px 20px 15px",
                  }}
                />
              )}
            </label>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ImagesPicker;
