import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import { Player } from "models/Player";
import { User } from "models/User";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import ScoutTitle from "shared/scout-title";
import { PlayerService } from "services/PlayerService";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Breakdown({
  sortedProspects,
  mode,
  year,
}: {
  sortedProspects: Player[];
  mode: "view" | "edit" | "breakdown_view" | "breakdown_edit" | "add";
  year: string;
}) {
  const me = useMe<User>();
  const previousMode = usePrevious(mode);
  const firestore = useFirestore();
  const [schAmount, setSchAmount] = useState(null);
  const [finAidAmount, setFinAidAmount] = useState(null);
  const [scholTotalPlayers, setScholTotalPlayers] = useState(null);
  const [numTotalPlayers, setNumTotalPlayers] = useState(null);

  useEffect(() => {
    if (mode === "breakdown_edit" && previousMode === "breakdown_view") {
      setSchAmount(me.totalScholarshipVal || "11.7");
      setFinAidAmount(me.totalFinAidVal || null);
      setScholTotalPlayers(me.scholarshipPlayersLimit || null);
      setNumTotalPlayers(me.rosterPlayersLimit || null);
    } else if (mode === "breakdown_view" && previousMode === "breakdown_edit") {
      saveAmounts();
    }
  }, [mode]);

  const saveAmounts = async () => {
    let finAid = null;
    if (!isNaN(parseFloat(finAidAmount))) {
      finAid = parseFloat(finAidAmount);
    }
    let sch = null;
    if (!isNaN(parseFloat(schAmount))) {
      sch = parseFloat(schAmount);
    }
    let totalSchPl = null;
    if (!isNaN(parseFloat(scholTotalPlayers))) {
      totalSchPl = parseFloat(scholTotalPlayers);
    }

    let totalPl = null;
    if (!isNaN(parseFloat(numTotalPlayers))) {
      totalPl = parseFloat(numTotalPlayers);
    }

    await firestore.collection("users").doc(me.id).update({
      totalScholarshipVal: sch,
      totalFinAidVal: finAid,
      scholarshipPlayersLimit: totalSchPl,
      rosterPlayersLimit: totalPl,
    });
  };

  const getPlayer = (position: string) => {
    if (position === "P") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "LHP" ||
          item.primary_position === "RHP" ||
          item.primary_position === "Pitcher" ||
          item.primary_position === "P" ||                
          item.position === "LHP" ||
          item.position === "RHP" ||
          item.position === "Pitcher" ||
          item.position === "P"
      );
    }
    if (position === "IF") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "1B" ||
          item.primary_position === "2B" ||
          item.primary_position === "3B" ||
          item.primary_position === "SS" ||
          item.primary_position === "MIF" ||
          item.primary_position === "Infield" ||
          item.primary_position === "IF" ||
          item.primary_position === "First Base" ||
          item.position === "1B" ||
          item.position === "2B" ||
          item.position === "3B" ||
          item.position === "SS" ||
          item.position === "MIF" ||
          item.position === "Infield" ||
          item.position === "IF" ||
          item.position === "First Base"
      );
    }
    if (position === "OF") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "OF" ||
          item.primary_position === "Outfield" ||
          item.primary_position === "LF" ||
          item.primary_position === "CF" ||
          item.primary_position === "RF" ||
          item.position === "OF" ||
          item.position === "Outfield" ||
          item.position === "LF" ||
          item.position === "CF" ||
          item.position === "RF"
      );
    }
    if (position === "C") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "C" ||
          item.primary_position === "Catcher" ||
          item.position === "C" ||
          item.position === "Catcher"
      );
    }
    let pros = sortedProspects.filter(
      (item) => item.primary_position === position || item.position === position
    );

    return pros;
  };

  const getPlayersWithScholarship = (
    position: string,
    kind: "both" | "schol" | "finAid"
  ) => {
    return (getPlayer(position) || [])
      .map((item) => ({
        ...item,
        totalVal: PlayerService.getScholarship(item, year, kind),
      }))
      .filter((item) => item.totalVal > 0);
  };

  const getScholarshipTotals = (
    position: string,
    kind: "both" | "schol" | "finAid"
  ) => {
    let pl = getPlayersWithScholarship(position, kind).map((item) => item.totalVal);
    let total = 0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getAllScholarshipTotal = (kind: "both" | "schol" | "finAid") => {
    let pl = (sortedProspects || [] || [])
      .map((item) => PlayerService.getScholarship(item, year, kind))
      .filter((item) => item > 0);
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          border: "1px solid #467DB3",
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val: string, bold?: boolean, limit?: number, red?: boolean) => {
    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: red ? colors.red : colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            fontWeight: bold ? "bold" : "normal",
          }}
        >
          <p>{val}</p>
          {limit && limit > 0 ? (
            <p
              style={{
                width: 90,
                marginRight: -90,
                fontWeight: "normal",
                textAlign: "center",
              }}
            >{`(Limit: ${limit})`}</p>
          ) : (
            <div />
          )}
        </div>
      </td>
    );
  };

  const tdTwoVal = (
    val: string,
    valTwo: string,
    bold?: boolean,
    red?: boolean
  ) => {
    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: red ? colors.red : colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            fontWeight: bold ? "bold" : "normal",
          }}
        >
          <p style={{ width: 60 }}>{val}</p>
          <p
            style={{
              width: 60,
              marginLeft: 20,
            }}
          >
            {valTwo}
          </p>
        </div>
      </td>
    );
  };

  const renderOverviewTable = () => {
    let totalLimit =
      (me.totalFinAidVal || 0) + (me.totalScholarshipVal || 11.7);

    let totalSch =
      getPlayersWithScholarship("P", "schol").length +
      getPlayersWithScholarship("OF", "schol").length +
      getPlayersWithScholarship("IF", "schol").length +
      getPlayersWithScholarship("C", "schol").length;

    let totalFin =
      getPlayersWithScholarship("P", "finAid").length +
      getPlayersWithScholarship("OF", "finAid").length +
      getPlayersWithScholarship("IF", "finAid").length +
      getPlayersWithScholarship("C", "finAid").length;

    return (
      <table
        style={{
          width: "100%",
          border: "1px solid #467DB3",
          backgroundColor: colors.white,
        }}
      >
        <tr>
          {th("Position")}
          {th("# Players")}
          {th("# On Scholarship (+ FA)")}
          {th(`Total Amount`)}
        </tr>
        <tr>
          {td("OF")}
          {td(`${(getPlayer("OF") || []).length}`)}
          {tdTwoVal(
            `${getPlayersWithScholarship("OF", "schol").length}`,
            `(+${getPlayersWithScholarship("OF", "finAid").length})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("OF", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("OF", "finAid").toFixed(2)})`
          )}
        </tr>
        <tr>
          {td("IF")}
          {td(`${(getPlayer("IF") || []).length}`)}
          {tdTwoVal(
            `${getPlayersWithScholarship("IF", "schol").length}`,
            `(+${getPlayersWithScholarship("IF", "finAid").length})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("IF", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("IF", "finAid").toFixed(2)})`
          )}
        </tr>
        <tr>
          {td("C")}
          {td(`${(getPlayer("C") || []).length}`)}
          {tdTwoVal(
            `${getPlayersWithScholarship("C", "schol").length}`,
            `(+${getPlayersWithScholarship("C", "finAid").length})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("C", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("C", "finAid").toFixed(2)})`
          )}
        </tr>
        <tr>
          {td("P")}
          {td(`${(getPlayer("P") || []).length}`)}
          {tdTwoVal(
            `${getPlayersWithScholarship("P", "schol").length}`,
            `(+${getPlayersWithScholarship("P", "finAid").length})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("P", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("P", "finAid").toFixed(2)})`
          )}
        </tr>
        <tr>
          {td("Total", true)}
          {td(
            `${(sortedProspects || [] || []).length}`,
            true,
            me.rosterPlayersLimit || 0,
            me.rosterPlayersLimit &&
              (sortedProspects || [] || []).length > me.rosterPlayersLimit
          )}

          <td
            style={{
              borderCollapse: "collapse",
              border: "1px solid #467DB3",
              color: colors.accent,
              textAlign: "center",
              padding: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              <p
                style={{
                  width: 60,
                  color:
                    me.scholarshipPlayersLimit &&
                    totalSch < me.scholarshipPlayersLimit
                      ? colors.red
                      : colors.accent,
                }}
              >{`${totalSch}`}</p>
              <p
                style={{
                  width: 70,
                  marginLeft: 20,
                }}
              >
                {`(+${totalFin})`}
              </p>
            </div>
          </td>
          <td
            style={{
              borderCollapse: "collapse",
              border: "1px solid #467DB3",
              color: colors.accent,
              textAlign: "center",
              padding: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              <p
                style={{
                  width: 60,
                  color:
                    getAllScholarshipTotal("schol") >
                    (me.totalScholarshipVal || 11.7)
                      ? colors.red
                      : colors.accent,
                }}
              >{`${getAllScholarshipTotal("schol").toFixed(2)}`}</p>
              <p
                style={{
                  width: 70,
                  marginLeft: 20,
                  color:
                    getAllScholarshipTotal("finAid") >
                    (me.totalFinAidVal || 11.7)
                      ? colors.red
                      : colors.accent,
                }}
              >
                {`(+${getAllScholarshipTotal("finAid").toFixed(2)})`}
              </p>
            </div>
          </td>
        </tr>
      </table>
    );
  };

  const renderTable = (kind: "schol" | "finAid") => {
    let totalLimit = 0;
    let peopleLimit = me.scholarshipPlayersLimit || 0;
    if (kind === "schol") {
      totalLimit = me.totalScholarshipVal ? me.totalScholarshipVal : 11.7;
    }
    if (kind === "finAid") {
      totalLimit = me.totalFinAidVal ? me.totalFinAidVal : 0;
      peopleLimit = 0;
    }

    if (mode === "breakdown_edit") {
      peopleLimit = 0;
      totalLimit = 0;
    }

    let total =
      getPlayersWithScholarship("P", kind).length +
      getPlayersWithScholarship("OF", kind).length +
      getPlayersWithScholarship("IF", kind).length +
      getPlayersWithScholarship("C", kind).length;

    const tooFewPlayers = kind === "schol" && total < peopleLimit;

    let totalString = `${total}`;

    return (
      <table
        style={{
          width: "100%",
          border: "1px solid #467DB3",
          backgroundColor: colors.white,
        }}
      >
        <tr>
          {th("Position")}
          {th("# Players")}
          {th(kind === "schol" ? "# On Scholarship" : "# On Financial Aid")}
          {th(
            `${
              kind === "schol"
                ? "Scholarship "
                : kind === "finAid"
                ? "Financial Aid "
                : ""
            }Total Amount`
          )}
        </tr>
        <tr>
          {td("OF")}
          {td(`${(getPlayer("OF") || []).length}`)}
          {td(`${getPlayersWithScholarship("OF", kind).length}`)}
          {td(`${getScholarshipTotals("OF", kind).toFixed(2)}`)}
        </tr>
        <tr>
          {td("IF")}
          {td(`${(getPlayer("IF") || []).length}`)}
          {td(`${getPlayersWithScholarship("IF", kind).length}`)}
          {td(`${getScholarshipTotals("IF", kind).toFixed(2)}`)}
        </tr>
        <tr>
          {td("C")}
          {td(`${(getPlayer("C") || []).length}`)}
          {td(`${getPlayersWithScholarship("C", kind).length}`)}
          {td(`${getScholarshipTotals("C", kind).toFixed(2)}`)}
        </tr>
        <tr>
          {td("P")}
          {td(`${(getPlayer("P") || []).length}`)}
          {td(`${getPlayersWithScholarship("P", kind).length}`)}
          {td(`${getScholarshipTotals("P", kind).toFixed(2)}`)}
        </tr>
        <tr>
          {td("Total", true)}
          {td(`${(sortedProspects || [] || []).length}`, true, 0)}
          {td(totalString, true, peopleLimit, tooFewPlayers)}

          {kind == "finAid"
            ? td(`${getAllScholarshipTotal(kind).toFixed(2)}`, true)
            : td(
                `${getAllScholarshipTotal(kind).toFixed(2)}`,
                true,
                totalLimit,
                totalLimit > 0 && getAllScholarshipTotal(kind) > totalLimit
              )}
        </tr>
        {mode === "breakdown_edit" && (
          <tr>
            {td("Limit", true)}
            <td
              style={{
                borderCollapse: "collapse",
                border: "1px solid #467DB3",
                color: colors.accent,
                textAlign: "center",
                padding: 4,
              }}
            >
              <input
                style={{
                  border: "2px solid",
                  borderColor: colors.lightBlue,
                  color: colors.accent,
                  textAlign: "center",
                }}
                value={numTotalPlayers}
                onChange={(evt) => setNumTotalPlayers(evt.target.value)}
              />
            </td>
            {kind === "schol" ? (
              <td
                style={{
                  borderCollapse: "collapse",
                  border: "1px solid #467DB3",
                  color: colors.accent,
                  textAlign: "center",
                  padding: 4,
                }}
              >
                <input
                  style={{
                    border: "2px solid",
                    borderColor: colors.lightBlue,
                    color: colors.accent,
                    textAlign: "center",
                  }}
                  value={scholTotalPlayers}
                  onChange={(evt) => setScholTotalPlayers(evt.target.value)}
                />
              </td>
            ) : (
              td("")
            )}
            {kind === "schol" ? (
              <td
                style={{
                  borderCollapse: "collapse",
                  border: "1px solid #467DB3",
                  color: colors.accent,
                  textAlign: "center",
                  padding: 4,
                }}
              >
                <input
                  placeholder="0"
                  style={{
                    border: "2px solid",
                    borderColor: colors.lightBlue,
                    color: colors.accent,
                    textAlign: "center",
                  }}
                  value={schAmount}
                  onChange={(evt) => setSchAmount(evt.target.value)}
                />
              </td>
            ) : (
              td("")
            )}
          </tr>
        )}
      </table>
    );
  };

  return (
    <div
      style={{ minHeight: "90vh", paddingLeft: "1rem", paddingRight: "1rem" }}
    >
      <div style={{ backgroundColor: colors.accent }}>
        <ScoutTitle
          text="Athletic Scholarship"
          light={true}
          styles={{ padding: 4, marginLeft: 5 }}
        />
      </div>
      {renderTable("schol")}

      <div style={{ backgroundColor: colors.accent, marginTop: 20 }}>
        <ScoutTitle
          text="Financial Aid"
          light={true}
          styles={{ padding: 4, marginLeft: 5 }}
        />
      </div>
      {renderTable("finAid")}

      {mode !== "breakdown_edit" && (
        <div style={{ backgroundColor: colors.accent, marginTop: 20 }}>
          <ScoutTitle
            text="Overview"
            light={true}
            styles={{ padding: 4, marginLeft: 5 }}
          />
        </div>
      )}
      {mode !== "breakdown_edit" && renderOverviewTable()}
    </div>
  );
}

export default Breakdown;
