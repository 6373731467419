import colors from "constants/colors";
import React from "react";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";

function ReportName(props: {
  report: any;
  containerStyles?: any;
  left?: boolean;
  hover?: boolean;
}) {
  return (
    <div
      style={{
        ...props.containerStyles,
        display: "flex",
        flexDirection: "row",

        borderStyle: "solid",
        borderRadius: 9,

        alignItems: "center",
      }}
    >
      <Link
        to={`/display-report/${props.report.id}`}
        style={{
          marginLeft: 8,
        }}
      >
        <span style={{ fontWeight: 700, marginRight: 4 }}>{`${
          props.report.kind === "GENERAL"
            ? "(GEN)"
            : props.report.kind === "NOTES"
            ? ""
            : "(PRO)"
        }`}</span>
        {props.report.playerName}
      </Link>
    </div>
  );
}

export default ReportName;
