import React, { useState } from "react";
import colors from "constants/colors";
import { Text, View } from "@react-pdf/renderer";

function PDFLabelValueFlexBox({ labels, values }) {
  return (
    <View
      style={{
        flexDirection: "row",
        display: "flex",
        borderColor: colors.softDarkText,
        borderWidth: 1,
        // borderRadius: 8,
        borderStyle: "solid",
        // marginRight: 20,
        flex: 1,
        height: 40,
      }}
    >
      {labels.map((item, index) => (
        <View
          style={
            index === labels.length - 1
              ? { flex: 1 }
              : {
                  flex: 1,
                  borderRightColor: colors.softDarkText,
                  borderRightWidth: 1,
                  borderRightStyle: "solid",
                }
          }
        >
          <View
            style={{
              // paddingTop: 6,
              // paddingBottom: 6,
              // paddingLeft: 12,
              // paddingRight: 12,
              backgroundColor: colors.mediumGray,
              borderBottomColor: colors.softDarkText,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 10 }}>{item}</Text>
          </View>
          <View style={{ paddingTop: 5 }}>
            <Text
              style={{ fontWeight: 800, fontSize: 14, textAlign: "center" }}
            >
              {values[index]}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
}

export default PDFLabelValueFlexBox;
