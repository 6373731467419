export const ALL_CONFERENCES = [
  "Southland Conference",
  "Southwestern Athletic Conference",
  "Sun Belt Conference",
  "Pacific-12 Conference",
  "Southeastern Conference",
  "Ohio Valley Conference",
  "Mid-American Conference",
  "Big 12 Conference",
  "Great Lakes Valley Conference",
  "Mid-Eastern Athletic Conference",
  "Atlantic Coast Conference",
  "Missouri Valley Conference",
  "Other",
  "Ivy League",
  "Northeast Conference",
  "Patriot League",
  "Big East Conference",
  "Pacific West Conference",
  "Big Sky Conference",
  "Western Athletic Conference",
  "Mountain West Conference",
  "Big West Conference",
  "Pioneer Football League",
  "Metro Atlantic Athletic Conference",
  "Big South Conference",
  "Southern Conference",
  "Colonial Athletic Association",
  "Heartland Conference",
  "Rocky Mountain Athletic Conference",
  "American Athletic Conference",
  "Conference USA",
  "Atlantic Sun Conference",
  "Atlantic 10 Conference",
  "West Coast Conference",
  "Missouri Valley Football Conference",
  "Big Ten Conference",
  "Division I-AA Independents",
  "Northeast-10 Conference",
  "Horizon League",
  "The Summit League",
  "America East Conference",
  "Lone Star Conference",
  "Division I-A Independents",
  "Division II Independents",
  "Independent Northeast Region",
  "Gulf South Conference",
];
export const CONFERENCE_ITEMS = [
  {
    name: "Abilene Christian University",
    location: "Abilene, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Alabama A&M University",
    location: "Normal, ALABAMA",
    region: "South East",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Alabama State University",
    location: "Montgomery, ALABAMA",
    region: "South East",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Alcorn State University",
    location: "Alcorn State, MISSISSIPPI",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Appalachian State University",
    location: "Boone, NORTH CAROLINA",
    region: "Mid East",
    conference: "Sun Belt Conference",
  },

  {
    name: "Arizona State University",
    location: "Tempe, ARIZONA",
    region: "South West",
    conference: "Pacific-12 Conference",
  },

  {
    name: "Arkansas State University",
    location: "Jonesboro, ARKANSAS",
    region: "Mid South",
    conference: "Sun Belt Conference",
  },

  {
    name: "Auburn University",
    location: "Auburn, ALABAMA",
    region: "South East",
    conference: "Southeastern Conference",
  },

  {
    name: "Austin Peay State University",
    location: "Clarksville, TENNESSEE",
    region: "Mid East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Ball State University",
    location: "Muncie, INDIANA",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Baylor University",
    location: "Waco, TEXAS",
    region: "Mid South",
    conference: "Big 12 Conference",
  },

  {
    name: "Bellarmine University",
    location: "Louisville, KENTUCKY",
    region: "Mid East",
    conference: "Great Lakes Valley Conference",
  },

  {
    name: "Belmont University",
    location: "Nashville, TENNESSEE",
    region: "Mid East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Bethune-Cookman University",
    location: "Daytona Beach, FLORIDA",
    region: "South East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "Boston College",
    location: "Chestnut Hill, MASSACHUSETTS",
    region: "New England",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "Bowling Green State University",
    location: "Bowling Green, OHIO",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Bradley University",
    location: "Peoria, ILLINOIS",
    region: "Great Lakes",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Brigham Young University",
    location: "Provo, UTAH",
    region: "South West",
    conference: "Other",
  },

  {
    name: "Brown University",
    location: "Providence, RHODE ISLAND",
    region: "New England",
    conference: "Ivy League",
  },

  {
    name: "Bryant University",
    location: "Smithfield, RHODE ISLAND",
    region: "New England",
    conference: "Northeast Conference",
  },

  {
    name: "Bucknell University",
    location: "Lewisburg, PENNSYLVANIA",
    region: "North East",
    conference: "Patriot League",
  },

  {
    name: "Butler University",
    location: "Indianapolis, INDIANA",
    region: "Great Lakes",
    conference: "Big East Conference",
  },

  {
    name: "California Baptist University",
    location: "Riverside, CALIFORNIA",
    region: "West Coast",
    conference: "Pacific West Conference",
  },

  {
    name: "California Polytechnic State University – San Luis Obispo",
    location: "San Luis Obispo, CALIFORNIA",
    region: "West Coast",
    conference: "Big Sky Conference",
  },

  {
    name: "California State University – Bakersfield",
    location: "Bakersfield, CALIFORNIA",
    region: "West Coast",
    conference: "Western Athletic Conference",
  },

  {
    name: "California State University – Fresno",
    location: "Fresno, CALIFORNIA",
    region: "West Coast",
    conference: "Mountain West Conference",
  },

  {
    name: "California State University – Fullerton",
    location: "Fullerton, CALIFORNIA",
    region: "West Coast",
    conference: "Big West Conference",
  },

  {
    name: "California State University – Long Beach",
    location: "Long Beach, CALIFORNIA",
    region: "West Coast",
    conference: "Big West Conference",
  },

  {
    name: "California State University – Northridge",
    location: "Northridge, CALIFORNIA",
    region: "West Coast",
    conference: "Big West Conference",
  },

  {
    name: "California State University – Sacramento",
    location: "Sacramento, CALIFORNIA",
    region: "West Coast",
    conference: "Big Sky Conference",
  },

  {
    name: "Campbell University",
    location: "Buies Creek, NORTH CAROLINA",
    region: "Mid East",
    conference: "Pioneer Football League",
  },

  {
    name: "Canisius College",
    location: "Buffalo, NEW YORK",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Central Connecticut State University",
    location: "New Britain, CONNECTICUT",
    region: "New England",
    conference: "Northeast Conference",
  },

  {
    name: "Central Michigan University",
    location: "Mount Pleasant, MICHIGAN",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Charleston Southern University",
    location: "Charleston, SOUTH CAROLINA",
    region: "South East",
    conference: "Big South Conference",
  },

  {
    name: "Citadel Military College of South Carolina",
    location: "Charleston, SOUTH CAROLINA",
    region: "South East",
    conference: "Southern Conference",
  },

  {
    name: "Clemson University",
    location: "Clemson, SOUTH CAROLINA",
    region: "South East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "Coastal Carolina University",
    location: "Conway, SOUTH CAROLINA",
    region: "South East",
    conference: "Sun Belt Conference",
  },

  {
    name: "College of Charleston",
    location: "Charleston, SOUTH CAROLINA",
    region: "South East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "College of the Holy Cross",
    location: "Worcester, MASSACHUSETTS",
    region: "New England",
    conference: "Patriot League",
  },

  {
    name: "Columbia University",
    location: "New York, NEW YORK",
    region: "North East",
    conference: "Ivy League",
  },

  {
    name: "Coppin State University",
    location: "Baltimore, MARYLAND",
    region: "North East",
    conference: "Other",
  },

  {
    name: "Cornell University",
    location: "Ithaca, NEW YORK",
    region: "North East",
    conference: "Ivy League",
  },

  {
    name: "Creighton University",
    location: "Omaha, NEBRASKA",
    region: "Mid West",
    conference: "Big East Conference",
  },

  {
    name: "Dallas Baptist University",
    location: "Dallas, TEXAS",
    region: "Mid South",
    conference: "Heartland Conference",
  },

  {
    name: "Dartmouth College",
    location: "Hanover, NEW HAMPSHIRE",
    region: "New England",
    conference: "Ivy League",
  },

  {
    name: "Davidson College",
    location: "Davidson, NORTH CAROLINA",
    region: "Mid East",
    conference: "Pioneer Football League",
  },

  {
    name: "Delaware State University",
    location: "Dover, DELAWARE",
    region: "North East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "Dixie State University",
    location: "Saint George, UTAH",
    region: "South West",
    conference: "Rocky Mountain Athletic Conference",
  },

  {
    name: "Duke University",
    location: "Durham, NORTH CAROLINA",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "East Carolina University",
    location: "Greenville, NORTH CAROLINA",
    region: "Mid East",
    conference: "American Athletic Conference",
  },

  {
    name: "East Tennessee State University",
    location: "Johnson City, TENNESSEE",
    region: "Mid East",
    conference: "Southern Conference",
  },

  {
    name: "Eastern Illinois University",
    location: "Charleston, ILLINOIS",
    region: "Great Lakes",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Eastern Kentucky University",
    location: "Richmond, KENTUCKY",
    region: "Mid East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Eastern Michigan University",
    location: "Ypsilanti, MICHIGAN",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Elon University",
    location: "Elon, NORTH CAROLINA",
    region: "Mid East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Fairfield University",
    location: "Fairfield, CONNECTICUT",
    region: "New England",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Fairleigh Dickinson University",
    location: "Teaneck, NEW JERSEY",
    region: "North East",
    conference: "Northeast Conference",
  },

  {
    name: "Florida A&M University",
    location: "Tallahassee, FLORIDA",
    region: "South East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "Florida Atlantic University",
    location: "Boca Raton, FLORIDA",
    region: "South East",
    conference: "Conference USA",
  },

  {
    name: "Florida Gulf Coast University",
    location: "Fort Myers, FLORIDA",
    region: "South East",
    conference: "Atlantic Sun Conference",
  },

  {
    name: "Florida International University",
    location: "Miami, FLORIDA",
    region: "South East",
    conference: "Conference USA",
  },

  {
    name: "Florida State University",
    location: "Tallahassee, FLORIDA",
    region: "South East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "Fordham University",
    location: "Bronx, NEW YORK",
    region: "North East",
    conference: "Patriot League",
  },

  {
    name: "Gardner-Webb University",
    location: "Boiling Springs, NORTH CAROLINA",
    region: "Mid East",
    conference: "Big South Conference",
  },

  {
    name: "George Mason University",
    location: "Fairfax, VIRGINIA",
    region: "Mid East",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "George Washington University",
    location: "Washington, DISTRICT OF COLUMBIA",
    region: "North East",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "Georgetown University",
    location: "Washington, DISTRICT OF COLUMBIA",
    region: "North East",
    conference: "Patriot League",
  },

  {
    name: "Georgia Southern University",
    location: "Statesboro, GEORGIA",
    region: "South East",
    conference: "Sun Belt Conference",
  },

  {
    name: "Georgia State University",
    location: "Atlanta, GEORGIA",
    region: "South East",
    conference: "Sun Belt Conference",
  },

  {
    name: "Georgia Tech",
    location: "Atlanta, GEORGIA",
    region: "South East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "Gonzaga University",
    location: "Spokane, WASHINGTON",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "Grambling State University",
    location: "Grambling, LOUISIANA",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Grand Canyon University",
    location: "Phoenix, ARIZONA",
    region: "South West",
    conference: "Western Athletic Conference",
  },

  {
    name: "Harvard University",
    location: "Cambridge, MASSACHUSETTS",
    region: "New England",
    conference: "Ivy League",
  },

  {
    name: "High Point University",
    location: "High Point, NORTH CAROLINA",
    region: "Mid East",
    conference: "Big South Conference",
  },

  {
    name: "Hofstra University",
    location: "Hempstead, NEW YORK",
    region: "North East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Houston Baptist University",
    location: "Houston, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Illinois State University",
    location: "Normal, ILLINOIS",
    region: "Great Lakes",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Indiana State University",
    location: "Terre Haute, INDIANA",
    region: "Great Lakes",
    conference: "Missouri Valley Football Conference",
  },

  {
    name: "Indiana University",
    location: "Bloomington, INDIANA",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "Iona College",
    location: "New Rochelle, NEW YORK",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Jackson State University",
    location: "Jackson, MISSISSIPPI",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Jacksonville State University",
    location: "Jacksonville, ALABAMA",
    region: "South East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Jacksonville University",
    location: "Jacksonville, FLORIDA",
    region: "South East",
    conference: "Pioneer Football League",
  },

  {
    name: "James Madison University",
    location: "Harrisonburg, VIRGINIA",
    region: "Mid East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Kansas State University",
    location: "Manhattan, KANSAS",
    region: "Mid West",
    conference: "Big 12 Conference",
  },

  {
    name: "Kennesaw State University",
    location: "Kennesaw, GEORGIA",
    region: "South East",
    conference: "Atlantic Sun Conference",
  },

  {
    name: "Kent State University",
    location: "Kent, OHIO",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Lafayette College",
    location: "Easton, PENNSYLVANIA",
    region: "North East",
    conference: "Patriot League",
  },

  {
    name: "Lamar University",
    location: "Beaumont, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Lehigh University",
    location: "Bethlehem, PENNSYLVANIA",
    region: "North East",
    conference: "Patriot League",
  },

  {
    name: "Liberty University",
    location: "Lynchburg, VIRGINIA",
    region: "Mid East",
    conference: "Division I-AA Independents",
  },

  {
    name: "Lipscomb University",
    location: "Nashville, TENNESSEE",
    region: "Mid East",
    conference: "Atlantic Sun Conference",
  },

  {
    name: "Long Island University",
    location: "Brooklyn, NEW YORK",
    region: "North East",
    conference: "Northeast Conference",
  },

  {
    name: "Longwood University",
    location: "Farmville, VIRGINIA",
    region: "Mid East",
    conference: "Big South Conference",
  },

  {
    name: "Louisiana State University (LSU)",
    location: "Baton Rouge, LOUISIANA",
    region: "Mid South",
    conference: "Southeastern Conference",
  },

  {
    name: "Louisiana Tech University",
    location: "Ruston, LOUISIANA",
    region: "Mid South",
    conference: "Conference USA",
  },

  {
    name: "Loyola Marymount University",
    location: "Los Angeles, CALIFORNIA",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "Manhattan College",
    location: "Riverdale, NEW YORK",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Marist College",
    location: "Poughkeepsie, NEW YORK",
    region: "North East",
    conference: "Pioneer Football League",
  },

  {
    name: "Marshall University",
    location: "Huntington, WEST VIRGINIA",
    region: "Mid East",
    conference: "Conference USA",
  },

  {
    name: "McNeese State University",
    location: "Lake Charles, LOUISIANA",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Mercer University",
    location: "Macon, GEORGIA",
    region: "South East",
    conference: "Southern Conference",
  },

  {
    name: "Merrimack College",
    location: "North Andover, MASSACHUSETTS",
    region: "New England",
    conference: "Northeast-10 Conference",
  },

  {
    name: "Miami University",
    location: "Oxford, OHIO",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Michigan State University",
    location: "East Lansing, MICHIGAN",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "Middle Tennessee State University",
    location: "Murfreesboro, TENNESSEE",
    region: "Mid East",
    conference: "Conference USA",
  },

  {
    name: "Mississippi State University",
    location: "Mississippi State, MISSISSIPPI",
    region: "Mid South",
    conference: "Southeastern Conference",
  },

  {
    name: "Mississippi Valley State University",
    location: "Itta Bena, MISSISSIPPI",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Missouri State University",
    location: "Springfield, MISSOURI",
    region: "Mid West",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Monmouth University",
    location: "West Long Branch, NEW JERSEY",
    region: "North East",
    conference: "Big South Conference",
  },

  {
    name: "Morehead State University",
    location: "Morehead, KENTUCKY",
    region: "Mid East",
    conference: "Pioneer Football League",
  },

  {
    name: "Mount St. Mary's University",
    location: "Emmitsburg, MARYLAND",
    region: "North East",
    conference: "Northeast Conference",
  },

  {
    name: "Murray State University",
    location: "Murray, KENTUCKY",
    region: "Mid East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "New Jersey Institute of Technology",
    location: "Newark, NEW JERSEY",
    region: "North East",
    conference: "Atlantic Sun Conference",
  },

  {
    name: "New Mexico State University",
    location: "Las Cruces, NEW MEXICO",
    region: "South West",
    conference: "Sun Belt Conference",
  },

  {
    name: "Niagara University",
    location: "Niagara University, NEW YORK",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Nicholls State University",
    location: "Thibodaux, LOUISIANA",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Norfolk State University",
    location: "Norfolk, VIRGINIA",
    region: "Mid East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "North Carolina A&T State University",
    location: "Greensboro, NORTH CAROLINA",
    region: "Mid East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "North Carolina Central University",
    location: "Durham, NORTH CAROLINA",
    region: "Mid East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "North Carolina State University",
    location: "Raleigh, NORTH CAROLINA",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "North Dakota State University",
    location: "Fargo, NORTH DAKOTA",
    region: "North West",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Northeastern University",
    location: "Boston, MASSACHUSETTS",
    region: "New England",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Northern Illinois University",
    location: "DeKalb, ILLINOIS",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Northern Kentucky University",
    location: "Highland Heights, KENTUCKY",
    region: "Mid East",
    conference: "Horizon League",
  },

  {
    name: "Northwestern State University of Louisiana",
    location: "Natchitoches, LOUISIANA",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Northwestern University",
    location: "Evanston, ILLINOIS",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "Oakland University",
    location: "Rochester, MICHIGAN",
    region: "Great Lakes",
    conference: "Horizon League",
  },

  {
    name: "Ohio State University",
    location: "Columbus, OHIO",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "Ohio University",
    location: "Athens, OHIO",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Oklahoma State University",
    location: "Stillwater, OKLAHOMA",
    region: "Mid South",
    conference: "Big 12 Conference",
  },

  {
    name: "Old Dominion University",
    location: "Norfolk, VIRGINIA",
    region: "Mid East",
    conference: "Conference USA",
  },

  {
    name: "Oral Roberts University",
    location: "Tulsa, OKLAHOMA",
    region: "Mid South",
    conference: "The Summit League",
  },

  {
    name: "Oregon State University",
    location: "Corvallis, OREGON",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "Penn State",
    location: "University Park, PENNSYLVANIA",
    region: "North East",
    conference: "Big Ten Conference",
  },

  {
    name: "Pepperdine University",
    location: "Malibu, CALIFORNIA",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "Prairie View A & M University",
    location: "Prairie View, TEXAS",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Presbyterian College",
    location: "Clinton, SOUTH CAROLINA",
    region: "South East",
    conference: "Big South Conference",
  },

  {
    name: "Princeton University",
    location: "Princeton, NEW JERSEY",
    region: "North East",
    conference: "Ivy League",
  },

  {
    name: "Purdue University",
    location: "West Lafayette, INDIANA",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "Purdue University – Fort Wayne",
    location: "Fort Wayne, INDIANA",
    region: "Great Lakes",
    conference: "The Summit League",
  },

  {
    name: "Quinnipiac University",
    location: "Hamden, CONNECTICUT",
    region: "New England",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Radford University",
    location: "Radford, VIRGINIA",
    region: "Mid East",
    conference: "Big South Conference",
  },

  {
    name: "Rice University",
    location: "Houston, TEXAS",
    region: "Mid South",
    conference: "Conference USA",
  },

  {
    name: "Rider University",
    location: "Lawrenceville, NEW JERSEY",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Rutgers University",
    location: "Piscataway, NEW JERSEY",
    region: "North East",
    conference: "Big Ten Conference",
  },

  {
    name: "Sacred Heart University",
    location: "Fairfield, CONNECTICUT",
    region: "New England",
    conference: "Northeast Conference",
  },

  {
    name: "Saint Joseph's University",
    location: "Philadelphia, PENNSYLVANIA",
    region: "North East",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "Saint Louis University",
    location: "St. Louis, MISSOURI",
    region: "Mid West",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "Saint Mary's College of California",
    location: "Moraga, CALIFORNIA",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "Saint Peter's University",
    location: "Jersey City, NEW JERSEY",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "Sam Houston State University",
    location: "Huntsville, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Samford University",
    location: "Birmingham, ALABAMA",
    region: "South East",
    conference: "Southern Conference",
  },

  {
    name: "San Diego State University",
    location: "San Diego, CALIFORNIA",
    region: "West Coast",
    conference: "Mountain West Conference",
  },

  {
    name: "San Jose State University",
    location: "San Jose, CALIFORNIA",
    region: "West Coast",
    conference: "Mountain West Conference",
  },

  {
    name: "Santa Clara University",
    location: "Santa Clara, CALIFORNIA",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "Savannah State University",
    location: "Savannah, GEORGIA",
    region: "South East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "Seattle University",
    location: "Seattle, WASHINGTON",
    region: "West Coast",
    conference: "Western Athletic Conference",
  },

  {
    name: "Seton Hall University",
    location: "South Orange, NEW JERSEY",
    region: "North East",
    conference: "Big East Conference",
  },

  {
    name: "Siena College",
    location: "Loudonville, NEW YORK",
    region: "North East",
    conference: "Metro Atlantic Athletic Conference",
  },

  {
    name: "South Dakota State University",
    location: "Brookings, SOUTH DAKOTA",
    region: "North West",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Southeast Missouri State University",
    location: "Cape Girardeau, MISSOURI",
    region: "Mid West",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Southeastern Louisiana University",
    location: "Hammond, LOUISIANA",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Southern Illinois University Carbondale",
    location: "Carbondale, ILLINOIS",
    region: "Great Lakes",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Southern Illinois University Edwardsville",
    location: "Edwardsville, ILLINOIS",
    region: "Great Lakes",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Southern University & A&M College",
    location: "Baton Rouge, LOUISIANA",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "St. Bonaventure University",
    location: "Saint Bonaventure, NEW YORK",
    region: "North East",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "St. John's University – New York",
    location: "Queens, NEW YORK",
    region: "North East",
    conference: "Big East Conference",
  },

  {
    name: "Stanford University",
    location: "Stanford, CALIFORNIA",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "Stephen F Austin State University",
    location: "Nacogdoches, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Stetson University",
    location: "DeLand, FLORIDA",
    region: "South East",
    conference: "Pioneer Football League",
  },

  {
    name: "SUNY Binghamton University",
    location: "Vestal, NEW YORK",
    region: "North East",
    conference: "America East Conference",
  },

  {
    name: "SUNY Stony Brook University",
    location: "Stony Brook, NEW YORK",
    region: "North East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "SUNY University at Albany",
    location: "Albany, NEW YORK",
    region: "North East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Tarleton State University",
    location: "Stephenville, TEXAS",
    region: "Mid South",
    conference: "Lone Star Conference",
  },

  {
    name: "Tennessee Technological University",
    location: "Cookeville, TENNESSEE",
    region: "Mid East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "Texas A&M University",
    location: "College Station, TEXAS",
    region: "Mid South",
    conference: "Southeastern Conference",
  },

  {
    name: "Texas A&M University – Corpus Christi",
    location: "Corpus Christi, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "Texas Christian University",
    location: "Fort Worth, TEXAS",
    region: "Mid South",
    conference: "Big 12 Conference",
  },

  {
    name: "Texas Southern University",
    location: "Houston, TEXAS",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "Texas State University",
    location: "San Marcos, TEXAS",
    region: "Mid South",
    conference: "Sun Belt Conference",
  },

  {
    name: "Texas Tech University",
    location: "Lubbock, TEXAS",
    region: "Mid South",
    conference: "Big 12 Conference",
  },

  {
    name: "Towson University",
    location: "Towson, MARYLAND",
    region: "North East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Troy University",
    location: "Troy, ALABAMA",
    region: "South East",
    conference: "Sun Belt Conference",
  },

  {
    name: "Tulane University",
    location: "New Orleans, LOUISIANA",
    region: "Mid South",
    conference: "American Athletic Conference",
  },

  {
    name: "United States Air Force Academy",
    location: "USAFA, COLORADO",
    region: "South West",
    conference: "Mountain West Conference",
  },

  {
    name: "United States Military Academy",
    location: "West Point, NEW YORK",
    region: "North East",
    conference: "Division I-A Independents",
  },

  {
    name: "United States Naval Academy",
    location: "Annapolis, MARYLAND",
    region: "North East",
    conference: "American Athletic Conference",
  },

  {
    name: "University of Akron",
    location: "Akron, OHIO",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "University of Alabama",
    location: "Tuscaloosa, ALABAMA",
    region: "South East",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Alabama – Birmingham",
    location: "Birmingham, ALABAMA",
    region: "South East",
    conference: "Conference USA",
  },

  {
    name: "University of Arizona",
    location: "Tucson, ARIZONA",
    region: "South West",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of Arkansas",
    location: "Fayetteville, ARKANSAS",
    region: "Mid South",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Arkansas at Little Rock",
    location: "Little Rock, ARKANSAS",
    region: "Mid South",
    conference: "Sun Belt Conference",
  },

  {
    name: "University of Arkansas at Pine Bluff",
    location: "Pine Bluff, ARKANSAS",
    region: "Mid South",
    conference: "Southwestern Athletic Conference",
  },

  {
    name: "University of California",
    location: "Berkeley, CALIFORNIA",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of California – Davis",
    location: "Davis, CALIFORNIA",
    region: "West Coast",
    conference: "Big Sky Conference",
  },

  {
    name: "University of California – Irvine",
    location: "Irvine, CALIFORNIA",
    region: "West Coast",
    conference: "Big West Conference",
  },

  {
    name: "University of California – Los Angeles – UCLA",
    location: "Los Angeles, CALIFORNIA",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of California – Riverside",
    location: "Riverside, CALIFORNIA",
    region: "West Coast",
    conference: "Big West Conference",
  },

  {
    name: "University of California – San Diego",
    location: "La Jolla, CALIFORNIA",
    region: "West Coast",
    conference: "Division II Independents",
  },

  {
    name: "University of California – Santa Barbara",
    location: "Santa Barbara, CALIFORNIA",
    region: "West Coast",
    conference: "Big West Conference",
  },

  {
    name: "University of Central Arkansas",
    location: "Conway, ARKANSAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "University of Central Florida",
    location: "Orlando, FLORIDA",
    region: "South East",
    conference: "American Athletic Conference",
  },

  {
    name: "University of Cincinnati",
    location: "Cincinnati, OHIO",
    region: "Great Lakes",
    conference: "American Athletic Conference",
  },

  {
    name: "University of Connecticut",
    location: "Storrs, CONNECTICUT",
    region: "New England",
    conference: "Big East Conference",
  },

  {
    name: "University of Dayton",
    location: "Dayton, OHIO",
    region: "Great Lakes",
    conference: "Pioneer Football League",
  },

  {
    name: "University of Delaware",
    location: "Newark, DELAWARE",
    region: "North East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "University of Evansville",
    location: "Evansville, INDIANA",
    region: "Great Lakes",
    conference: "Missouri Valley Conference",
  },

  {
    name: "University of Florida",
    location: "Gainesville, FLORIDA",
    region: "South East",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Georgia",
    location: "Athens, GEORGIA",
    region: "South East",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Hartford",
    location: "West Hartford, CONNECTICUT",
    region: "New England",
    conference: "America East Conference",
  },

  {
    name: "University of Hawaii at Manoa",
    location: "Honolulu, HAWAII",
    region: "West Coast",
    conference: "Mountain West Conference",
  },

  {
    name: "University of Houston",
    location: "Houston, TEXAS",
    region: "Mid South",
    conference: "American Athletic Conference",
  },

  {
    name: "University of Illinois",
    location: "Champaign, ILLINOIS",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "University of Illinois at Chicago",
    location: "Chicago, ILLINOIS",
    region: "Great Lakes",
    conference: "Horizon League",
  },

  {
    name: "University of Iowa",
    location: "Iowa City, IOWA",
    region: "Mid West",
    conference: "Big Ten Conference",
  },

  {
    name: "University of Kansas",
    location: "Lawrence, KANSAS",
    region: "Mid West",
    conference: "Big 12 Conference",
  },

  {
    name: "University of Kentucky",
    location: "Lexington, KENTUCKY",
    region: "Mid East",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Louisiana – Lafayette",
    location: "Lafayette, LOUISIANA",
    region: "Mid South",
    conference: "Sun Belt Conference",
  },

  {
    name: "University of Louisiana – Monroe",
    location: "Monroe, LOUISIANA",
    region: "Mid South",
    conference: "Sun Belt Conference",
  },

  {
    name: "University of Louisville",
    location: "Louisville, KENTUCKY",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "University of Maine",
    location: "Orono, MAINE",
    region: "New England",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "University of Maryland",
    location: "College Park, MARYLAND",
    region: "North East",
    conference: "Big Ten Conference",
  },

  {
    name: "University of Maryland – Baltimore County",
    location: "Baltimore, MARYLAND",
    region: "North East",
    conference: "America East Conference",
  },

  {
    name: "University of Maryland Eastern Shore",
    location: "Princess Anne, MARYLAND",
    region: "North East",
    conference: "Mid-Eastern Athletic Conference",
  },

  {
    name: "University of Massachusetts – Amherst",
    location: "Amherst, MASSACHUSETTS",
    region: "New England",
    conference: "Independent Northeast Region",
  },

  {
    name: "University of Massachusetts – Lowell",
    location: "Lowell, MASSACHUSETTS",
    region: "New England",
    conference: "America East Conference",
  },

  {
    name: "University of Memphis",
    location: "Memphis, TENNESSEE",
    region: "Mid East",
    conference: "American Athletic Conference",
  },

  {
    name: "University of Miami",
    location: "Coral Gables, FLORIDA",
    region: "South East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "University of Michigan",
    location: "Ann Arbor, MICHIGAN",
    region: "Great Lakes",
    conference: "Big Ten Conference",
  },

  {
    name: "University of Minnesota",
    location: "Minneapolis, MINNESOTA",
    region: "Mid West",
    conference: "Big Ten Conference",
  },

  {
    name: "University of Mississippi",
    location: "University, MISSISSIPPI",
    region: "Mid South",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Missouri",
    location: "Columbia, MISSOURI",
    region: "Mid West",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Nebraska",
    location: "Lincoln, NEBRASKA",
    region: "Mid West",
    conference: "Big Ten Conference",
  },

  {
    name: "University of Nebraska at Omaha",
    location: "Omaha, NEBRASKA",
    region: "Mid West",
    conference: "The Summit League",
  },

  {
    name: "University of Nevada – Las Vegas",
    location: "Las Vegas, NEVADA",
    region: "South West",
    conference: "Mountain West Conference",
  },

  {
    name: "University of Nevada – Reno",
    location: "Reno, NEVADA",
    region: "South West",
    conference: "Mountain West Conference",
  },

  {
    name: "University of New Mexico",
    location: "Albuquerque, NEW MEXICO",
    region: "South West",
    conference: "Mountain West Conference",
  },

  {
    name: "University of New Orleans",
    location: "New Orleans, LOUISIANA",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "University of North Alabama",
    location: "Florence, ALABAMA",
    region: "South East",
    conference: "Gulf South Conference",
  },

  {
    name: "University of North Carolina – Greensboro",
    location: "Greensboro, NORTH CAROLINA",
    region: "Mid East",
    conference: "Southern Conference",
  },

  {
    name: "University of North Carolina – Wilmington",
    location: "Wilmington, NORTH CAROLINA",
    region: "Mid East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "University of North Carolina Asheville",
    location: "Asheville, NORTH CAROLINA",
    region: "Mid East",
    conference: "Big South Conference",
  },

  {
    name: "University of North Carolina at Chapel Hill",
    location: "Chapel Hill, NORTH CAROLINA",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "University of North Carolina at Charlotte",
    location: "Charlotte, NORTH CAROLINA",
    region: "Mid East",
    conference: "Conference USA",
  },

  {
    name: "University of North Florida",
    location: "Jacksonville, FLORIDA",
    region: "South East",
    conference: "Atlantic Sun Conference",
  },

  {
    name: "University of Northern Colorado",
    location: "Greeley, COLORADO",
    region: "South West",
    conference: "Big Sky Conference",
  },

  {
    name: "University of Notre Dame",
    location: "Notre Dame, INDIANA",
    region: "Great Lakes",
    conference: "Division I-A Independents",
  },

  {
    name: "University of Oklahoma",
    location: "Norman, OKLAHOMA",
    region: "Mid South",
    conference: "Big 12 Conference",
  },

  {
    name: "University of Oregon",
    location: "Eugene, OREGON",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of Pennsylvania – Penn",
    location: "Philadelphia, PENNSYLVANIA",
    region: "North East",
    conference: "Ivy League",
  },

  {
    name: "University of Pittsburgh",
    location: "Pittsburgh, PENNSYLVANIA",
    region: "North East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "University of Portland",
    location: "Portland, OREGON",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "University of Rhode Island",
    location: "Kingston, RHODE ISLAND",
    region: "New England",
    conference: "Colonial Athletic Association",
  },

  {
    name: "University of Richmond",
    location: "VIRGINIA",
    region: "Mid East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "University of San Diego",
    location: "San Diego, CALIFORNIA",
    region: "West Coast",
    conference: "Pioneer Football League",
  },

  {
    name: "University of San Francisco",
    location: "San Francisco, CALIFORNIA",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "University of South Alabama",
    location: "Mobile, ALABAMA",
    region: "South East",
    conference: "Sun Belt Conference",
  },

  {
    name: "University of South Carolina",
    location: "Columbia, SOUTH CAROLINA",
    region: "South East",
    conference: "Southeastern Conference",
  },

  {
    name: "University of South Carolina – Upstate",
    location: "Spartanburg, SOUTH CAROLINA",
    region: "South East",
    conference: "Atlantic Sun Conference",
  },

  {
    name: "University of South Florida",
    location: "Tampa, FLORIDA",
    region: "South East",
    conference: "American Athletic Conference",
  },

  {
    name: "University of Southern California",
    location: "Los Angeles, CALIFORNIA",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of Southern Mississippi",
    location: "Hattiesburg, MISSISSIPPI",
    region: "Mid South",
    conference: "Conference USA",
  },

  {
    name: "University of Tennessee",
    location: "Knoxville, TENNESSEE",
    region: "Mid East",
    conference: "Southeastern Conference",
  },

  {
    name: "University of Tennessee – Martin",
    location: "Martin, TENNESSEE",
    region: "Mid East",
    conference: "Ohio Valley Conference",
  },

  {
    name: "University of Texas – Arlington",
    location: "Arlington, TEXAS",
    region: "Mid South",
    conference: "Sun Belt Conference",
  },

  {
    name: "University of Texas – Austin",
    location: "Austin, TEXAS",
    region: "Mid South",
    conference: "Big 12 Conference",
  },

  {
    name: "University of Texas – Rio Grande Valley",
    location: "Edinburg, TEXAS",
    region: "Mid South",
    conference: "Western Athletic Conference",
  },

  {
    name: "University of Texas – San Antonio",
    location: "San Antonio, TEXAS",
    region: "Mid South",
    conference: "Conference USA",
  },

  {
    name: "University of the Incarnate Word",
    location: "San Antonio, TEXAS",
    region: "Mid South",
    conference: "Southland Conference",
  },

  {
    name: "University of the Pacific",
    location: "Stockton, CALIFORNIA",
    region: "West Coast",
    conference: "West Coast Conference",
  },

  {
    name: "University of Toledo",
    location: "Toledo, OHIO",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "University of Utah",
    location: "Salt Lake City, UTAH",
    region: "South West",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of Vermont",
    location: "Burlington, VERMONT",
    region: "New England",
    conference: "America East Conference",
  },

  {
    name: "University of Virginia",
    location: "Charlottesville, VIRGINIA",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "University of Washington",
    location: "Seattle, WASHINGTON",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "University of Wisconsin – Milwaukee",
    location: "Milwaukee, WISCONSIN",
    region: "Great Lakes",
    conference: "Horizon League",
  },

  {
    name: "Utah Valley University",
    location: "Orem, UTAH",
    region: "South West",
    conference: "Western Athletic Conference",
  },

  {
    name: "Valparaiso University",
    location: "Valparaiso, INDIANA",
    region: "Great Lakes",
    conference: "Horizon League",
  },

  {
    name: "Vanderbilt University",
    location: "Nashville, TENNESSEE",
    region: "Mid East",
    conference: "Southeastern Conference",
  },

  {
    name: "Villanova University",
    location: "Villanova, PENNSYLVANIA",
    region: "North East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Virginia Commonwealth University",
    location: "Richmond, VIRGINIA",
    region: "Mid East",
    conference: "Atlantic 10 Conference",
  },

  {
    name: "Virginia Military Institute – VMI",
    location: "Lexington, VIRGINIA",
    region: "Mid East",
    conference: "Big South Conference",
  },

  {
    name: "Virginia Tech",
    location: "Blacksburg, VIRGINIA",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "Wagner College",
    location: "Staten Island, NEW YORK",
    region: "North East",
    conference: "Northeast Conference",
  },

  {
    name: "Wake Forest University",
    location: "Winston-Salem, NORTH CAROLINA",
    region: "Mid East",
    conference: "Atlantic Coast Conference",
  },

  {
    name: "Washington State University",
    location: "Pullman, WASHINGTON",
    region: "West Coast",
    conference: "Pacific-12 Conference",
  },

  {
    name: "West Virginia University",
    location: "Morgantown, WEST VIRGINIA",
    region: "Mid East",
    conference: "Big 12 Conference",
  },

  {
    name: "Western Carolina University",
    location: "Cullowhee, NORTH CAROLINA",
    region: "Mid East",
    conference: "Southern Conference",
  },

  {
    name: "Western Illinois University",
    location: "Macomb, ILLINOIS",
    region: "Great Lakes",
    conference: "Missouri Valley Conference",
  },

  {
    name: "Western Kentucky University",
    location: "Bowling Green, KENTUCKY",
    region: "Mid East",
    conference: "Conference USA",
  },

  {
    name: "Western Michigan University",
    location: "Kalamazoo, MICHIGAN",
    region: "Great Lakes",
    conference: "Mid-American Conference",
  },

  {
    name: "Wichita State University",
    location: "Wichita, KANSAS",
    region: "Mid West",
    conference: "American Athletic Conference",
  },

  {
    name: "William & Mary",
    location: "Williamsburg, VIRGINIA",
    region: "Mid East",
    conference: "Colonial Athletic Association",
  },

  {
    name: "Winthrop University",
    location: "Rock Hill, SOUTH CAROLINA",
    region: "South East",
    conference: "Big South Conference",
  },

  {
    name: "Wofford College",
    location: "Spartanburg, SOUTH CAROLINA",
    region: "South East",
    conference: "Southern Conference",
  },

  {
    name: "Wright State University",
    location: "Dayton, OHIO",
    region: "Great Lakes",
    conference: "Horizon League",
  },

  {
    name: "Xavier University",
    location: "Cincinnati, OHIO",
    region: "Great Lakes",
    conference: "Big East Conference",
  },

  {
    name: "Yale University",
    location: "New Haven, CONNECTICUT",
    region: "New England",
    conference: "Ivy League",
  },

  {
    name: "Youngstown State University",
    location: "Youngstown, OHIO",
    region: "Great Lakes",
    conference: "Missouri Valley Conference",
  },
];
