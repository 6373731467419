import colors from "constants/colors";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaShareSquare } from "react-icons/fa";

function HelpModal(props: {
  show: boolean;
  onHide: any;
  kind:
    | "BIG BOARD"
    | "PLAYER PROFILE"
    | "REPORTS"
    | "FRIENDS"
    | "PROSPECTS"
    | "NOTIFICATIONS"
    | "SETTINGS"
    | "SEARCH"
    | "CALENDAR";
}) {
  const getBulletPoints = (): any[] => {
    switch (props.kind) {
      case "BIG BOARD":
        return [
          {
            text:
              "The Big Board is a tool for you to rank the players you have saved in the Prospect Page, by year and position",
            subText: [
              {
                text:
                  "Press and hold a player, and then drag them up or down to change their ranking in relation to the players listed on the Big Board",
              },
              {
                text:
                  "You can edit the Class year filter or Position filter using the drop-down menus at the top of the screen",
              },
            ],
          },

          {
            text:
              "Press the share button in the top right to share this with your Friends",
          },
          {
            text:
              "The Big Board view will default to your Big Board. Use the dropdown in the top left corner to view other Friend’s Big Boards that have been shared with you.",
          },
        ];
      case "PLAYER PROFILE":
        return [
          {
            text: "The following buttons are in the top right corner",
            subText: [
              {
                text:
                  "Press the star to add/remove the player from your Prospect List",
              },
              {
                text:
                  'Press "Save to Contacts" in the menu to pull up the player’s available contact information. From this window, you can save the player’s contact info directly into your iPhone contacts.',
              },
              {
                text:
                  'Press "Notify Me" in the menu to save a single/recurring reminder about the player – for example, to call them every week; this will link to your iPhone calendar.',
              },
              {
                text:
                  'Press "Share" in the menu to share the player profile with one of your friends',
              },
              {
                text: `Press "Edit" in the menu to change the player's position or team (this change will only be visible to you.)`,
              },
            ],
          },
          {
            text:
              "Press the (+) sign under the Reports section in the player profile to create one of the following reports on the player currently being viewed.",
            subText: [
              {
                text:
                  "General Report: This report allows you to write a date-stamped report with a general description, uploaded videos, and linked Game/Event.",
              },
              {
                text: "Position 20-80 Report:",
                subText: [
                  {
                    text:
                      "Future Position: The position he will play in the Major Leagues",
                  },
                  {
                    text: "Round: What round he should be taken in",
                  },
                  {
                    text:
                      "Games: # of games player has been seen for the report",
                  },
                  {
                    text:
                      "Innings: # of innings player has been seen for the report",
                  },
                  {
                    text:
                      "ABs: # of At Bats player has been seen for the report",
                  },
                  {
                    text: "Raw: Player’s raw talent right now",
                  },
                  {
                    text:
                      "Adj: Ceiling of player’s raw talent OR ceiling of player’s said metric",
                  },
                  {
                    text: "Current: Current position",
                  },
                  {
                    text: "Future: Future Position",
                  },
                  {
                    text:
                      "Physical Description: What the player physically looks like",
                  },
                  {
                    text: "Player Comparison: MLB/MiLB player comparison",
                  },
                  {
                    text: "P: Present rating",
                    subText: [
                      {
                        text:
                          "3 = you will struggle at the level you will be drafted to at that year, 4 is average, 5 you will excel ",
                      },
                    ],
                  },

                  {
                    text: "F: Ceiling (Projected Major League value)",
                    subText: [
                      {
                        text:
                          "What he will be in the big leagues if he reaches his potential",
                      },
                      {
                        text: "Average hitter in the big leagues is   .270",
                      },
                    ],
                  },

                  {
                    text:
                      "Hitting ability: Balance, rhythm, timing, Bat speed, looseness/strength",
                  },
                  {
                    text: "Hitting Approach/Type: Swing Mechanics",
                    subText: [
                      {
                        text:
                          "Stance itself, what he does with the bat to get going, leg kick, hand hitch, ",
                      },
                    ],
                  },

                  {
                    text: "Power Freq: How many HR he would hit right now",
                  },
                  {
                    text:
                      "Raw Power: How far he can hit a baseball and how many times he can do it in batting practice ",
                  },
                  {
                    text: "Running Ability: How fast is the player ",
                  },
                  {
                    text:
                      "Base Stealer: How good of a base stealer is the player",
                  },
                  {
                    text:
                      "Arm Strength: How hard can the player throw the baseball",
                  },
                  {
                    text:
                      "Fielding ability: Actions, consistency in catching the ball without fumbling it",
                  },
                  {
                    text:
                      "Hands: How well does the player’s hands work defensively (i.e. Strong/soft/really soft hands)",
                  },
                  {
                    text:
                      "Range: How much defensive range does the player have",
                  },
                  {
                    text:
                      "Feet: How well does the player use his feet defensively",
                  },
                  {
                    text: "Can He Play: Overall feel for the game, baseball IQ",
                  },
                  {
                    text:
                      "OFP: (Hitting+Defense+Arm+Speed+Power+Can he play)/6",
                  },
                  {
                    text:
                      "Home to First: Time it takes to get from home to first",
                  },
                  {
                    text: "60 Yard: 60 yard dash time",
                  },
                  {
                    text: "40 yard: 40 yard dash time",
                  },
                  {
                    text:
                      "Position in Order: Player’s projected position in the batting order",
                  },
                  {
                    text: "Character: Player’s character",
                  },
                  {
                    text:
                      "Entry Level: What level of the minor leagues will the player start off at",
                  },
                  {
                    text:
                      "ETA: Estimated time it will take to get to the major leagues",
                  },
                  {
                    text:
                      "How Well Known: How well is the player known by the scout",
                  },
                  {
                    text:
                      "How Well Seen: How well or how many times the scout has seen the player",
                  },
                  {
                    text:
                      "Signability: If the player is drafted what is the likelihood he will sign",
                  },
                  {
                    text: "Summary: Overall Summary of the player",
                  },
                ],
              },
              {
                text: "Pitcher 20-80 Report:",
                subText: [
                  {
                    text:
                      "Arm Angle: What kind of arm angle does the pitcher throw the ball from",
                  },
                  { text: "Delivery: What is the pitcher’s delivery like" },
                  {
                    text:
                      "Arm Action: What kind of arm action does the pitcher have (i.e. Loose, stiff, etc.)",
                  },
                  { text: "H: Highest velocity recorded for said pitch" },
                  { text: "L: Lowest recorded velocity for said pitch" },
                  { text: "A: Average velocity of said pitch" },
                  { text: "FB: How hard is his fastball" },
                  { text: "FBMVMT: How well does his fastball move" },
                  {
                    text:
                      "FB Stretch: How hard does he throw his fastball from the stretch",
                  },
                  { text: "SL: How good is the player’s slider" },
                  { text: "CB: How good is the player’s curveball" },
                  { text: "CH: How good is the player’s changeup" },
                  { text: "Control: How well does the player throw strikes" },
                  {
                    text:
                      "Command: How good is the pitcher at putting the ball where he wants to in the strike zone",
                  },
                  { text: "Can He Pitch: Overall feel for pitching" },
                  { text: "OFP: (Control+FB+CB+Ch+Sl+Can he pitch)/6" },
                  {
                    text:
                      "Aggressiveness: How aggressive is the pitcher at attacking hitters",
                  },
                  { text: "Athleticism: How athletic is the pitcher" },
                  { text: "Aptitude: Raw talent of the pitcher" },
                  {
                    text: "Poise: How well poised is the pitcher on the mound",
                  },
                  { text: "Baseball IQ: Pitcher’s overall baseball IQ" },
                  { text: "Fielding: How good of a fielder is the pitcher" },
                  {
                    text:
                      "Deception: How deceptive is the pitcher to the hitter",
                  },
                  {
                    text:
                      "Release times: Time it takes for the pitcher to throw the pitch",
                  },
                  {
                    text:
                      "Outpitch: What is the pitch the pitcher goes to to get hitters out",
                  },
                ],
              },
              {
                text:
                  "Notes: You can jot down general notes for reference later or to share with Friends.",
              },
            ],
          },
          {
            textOne: "",
            bold: "Reports/Notes that you create will only be visible by you.",
            textTwo:
              " You can share your Reports and Notes with other Friends if you choose to (under the Reports Page).",
          },
          {
            text:
              "Any Games linked to this player will show in the bottom section of the Player Profile page",
          },
        ];
      case "REPORTS":
        return [
          {
            text:
              "Reports can only be initiated/created from a Player Profile, but any Reports that you create will show up in this Page for future access.",
            subText: [
              {
                text:
                  "Reports can be filtered by the sections at the top: “All”, “Position”, “Pitcher”, “General”",
              },
            ],
          },

          { text: "Notes are listed under a separate section below reports." },
          {
            text: "Press edit to edit/delete a report.",
          },
          { text: "Press a report name to navigate to the report." },

          {
            text: "In the top left corner (below the Report title)",
            subText: [
              {
                text: "Press report.xls to export this report to an Excel file",
              },
              { text: "Press report.pdf to export this report to a PDF file" },
            ],
          },
        ];
      case "FRIENDS":
        return [
          { text: "Any of your accepted Friends will show up in this Page." },
          {
            textOne: "Press ",
            bold: "Add Friends",
            textTwo:
              " to send new requests to other users using their linked email address",
          },
          {
            textOne: `Click on your friend and press `,
            bold: "Remove Friend",
            textTwo:
              " to delete any friends in your list that you don’t want to be able to share information within the app",
          },
          {
            text:
              "Press the name of a Friend within the list to access their full profile information",
          },

          {
            textOne:
              "If you have shared your big board with a friend, click on their name to see the option to ",
            bold: "Revoke Access",
            textTwo: " to your big board.",
          },
        ];
      case "PROSPECTS":
        return [
          {
            textOne:
              "This Page shows the list of Prospects that you have saved from the ",
            bold: "Search Page",
            textTwo: " or Notifications Page.",
          },
          {
            text:
              "Press the star on the left of the player name to remove the player from your Prospect List",
          },
          {
            text:
              "Press the player name to navigate to their Player Profile page",
          },
          { text: "You can view this Page in Landscape" },
        ];
      case "NOTIFICATIONS":
        return [
          {
            text:
              "This Page is a central location where you receive any notifications for the following:",
            subText: [
              {
                text:
                  "Player Matching (from Saved Searches in the Search Page)",
              },
              {
                text: "New Friend Requests from other users",
              },
              {
                text:
                  "Any reports/notes on a player that were shared with you by another user",
              },
              {
                text: "Shared Big Boards by other Friends",
              },
            ],
          },
          {
            text:
              "Press “All”, “Players”, or “Friends” for different filtered versions of the notifications",
            subText: [
              {
                text:
                  " Notifications are listed with the most recent notification at the top",
              },
            ],
          },
          {
            text: "Press a notification to mark it as “read”",
          },
          {
            text:
              "Press a read notification to delete it, (a confirmation will pop-up)",
          },
          {
            text:
              "Press any of the blue underlined subjects within the notification to navigate to, for example, the shared report",
            subText: [
              {
                text:
                  "Press on a shared Report/Notes notification to navigate to the shared Report; press the (floppy disk icon) in the top right corner to save the Report to your Reports Page",
                subText: [
                  {
                    text:
                      "You will only see a snapshot of the Report from when your Friend shared it with you. You will not see live updates if the original creator edits the Report later in their own account.",
                  },
                ],
              },
              {
                text:
                  "Press on a friend request to confirm you would like to accept the friend request (a window will pop-up to confirm). They will then show up in your Friends Page.",
              },
              {
                text:
                  "Press on a shared Big Board notification to view your friend’s live Big Board under your Big Board Page",
              },
              {
                text:
                  "Press on a shared/player-matched Player Profile notification to navigate to the Player Profile. From there, you can star the player if you would like to add them to your Prospect List.",
              },
            ],
          },
        ];
      case "SETTINGS":
        return [
          {
            textOne: "Changing the ",
            bold: "Friend Requests",
            textTwo:
              " toggle to “on” allows you to receive notifications for new friend requests from other users that have your email address. Please see the Friends Page for your list of Friends within the app.",
          },
          {
            textOne: "Changing the ",
            bold: "In-App Notifications",
            textTwo:
              " toggle to “on” allows you to receive notifications for messages from your Friends",
          },
          {
            textOne: "Changing the ",
            bold: "Email Notifications",
            textTwo:
              " toggle to “on” allows you to receive email notifications for any of the notifications that you receive within the Notifications Page",
          },
          {
            textOne: "Changing the ",
            bold: "Player Matching",
            textTwo:
              " toggle to “on” allows you to receive notifications for players that are newly fitting into the filters of your Saved Searches under the Search Page; this will also cause the menu of Saved Searches to pop-up below.",
            subText: [
              {
                text:
                  "Press each of the toggles on/off to the right of each of the Saved Searches to turn notifications on/off",
              },
              {
                text:
                  "Press “Edit List” to either edit the name of a saved search, or delete a saved search",
              },
            ],
          },
          {
            textOne:
              "Press Edit Profile to change any of the profile information that you entered when creating the account (including Name, Contact Info, School Affiliation, Title, Affiliated Team). Press ",
            bold: "Save",
            textTwo: " on the bottom to save any changes made.",
          },
        ];
      case "SEARCH":
        return [
          {
            text:
              "The Search Page allows you to search for new Prospects by a direct Player Name Search or an Advanced Search",
          },
          {
            textOne: "In the ",
            bold: "Player Name Search Bar",
            textTwo:
              ", type in the name of prospect (by first or last name) to search for a specific player.",
          },
          {
            textOne: "Press ",
            bold: "Advanced Search",
            textTwo:
              " to expand the list of filters by which you can search for a player profile.",
            subText: [
              {
                text:
                  "Note: You will need to refine search until you get 150 or less results",
              },
              {
                text:
                  "Once you have selected all of the desired filters (checking the box next to the field and selecting the accepPagele field range), you will navigate to the results page.",
              },
              {
                textOne: "From the ",
                bold: "Results Page",
                textTwo:
                  ", you can press player names to navigate to individual player profile",
                subText: [
                  {
                    text:
                      "Press the star next to each player to save them to your Prospects list (under the Prospects Page)",
                  },
                  {
                    text:
                      "Press the star at the top right corner to add all of the players listed in the Results page to your Prospects list",
                  },
                  {
                    text:
                      "Press the (floppy disk icon) in the top right corner to save this filtered search as a Saved Search (this will show on the previous Search Page screen)",
                  },
                  {
                    text: "You can view the Results Page in Landscape format",
                  },
                ],
              },
            ],
          },
          {
            textOne: "Press ",
            bold: "My Saved Searches",
            textTwo: " to see the list of Saved Searches you have created",
            subText: [
              {
                text:
                  "Press one of the saved searches to be directed to the results page that fit in with the previously saved filters",
              },
              {
                text:
                  "In the settings page (which you can access by pressing the icon in the top right corner). you can edit saved profile names, delete saved searches, or edit the notifications settings for each.",
              },
            ],
          },
        ];
      case "CALENDAR":
        return [
          {
            text:
              "The Schedule Page shows games at which your Prospects (saved under the Prospects Page) will be playing.",
          },
          {
            textOne: "Games listed under ",
            bold: "Upcoming Games",
            textTwo: " are due to occur within the next three days",
          },
          {
            textOne: "Games listed under ",
            bold: "Other Games",
            textTwo: " are due to occur more than three days from now",
          },
          {
            textOne: "Press ",
            bold: "Add Event +",
            textTwo:
              " to manually add a game that is not already listed in the app. This will prompt you to enter in the date, address, teams playing, and prospects expected to play for this event.",
          },
          {
            text:
              "Press an event listed to see its details. You can edit or delete the event by pressing (the pencil icon) or (the trash icon) at the bottom of the event details",
            subText: [
              {
                text:
                  "Press (the location icon) to open the event location in Apple Maps",
              },
              {
                text:
                  "Press (the calendar icon) to add the event to your Apple Calendar. Should the event information change, you will need to edit the event info directly in the Apple Calendar.",
              },
              {
                text:
                  "Press the name of the Prospects listed under the event to navigate directly to their profile",
              },
            ],
          },
        ];
    }

    return [
      {
        text: "",
      },
    ];
  };

  const getBullet = (indent: number) => {
    if (indent === 0) {
      return <span style={{ color: "white" }}>{`• `}</span>;
    } else if (indent === 30) {
      return <span style={{ color: "white" }}>{`○  `}</span>;
    } else if (indent === 60) {
      return <span style={{ color: "white" }}>{`‣  `}</span>;
    }
    return <div />;
  };

  const renderLastText = (text: string) => {
    if (!text) {
      return <span />;
    }
    let splitString = null;
    if (text.includes("Friends Page")) {
      splitString = "Friends Page";
    }
    if (text.includes("Notifications Page")) {
      splitString = "Notifications Page";
    }
    if (text.includes("Search Page")) {
      splitString = "Search Page";
    }
    if (text.includes("Saved Searches")) {
      splitString = "Saved Searches";
    }
    if (text.includes("Big Board Page")) {
      splitString = "Big Board Page";
    }
    if (text.includes("Reports Page")) {
      splitString = "Reports Page";
    }
    if (splitString) {
      let items = text.split(splitString);
      if (items.length === 1) {
        return (
          <span>
            {items[0]}
            <span style={{ fontWeight: "bold" }}>{splitString}</span>
          </span>
        );
      } else if (items.length === 2) {
        return (
          <span>
            {items[0]}
            <span style={{ fontWeight: "bold" }}>{splitString}</span>
            {items[1]}
          </span>
        );
      }
    }
    return <span>{text}</span>;
  };

  const renderTextBullet = (
    item: {
      text?: string;
      textOne?: string;
      bold?: string;
      textTwo?: string;
      icon?: any;
    },
    marginLeft: number,
    bullet: any
  ) => {
    if (item.icon) {
      return (
        <div
          key={item.text ? item.text.substr(0, 12) : item.textOne.substr(0, 12)}
          style={{
            marginBottom: 8,
            marginLeft: marginLeft,
          }}
        >
          {bullet}
          <span
            style={{
              color: colors.white,
            }}
          >
            {item.textOne}
            {item.icon}
            {renderLastText(item.textTwo)}
          </span>
        </div>
      );
    }
    return item.bold ? (
      <div
        key={item.text ? item.text.substr(0, 12) : item.textOne.substr(0, 12)}
        style={{
          marginBottom: 8,
          marginLeft: marginLeft,
        }}
      >
        <span
          style={{
            color: colors.white,
          }}
        >
          {bullet}
          {item.textOne}
          <span style={{ fontWeight: "bold" }}>{item.bold}</span>
          <span>{renderLastText(item.textTwo)}</span>
        </span>
      </div>
    ) : (
      <div
        key={item.text ? item.text.substr(0, 12) : item.textOne.substr(0, 12)}
        style={{
          marginBottom: 8,
          marginLeft: marginLeft,
        }}
      >
        <span
          style={{
            color: colors.white,
          }}
        >
          {bullet}
          {renderLastText(item.text)}
        </span>
      </div>
    );
  };

  const renderTextWithSubtext = (
    item: { text?: string; textOne?: string; bold?: string; textTwo?: string },
    subText: { text: string }[],
    indent: number
  ) => {
    return (
      <div
        key={item.text ? item.text.substr(0, 12) : item.textOne.substr(0, 12)}
      >
        {renderTextBullet(item, indent, getBullet(indent))}
        {renderBullets(subText, indent + 30)}
      </div>
    );
  };

  const renderBullets = (bullets: any[], indent: number) => {
    return (
      <div>
        {bullets.map((item, index) => {
          if (item.subText) {
            return renderTextWithSubtext(item, item.subText, indent);
          } else {
            return renderTextBullet(item, indent, getBullet(indent));
          }
        })}
      </div>
    );
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.kind}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: colors.white, backgroundColor: colors.accent }}
      >
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {renderBullets(getBulletPoints(), 0)}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default HelpModal;
