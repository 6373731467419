import React, { useState } from "react";
import "../../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import colors from "constants/colors";

function SingleVideoRow({ title, value }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <table style={{ width: "100%", marginBottom: 10 }}>
        <th
          style={{
            width: "50%",
            borderCollapse: "collapse",
            fontWeight: "bold",
            color: colors.accent,
            textAlign: "center",
          }}
        >
          <td>{title}</td>
        </th>
        {value === null ? (
          <tr>
            <td>{"N/A"}</td>
          </tr>
        ) : (
          <tr />
        )}
      </table>
      {value !== null ? (
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ marginRight: 5, width: 300 }}>
              <Player>
                <source src={value} />
              </Player>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default SingleVideoRow;
