import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";

function TopLabelMultilineBox(props: {
  title: string;
  placeholder?: string;
  value: string;
  setValue: any;
  minHeight?: number;
  skipRichText?: boolean;
}) {
  const [editorState, setEditorState] = React.useState(() =>
    RichTextEditor.createEmptyValue()
  );

  useEffect(() => {
    if (props.value) {
      setEditorState(RichTextEditor.createValueFromString(props.value, "html"));
    }
  }, []);

  const onChange = (value) => {
    setEditorState(value);
    if (props.setValue) {
      props.setValue(value.toString("html"));
    }
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],

    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 20,
      }}
    >
      <p>{props.title}</p>
      {props.skipRichText ? (
        <textarea
          placeholder={props.placeholder}
          style={{
            minHeight: props.minHeight ? props.minHeight : 50,
            backgroundColor: colors.white,
            borderRadius: 4,
            color: "black",
            borderStyle: "solid",
            padding: 8,
          }}
          value={props.value}
          onChange={(evt) => props.setValue(evt.target.value)}
        />
      ) : (
        <RichTextEditor
          value={editorState}
          onChange={onChange}
          toolbarConfig={toolbarConfig}
          // autoFocus={true}
        />
      )}
    </div>
  );
}

export default TopLabelMultilineBox;
