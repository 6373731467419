import colors from "constants/colors";
import React, { useEffect, useState } from "react";

function OneBoxLine(props: { title: string; value: string; setValue: any }) {
  const ignore_color = [
    "Home to First",
    "60 Yard",
    "40 Yard",
    "Pos. in Order",
    "Character",
    "Entry Level",
    "ETA",
    "Arm Angle",
  ];

  const colorForValue = (value: string) => {
    if (
      props.title &&
      props.title !== "" &&
      !ignore_color.includes(props.title)
    ) {
      if (value) {
        switch (parseInt(value)) {
          case 2:
            return "#4A3D3D";
          case 3:
            return "#DB2330";
          case 4:
            return "#DB8E33";
          case 5:
            return "#80B304";
          case 6:
            return "#6690FF";
          case 7:
            return "#F5EE48";
          case 8:
            return "rgba(0, 0, 0, 0.4)";
          default:
            return colors.transparentContrast5;
        }
      }
      return colors.transparentContrast5;
    }
    return colors.transparentContrast5;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 20,
      }}
    >
      <div className="report-row">
        <p style={{ width: 150, alignSelf: "center" }}>{props.title}</p>
        <input
          type="text"
          style={{
            fontWeight: 800,
            textAlign: "center",
            marginRight: 10,
            marginLeft: 40,
            borderWidth: 2,
            borderStyle: "solid",
            backgroundColor: colors.white,
            borderRadius: 4,
            color: colorForValue(props.value),
            borderColor: colorForValue(props.value),
            padding: 8,
          }}
          value={props.value}
          onChange={(evt) => props.setValue(evt.target.value)}
        />
      </div>
    </div>
  );
}

export default OneBoxLine;
