import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import { MonitorDocument } from "models/MonitorDocument";
import { User } from "models/User";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import Linkify from "react-linkify";
import { useFirestore } from "react-redux-firebase";
import { useHistory, useParams } from "react-router-dom";
import { createUser, fetchDocument } from "services/MonitorService";
import { AiFillGoogleCircle } from "react-icons/ai";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function MonitorDetailPage(props: any) {
  const { monitorId } = useParams();
  let history = useHistory();
  const [post, setPost] = useState<MonitorDocument | null>(null);
  const [stoplightId, setStoplightId] = useState(null);
  const me = useMe<User>();
  const firestore = useFirestore();
  const prevId = usePrevious(stoplightId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = () => {
    if (me.stoplightId) {
      setStoplightId(me.stoplightId);
    } else {
      createStoplightUser();
    }
  };

  const createStoplightUser = async () => {
    setLoading(true);
    let res = await createUser(me);
    setLoading(false);
    if (res && res.guid) {
      await firestore.collection("users").doc(me.id).update({
        stoplightId: res.guid,
      });
      setStoplightId(me.stoplightId);
    }
  };

  useEffect(() => {
    if (stoplightId && !prevId) {
      loadPost();
    }
  }, [stoplightId]);

  const loadPost = async () => {
    console.log("start fetch");
    let res = await fetchDocument(stoplightId, monitorId);
    if (res && res.docs && res.docs.length == 1) {
      console.log(res.docs[0]);
      setPost(res.docs[0]);
    }
  };

  const renderHeader = () => {
    if (post._source.pai_source_name == "Google" && post._source.title) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 12,
            backgroundColor: colors.lightBlue,
            padding: 20,
            color: colors.white,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              flex: 1,
              alignSelf: "center",
            }}
            className="white-urls"
          >
            <p style={{ fontWeight: "bold", fontSize: 18 }}>
              {post._source.title}
            </p>
          </div>
          <a href={post._source.url}>
            <AiFillGoogleCircle style={{ color: colors.white, fontSize: 24 }} />
          </a>
        </div>
      );
    }
    let avatar = null;
    if ((post._source.images || []).length > 0) {
      avatar = post._source.images[post._source.images.length - 1];
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 12,
          backgroundColor: colors.lightBlue,
          padding: 20,
          color: colors.white,
        }}
      >
        <div>
          <img
            src={avatar}
            style={{
              width: 70,
              height: 70,
              borderRadius: 70 / 2,
              marginRight: 20,
            }}
          />
        </div>

        <div
          style={{
            flexDirection: "column",
            display: "flex",
            flex: 1,
            alignSelf: "center",
          }}
          className="white-urls"
        >
          <p style={{ fontWeight: "bold", fontSize: 18 }}>
            {post._source.author_name}
          </p>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <p style={{ fontSize: 14 }}>{post._source.author_bio}</p>
          </Linkify>
        </div>
        <a href={post._source.url} style={{ color: colors.white }}>
          <p style={{ fontWeight: "bold" }}>
            {post._source.author_twitter_handle}
          </p>
        </a>
      </div>
    );
  };

  const renderDetails = () => {
    if (post._source.pai_source_name == "Google" && post._source.title) {
      return <div />;
    }

    return (
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <p style={{ fontSize: 14 }}>
          <span
            style={{ color: colors.lightBlue, fontWeight: "bold" }}
          >{`Source: `}</span>
          {post._source.pai_source_name}
        </p>
        <p style={{ fontSize: 14 }}>
          <span
            style={{ color: colors.lightBlue, fontWeight: "bold" }}
          >{`Followers: `}</span>
          {`${post._source.author_followers}`}
        </p>
        <p style={{ fontSize: 14 }}>
          <span
            style={{ color: colors.lightBlue, fontWeight: "bold" }}
          >{`Following: `}</span>
          {`${post._source.author_following}`}
        </p>
        <p style={{ fontSize: 14 }}>
          <span
            style={{ color: colors.lightBlue, fontWeight: "bold" }}
          >{`Region: `}</span>
          {`${post._source.author_region}`}
        </p>
      </div>
    );
  };

  const renderPost = () => {
    return (
      <div style={{ margin: 20 }}>
        <div
          style={{
            backgroundColor: colors.lightGray,
            padding: 20,
            paddingTop: 10,
            borderRadius: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 3,
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: 14 }}>
              {moment(post._source.created_on).format("MM/DD hh:mm a")}
            </p>
          </div>

          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <p>{post._source.content}</p>
          </Linkify>
        </div>
      </div>
    );
  };

  const renderGallery = () => {
    let images = [...(post._source.images || [])];
    if (post._source.pai_source_name == "Google" && post._source.title) {
      //all images
    } else {
      if (images.length == 3) {
        images = [images[0]];
      } else {
        images.splice(-1);
      }
    }

    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {images.map((item, index) => (
          <div key={index} style={{ marginRight: 12, marginBottom: 12 }}>
            <img
              src={item}
              style={{
                height: 250,
                objectFit: "contain",
                borderRadius: 8,
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return post ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        minHeight: "80vh",
        marginLeft: "1rem",
        marginRight: "1rem",
      }}
    >
      <div
        style={{
          backgroundColor: colors.white,
          marginBottom: 20,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {renderHeader()}
        {renderDetails()}
        {renderPost()}
        {renderGallery()}

        {loading ? (
          <div
            style={{
              position: "absolute",
              left: "47vw",
              top: "30vh",
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        flex: 1,
        display: "flex",
        minHeight: "80vh",
        marginLeft: "1rem",
        marginRight: "1rem",
      }}
    >
      <div
        style={{
          backgroundColor: colors.white,
          marginBottom: 20,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "47vw",
            top: "30vh",
          }}
        >
          <Spinner animation="border" />
        </div>
      </div>
    </div>
  );
}

export default MonitorDetailPage;
