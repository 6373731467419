import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Button } from "react-bootstrap";

export default function RemovableChip(props: { name: string; onClick: any }) {
  return (
    <Button
      onClick={() => props.onClick()}
      style={{
        height: 40,
        minHeight: 40,
        borderRadius: 60,
        minWidth: 100,
        backgroundColor: "white",
        borderColor: "#003366",
        borderWidth: 3,
        color: "#003366",
        paddingLeft: 18,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontWeight: 600,
        fontSize: 14,
        whiteSpace: "nowrap",
        paddingRight: 0,
        justifyContent: "space-between",
      }}
    >
      {props.name.toUpperCase()}
      <AiFillCloseCircle style={{ marginLeft: 8, width: 32, height: 32 }} />
    </Button>
  );
}
