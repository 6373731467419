import colors, { TAG_COLORS } from "constants/colors";
import { Player } from "models/Player";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PlayerService } from "services/PlayerService";
import { FaTshirt } from "react-icons/fa";
import { usePlayersMeta, usePlayerMeta } from "hooks/player-meta";

function ReadonlyPlayerItem({
  player,
  year,
}: {
  player: Player;
  year: string;
}) {
  const playerMeta = usePlayerMeta(player?.id);
  
  const aidType = () => {
    let kind = "none";
    if (PlayerService.getScholarship(player, year, "schol") > 0) {
      kind = "schol";
    }
    if (PlayerService.getScholarship(player, year, "finAid") > 0) {
      if (kind == "schol") {
        kind = "both";
      } else {
        kind = "finAid";
      }
    }
    return kind;
  };

  const aidColor = () => {
    let aid = aidType();
    switch (aid) {
      case "none":
        return colors.transparentContrast3;
      case "both":
        return colors.accent;
      case "schol":
        return colors.red;
      case "finAid":
        return "green";
    }

    return "#000";
  };

  return (
    <div
      style={{
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: colors.lightBlue,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: TAG_COLORS[playerMeta?.tagColor] || colors.white,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            backgroundColor: colors.accent,
            width: 40,
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: colors.lightBlue,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: colors.white,
              width: 40,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {`#${player.jerseyNumber ? player.jerseyNumber : ""}`}
          </p>
        </div>
        {/* @ts-ignore */}
        {player.manual ? (
          <OverlayTrigger
            rootClose
            trigger={["click"]}
            placement={"top"}
            overlay={
              <Popover id={`manual-player`}>
                <Popover.Content style={{}}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p
                      style={{
                        color: colors.accent,
                        textAlign: "center",
                        fontStyle: "italic",
                      }}
                    >
                      {`This player was manually added to the system.`}
                    </p>
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <p
              style={{
                cursor: "pointer",
                paddingTop: 1,
                paddingBottom: 1,
                marginRight: 5,
                paddingLeft: 5,
                color:
                  player.bats == "S"
                    ? colors.lightBlue
                    : player.bats == "L"
                    ? colors.red
                    : "black",
              }}
            >
              {`${PlayerService.getName(player)}`}
            </p>
          </OverlayTrigger>
        ) : (
          <Link
            to={
              player && player.college
                ? `/collegeplayer/${player.external_id}`
                : `/player/${player.external_id}`
            }
            style={{
              color:
                player.bats == "S"
                  ? colors.lightBlue
                  : player.bats == "L"
                  ? colors.red
                  : "black",
            }}
          >
            <p
              style={{
                paddingTop: 1,
                paddingBottom: 1,
                marginRight: 5,
                paddingLeft: 5,
              }}
            >
              {`${PlayerService.getName(player)}`}
            </p>
          </Link>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <OverlayTrigger
          rootClose
          trigger={["click"]}
          placement={"right"}
          overlay={
            <Popover id={`popover-positioned-right`}>
              <Popover.Title
                style={{
                  backgroundColor: "#003366",
                  color: "#fff",
                  padding: 0,
                  margin: 0,
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "row", width: 250 }}
                >
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      {"Scholarship"}
                    </p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      {"Financial Aid"}
                    </p>
                  </div>
                </div>
              </Popover.Title>
              <Popover.Content
                style={{
                  padding: 0,
                  margin: 0,
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        color: colors.accent,
                        textAlign: "center",
                      }}
                    >
                      {PlayerService.getScholarship(player, year, "schol")}
                    </p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        color: colors.accent,
                        textAlign: "center",
                      }}
                    >
                      {PlayerService.getScholarship(player, year, "finAid")}
                    </p>
                  </div>
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <div
            style={{
              width: 40,
              borderLeftStyle: "solid",
              borderLeftWidth: 1,
              borderLeftColor: colors.lightBlue,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: aidColor(),
                fontWeight: "bold",
              }}
            >
              {PlayerService.getScholarship(player, year, "both").toFixed(2)}
            </p>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
}

export default ReadonlyPlayerItem;
