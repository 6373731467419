import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const UnderlineButton = (props: {
  onClick: any;
  text: string;
  color?: string;
  style?: any;
}) => {
  return (
    <Button
      style={{
        color: colors.accent,
        backgroundColor: "transparent",
        padding: 6,
        borderWidth: 0,
        boxShadow: "none",
        whiteSpace: "nowrap",
      }}
      onClick={props.onClick}
    >
      <p
        style={{
          color: props.color ? props.color : colors.accent,
          textAlign: "center",
          opacity: 0.6,
          textDecorationColor: props.color ? props.color : colors.accent,
          textDecorationLine: "underline",
          textDecorationStyle: "solid",
          ...props.style,
        }}
      >
        {props.text}
      </p>
    </Button>
  );
};

export default UnderlineButton;
