import colors from "constants/colors";
import React, { useState } from "react";

function LabelDescription({ label, value }) {
  return (
    <table style={{ width: "100%", marginBottom: 10 }}>
      <th
        style={{
          width: "50%",
          border: "1px solid #467db3",
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
        }}
      >
        <td>{label}</td>
      </th>
      <tr>
        <td>{value ? value : "N/A"}</td>
      </tr>
    </table>
  );
}

export default LabelDescription;
