import { YEARS } from "constants/data";
import colors from "constants/colors";
import React from "react";
import Select from "react-select";

const YearDropdown = ({
  selectYear,
  allowAll,
  minWidth,
  maxWidth,
  customYears,
  placeholder,
  season,
}: {
  selectYear: any;
  allowAll: boolean;
  minWidth?: number;
  maxWidth?: number;
  customYears?: string[];
  placeholder?: string;
  season?: boolean;
}) => {
  const onChange = (item) => {
    selectYear([item.value]);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth || 250,
      maxWidth: maxWidth || 320,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: colors.lightBlue,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: colors.white,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: colors.white,
      ":hover": {
        backgroundColor: colors.accent,
      },
    }),
  };

  return (
    <Select
      options={(customYears || YEARS)
        .filter((item) => (allowAll ? true : item !== "ALL YEARS"))
        .map((item) => ({
          value: item,
          label: !season ? item :(
            parseInt(item) ? `${item}-${parseInt(item) + 1}` : item
          )
      }))}
      isMulti={false}
      isClearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder || "Select year..."}
    />
  );
};

export default YearDropdown;
