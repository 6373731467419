import React, { useEffect, useState } from "react";
import { PlayerService } from "services/PlayerService";

export default function LargePlayerImage({
  player,
  playerName,
  containerStyles,
}) {
  const getInitials = () => {
    if (playerName) {
      let names = playerName.split(" ");
      if (names.length > 0) {
        return `${names[0].charAt(0).toUpperCase()}${names[1]
          .charAt(0)
          .toUpperCase()}`;
      }
    }
    return "";
  };

  let pic = player ? PlayerService.getServerPhoto(player) : null;
  return pic ? (
    <img
      src={pic}
      style={{
        ...containerStyles,
        width: 100,
        height: 130,
        overflow: "hidden",
        borderRadius: 4,
      }}
    />
  ) : player ? (
    <div
      style={{
        ...containerStyles,
        width: 100,
        height: 130,
        backgroundColor: "#003366",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: "#fff",
          textAlign: "center",
          fontWeight: 600,
          fontSize: 26,
        }}
      >
        {PlayerService.getInitials(player)}
      </p>
    </div>
  ) : (
    <div
      style={{
        ...containerStyles,
        width: 100,
        height: 130,
        backgroundColor: "#003366",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: "#fff",
          textAlign: "center",
          fontWeight: 600,
          fontSize: 26,
        }}
      >
        {getInitials()}
      </p>
    </div>
  );
}
