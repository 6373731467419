import colors from "constants/colors";
import React from "react";
import Select from "react-select";

const SourceDropdown = ({
  selectSource,
  selected,
  minWidth,
  maxWidth,
  sources,
  placeholder,
}: {
  selectSource: any;
  selected: {
    active: boolean;

    guid: string;
    name: string;
  }[];
  minWidth?: number;
  maxWidth?: number;
  sources: {
    active: boolean;

    guid: string;
    name: string;
  }[];
  placeholder?: string;
}) => {
  const onChange = (items, val) => {
    selectSource(items.map((item) => item.value));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: minWidth || 250,
      maxWidth: maxWidth || 320,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: colors.lightBlue,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: colors.white,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: colors.white,
      ":hover": {
        backgroundColor: colors.accent,
      },
    }),
  };

  return (
    <Select
      options={sources.map((item) => ({ value: item, label: item.name }))}
      value={selected.map((item) => ({
        value: item,
        label: item.name,
      }))}
      isMulti={true}
      clearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder || "Source"}
    />
  );
};

export default SourceDropdown;
