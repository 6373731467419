import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function MediumButton(props: {
  title: string;
  selected: boolean;
  onClick: any;
}) {
  return (
    <Button
      className={props.selected ? "position-tile" : "position-tile-unselected"}
      style={{ width: 120 }}
      onClick={(evt) => {
        props.onClick();
      }}
    >
      <p
        className="position-text"
        style={{ color: props.selected ? "#003366" : "#fff" }}
      >
        {props.title}
      </p>
    </Button>
  );
}

export default MediumButton;
