import firebase from "firebase";

const RESERVED_USERNAMES = [
  "Aaliyah",
  "AaronPeterson",
  "Abigail",
  "Adalyn",
  "AdamPeterson",
  "Addison",
  "Adeline",
  "Adrian",
  "Adrienne",
  "Agustin",
  "Alaina",
  "Albert",
  "Alexander",
  "Alexis",
  "Allison",
  "Allude",
  "Allude.com",
  "Alluder",
  "Alluder.com",
  "Allusion",
  "Althea",
  "Alysha",
  "Amanda",
  "Amelia",
  "Amina",
  "Anastasia",
  "Anaya",
  "Andrea",
  "Andrei",
  "Andrew",
  "Angela",
  "Anthony",
  "Antoni",
  "Ariana",
  "Ashley",
  "Aubrey",
  "Audrey",
  "Aurora",
  "Austin",
  "Barbara",
  "Barbare",
  "Benjamin",
  "Beverly",
  "Bradley",
  "Brandon",
  "Brayden",
  "Brenda",
  "Brendon",
  "Brittany",
  "Brooke",
  "Brooklyn",
  "Bryson",
  "Callie",
  "Cameron",
  "Camila",
  "Camilla",
  "Carlota",
  "Carole",
  "Carolyn",
  "Carson",
  "Carter",
  "Catherine",
  "Charles",
  "Charlie",
  "Charlotte",
  "Checkout",
  "Cheryl",
  "Chinese",
  "Chrissy",
  "Christian",
  "Christina",
  "Christine",
  "Christopher",
  "Claire",
  "Colton",
  "Connor",
  "Cooper",
  "Cynthia",
  "Damian",
  "Daniel",
  "Danielle",
  "Deborah",
  "Delilah",
  "Denise",
  "Dennis",
  "Documentation",
  "Dominic",
  "Dominique",
  "Donald",
  "Dorothy",
  "Douglas",
  "Dragan",
  "Easton",
  "Edward",
  "Eleanor",
  "Eliana",
  "Elijah",
  "Elizabeth",
  "Elliot",
  "Emerson",
  "Emilia",
  "Enzo Gabriel",
  "Eugene",
  "Evelyn",
  "Ezekiel",
  "Farnam",
  "Fatemeh",
  "Florencia",
  "Following",
  "Frances",
  "Franklin",
  "Gabriel",
  "Gabriella",
  "Gabrielle",
  "George",
  "Georgie",
  "Gerald",
  "Gianna",
  "Giulia",
  "Gloria",
  "Grayson",
  "Gregory",
  "Gujarati",
  "Hailey",
  "Hannah",
  "Harold",
  "Harper",
  "Hassan",
  "Heather",
  "Hudson",
  "Hunter",
  "Hussein",
  "Ibrahim",
  "Imulus",
  "Ioana",
  "Isabella",
  "Isabelle",
  "Isaiah",
  "Ivaana",
  "Ivanka",
  "Jackson",
  "Jacqueline",
  "Jameson",
  "Janice",
  "Jasmine",
  "Jasmyn",
  "Jayden",
  "Jazmin",
  "Jeffrey",
  "Jelena",
  "Jennifer",
  "Jeremiah",
  "Jeremy",
  "Jessica",
  "Jessie",
  "Johnny",
  "Jonathan",
  "Jordan",
  "Jordyn",
  "Joseph",
  "Joshua",
  "Josiah",
  "Jovana",
  "Judith",
  "Julian",
  "Julisa",
  "Junior",
  "Katherine",
  "Kathleen",
  "Kathryn",
  "Kaylee",
  "Kennedy",
  "Kenneth",
  "Khaled",
  "Kimberly",
  "Kinsley",
  "Kirollos",
  "Krishna",
  "Landon",
  "Lauren",
  "Laurie",
  "Lawrence",
  "Lelani",
  "Leonardo",
  "Leonor",
  "Liliana",
  "Lillian",
  "Lincoln",
  "London",
  "Louise",
  "Lucija",
  "Mackenzie",
  "Madelyn",
  "Madison",
  "Mahmoud",
  "Makayla",
  "Malachi",
  "Mamadou",
  "Mandarin",
  "Manuel",
  "Margaret",
  "Margret",
  "Mariam",
  "Mariana",
  "Marija",
  "Marilyn",
  "Marina",
  "Martha",
  "Martina",
  "Maryam",
  "Matilde",
  "Matthew",
  "Maverick",
  "Maximilien",
  "Mehmet",
  "Melanie",
  "Melisa",
  "Melissa",
  "Merjem",
  "Michael",
  "Michelle",
  "Mihail",
  "Milica",
  "Min-nan",
  "Mohamed",
  "Mohammed",
  "Muhamad",
  "Muhammad",
  "Mustafa",
  "Natalie",
  "Nathan",
  "Nathaniel",
  "Nevaeh",
  "Nicholas",
  "Nicole",
  "Nikola",
  "Oauth",
  "Offers",
  "Oliver",
  "Olivia",
  "Oscar",
  "Paisley",
  "Pamela",
  "Panjabi",
  "Patricia",
  "Patrick",
  "Pauline",
  "Penelope",
  "Peyton",
  "Pierre",
  "Portuguese",
  "Python",
  "Radrigo",
  "Raelynn",
  "Ralphy",
  "Raymond",
  "Reagan",
  "Rebecca",
  "Richard",
  "Robert",
  "Roberts",
  "Roman",
  "Ronald",
  "Rowan",
  "Russell",
  "Russian",
  "Salma",
  "Samantha",
  "Samuel",
  "Sandra",
  "Santiago",
  "Savannah",
  "Scarlett",
  "Scotty",
  "Sebastian",
  "Security",
  "Sharon",
  "Shirley",
  "Signup",
  "Skyler",
  "Sofiya",
  "Somchai",
  "Sophia",
  "Sophie",
  "Sslwebmaster",
  "Stefan",
  "Stella",
  "Stephanie",
  "Stephen",
  "Steven",
  "Stevenson",
  "Sumayah",
  "Sysadmin",
  "Sysadministrator",
  "TaraWilson",
  "Telugu",
  "Teresa",
  "Tereza",
  "Theodore",
  "Theresa",
  "Thomas",
  "Timothy",
  "Translations",
  "Upload",
  "Valentina",
  "Victor",
  "Victoria",
  "Viktor",
  "Viktoria",
  "Vincent",
  "Violet",
  "Virginia",
  "Walter",
  "Widelene",
  "William",
  "Willie",
  "Willow",
  "Xavier",
  "Yasmine",
  "Yassin",
  "Youssef",
  "Zachary",
  "Zuzanna",
  "access",
  "account",
  "accounts",
  "activate",
  "address",
  "administration",
  "administrator",
  "advertising",
  "affiliate",
  "affiliates",
  "analytics",
  "android",
  "anonymous",
  "arabic",
  "archive",
  "archives",
  "authentication",
  "avatar",
  "awadhi",
  "azerbaijani",
  "backup",
  "banner",
  "banners",
  "bengali",
  "bhojpuri",
  "billing",
  "burmese",
  "business",
  "cadastro",
  "calendar",
  "campaign",
  "cancel",
  "careers",
  "changelog",
  "client",
  "cliente",
  "codereview",
  "comercial",
  "compare",
  "compras",
  "config",
  "configuration",
  "connect",
  "contact",
  "contest",
  "create",
  "create",
  "dashboard",
  "delete",
  "design",
  "designer",
  "direct_messages",
  "directory",
  "domain",
  "download",
  "downloads",
  "e-commerce",
  "ecommerce",
  "editor",
  "employment",
  "english",
  "enterprise",
  "facebook",
  "favorite",
  "favorites",
  "feedback",
  "fleets",
  "follow",
  "followers",
  "forums",
  "friend",
  "friends",
  "gadget",
  "gadgets",
  "german",
  "github",
  "google",
  "groups",
  "homepage",
  "hosting",
  "hostmaster",
  "hostname",
  "image",
  "images",
  "images",
  "index",
  "indice",
  "indice",
  "information",
  "intranet",
  "invitations",
  "invite",
  "iphone",
  "italian",
  "japanese",
  "javanese",
  "javascript",
  "kannada",
  "knowledgebase",
  "korean",
  "language",
  "languages",
  "logout",
  "mailer",
  "mailing",
  "maithil",
  "malayalam",
  "manager",
  "marathi",
  "marketing",
  "master",
  "message",
  "messenger",
  "microblog",
  "microblogs",
  "mobile",
  "movies",
  "musicas",
  "named",
  "network",
  "newsletter",
  "nickname",
  "noticias",
  "oauth_clients",
  "online",
  "openid",
  "operator",
  "orders",
  "organizations",
  "panjabi",
  "password",
  "photoalbum",
  "photos",
  "plugin",
  "plugins",
  "polish",
  "popular",
  "postfix",
  "postmaster",
  "privacy",
  "profile",
  "project",
  "projects",
  "public",
  "python",
  "random",
  "recruitment",
  "register",
  "registration",
  "remove",
  "replies",
  "romanian",
  "sample",
  "samples",
  "scripts",
  "search",
  "secure",
  "security",
  "serbo-croatian",
  "service",
  "sessions",
  "setting",
  "settings",
  "setup",
  "signin",
  "signup",
  "sitemap",
  "soporte",
  "spanish",
  "ssladmin",
  "ssladministrator",
  "staging",
  "static",
  "status",
  "stores",
  "stories",
  "styleguide",
  "subdomain",
  "subscribe",
  "subscriptions",
  "suporte",
  "support",
  "support-details",
  "supportdetails",
  "system",
  "tablet",
  "tablets",
  "telnet",
  "themes",
  "trends",
  "turkish",
  "twitter",
  "ukrainian",
  "unfollow",
  "unsubscribe",
  "update",
  "upload",
  "usage",
  "username",
  "usuario",
  "vendas",
  "videos",
  "vietnamese",
  "visitor",
  "weather",
  "webmail",
  "webmaster",
  "website",
  "websites",
  "widgets",
  "workshop",
  "yoruba",
  "yourdomain",
  "yourname",
  "yoursite",
  "yourusername",
];

export function validateUsernameFormat(username) {
  if (username.length < 6 || !username) {
    return false;
  }
  var letterNumberUnderscore = /^[\w]+$/;

  return !!username.match(letterNumberUnderscore);
}

export function validatePasswordFormat(password) {
  const Lt = "a-zA-Z"; /* letters */
  const Nu = "0-9"; /* numbers */
  const Sp = "@#$%^&+=!_\\-()"; /* specials */
  const LNS = `${Lt}${Nu}${Sp}`;
  const passwordRegex = new RegExp(`^(?=.*[${Nu}])(?=.*[${Lt}])[${LNS}]{6,}$`);

  return !!password && !!password.match(passwordRegex);
}

export async function checkUsernameExists(username) {
  if (
    RESERVED_USERNAMES.map((item) => item.toLowerCase()).includes(
      username.toLowerCase()
    )
  ) {
    return true;
  }
  return await firebase
    .firestore()
    .collection("users")
    .where("username", "==", username)
    .get()
    .then((querySnapshot) => {
      return !!querySnapshot.size;
    });
}

export async function checkUserEmailExists(email) {
  return await firebase
    .firestore()
    .collection("users")
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      return !!querySnapshot.size;
    });
}

export function validateEmailFormat(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !!email && !!email.match(emailRegex);
}
