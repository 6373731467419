import React, { useState } from "react";
import "../../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import colors from "constants/colors";

function VideosList({ posReport }) {
  return (
    <div style={{ marginLeft: 10, marginTop: 20 }}>
      <table style={{ width: "100%", marginBottom: 10 }}>
        <th
          style={{
            width: "50%",
            border: "1px solid #467db3",
            borderCollapse: "collapse",
            backgroundColor: colors.lightBlue,
            color: colors.white,
            textAlign: "center",
          }}
        >
          <td>Videos</td>
        </th>
        {(posReport.videos || []).length === 0 ? (
          <tr>
            <td>{"N/A"}</td>
          </tr>
        ) : (
          <tr />
        )}
      </table>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
        <div style={{ flexDirection: "row", display: "flex" }}>
          {(posReport.videos || []).map((item, index) => (
            <div style={{ marginRight: 5, width: 300 }} key={index}>
              <Player>
                <source src={item} />
              </Player>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideosList;
