/*****************************************************************************
 * Import
 *****************************************************************************/
import React from "react";

import { CollegePlayer } from "models/CollegePlayer";
import colors from "constants/colors";

import { IoMdSchool } from "react-icons/io";
import { TbSchool } from "react-icons/tb";

/*****************************************************************************
 * Public Components
 *****************************************************************************/

export const CollegePlayerIcon = ({
  player,
  color,
  style,
}: {
  player?: CollegePlayer;
  color?: any;
  style?: any;
}) => {
  const Icon = player?.juco ? TbSchool : IoMdSchool;

  return (
    <Icon
      color={color || colors.lightBlue}
      style={{
        ...style,
      }}
    />
  );
};
