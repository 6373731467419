import colors from "constants/colors";
import YearDropdown from "dropdowns/year-dropdown";
import React from "react";
import { useState } from "react";
import ScoutTitle from "shared/scout-title";

export default function AbsoluteYearPicker({
  year,
  setYear,
  sortedProspects,
  mode,
}) {
  const [editing, setEditing] = useState(false);
  return (
    <div
      style={{
        zIndex: 100,
        width: editing ? 240 : 220,
        marginTop: 20,
        backgroundColor: colors.accent,
        display: "flex",
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: colors.lightBlue,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 46,
          borderBottomStyle: "solid",
          borderBottomWidth: 2,
          borderBottomColor: colors.lightBlue,
        }}
      >
        <div
          style={{
            width: 100,
            display: "flex",
            justifyContent: "center",
            borderRightStyle: "solid",
            borderRightWidth: 2,
            borderRightColor: colors.lightBlue,
          }}
        >
          <ScoutTitle
            text="Roster"
            light={true}
            styles={{ padding: 0, margin: 0 }}
          />
        </div>

        <div style={{ padding: 4, zIndex: 100, width: 120 }}>
          <p style={{ color: colors.white, textAlign: "center" }}>{`${
            (sortedProspects || []).length
          } players`}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 46,
        }}
      >
        <div
          style={{
            width: 100,
            display: "flex",
            justifyContent: "center",
            borderRightStyle: "solid",
            borderRightWidth: 2,
            borderRightColor: colors.lightBlue,
          }}
        >
          <ScoutTitle
            text="Year"
            light={true}
            styles={{ padding: 0, margin: 0 }}
          />
        </div>

        {!editing ? (
          <div
            style={{ padding: 4, zIndex: 100, width: 120, cursor: "pointer" }}
            onClick={() => setEditing(true)}
          >
            <p style={{ color: colors.white, textAlign: "center" }}>{`${year}-${
              parseInt(year) + 1
            }`}</p>
          </div>
        ) : (
          <div style={{ padding: 4, zIndex: 100 }}>
            <YearDropdown
              season
              customYears={[
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
              ]}
              selectYear={(yr) => {
                setEditing(false);
                setYear(yr[0]);
              }}
              allowAll={false}
              minWidth={120}
              maxWidth={120}
              placeholder={"YEAR"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
