import React, { useState } from "react";
import colors from "../../../constants/colors";

function OneBoxRow({ label, value }) {
  const ignore_color = [
    "Home to First",
    "60 Yard",
    "40 Yard",
    "Pos. in Order",
    "Character",
    "Entry Level",
    "ETA",
    "Arm Angle",
  ];

  const colorForValue = (value: string) => {
    if (label && label !== "" && !ignore_color.includes(label)) {
      if (value) {
        switch (parseInt(value)) {
          case 2:
            return "#4A3D3D";
          case 3:
            return "#DB2330";
          case 4:
            return "#DB8E33";
          case 5:
            return "#80B304";
          case 6:
            return "#6690FF";
          case 7:
            return "#F5EE48";
          case 8:
            return "rgba(0, 0, 0, 0.4)";
          default:
            return "transparent";
        }
      }
      return "transparent";
    }
    return "transparent";
  };

  return (
    <tr>
      <td
        style={{
          width: "50%",
          padding: "2px 4px",
          border: "1px solid black",
          textAlign: "center",
        }}
      >
        {label}
      </td>
      <td
        style={{
          width: "50%",
          backgroundColor: colorForValue(value),
          padding: "2px 4px",
          border: "1px solid black",
          textAlign: "center",
        }}
      >
        {value}
      </td>
    </tr>
  );
}

export default OneBoxRow;
