import React, { useEffect, useState } from "react";
import colors from "constants/colors";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import LabelValueBox from "../components-pdf/pdf-label-value-box";
import LabelValueFlexBox from "../components-pdf/pdf-label-value-flex-box";
import LabelDescription from "../components-pdf/pdf-label-description";
import ThreeBoxRow from "../components-pdf/pdf-three-box-row";
import OneBoxRow from "../components-pdf/pdf-one-box-row";
import OneBoxRowComments from "../components-pdf/pdf-one-box-row-comments";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import PDFImagesList from "../components-pdf/pdf-images-list";
import ProReportTop from "./pro-report-top";

function PDFPositionProReport({ posReport, fullScreen }) {
  const styles = StyleSheet.create({
    reportRow: {
      flexDirection: "row",
      display: "flex",
      flex: 1,
      marginBottom: 20,
    },
  });

  useEffect(() => {
    Font.register({
      family: "Raleway",
      fonts: [
        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf",
          fontWeight: "bold",
        },

        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf",
          fontWeight: "normal",
          fontStyle: "normal",
        },

        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf",
          fontStyle: "italic",
        },
      ],
    });
    Font.register({
      family: "RalewayBold",
      fonts: [
        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf",
          fontWeight: 800,
          fontStyle: "normal",
        },
      ],
    });
  }, []);

  const MyDocument = () => (
    <Document>
      <Page
        size="A4"
        style={{
          fontFamily: "Raleway",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
          marginLeft: 10,
          paddingRight: 30,
          marginTop: 10,
          marginRight: 10,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: "RalewayBold",
            textDecoration: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: colors.accent,
            color: colors.accent,
            fontWeight: 800,
          }}
        >
          {posReport.playerName}
        </Text>
        <Text style={{ marginBottom: 20, fontSize: 10 }}>
          Report
          {posReport.date
            ? moment(new Date(posReport.date.seconds * 1000)).format(
                " MM/DD/YYYY"
              )
            : ""}
        </Text>

        <ProReportTop posReport={posReport} />

        <View
          style={{
            ...styles.reportRow,
            height: 50,
            maxHeight: 50,
            marginBottom: 0,
            marginTop: 40,
          }}
        >
          <LabelDescription
            label="Physical Description"
            value={posReport.physicalDescription}
          />
        </View>
        <View
          style={{
            ...styles.reportRow,
            height: 50,
            marginBottom: 20,
            maxHeight: 50,
          }}
        >
          <LabelDescription
            label="Player Comparison"
            value={posReport.playerComparison}
          />
        </View>

        <ThreeBoxRow label={""} p={"P"} f={"F"} adj={"Adj"} comments={""} />
        <ThreeBoxRow
          label={"Hitting Ability"}
          p={posReport.hittingAbility.p}
          f={posReport.hittingAbility.f}
          adj={posReport.hittingAbility.adj}
          comments={posReport.hittingAbility.comments}
        />

        <ThreeBoxRow
          label={"Hit App/Type"}
          p={posReport.hitAppType.p}
          f={posReport.hitAppType.f}
          adj={posReport.hitAppType.adj}
          comments={posReport.hitAppType.comments}
        />
        <ThreeBoxRow
          label={"Power Freq."}
          p={posReport.powerFreq.p}
          f={posReport.powerFreq.f}
          adj={posReport.powerFreq.adj}
          comments={posReport.powerFreq.comments}
        />
        <ThreeBoxRow
          label={"Raw Power"}
          p={posReport.rawPower.p}
          f={posReport.rawPower.f}
          adj={posReport.rawPower.adj}
          comments={posReport.rawPower.comments}
        />
        <ThreeBoxRow
          label={"Running Ability"}
          p={posReport.runningAbility.p}
          f={posReport.runningAbility.f}
          adj={posReport.runningAbility.adj}
          comments={posReport.runningAbility.comments}
        />
        <ThreeBoxRow
          label={"Base Stealer"}
          p={posReport.baseStealer.p}
          f={posReport.baseStealer.f}
          adj={posReport.baseStealer.adj}
          comments={posReport.baseStealer.comments}
        />
        <ThreeBoxRow
          label={"Arm Strength"}
          p={posReport.armStrength.p}
          f={posReport.armStrength.f}
          adj={posReport.armStrength.adj}
          comments={posReport.armStrength.comments}
        />
        <ThreeBoxRow
          label={"Fielding Ability"}
          p={posReport.fieldingAbility.p}
          f={posReport.fieldingAbility.f}
          adj={posReport.fieldingAbility.adj}
          comments={posReport.fieldingAbility.comments}
        />

        <View style={{ flex: 1 }} />
      </Page>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
        }}
      >
        <ThreeBoxRow
          label={"Hands"}
          p={posReport.hands.p}
          f={posReport.hands.f}
          adj={posReport.hands.adj}
          comments={posReport.hands.comments}
        />
        <ThreeBoxRow
          label={"Range"}
          p={posReport.range.p}
          f={posReport.range.f}
          adj={posReport.range.adj}
          comments={posReport.range.comments}
        />
        <ThreeBoxRow
          label={"Feet"}
          p={posReport.feet.p}
          f={posReport.feet.f}
          adj={posReport.feet.adj}
          comments={posReport.feet.comments}
        />
        <ThreeBoxRow
          label={"Can He Play"}
          p={posReport.canHePlay.p}
          f={posReport.canHePlay.f}
          adj={posReport.canHePlay.adj}
          comments={posReport.canHePlay.comments}
        />
        <ThreeBoxRow
          label={"OFP"}
          p={posReport.oFP.p}
          f={posReport.oFP.f}
          adj={posReport.oFP.adj}
          comments={posReport.oFP.comments}
        />

        <View style={{ display: "flex", flexDirection: "row" }}>
          <View
            style={{
              marginTop: 20,
              borderTopColor: colors.accent,
              borderTopWidth: 1,
              borderTopStyle: "solid",
              width: "48%",
              marginRight: 10,
            }}
          >
            <OneBoxRow
              label="Aggressiveness"
              value={posReport.aggressiveness}
            />
            <OneBoxRow label="Athleticism" value={posReport.athleticism} />
            <OneBoxRow label="Body Control" value={posReport.bodyControl} />
            <OneBoxRow label="Instincts" value={posReport.instincts} />
            <OneBoxRow
              label="Competitiveness"
              value={posReport.competitiveness}
            />
            <OneBoxRow label="Baseball IQ" value={posReport.baseballIQ} />
            <OneBoxRow
              label="Hand-eye Control"
              value={posReport.handEyeControl}
            />
          </View>

          <View
            style={{
              marginTop: 20,
              borderTopColor: colors.accent,
              borderTopWidth: 1,
              borderTopStyle: "solid",
              width: "48%",
            }}
          >
            <OneBoxRow label="OFP Offense" value={posReport.oFPOffense} />
            <OneBoxRow label="OFP Defense" value={posReport.oFPDefense} />
            <OneBoxRow label="Home to First" value={posReport.homeToFirst} />
            <OneBoxRow label="60 Yard" value={posReport.sixtyYard} />
            <OneBoxRow label="40 Yard" value={posReport.fortyYard} />
          </View>
        </View>

        <View
          style={{
            marginTop: 20,
            marginBottom: 20,
            borderTopColor: colors.accent,
            borderTopWidth: 1,
            borderTopStyle: "solid",
          }}
        >
          <OneBoxRow label="Pos. in Order" value={posReport.posInOrder} />
          <OneBoxRow label="Character" value={posReport.character} />
          <OneBoxRow label="Entry Level" value={posReport.entryLevel} />
          <OneBoxRow label="ETA" value={posReport.eTA} />
        </View>

        <OneBoxRowComments
          label="How Well Known"
          value={posReport.howWellKnown.value}
          comments={posReport.howWellKnown.comments}
        />
        <OneBoxRowComments
          label="How Well Seen"
          value={posReport.howWellSeen.value}
          comments={posReport.howWellSeen.comments}
        />
        <OneBoxRowComments
          label="Signablility"
          value={posReport.signability.value}
          comments={posReport.signability.comments}
        />
      </Page>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
        }}
      >
        <LabelDescription label="Summary" value={posReport.summary} />

        <PDFImagesList posReport={posReport} fancyText={false} />

        <View style={{ flex: 1 }} />
      </Page>
    </Document>
  );

  return (
    <PDFViewer
      style={
        fullScreen
          ? { minWidth: "100vw", minHeight: "100vh" }
          : {
              minWidth: "70vw",
              minHeight: "90vh",
            }
      }
    >
      <MyDocument />
    </PDFViewer>
  );
}

export default PDFPositionProReport;
