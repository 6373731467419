import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

export default function LoadingView() {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 250,
      }}
    >
      <Spinner animation="border" />
    </div>
  );
}
