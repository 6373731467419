import React from "react";
import PlayerCalendarEvent from "popovers/player-calendar-event";
import { useHistory } from "react-router-dom";
import EventDropdown from "dropdowns/event-dropdown";
import { AiOutlinePlus, AiFillCloseCircle } from "react-icons/ai";
import colors from "constants/colors";

function RelatedGamePicker({ posReport, setPosReport }) {
  const history = useHistory();
  return (
    <div className="report-row">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>Related Games</p>
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}
        >
          <AiOutlinePlus
            style={{
              width: 40,
              height: 40,
            }}
          />
          <EventDropdown
            selectEvent={(evt) =>
              setPosReport({
                ...posReport,
                relatedGames: [...posReport.relatedGames, evt],
              })
            }
          />
        </div>

        <div style={{ flexDirection: "row", display: "flex" }}>
          {(posReport.relatedGames || []).map((item) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PlayerCalendarEvent
                event={item}
                light={true}
                prospects={[]}
                onViewCalendar={() => {
                  history.push("/calendar");
                }}
              />
              <div
                style={{
                  marginLeft: -26,
                  marginRight: 20,
                  marginTop: -7,
                  backgroundColor: colors.white,
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                }}
                onClick={() =>
                  setPosReport({
                    ...posReport,
                    relatedGames: posReport.relatedGames.filter(
                      (g) => g.id !== item.id
                    ),
                  })
                }
              >
                <AiFillCloseCircle
                  style={{
                    width: 28,
                    height: 28,
                    color: colors.red,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RelatedGamePicker;
