import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { PlayerService } from "services/PlayerService";
import LoadingView from "shared/loading-view";
import "./scholarships-page.scss";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import { Link } from "react-router-dom";
import { useMe } from "@tara-wilson/mcc-lib";
import { useParams } from "react-router-dom";
import PDFBreakdown from "./components/pdf-breakdown";
import { Player } from "models/Player";
import { User } from "models/User";
import PdfField from "./components/pdf-field";

function ScholarshipsPDFPage(props: any) {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const myRoster = useSelector((state) => state.firestore.data.myRoster);
  let { year } = useParams();
  const me = useMe<User>();

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "roster" }],
      storeAs: "myRoster",
    },
  ]);

  const sortedProspects = (includeRedshirt?: boolean) => {
    if (!isLoaded(myRoster)) {
      return [];
    }

    let prospects = myRoster
      ? Object.keys(myRoster).map((key) =>
          myRoster ? { ...myRoster[key], id: key } : null
        )
      : [];

    prospects.sort(function (a, b) {
      if (a.rosterNumber > b.rosterNumber) return 1;
      if (a.rosterNumber < b.rosterNumber) return -1;
      return 0;
    });

    prospects = prospects
      .filter((item) => {
        if (item) {
          let startYear = PlayerService.getStartYear(item);
          let endYear = PlayerService.getEndYear(item);
          if (startYear <= parseInt(year) && endYear >= parseInt(year)) {
            return item;
          }
        }
        return null;
      })
      .filter((item) => item !== null);

    let final = [];

    prospects.forEach((pros, index) => {
      final.push({ ...pros, rosterNumber: index + 1 });
    });
    return final;
  };

  if (!isLoaded(myRoster)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.white,
          marginLeft: "1rem",
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 12,
          paddingBottom: 200,
        }}
      >
        <LoadingView />
      </div>
    );
  }

  return (
    <div
      style={{
        paddingLeft: "0.5rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Link to={`/scholarships`}>
          <UnderlineButton
            text={"back to scholarships"}
            onClick={() => {}}
            color={colors.accent}
          />
        </Link>
      </div>
      {me && me.id && (
        <ScholarshipsPDF
          sortedProspects={sortedProspects()}
          year={year}
          me={me}
        />
      )}
    </div>
  );
}

function ScholarshipsPDF({
  sortedProspects,
  year,
  me,
}: {
  sortedProspects: Player[];
  year: string;
  me: User;
}) {
  useEffect(() => {
    Font.register({
      family: "Raleway",
      fonts: [
        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf",
          fontWeight: "bold",
        },

        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf",
          fontWeight: "normal",
          fontStyle: "normal",
        },

        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf",
          fontStyle: "italic",
        },
      ],
    });
    Font.register({
      family: "RalewayBold",
      fonts: [
        {
          src: "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf",
          fontWeight: 800,
          fontStyle: "normal",
        },
      ],
    });
    Font.registerHyphenationCallback((word) => [word]);
  }, []);

  const MyDocument = () => (
    <Document>
      <Page
        size="A4"
        orientation={"landscape"}
        style={{
          fontFamily: "Raleway",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
          paddingTop: 15,
          marginLeft: 10,
          paddingRight: 30,
          marginRight: 10,
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "RalewayBold",
              textDecoration: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: colors.accent,
              color: colors.accent,
              fontWeight: 800,
            }}
          >
            {/* @ts-ignore */}
            {`${me.college ? `${me.college} ` : ""} ${year}-${
              parseInt(year) + 1
            } Scholarships`}
          </Text>
          <Text style={{ marginBottom: 10, fontSize: 10 }}>
            {moment().format(" MM/DD/YYYY")}
          </Text>
        </View>

        <PdfField sortedProspects={sortedProspects} me={me} year={year} />
      </Page>
      <Page
        size="A4"
        orientation={"portrait"}
        style={{
          fontFamily: "Raleway",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
          marginLeft: 10,
          paddingRight: 30,
          marginTop: 10,
          marginRight: 10,
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "RalewayBold",
              textDecoration: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: colors.accent,
              color: colors.accent,
              fontWeight: 800,
            }}
          >
            {/* @ts-ignore */}
            {`${me.college ? `${me.college} ` : ""} ${year}-${
              parseInt(year) + 1
            } Scholarships`}
          </Text>
          <Text style={{ marginBottom: 10, fontSize: 10 }}>
            {moment().format(" MM/DD/YYYY")}
          </Text>
        </View>
        <PDFBreakdown year={year} sortedProspects={sortedProspects} me={me} />
        <View style={{ flex: 1 }} />
      </Page>
    </Document>
  );

  return sortedProspects.length > 0 ? (
    <PDFViewer
      style={{
        minWidth: "90vw",
        minHeight: "110vh",
      }}
    >
      <MyDocument />
    </PDFViewer>
  ) : (
    <div />
  );
}

export default ScholarshipsPDFPage;
