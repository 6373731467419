import React, { useState } from "react";
import colors from "../../../constants/colors";
import { Text, View } from "@react-pdf/renderer";

function PDFOneBoxRow({ label, value }) {
  const ignore_color = [
    "Home to First",
    "60 Yard",
    "40 Yard",
    "Pos. in Order",
    "Character",
    "Entry Level",
    "ETA",
    "Arm Angle",
  ];

  const colorForValue = (value: string) => {
    if (label && label !== "" && !ignore_color.includes(label)) {
      if (value) {
        switch (parseInt(value)) {
          case 2:
            return "#4A3D3D";
          case 3:
            return "#DB2330";
          case 4:
            return "#DB8E33";
          case 5:
            return "#80B304";
          case 6:
            return "#6690FF";
          case 7:
            return "#F5EE48";
          case 8:
            return "rgba(0, 0, 0, 0.4)";

          default:
            return colors.lightGray;
        }
      }
      return colors.lightGray;
    }
    return colors.lightGray;
  };

  return (
    <View
      style={{
        flexDirection: "row",
        display: "flex",
        flex: 1,
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <Text
        style={{
          fontSize: 10,
          borderColor: colors.softDarkText,
          borderWidth: 1,
          flex: 1,
          padding: 4,
          borderRightWidth: 0,
          borderStyle: "solid",
          borderTopWidth: 0,
        }}
      >
        {label}
      </Text>

      <View
        style={{
          flex: 1,
          borderColor: colors.softDarkText,
          borderWidth: 1,

          padding: 4,
          borderStyle: "solid",
          borderTopWidth: 0,
          backgroundColor: colorForValue(value),
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 10 }}>{value}</Text>
      </View>
    </View>
  );
}

export default PDFOneBoxRow;
