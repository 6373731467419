import React, { useEffect, useState } from "react";
import {
  useFirestoreConnect,
  isLoaded,
  withFirestore,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import "./bigboard.css";
import colors, { TAG_COLORS } from "constants/colors";
import LoadingView from "shared/loading-view";
import { useParams } from "react-router-dom";
import RemovableChip from "./components/removable-chip";
import YearDropdown from "dropdowns/year-dropdown";
import Reorder from "react-reorder";
import PlayerAvatar from "shared/player-avatar";
import { PlayerService } from "services/PlayerService";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import UnderlineButton from "shared/underline-button";
import ShareBigBoardModal from "../../modals/share-board-modal";
import BigBoardsDropdown from "dropdowns/big-boards-dropdown";
import AreYouSureModal from "modals/are-you-sure-modal";
import { NotificationService } from "services/NotificationService";
import { toast } from "react-toastify";
import { Player } from "models/Player";
import HelpModal from "modals/help-modal";
import ScoutTitle from "shared/scout-title";
import { useHistory } from "react-router-dom";
import { MdClose, MdDragHandle } from "react-icons/md";
import Switch from "react-switch";
import firebase from "firebase";
import CreateBigBoardModal from "modals/create-board-modal";
import AddToBoard from "./add-to-board";
import { useMe } from "hooks/users";
import { CollegePlayerIcon } from "components/smart-icons";
import { CollegePlayer } from "models/CollegePlayer";
import { usePlayersMeta } from "hooks/player-meta";

function BigBoard(props: any) {
  let { boardId, boardOwnerId, boardYear } = useParams();
  const history = useHistory();

  const me = useMe();
  const userId = me.id;
  const boardProspects = useSelector(
    (state) => state.firestore.data.bigBoardProspects
  );
  const sharedBoards = useSelector(
    (state) => state.firestore.data.sharedBoards
  );
  const _boardFriends = useSelector(
    (state) => state.firestore.data.boardFriends
  ) || {};
  const boardFriends = Object.keys(_boardFriends).includes(userId) ? _boardFriends
                   : { ..._boardFriends, [userId]: me }
  const [editingYear, setEditingYear] = useState(false);
  const [readonly, setReadonly] = useState(false);

  const [position, setPosition] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [askRemoveFromBoard, setAskRemoveFromBoard] = useState(null);
  const [confirmRemoveModalShow, setConfirmRemoveModalShow] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [changingBoard, setChangingBoard] = useState(false);
  const [showCreateBoard, setShowCreateBoard] = useState(false);
  const [showAddPlayers, setShowAddPlayers] = useState(false);

  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: "boards",
      doc: boardId,
      subcollections: [
        {
          collection: "prospects",
        },
      ],
      storeAs: "bigBoardProspects",
    },
    {
      collection: "boards",
      where: ["recipientIds", "array-contains", userId],
      storeAs: `sharedBoards`,
    },
    {
      collection: "users",
      where: ["friends", "array-contains", userId],
      storeAs: "boardFriends",
    }
  ]);

  const allPlayersMeta = usePlayersMeta();

  const currentBoard = React.useMemo(() => {
    if (Object.keys(sharedBoards || {}).includes(boardId)) {
      return { ...sharedBoards[boardId], id: boardId };
    }
    return null;
  }, [boardId, sharedBoards]);

  const isMyBoard = React.useMemo(() => {
    return currentBoard && currentBoard.ownerId === userId;
  }, [currentBoard, userId])

  const otherBoards = () => {
    if (!isLoaded(sharedBoards)) {
      return [];
    }

    let boards = Object.keys(sharedBoards || {}).reduce((accum, key) => {
      const item = sharedBoards[key];
      return !item ? accum : [
        ...accum,
        {
          ...item,
          id: key,
          name: item?.name || `${item.senderName}'s Board`,
        }
      ]
    }, []);

    return [...boards];
  };

  const sortedProspects = React.useMemo(() => {
    if (!isLoaded(boardProspects)) {
      return [];
    }

    let prospects = boardProspects
      ? Object.keys(boardProspects).map((key) =>
          boardProspects ? { ...boardProspects[key], id: key } : null
        )
      : [];

    prospects = prospects.map((item) => ({
      ...item,
      ordering: Object.keys(item.customOrdering || {}).includes(boardId)
        ? item.customOrdering[boardId]
        : item.ordering,
    }));
    prospects.sort(function (a, b) {
      if (a.ordering && b.ordering) {
        if (a.ordering > b.ordering) return 1;
        if (a.ordering < b.ordering) return -1;
      }
      return 0;
    });

    if (position !== "") {
      prospects = prospects.filter(
        (item) =>
          item.primary_position === position || item.alt_position === position
      );
    }
    if (boardYear && boardYear !== "ALL YEARS") {
      prospects = prospects.filter(
        (item) => parseInt(PlayerService.getGraduatingYear(item)) === parseInt(boardYear)
      );
    }

    return prospects.filter((item) => item !== null);
  }, [boardProspects, position, boardYear, boardId]);

  if (!isLoaded(boardProspects)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "1rem",
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 12,
          paddingBottom: 200,
        }}
      >
        <LoadingView />
      </div>
    );
  }

  const playersForPosition = (pos: string) => {
    return sortedProspects.filter((item) => {
      if ((item.bbPositions || []).length) {
        return item.bbPositions.includes(pos);
      } else {
        return item.primary_position === pos || item.alt_position === pos || item.position === pos || item.POS === pos
      }
    });
  };

  const selectBoard = async (bd) => {
    if (bd && bd.name) {
      setChangingBoard(false);
      let senderId = bd.senderId ? bd.senderId : bd.ownerId;
      history.push(`/bigboard/${bd.id}/${senderId}/${boardYear}`);
    }
  };

  const selectYear = async (year) => {
    setEditingYear(false);
    await firestore
      .collection("users")
      .doc(userId)
      .update({ lastBigBoardYear: year });
    history.push(`/bigboard/${boardId}/${boardOwnerId}/${year}`);
  };

  const removeBoard = async () => {
    setConfirmRemoveModalShow(false);

    const friendIds = Object.keys(boardFriends)
    const validShare = friendIds.includes(boardOwnerId);

    if (validShare) {
      await NotificationService.revokeBoardAccess(
        isMyBoard ? friendIds : [userId],
        {
          ...boardFriends[boardOwnerId],
          id: boardOwnerId,
        },
        { id: boardId, senderId: boardOwnerId },
        props.firestore
      );
      toast.dark(`Big Board removed.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    history.push(`/bigboard/${userId}/${userId}/${boardYear}`);
  }

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 12,
          zIndex: 99,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <ScoutTitle
            text="Big Board"
            withHelp={true}
            onClick={() => setShowHelp(true)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {!changingBoard ? (
              <RemovableChip
                name={
                  currentBoard ? currentBoard.name || "Board..." : "Loading..."
                }
                onClick={() => {
                  setChangingBoard(true);
                }}
              />
            ) : (
              <div style={{ marginLeft: 12 }}>
                <BigBoardsDropdown
                  boards={otherBoards()}
                  selectBoard={(bd) => {
                    selectBoard(bd[0]);
                  }}
                />
              </div>
            )}
            {!editingYear ? (
              <RemovableChip
                name={
                  parseInt(boardYear)
                    ? `${boardYear}-${parseInt(boardYear) + 1}`
                    : boardYear
                    ? boardYear
                    : "Loading..."
                }
                onClick={() => {
                  setEditingYear(true);
                }}
              />
            ) : (
              <div style={{ marginLeft: 12, zIndex: 99 }}>
                <YearDropdown
                  season
                  selectYear={(yr) => selectYear(yr[0])}
                  allowAll={true}
                />
              </div>
            )}
            {position !== "" ? (
              <RemovableChip
                name={position}
                onClick={() => {
                  setPosition("");
                }}
              />
            ) : (
              <div />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <p
                style={{
                  opacity: readonly ? 1 : 0.4,
                  color: colors.accent,
                  marginRight: 8,
                  marginLeft: 8,
                  fontWeight: "bold",
                }}
              >
                VIEW MODE
              </p>
              <Switch
                checked={!readonly}
                onChange={() => setReadonly(!readonly)}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.lightBlue}
              />
              <p
                style={{
                  opacity: readonly ? 0.4 : 1,
                  color: colors.accent,
                  marginRight: 8,
                  marginLeft: 8,
                  fontWeight: "bold",
                }}
              >
                EDIT MODE
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            {isMyBoard ? (
              <UnderlineButton
                text="Add players to board"
                onClick={() => {
                  setShowAddPlayers(true);
                }}
              />
            ) : (
              <div />
            )}

            <UnderlineButton
              text="Create new board"
              onClick={() => {
                setShowCreateBoard(true);
              }}
            />

            <UnderlineButton
              text="View PDF"
              onClick={() => {
                history.push(
                  `/pdf-bigboard/${boardId}/${boardOwnerId}/${boardYear}`
                );
              }}
            />

            {currentBoard && boardId != userId ? (
              <UnderlineButton
                text={`Remove ${
                  currentBoard && currentBoard.name
                    ? currentBoard.name
                    : "this board"
                }`}
                onClick={() => {
                  setConfirmRemoveModalShow(true);
                }}
              />
            ) : (
              <div />
            )}

            {isMyBoard && (
              <UnderlineButton
                text="Share"
                onClick={() => {
                  setModalShow(true);
                }}
              />
            )}
          </div>
        </div>

        <CreateBigBoardModal
          show={showCreateBoard}
          onHide={(newBoard) => {
            setShowCreateBoard(false);
            if (newBoard) {
              history.push(
                history.push(
                  `/bigboard/${newBoard.id}/${newBoard.ownerId}/${"ALL YEARS"}`
                )
              );
            }
          }}
        />

        <ShareBigBoardModal
          show={modalShow}
          board={currentBoard}
          onHide={() => setModalShow(false)}
        />

        <AreYouSureModal
          //@ts-ignore
          show={askRemoveFromBoard != null}
          onConfirm={async () => {
            setConfirmRemoveModalShow(false);

            firestore
              .collection("boards")
              .doc(boardId)
              .collection("prospects")
              .doc(askRemoveFromBoard.external_id)
              .delete();

            setAskRemoveFromBoard(null);
            toast.dark(`Player removed.`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }}
          onHide={() => setAskRemoveFromBoard(null)}
          title={`Remove Player`}
          body={`Are you sure you want to remove ${
            askRemoveFromBoard
              ? PlayerService.getName(askRemoveFromBoard)
              : "this player"
          } from ${currentBoard ? currentBoard.name : "this board"}?`}
        />

        <AreYouSureModal
          //@ts-ignore
          show={confirmRemoveModalShow}
          onConfirm={removeBoard}
          onHide={() => setConfirmRemoveModalShow(false)}
          title={"Remove Big Board"}
          body={`Are you sure you want to remove ${
            currentBoard ? currentBoard.name : "this board"
          }?`}
        />
        <HelpModal
          show={showHelp}
          kind={"BIG BOARD"}
          onHide={() => setShowHelp(false)}
        />
      </div>
    );
  };

  const onDragEnd = async (prospects, from, to) => {
    if (boardId === userId || boardOwnerId == userId) {
      await PlayerService.updateOrdering(
        userId,
        prospects,
        from,
        to,
        boardId,
        props.firestore
      );
    } else {
      toast.dark(`You cannot edit someone else's big board.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const renderReorderList = (listId: string, prospects: (Player | CollegePlayer)[]) => {
    if (readonly) {
      return (
        <div>
          {prospects.map((item, index) => (
            <div
              key={item.external_id}
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 8,
                
                color: colors.accent,
                borderRadius: 6,
                marginBottom: 8,
                cursor: "pointer",
                maxWidth: 300,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 8,
                }}
              >
                <p>{index + 1}.</p>
              </div>

              <Link
                to={`/${item.college ? "collegeplayer" : "player"}/${
                  item.external_id
                }`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 6,
                  flexDirection: "row",
                }}
              >
                <PlayerAvatar
                  player={item}
                  blueBackground={true}
                  containerStyles={{}}
                />
              </Link>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",

                  minWidth: 140,
                  maxWidth: 300,
                }}
              >
                <Link
                  to={`/${item.college ? "collegeplayer" : "player"}/${
                    item.external_id
                  }`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 6,
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color:
                          item.bats == "S"
                            ? colors.lightBlue
                            : item.bats == "L"
                            ? colors.red
                            : "black",
                      }}
                    >
                      {`${PlayerService.getName(item)}`}

                      {boardYear === "ALL YEARS" ? (
                        <span
                          style={{ fontWeight: "bold", color: "black" }}
                        >{` (${PlayerService.getGraduatingYear(item)})`}</span>
                      ) : (
                        <div />
                      )}
                    </p>
                  </div>
                </Link>
              </div>
              <div style={{ width: 16, height: 1 }} />
            </div>
          ))}
        </div>
      );
    }
    return (
      <Reorder
        reorderId={listId}
        draggedClassName="dragged"
        lock="horizontal"
        onReorder={(event, nextIndex, previousIndex, fromId, toId) => {
          onDragEnd(prospects, nextIndex, previousIndex);
        }}
      >
        {prospects.map((item, index) => (
          <div
            key={item.external_id}
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 8,
              backgroundColor: TAG_COLORS[allPlayersMeta[item?.id]?.tagColor] || colors.white,
              color: colors.accent,
              borderRadius: 6,
              marginBottom: 8,
              cursor: "pointer",
              maxWidth: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <p>{index + 1}.</p>
            </div>

            <Link
              to={`/${item.college ? "collegeplayer" : "player"}/${
                item.external_id
              }`}
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 6,
                flexDirection: "row",
              }}
            >
              <PlayerAvatar
                player={item}
                blueBackground={true}
                containerStyles={{}}
              />
            </Link>

            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",

                minWidth: 140,
                maxWidth: 300,
              }}
            >
              <div>
                <p
                  style={{
                    color:
                      item.bats == "S"
                        ? colors.lightBlue
                        : item.bats == "L"
                        ? colors.red
                        : "black",
                  }}
                >
                  {`${PlayerService.getName(item)}`}

                  {item.college && (
                    <CollegePlayerIcon
                      player={item as unknown as CollegePlayer}
                      color={colors.lightBlue}
                      style={{ marginLeft: 4 }}
                    />
                  )}

                  {boardYear === "ALL YEARS" ? (
                    <span
                      style={{ fontWeight: "bold", color: "black" }}
                    >{` (${PlayerService.getGraduatingYear(item)})`}</span>
                  ) : (
                    <div />
                  )}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div
                onClick={() => setAskRemoveFromBoard(item)}
                style={{ marginTop: -8 }}
              >
                <MdClose
                  style={{
                    color: colors.red,
                  }}
                />
              </div>

              <MdDragHandle
                style={{
                  color: colors.accent,
                }}
              />
            </div>
          </div>
        ))}
      </Reorder>
    );
  };

  if (showAddPlayers) {
    return (
      <div
        style={{
          paddingLeft: "1rem",
          paddingRight: "1rem",
          margin: 0,

          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AddToBoard
          sortedProspects={sortedProspects}
          setShowAddPlayers={setShowAddPlayers}
          boardId={boardId}
        />
      </div>
    );
  }

  if (position === "") {
    return (
      <div
        style={{
          paddingLeft: "1rem",
          paddingRight: "1rem",
          margin: 0,
          width: "100vw",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {renderHeader()}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "98vw",
            overflowX: "scroll",

            borderRight: "2px solid #fff",
            minHeight: "40vh",
          }}
        >
          {["RHP", "LHP", "1B", "2B", "SS", "3B", "C", "OF", "MIF"].map(
            (item) => (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
                key={item}
              >
                <div
                  style={{
                    backgroundColor: colors.white,
                    paddingTop: 8,
                    paddingBottom: 8,
                    cursor: "pointer",
                  }}
                  onClick={() => setPosition(item)}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: colors.accent,
                      fontSize: 18,
                      textDecorationLine: "underline",
                      textDecorationStyle: "solid",
                      textDecorationColor: colors.accent,
                    }}
                  >
                    {item}
                  </p>
                </div>
                <div
                  style={{
                    borderLeft: "2px solid #fff",
                    borderBottom: "2px solid #fff",
                    flex: 1,
                    paddingTop: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                >
                  {playersForPosition(item).length > 0 ? (
                    renderReorderList(item, playersForPosition(item))
                  ) : (
                    <p
                      style={{
                        fontStyle: "italic",
                        opacity: 0.7,
                        textAlign: "center",
                        marginBottom: 10,
                      }}
                    >
                      No results.
                    </p>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
        margin: 0,

        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderHeader()}
      {sortedProspects.length > 0 ? (
        renderReorderList(position, sortedProspects)
      ) : (
        <p
          style={{
            fontStyle: "italic",
            opacity: 0.8,
            marginTop: 100,
            textAlign: "center",
          }}
        >
          No prospects saved yet.
        </p>
      )}
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(BigBoard);
