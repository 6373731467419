import React from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";

const EventDropdown = ({ selectEvent }) => {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const myEvents = useSelector((state) => state.firestore.data.myEvents);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "events" }],
      storeAs: `myEvents`,
    },
  ]);

  if (!isLoaded(myEvents)) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }

  const sortedEvents = () => {
    let events = myEvents
      ? Object.keys(myEvents).map((key) =>
          myEvents ? { ...myEvents[key], id: key } : null
        )
      : [];

    return events.filter((item) => item !== null);
  };

  return sortedEvents().length > 0 ? (
    <Typeahead
      id="event"
      labelKey="name"
      options={sortedEvents().map((item) => ({
        ...item,
        name: `${item.teamOne || ""} vs. ${item.teamTwo || ""}`,
      }))}
      onChange={(vals) => selectEvent(vals[0])}
      placeholder="Select..."
    />
  ) : (
    <div>
      <p>No events.</p>
    </div>
  );
};

export default EventDropdown;
