/*****************************************************************************
 * Import
 *****************************************************************************/
import React from "react";
import colors, { TAG_COLORS } from "constants/colors";
import { FaCheck } from "react-icons/fa";
import { Box, Row } from "components/ui";

/*****************************************************************************
 * Public Components
 *****************************************************************************/

export const ColorTagSelector = ({
  selectedTag,
  setSelectedTag
}: {
  selectedTag: string;
  setSelectedTag: Function;
}) => {
  return (
    <Row gap="4px" align="center">
      {Object.entries(TAG_COLORS).map(([tag, color]) => {
        const isSelected = (tag === selectedTag);
          
        return (
          <ColorTag
            color={color}
            selected={isSelected}
            onClick={() => setSelectedTag(isSelected ? null : tag)}
          />
        );
      })}
    </Row>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const ColorTag = ({
  color,
  selected,
  onClick,
}: {
  color: string;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <Box
      interact
      center
      color={color}
      w="48px"
      h="24px"
      radius="8px"
      onClick={onClick}
    >
      {selected && (
        <FaCheck color={colors.accent} size="12px" />
      )}
    </Box>
  );
}


