/*****************************************************************************
 * Import
 *****************************************************************************/
import React from "react";

/*****************************************************************************
 * Public Components
 *****************************************************************************/

export const GapBox = ({ children, gap, style, ...props }: {
  children?: any,
  gap?: any,
  style?: React.CSSProperties,
}) => {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        gap: gap || undefined,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export const Row = ({ children, ...props }) => {
  return (
    <GapBox
      {...props}
      gap={props.gap}
      style={{
        flexDirection: "row",
        ...props.style
      }}
    >
      {children}
    </GapBox>
  );
}

export const Column = ({ children, ...props }) => {
  return (
    <GapBox
      {...props}
      gap={props.gap}
      style={{
        flexDirection: "column",
        ...props.style
      }}
    >
      {children}
    </GapBox>
  );
}

export const Centered = ({ children, ...props }) => {
  return (
    <GapBox
      {...props}
      gap={props.gap}
      style={{
        justifyContent: "center",
        alignItems: "center",
        ...props.style
      }}
    >
      {children}
    </GapBox>
  );
}
