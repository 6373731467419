import { useMe } from "@tara-wilson/mcc-lib";
import colors from "constants/colors";
import firebase from "firebase";
import { User } from "models/User";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import { toast } from "react-toastify";
import UnderlineButton from "shared/underline-button";

function PrepCredentialsModal(props: { onHide: any; show: any }) {
  const me = useMe<User>();
  const firestore = useFirestore();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  useEffect(() => {
    if (me.credentials && me.credentials.prep) {
      setEmail(me.credentials.prep.email);
      setPass(me.credentials.prep.password);
    }
  }, []);

  const clearCreds = () => {
    setEmail("");
    setPass("");
    let credentials = {
      ...me.credentials,
      prep: null,
    };

    const docRefConfig = {
      collection: "users",
      doc: me.id,
    };

    firestore.update(docRefConfig, { credentials: credentials });
    props.onHide();
  };

  const save = () => {
    let credentials = {
      ...me.credentials,
      prep: {
        email: email,
        password: pass,
        processing: true,
        approved: false,
      },
    };

    const docRefConfig = {
      collection: "users",
      doc: me.id,
    };

    firestore.update(docRefConfig, { credentials: credentials });

    let callable = firebase.functions().httpsCallable("addCredentialsToTable");

    try {
      callable({ email: email, pass: pass, domain: "Prep Baseball" }).then(
        (res) => {
          console.log("callable response", res);
        }
      );
    } catch (err) {
      console.log("err", err);
    }

    toast.dark(`Credentials processing... (this could take about 60 seconds)`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

    props.onHide();
  };
  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Prep Baseball Credentials
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        <input
          type="text"
          style={{
            marginTop: -10,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={email}
          placeholder="Prep Baseball Login Email"
          onChange={(evt) => setEmail(evt.target.value)}
        />

        <input
          type="password"
          style={{
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            border: "0px solid",
            color: "#fff",
            backgroundColor: "transparent",
            borderBottom: "2px solid #fff",
          }}
          value={pass}
          placeholder="Prep Baseball Login Password"
          onChange={(evt) => setPass(evt.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        {me && me.credentials && me.credentials.prep && (
          <div style={{ flex: 1, justifyContent: "flex-end" }}>
            <UnderlineButton text="Clear Credentials" onClick={clearCreds} />
          </div>
        )}
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Check Credentials
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrepCredentialsModal;
