import colors from "constants/colors";
import React, { useEffect, useState } from "react";

function TopLabelBox(props: {
  title: string;
  placeholder?: string;
  value: string;
  setValue: any;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 20,
      }}
    >
      <p>{props.title}</p>
      <input
        type="text"
        placeholder={props.placeholder}
        style={{
          backgroundColor: colors.white,
          borderRadius: 4,
          color: "black",
          borderStyle: "solid",
          padding: 8,
        }}
        value={props.value}
        onChange={(evt) => props.setValue(evt.target.value)}
      />
    </div>
  );
}

export default TopLabelBox;
