import { PlayerQuery } from "../models/Query";
import base64 from "base-64";
import { CONFERENCE_ITEMS } from "constants/conferences";
const url = `https://822d72960b3143a2aa8946b58375a7ef.us-east-1.aws.found.io:9243/players/_search?`;
const collegeUrl = `https://822d72960b3143a2aa8946b58375a7ef.us-east-1.aws.found.io:9243/college_players/_search?`;

export class QueryHelper {
  static getPlayerForExternalId(id: string) {
    if (id) {
      if (id.includes("cl_")) {
        let queryURL = `${collegeUrl}size=1&q=external_id:${id}`;
        return this.fetchDataForURLwithCount(queryURL);
      } else {
        let queryURL = `${url}size=1&q=external_id:${id}`;
        return this.fetchDataForURLwithCount(queryURL);
      }
    }
    return Promise.resolve(null);
  }

  static getCollegePlayerForExternalId(id: string) {
    let queryURL = `${collegeUrl}size=1&q=external_id:${id}`;
    return this.fetchDataForURLwithCount(queryURL);
  }

  static loadMoreForURL(
    url: string,
    length: number,
    sortKey: string,
    ascending: boolean,
    includeUncommited: boolean
  ) {
    let queryURL = url;
    if (sortKey) {
      queryURL = `${url}&sort=${sortKey}:${ascending ? "asc" : "desc"}`;
      // tara here
      if (sortKey == "college_commitment.keyword") {
        queryURL = `${url}${"+!college_commitment:"}Uncommited&sort=${sortKey}:${
          ascending ? "asc" : "desc"
        }`;
        if (includeUncommited) {
          queryURL = `${url}${"+college_commitment:"}Uncommited`;
        }
      }
    }
    queryURL = `${queryURL}&from=${length}`;

    console.log("load more", queryURL);
    return this.fetchDataForURLwithCount(queryURL);
  }

  static getAllForURL(url: string) {
    let queryURL = url.replace("size=20", "size=500");
    return this.fetchDataForURLwithCount(queryURL);
  }

  static loadedSortedForUrl(
    url: string,
    sortKey: string,
    ascending: boolean,
    includeUncommited: boolean
  ) {
    console.log("sortKey", sortKey);
    if (sortKey == "college_commitment.keyword") {
      let queryURL = `${url}${"+!college_commitment:"}Uncommited&sort=${sortKey}:${
        ascending ? "asc" : "desc"
      }`;
      if (includeUncommited) {
        queryURL = `${url}${"+college_commitment:"}Uncommited&sort=${sortKey}:${
          ascending ? "asc" : "desc"
        }`;
      }

      return this.fetchDataForURLwithCount(queryURL);
    } else {
      let queryURL = `${url}&sort=${sortKey}:${ascending ? "asc" : "desc"}`;
      return this.fetchDataForURLwithCount(queryURL);
    }
  }

  static urlForExactName(query: string) {
    return `${url}size=20&q=name:"${query}"`;
  }

  static queryEndForQuery(query: PlayerQuery) {
    let queryURL = ``;
    if (query.weight) {
      queryURL = `${queryURL}${QueryHelper.weightRange(
        query.weight.min,
        query.weight.max
      )}`;
    }
    if (query.height) {
      queryURL = `${queryURL}${"+"}${QueryHelper.heightRange(
        query.height.min,
        query.height.max
      )}`;
    }

    if (query.age) {
      queryURL = `${queryURL}${"+"}${QueryHelper.ageRange(
        query.age.min,
        query.age.max
      )}`;
    }

    if (query.sixtytime) {
      queryURL = `${queryURL}${"+"}${QueryHelper.sixtyRange(
        query.sixtytime.min,
        query.sixtytime.max
      )}`;
    }
    if (query.changeup) {
      queryURL = `${queryURL}${"+"}${QueryHelper.changeupRange(
        query.changeup.min,
        query.changeup.max
      )}`;
    }
    if (query.slider) {
      queryURL = `${queryURL}${"+"}${QueryHelper.sliderRange(
        query.slider.min,
        query.slider.max
      )}`;
    }

    if (query.statepositionranking) {
      queryURL = `${queryURL}${"+"}${QueryHelper.generalRange(
        "state_position_ranking",
        query.statepositionranking.min,
        query.statepositionranking.max
      )}`;
    }
    if (query.stateoverallranking) {
      queryURL = `${queryURL}${"+"}${QueryHelper.generalRange(
        "state_overall_ranking",
        query.stateoverallranking.min,
        query.stateoverallranking.max
      )}`;
    }
    if (query.nationalpositionranking) {
      queryURL = `${queryURL}${"+"}${QueryHelper.generalRange(
        "national_position_ranking",
        query.nationalpositionranking.min,
        query.nationalpositionranking.max
      )}`;
    }
    if (query.nationaloverallranking) {
      queryURL = `${queryURL}${"+"}${QueryHelper.generalRange(
        "national_overall_ranking",
        query.nationaloverallranking.min,
        query.nationaloverallranking.max
      )}`;
    }

    if (query.curveball) {
      queryURL = `${queryURL}${"+"}${QueryHelper.curveballRange(
        query.curveball.min,
        query.curveball.max
      )}`;
    }
    if (query.knuckleball) {
      queryURL = `${queryURL}${"+"}${QueryHelper.knuckleballRange(
        query.knuckleball.min,
        query.knuckleball.max
      )}`;
    }
    if (query.curveball) {
      queryURL = `${queryURL}${"+"}${QueryHelper.curveballRange(
        query.curveball.min,
        query.curveball.max
      )}`;
    }
    if (query.tenyardsplit) {
      queryURL = `${queryURL}${"+"}${QueryHelper.tenyardsplitRange(
        query.tenyardsplit.min,
        query.tenyardsplit.max
      )}`;
    }
    if (query.maxfastball) {
      queryURL = `${queryURL}${"+"}${QueryHelper.fastballRange(
        query.maxfastball.min,
        query.maxfastball.max
      )}`;
    }
    if (query.catcherpoptime) {
      queryURL = `${queryURL}${"+"}${QueryHelper.poptimeRange(
        query.catcherpoptime.min,
        query.catcherpoptime.max
      )}`;
    }
    if (query.exitvelocity) {
      queryURL = `${queryURL}${"+"}${QueryHelper.exitvelocityRange(
        query.exitvelocity.min,
        query.exitvelocity.max
      )}`;
    }
    if (query.positionvelocityc) {
      queryURL = `${queryURL}${"+"}${QueryHelper.PVCRange(
        query.positionvelocityc.min,
        query.positionvelocityc.max
      )}`;
    }
    if (query.positionvelocityif) {
      queryURL = `${queryURL}${"+"}${QueryHelper.PVIFRange(
        query.positionvelocityif.min,
        query.positionvelocityif.max
      )}`;
    }
    if (query.positionvelocityof) {
      queryURL = `${queryURL}${"+"}${QueryHelper.PVOFRange(
        query.positionvelocityof.min,
        query.positionvelocityof.max
      )}`;
    }

    if (query.graduatingclass) {
      queryURL = `${queryURL}${"+"}${QueryHelper.graduationRange(
        query.graduatingclass.min,
        query.graduatingclass.max
      )}`;
    }

    if (query.positions) {
      let posKey = query.asCollege ? "position:" : "primary_position:";
      if (query.positions.length === 1) {
        queryURL = `${queryURL}${`+${posKey}`}${query.positions[0]}`;
      } else {
        queryURL = `${queryURL}+(`;
        query.positions.forEach((state, index) => {
          if (index === query.positions.length - 1) {
            queryURL = `${queryURL}${posKey}${state}`;
          } else {
            queryURL = `${queryURL}${posKey}${state} OR `;
          }
        });
        queryURL = `${queryURL})`;
      }
    }

    if (query.conference) {
      queryURL = `${queryURL}${"+conference:"}${query.conference}`;
    }

    if (query.states && query.states.length > 0) {
      if (query.states.length === 1) {
        queryURL = `${queryURL}${"+state:"}${query.states[0]}`;
      } else {
        queryURL = `${queryURL}+(`;
        query.states.forEach((state, index) => {
          if (index === query.states.length - 1) {
            queryURL = `${queryURL}${"state:"}${state}`;
          } else {
            queryURL = `${queryURL}${"state:"}${state} OR `;
          }
        });
        queryURL = `${queryURL})`;
      }
    }

    if (query.uncommitted) {
      if (query.uncommitted === "Uncommitted") {
        queryURL = `${queryURL}${"+college_commitment:"}Uncommited`;
      } else {
        queryURL = `${queryURL}${"+!college_commitment:"}Uncommited`;
      }
    }
    if (query.commitment) {
      queryURL = `${queryURL}${"+college_commitment:"}${query.commitment}`;
    }
    if (query.summerTeam) {
      let team = query.summerTeam.replace("/", " ");
      queryURL = `${queryURL}${"+summer_team:"}${team}`;
    }
    if (query.bat) {
      queryURL = `${queryURL}${"+bats:"}${query.bat}`;
    }
    if (query.throw) {
      queryURL = `${queryURL}${"+throws:"}${query.throw}`;
    }

    // college
    if (query.university) {
      queryURL = `${queryURL}${"+university:"}${query.university}`;
    }

    if (query.classKey) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.classKey.min,
        query.classKey.max,
        "classKey"
      )}`;
    }
    if (query["2B"]) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query["2B"].min,
        query["2B"].max,
        "2B"
      )}`;
    }
    if (query["3B"]) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query["3B"].min,
        query["3B"].max,
        "3B"
      )}`;
    }

    if (query.AVG) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.AVG.min,
        query.AVG.max,
        "AVG"
      )}`;
    }
    if (query.RBI) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.RBI.min,
        query.RBI.max,
        "RBI"
      )}`;
    }

    if (query.BB) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.BB.min,
        query.BB.max,
        "BB"
      )}`;
    }
    if (query.OPS) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.OPS.min,
        query.OPS.max,
        "OPS"
      )}`;
    }
    if (query.HR) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.HR.min,
        query.HR.max,
        "HR"
      )}`;
    }
    if (query.H) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.H.min,
        query.H.max,
        "H"
      )}`;
    }
    if (query.HP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.HP.min,
        query.HP.max,
        "HP"
      )}`;
    }
    if (query.GP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.GP.min,
        query.GP.max,
        "GP"
      )}`;
    }
    if (query.AB) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.AB.min,
        query.AB.max,
        "AB"
      )}`;
    }
    if (query.SO) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.SO.min,
        query.SO.max,
        "SO"
      )}`;
    }
    if (query.CS) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.CS.min,
        query.CS.max,
        "CS"
      )}`;
    }
    if (query.R) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.R.min,
        query.R.max,
        "R"
      )}`;
    }

    if (query.SLG) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.SLG.min,
        query.SLG.max,
        "SLG"
      )}`;
    }
    if (query.OBP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.OBP.min,
        query.OBP.max,
        "OBP"
      )}`;
    }
    if (query.SB) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.SB.min,
        query.SB.max,
        "SB"
      )}`;
    }
    if (query.GS) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.GS.min,
        query.GS.max,
        "GS"
      )}`;
    }
    if (query.IP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.IP.min,
        query.IP.max,
        "IP"
      )}`;
    }
    if (query.APP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.APP.min,
        query.APP.max,
        "APP"
      )}`;
    }
    if (query.WP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.WP.min,
        query.WP.max,
        "WP"
      )}`;
    }
    if (query.CG) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.CG.min,
        query.CG.max,
        "CG"
      )}`;
    }
    if (query.L) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.L.min,
        query.L.max,
        "L"
      )}`;
    }
    if (query.SV) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.SV.min,
        query.SV.max,
        "SV"
      )}`;
    }

    if (query.OBA) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.OBA.min,
        query.OBA.max,
        "OBA"
      )}`;
    }
    if (query.ERA) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.ERA.min,
        query.ERA.max,
        "ERA"
      )}`;
    }
    if (query.W) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.W.min,
        query.W.max,
        "W"
      )}`;
    }
    if (query.ER) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.ER.min,
        query.ER.max,
        "ER"
      )}`;
    }
    if (query.SHO) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.SHO.min,
        query.SHO.max,
        "SHO"
      )}`;
    }

    if (query.HBP) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.HBP.min,
        query.HBP.max,
        "HBP"
      )}`;
    }

    if (query.PA) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.PA.min,
        query.PA.max,
        "PA"
      )}`;
    }

    if (query.K) {
      queryURL = `${queryURL}${"+"}${QueryHelper.collegeRange(
        query.K.min,
        query.K.max,
        "K"
      )}`;
    }

    return queryURL;
  }

  static urlForQuery(query: PlayerQuery) {
    let startUrl = query.asCollege ? collegeUrl : url;
    let queryURL = "";

    queryURL = `${startUrl}size=20&default_operator=AND&q=${this.queryEndForQuery(
      query
    )}`;

    return queryURL;
  }

  static urlForNameAutocomplete(name: string, key: string, college: boolean) {
    let startUrl = college ? collegeUrl : url;
    let names = name.split(" ");
    if (key === "name") {
      let nameString = `${startUrl}size=20&q=${key}:${name}*`;
      if (names.length > 1) {
        nameString = `${nameString}+last_name:${names[1]}*`;
      }
      return nameString;
    } else {
      let nameString = `${startUrl}size=20&q=${key}:${names[0]}*`;
      if (names.length > 1) {
        nameString = `${nameString}+${key}:*${names[1]}*`;
      }
      return nameString;
    }
  }

  static heightRange(min: number, max: number) {
    return `calculated_height:[${min} TO ${max}]`;
  }

  static weightRange(min: number, max: number) {
    return `weight:[${min} TO ${max}]`;
  }

  static ageRange(min: number, max: number) {
    return `age:[${min} TO ${max}]`;
  }

  static graduationRange(min: number, max: number) {
    return `graduating_class:[${min} TO ${max}]`;
  }

  static sixtyRange(min: number, max: number) {
    return `sixty_yard:[${min} TO ${max}]`;
  }

  static generalRange(key: string, min: number, max: number) {
    return `${key}:[${min} TO ${max}]`;
  }
  static curveballRange(min: number, max: number) {
    return `curveRangeMin:[${min} TO ${max}]+curveRangeMax:[${min} TO ${max}]`;
  }

  static changeupRange(min: number, max: number) {
    return `chRangeMin:[${min} TO ${max}]+chRangeMax:[${min} TO ${max}]`;
  }

  static cutterRange(min: number, max: number) {
    return `cutterRangeMin:[${min} TO ${max}]+cutterRangeMax:[${min} TO ${max}]`;
  }

  static knuckleballRange(min: number, max: number) {
    return `knRangeMin:[${min} TO ${max}]+knRangeMax:[${min} TO ${max}]`;
  }

  static sliderRange(min: number, max: number) {
    return `slideRangeMin:[${min} TO ${max}]+slideRangeMax:[${min} TO ${max}]`;
  }

  static tenyardsplitRange(min: number, max: number) {
    return `ten_yard:[${min} TO ${max}]`;
  }

  static poptimeRange(min: number, max: number) {
    return `poptime:[${min} TO ${max}]`;
  }

  static fastballRange(min: number, max: number) {
    return `fbRangeMin:[${min} TO ${max}]+fbRangeMax:[${min} TO ${max}]`;
  }
  static exitvelocityRange(min: number, max: number) {
    return `exitvelo:[${min} TO ${max}]`;
  }
  static PVCRange(min: number, max: number) {
    return `cvelo:[${min} TO ${max}]`;
  }
  static PVIFRange(min: number, max: number) {
    return `infvelo:[${min} TO ${max}]`;
  }
  static PVOFRange(min: number, max: number) {
    return `ofvelo:[${min} TO ${max}]`;
  }

  static collegeRange(min: number, max: number, key: string) {
    return `${key}:[${min} TO ${max}]`;
  }

  // fetch utils

  static getParams = () => {
    var headers = new Headers();
    headers.append(
      "Authorization",
      "Basic " + base64.encode("elastic:sbiAbeKXR7WeGjL7YMmKXduB")
    );
    return {
      method: "GET",
      headers: headers,
    };
  };

  static fetchDataForURLwithCount(url: string) {
    //   tara later clean up your proxy
    let base = `https://tara-proxy.herokuapp.com`;
    if (!url.includes("size=1")) {
      console.log(`${base}/${url}`);
    }

    return fetch(`${base}/${url}`, QueryHelper.getParams())
      .then((response) => response.json())
      .then((json) => {
        if (json.hits) {
          let data = json.hits.hits.map((item) => ({
            ...item._source,
            score: item._score,
          }));
          return { data: data, count: json.hits.total.value };
        } else {
          return { data: [], count: 0 };
        }
      });
  }
}
