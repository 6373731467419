/*****************************************************************************
 * Import
 *****************************************************************************/
import React from "react";
import styled, { css } from "styled-components";
import {
  BoxStyleProps,
  boxStyles,
} from "./styled";

/*****************************************************************************
 * Styled
 *****************************************************************************/

export const Box = styled.div<BoxStyleProps>`
  ${boxStyles}
`;

export const Circle = ({ radius, ...rest }: { radius: React.CSSProperties["width"] } & BoxStyleProps) => {
  return (
    <Box w={radius} h={radius} radius={radius} {...rest} />
  );
}

export const Stack = styled(Box)`
  flex-direction: column;
`

export const Row = styled(Box)`
  flex-direction: row;
`
