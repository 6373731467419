import React from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FriendName from "popovers/friend-name";
import { Button } from "react-bootstrap";

import CreateRequestModal from "../modals/create-request-modal";
import { ScoutNotification } from "models/ScoutNotification";
import colors from "constants/colors";
import ScoutTitle from "./scout-title";
import CTALink from "./cta-link";

function SidebarFriends(props: any) {
  const profile = useSelector((state) => state.firebase.profile);
  const userId = useSelector((state) => state.firebase.auth.uid);
  const users = useSelector((state) => state.firestore.data.users);
  const pendingRequests = useSelector(
    (state) => state.firestore.data.pendingRequests
  );
  useFirestoreConnect([
    { collection: "users", where: ["friends", "array-contains", userId] },
    {
      collection: "notifications",
      where: [
        ["category", "==", "friends"],
        ["sender", "==", userId],
        ["removed", "==", false],
      ],
      storeAs: `pendingRequests`,
    },
  ]);
  const [modalShow, setModalShow] = React.useState(false);

  const sortedFriends = () => {
    if (!isLoaded(users)) {
      return [];
    }

    let friends = users
      ? Object.keys(users).map((key) =>
          users ? { ...users[key], id: key } : null
        )
      : [];

    return friends.filter((item) => item !== null).slice(0, 5);
  };

  const pending = (): ScoutNotification[] => {
    if (!isLoaded(pendingRequests)) {
      return [];
    }

    let pending = pendingRequests
      ? Object.keys(pendingRequests).map((key) =>
          pendingRequests ? { ...pendingRequests[key], id: key } : null
        )
      : [];

    return pending.filter(
      (item) =>
        item !== null &&
        item.recipientName !== null &&
        !item.removed &&
        !(profile.friends || []).includes(item.recipient)
    );
  };

  return props.hideFriends ? (
    <div />
  ) : (
    <div style={{ backgroundColor: "white" }}>
      <ScoutTitle
        text="Friends"
        withPlus={true}
        onClick={() => setModalShow(true)}
      />

      {(sortedFriends() || []).length > 0 ? (
        <div
          style={{
            flex: 1,
            backgroundColor: colors.white,
            padding: "1rem",
            paddingBottom: 0,
            borderRadius: 6,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(sortedFriends() || []).map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FriendName
                  friend={item}
                  left={true}
                  hover={true}
                  blueAvatar={true}
                  containerStyles={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            );
          })}
          <CTALink
            styles={{ marginBottom: 12, marginTop: 12 }}
            linkTo={`/settings`}
            text={"VIEW ALL"}
          />
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            minHeight: 80,
            backgroundColor: colors.white,
            padding: "1rem",
            borderRadius: 6,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontStyle: "italic", opacity: 0.8 }}>
            No friends added yet.
          </p>
        </div>
      )}

      <CreateRequestModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        friends={sortedFriends()}
        me={{ ...profile, id: userId }}
        pendingRequests={pending()}
      />
    </div>
  );
}

export default SidebarFriends;
