import colors from "constants/colors";
import React, { useEffect, useState } from "react";

function OneBoxLineWithComments(props: {
  title: string;
  value: {
    value: string;
    comments: string;
  };
  setValue: any;
}) {
  const colorForValue = (value: string) => {
    if (props.title && props.title !== "") {
      if (value) {
        switch (parseInt(value)) {
          case 2:
            return "#4A3D3D";
          case 3:
            return "#DB2330";
          case 4:
            return "#DB8E33";
          case 5:
            return "#80B304";
          case 6:
            return "#6690FF";
          case 7:
            return "#F5EE48";
          case 8:
            return "rgba(0, 0, 0, 0.4)";
          default:
            return colors.transparentContrast5;
        }
      }
      return colors.transparentContrast5;
    }
    return colors.transparentContrast5;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 20,
      }}
    >
      <div className="report-row">
        <p style={{ width: 150, alignSelf: "center" }}>{props.title}</p>
        <input
          type="text"
          style={{
            fontWeight: 800,
            textAlign: "center",
            marginRight: 10,
            marginLeft: 40,
            borderWidth: 2,
            borderStyle: "solid",
            backgroundColor: colors.white,
            borderRadius: 4,
            color: colorForValue(props.value ? props.value.value : null),
            borderColor: colorForValue(props.value ? props.value.value : null),
            borderTopColor: colorForValue(
              props.value ? props.value.value : null
            ),
            padding: 8,
          }}
          value={props.value ? props.value.value : ""}
          onChange={(evt) =>
            props.setValue({ ...props.value, value: evt.target.value })
          }
        />
      </div>

      <textarea
        placeholder={"Comments..."}
        style={{
          backgroundColor: colors.white,
          borderRadius: 4,
          color: "black",
          padding: 8,
        }}
        value={props.value ? props.value.comments : ""}
        onChange={(evt) =>
          props.setValue({ ...props.value, comments: evt.target.value })
        }
      />
    </div>
  );
}

export default OneBoxLineWithComments;
