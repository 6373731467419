import React, { useEffect, useState } from "react";
import colors from "constants/colors";

import LabelDescription from "../components-pdf/pdf-label-description";
import OneBoxRow from "../components-pdf/pdf-one-box-row";
import OneBoxRowComments from "../components-pdf/pdf-one-box-row-comments";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import SixBoxRow from "../components-pdf/pdf-six-box-row";
import PDFThreeBoxRow from "../components-pdf/pdf-three-box-row";
import PDFImagesList from "../components-pdf/pdf-images-list";
import ProReportTop from "./pro-report-top";

function PDFPitcherReport({ posReport, fullScreen }) {
  const styles = StyleSheet.create({
    reportRow: {
      flexDirection: "row",
      display: "flex",
      flex: 1,
      marginBottom: 20,
    },
    reportCol: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
      marginBottom: 20,
    },
  });

  useEffect(() => {
    Font.register({
      family: "Raleway",
      fonts: [
        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf",
          fontWeight: "bold",
        },

        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf",
          fontWeight: "normal",
          fontStyle: "normal",
        },

        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf",
          fontStyle: "italic",
        },
      ],
    });
    Font.register({
      family: "RalewayBold",
      fonts: [
        {
          src:
            "https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf",
          fontWeight: 800,
          fontStyle: "normal",
        },
      ],
    });
  }, []);

  const MyDocument = () => (
    <Document>
      <Page
        size="A4"
        style={{
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
          marginLeft: 10,
          paddingRight: 30,
          marginTop: 10,
          marginRight: 10,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: "RalewayBold",
            textDecoration: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: colors.accent,
            color: colors.accent,
            fontWeight: 800,
          }}
        >
          {posReport.playerName}
        </Text>
        <Text style={{ marginBottom: 20, fontSize: 10 }}>
          Report
          {posReport.date
            ? moment(new Date(posReport.date.seconds * 1000)).format(
                " MM/DD/YYYY"
              )
            : ""}
        </Text>

        <ProReportTop posReport={posReport} />

        <View style={{ marginTop: 30 }}>
          <OneBoxRowComments
            label="Arm Angle"
            value={posReport.armAngle}
            comments={""}
          />
        </View>

        <OneBoxRowComments
          label="Delivery"
          value={posReport.delivery.value}
          comments={posReport.delivery.comments}
        />

        <OneBoxRowComments
          label="Arm Action"
          value={posReport.armAction.value}
          comments={posReport.armAction.comments}
        />

        <View
          style={{
            ...styles.reportRow,
            height: 50,
            maxHeight: 50,
            marginBottom: 0,
          }}
        >
          <LabelDescription
            label="Physical Description"
            value={posReport.physicalDescription}
          />
        </View>
        <View
          style={{
            ...styles.reportRow,
            height: 50,
            marginBottom: 20,
            maxHeight: 50,
          }}
        >
          <LabelDescription
            label="Player Comparison"
            value={posReport.playerComparison}
          />
        </View>

        <SixBoxRow
          label={""}
          p={"P"}
          f={"F"}
          adj={"Adj"}
          h={"H"}
          l={"L"}
          a={"A"}
          comments={""}
        />
        <SixBoxRow
          label={"FB"}
          p={posReport.fB.p}
          f={posReport.fB.f}
          adj={posReport.fB.adj}
          h={posReport.fB.h}
          l={posReport.fB.l}
          a={posReport.fB.a}
          comments={posReport.fB.comments}
        />

        <SixBoxRow
          label={"FBMVMT"}
          p={posReport.fBMVMT.p}
          f={posReport.fBMVMT.f}
          adj={posReport.fBMVMT.adj}
          h={posReport.fBMVMT.h}
          l={posReport.fBMVMT.l}
          a={posReport.fBMVMT.a}
          comments={posReport.fBMVMT.comments}
        />
        <SixBoxRow
          label={"FB Stretch"}
          p={posReport.fBStretch.p}
          f={posReport.fBStretch.f}
          adj={posReport.fBStretch.adj}
          h={posReport.fBStretch.h}
          l={posReport.fBStretch.l}
          a={posReport.fBStretch.a}
          comments={posReport.fBStretch.comments}
        />
        <SixBoxRow
          label={"SL"}
          p={posReport.sL.p}
          f={posReport.fBStretch.f}
          adj={posReport.fBStretch.adj}
          h={posReport.fBStretch.h}
          l={posReport.fBStretch.l}
          a={posReport.fBStretch.a}
          comments={posReport.fBStretch.comments}
        />
        <SixBoxRow
          label={"CB"}
          p={posReport.cB.p}
          f={posReport.cB.f}
          adj={posReport.cB.adj}
          h={posReport.cB.h}
          l={posReport.cB.l}
          a={posReport.cB.a}
          comments={posReport.cB.comments}
        />
        <SixBoxRow
          label={"CH"}
          p={posReport.cH.p}
          f={posReport.cH.f}
          adj={posReport.cH.adj}
          h={posReport.cH.h}
          l={posReport.cH.l}
          a={posReport.cH.a}
          comments={posReport.cH.comments}
        />
        <View style={{ flex: 1 }} />
      </Page>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
        }}
      >
        <SixBoxRow
          label={"Control"}
          p={posReport.control.p}
          f={posReport.control.f}
          adj={posReport.control.adj}
          h={posReport.control.h}
          l={posReport.control.l}
          a={posReport.control.a}
          comments={posReport.control.comments}
        />
        <SixBoxRow
          label={"Command"}
          p={posReport.command.p}
          f={posReport.command.f}
          adj={posReport.command.adj}
          h={posReport.command.h}
          l={posReport.command.l}
          a={posReport.command.a}
          comments={posReport.command.comments}
        />
        <SixBoxRow
          label={"Can He Pitch"}
          p={posReport.canHePitch.p}
          f={posReport.canHePitch.f}
          adj={posReport.canHePitch.adj}
          h={posReport.canHePitch.h}
          l={posReport.canHePitch.l}
          a={posReport.canHePitch.a}
          comments={posReport.canHePitch.comments}
        />
        <SixBoxRow
          label={"OFP"}
          p={posReport.oFP.p}
          f={posReport.oFP.f}
          adj={posReport.oFP.adj}
          h={posReport.oFP.h}
          l={posReport.oFP.l}
          a={posReport.oFP.a}
          comments={posReport.oFP.comments}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View
            style={{
              marginTop: 20,
              borderTopColor: colors.accent,
              borderTopWidth: 1,
              borderTopStyle: "solid",
              width: "48%",
              marginRight: 10,
            }}
          >
            <OneBoxRow
              label="Aggressiveness"
              value={posReport.aggressiveness}
            />
            <OneBoxRow label="Athleticism" value={posReport.athleticism} />
            <OneBoxRow label="Aptitude" value={posReport.aptitude} />
            <OneBoxRow label="Poise" value={posReport.poise} />
            <OneBoxRow label="Baseball IQ" value={posReport.baseballIQ} />
          </View>

          <View
            style={{
              marginTop: 20,
              borderTopColor: colors.accent,
              borderTopWidth: 1,
              borderTopStyle: "solid",
              width: "48%",
            }}
          >
            <OneBoxRow label="Fielding" value={posReport.fielding} />
            <OneBoxRow label="Deception" value={posReport.deception} />
            <OneBoxRow label="Release Times" value={posReport.releaseTimes} />
            <OneBoxRow label="Outpitch" value={posReport.outpitch} />
            <View
              style={{
                width: 2,
                height: 18,
              }}
            />
          </View>
        </View>
        <View
          style={{
            marginTop: 20,
            marginBottom: 20,
            borderTopColor: colors.accent,
            borderTopWidth: 1,
            borderTopStyle: "solid",
          }}
        >
          <OneBoxRow label="Character" value={posReport.character} />
          <OneBoxRow label="Entry Level" value={posReport.entryLevel} />
          <OneBoxRow label="ETA" value={posReport.eTA} />
        </View>
        <OneBoxRowComments
          label="How Well Known"
          value={posReport.howWellKnown.value}
          comments={posReport.howWellKnown.comments}
        />
        <OneBoxRowComments
          label="How Well Seen"
          value={posReport.howWellSeen.value}
          comments={posReport.howWellSeen.comments}
        />
        <OneBoxRowComments
          label="Signablility"
          value={posReport.signability.value}
          comments={posReport.signability.comments}
        />
      </Page>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFF",
          padding: 20,
        }}
      >
        <LabelDescription label="Summary" value={posReport.summary} />

        <PDFImagesList posReport={posReport} fancyText={false} />
        <View style={{ flex: 1 }} />
      </Page>
    </Document>
  );

  return (
    <PDFViewer
      style={
        fullScreen
          ? { minWidth: "100vw", minHeight: "100vh" }
          : {
              minWidth: "70vw",
              minHeight: "90vh",
            }
      }
    >
      <MyDocument />
    </PDFViewer>
  );
}

export default PDFPitcherReport;
